import Table from './benutzer/Table'
import Form from './benutzer/Form'

const Benutzer = () => {
  return (
    <div className="container">
      <Table />
      <Form />
  </div>
  );
}

export default Benutzer
