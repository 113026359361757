import React from "react";
import { LuLogOut } from "react-icons/lu";
import useAuthCall from "../../../../hooks/useAuthCall";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { formElements } from "./formElements";

const PersonalBogenForm = ({
  initialValue,
  formData,
  handleSubmit,
  validate,
}) => {
  const { logout } = useAuthCall();
  const handleLogout = () => {
    logout();
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formData || initialValue}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validationSchema={validate}
    >
      {({}) => (
        <Form>
          <div className="row mb-3">
            {formElements.map((field, index) => (
              <div key={index} className="col-12 col-md-6 mb-2">
                <label htmlFor={field.name} className="form-label fw-semibold">
                  {field.label}
                </label>
                {field.type === "select" ? (
                  <Field as="select" name={field.name} className="form-select">
                    {field.options.map((option, idx) => (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    ))}
                  </Field>
                ) : field.type === "checkbox" ? (
                  <div>
                    {field.options.map((option, idx) => (
                      <label key={idx} className="form-check form-check-inline">
                        <Field
                          type="checkbox"
                          name={field.name}
                          value={option}
                          className="form-check-input"
                        />
                        {option}
                      </label>
                    ))}
                  </div>
                ) : field.type === "textarea" ? (
                  <Field
                    as="textarea"
                    name={field.name}
                    className="form-control"
                    placeholder={field.placeholder}
                  />
                ) : field.type === "radio" ? (
                  <div>
                    {field.options.map((option, idx) => (
                      <label key={idx} className="form-check form-check-inline">
                        <Field
                          type="radio"
                          name={field.name}
                          value={option}
                          className="form-check-input"
                        />
                        {option}
                      </label>
                    ))}
                  </div>
                ) : (
                  <Field
                    type={field.type}
                    name={field.name}
                    className="form-control"
                    placeholder={field.placeholder}
                  />
                )}
                <ErrorMessage
                  name={field.name}
                  component="div"
                  className="text-danger"
                  style={{ fontSize: "0.8rem" }}
                />
              </div>
            ))}
          </div>

          <div className="row d-flex justify-content-between align-items-center">
            <div className="col">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ width: "120px" }}
              >
                Speichern
              </button>
            </div>
            <div className="col  text-primary text-end">
              <LuLogOut onClick={handleLogout} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default PersonalBogenForm;
