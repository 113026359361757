import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import FahrzeugTable from "./FahrzeugTable";
import FahrzeugForm from "./FahrzeugForm";

const Fahrzeug = () => {
  const initialFormValues = {
    kfzmarke: "",
    kfzmodel: "",
    kfzerstzulassung: "",
    kfzkennzeichen: "",
    kfzneupreis: "",
    kfzkaufpreis: "",
    kfztuvdatum: "",
    kfzuvvdatum: "",
    kfzschlusselnr: "",
    kfzschlusselnrtsn: "",
    kfzidentifizierungsnummer: "",
    kfzfahrzeugkilometer: "",
    kfzfahrzeuginspektionkm: "",
    kfzfahrzeugreifenkm: "",
    kfzleistungkw: "",
    kfzhubraum: "",
    kfzkraftstoff: "",
    kfznutzungsstatus: "",
    kfzversicherungsname: "",
    kfzversicherungsnr: "",
    kfzreifen: "",
    kfzanschaffungsart: "",
    kfzvertragsbeginn: "",
    kfzvertragsende: "",
    kfzmonkosten: "",
    kfznotiz: "",
    kfzstatus: "AKTIV",
  };

  const [formData, setFormData] = useState(initialFormValues);
  const [submittedData, setSubmittedData] = useState([]);

  const inputFocus = useRef(null);
  const { token } = useSelector((state) => state.auth);
  const apiUrl = "https://dev.zone-edv.de/api";

  const [warningMessage, setWarningMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${apiUrl}/fahrzeuges?access_token=${token}`
        );

        const modifiedData = data.map((item) => ({
          ...item,
          kfzerstzulassung: item.kfzerstzulassung?.split("T")[0],
          kfzvertragsbeginn: item.kfzvertragsbeginn?.split("T")[0],
          kfzvertragsende: item.kfzvertragsende?.split("T")[0],
        }));

        setSubmittedData(modifiedData);
        console.log("Data was successfully fetched");
      } catch (error) {
        console.error("Data getting error", error);
      }
    };
    fetchData();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mieteForm = {
    kfzstatus: formData.kfzstatus,
    kfznutzungsstatus: formData.kfznutzungsstatus,
    kfzmarke: formData.kfzmarke,
    kfzmodel: formData.kfzmodel,
    kfzkennzeichen: formData.kfzkennzeichen,
    kfzschlusselnr: formData.kfzschlusselnr,
    kfzschlusselnrtsn: formData.kfzschlusselnrtsn,
    kfzidentifizierungsnummer: formData.kfzidentifizierungsnummer,
    kfzfahrzeugkilometer: formData.kfzfahrzeugkilometer,
    kfzkraftstoff: formData.kfzkraftstoff,
    kfzanschaffungsart: formData.kfzanschaffungsart,
    kfzvertragsbeginn: formData.kfzvertragsbeginn,
    kfzvertragsende: formData.kfzvertragsende,
  };

  //? Shortcut of the above code

  const { kfzvertragsbeginn, kfzvertragsende, ...eigentumForm } = formData;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isMieteSelected) {
      for (const key in mieteForm) {
        if (mieteForm[key] === "string" && mieteForm[key].trim() === "") {
          setWarningMessage("Bitte füllen Sie alle Felder aus!");

          return;
        }
      }
    }
    if (isEigentumSelected) {
      for (const key in eigentumForm) {
        if (eigentumForm[key] === "string" && eigentumForm[key].trim() === "") {
          setWarningMessage("Bitte füllen Sie alle Felder aus!");

          return;
        }
      }
    }

    if (!isMieteSelected && !isEigentumSelected) {
      for (const key in formData) {
        if (formData[key] === "string" && formData[key].trim() === "") {
          setWarningMessage("Bitte füllen Sie alle Felder aus!");

          return;
        }
      }
    }

    try {
      if (formData.id) {
        // PUT request for existing resource
        const { data: dataFromApi } = await axios.put(
          `${apiUrl}/fahrzeuges/${formData.id}?access_token=${token}`,
          isMieteSelected ? mieteForm : formData
        );

        const updatedData = submittedData.map((data) =>
          data.id === formData.id ? dataFromApi : data
        );
        setSubmittedData(updatedData);
      } else {
        // POST
        const { data: apiFromData } = await axios.post(
          `${apiUrl}/fahrzeuges?access_token=${token}`,
          isMieteSelected ? mieteForm : formData || isEigentumSelected
        );
        setSubmittedData((prevData) => [...prevData, apiFromData]);
      }
      setFormData(initialFormValues);
    } catch (error) {
      console.error("API Hatası:", error);
    }
  };

  const isMieteSelected = formData.kfzanschaffungsart === "miete";
  const isEigentumSelected = formData.kfzanschaffungsart === "eigentum";

  useEffect(() => {
    if (isMieteSelected || isEigentumSelected) {
      document.querySelectorAll("input[disabled]").forEach((input) => {
        input.value = "";
      });
    }
    if (isMieteSelected) {
      document.querySelectorAll("textarea[disabled]").forEach((textarea) => {
        textarea.value = "";
      });
    }
  }, [isMieteSelected, isEigentumSelected]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiUrl}/fahrzeuges/${id}?access_token=${token}`);

      const updatedList = submittedData.filter((data) => data.id !== id);
      setSubmittedData(updatedList);
    } catch (error) {
      console.error("API Hatası:", error);
    }
  };

  //*****************************************************************************

  // Edit

  const handleEdit = (id) => {
    inputFocus.current.focus();

    const selectedData = submittedData.find((data) => data.id === id);


    setFormData({
      id,

      ...selectedData,
      kfzerstzulassung: selectedData.kfzerstzulassung?.split("T")[0],
      kfzvertragsbeginn: selectedData.kfzvertragsbeginn?.split("T")[0],
      kfzvertragsende: selectedData.kfzvertragsende?.split("T")[0],
    });
  };

  const handleCancel = () => {
    setFormData(initialFormValues);
  };

  return (
    <div className="container">
      <FahrzeugTable
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        submittedData={submittedData}
      />
      <FahrzeugForm
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        inputFocus={inputFocus}
        warningMessage={warningMessage}
        setWarningMessage={setWarningMessage}
        isMieteSelected={isMieteSelected}
        isEigentumSelected={isEigentumSelected}
        handleCancel={handleCancel}
      />
    </div>
  );
};

export default Fahrzeug;
