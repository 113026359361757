import { createSlice } from '@reduxjs/toolkit';
import { optionApi } from './optionApi';


export const optionSlice = createSlice({
  name: 'option',
  initialState: {
    optionData: [],
    option: {},
    //  uniqueOptionList: [],
  },
  reducers: {
    optionForEdit: (state, action) => {
      state.option = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(optionApi.endpoints.getOptions.matchFulfilled, (state, action) => {
      console.log('action.payload  ?????', action.payload);
      state.optionData = action.payload;
    });

    // builder.addMatcher(optionApi.endpoints.fetchUsers.matchFulfilled, (state, action) => {
    //   state.uniqueOptionList = action.payload;
    // });
  },
});
export const { optionForEdit } = optionSlice.actions;

export default optionSlice.reducer;