import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const AbholungsInfoModal = ({ setAbholungsInfoModal, sortingData }) => {
  const { token, user } = useSelector((state) => state.auth);
  const url = process.env.REACT_APP_BASE_URL;

  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  console.log("formattedDate", formattedDate);

  const initialValue = {
    abholdatum: formattedDate,
    endzeit: "",
    werknotiz: "",
  };

  const [formData, setFormData] = useState(initialValue);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const submittedData = {
        ...sortingData[0],
        status: "in Arbeit",
        fahrer: `${user.id}`,
        abholdatum: formData.abholdatum
          ? formData.abholdatum
          : sortingData[0].abholdatum,
        abholzeit: formData.abholzeit
          ? formData.abholzeit
          : sortingData[0].abholzeit,
        werknotiz: formData.werknotiz
          ? formData.werknotiz
          : sortingData[0].werknotiz,
      };
      await axios.put(
        `${url}werkstatts/${sortingData[0].id}?access_token=${token}`,
        submittedData,
        {}
      );
      setAbholungsInfoModal(false);
    } catch (error) {
      console.error("Veri güncelleme hatası:", error);
    }
  };

  return (
    <div>
      <Modal show={true} size="md" centered className="text-center">
        <Modal.Body className="bg-light">
          <p className="fw-semibold ">
            Bitte geben Sie das Datum und die Uhrzeit ein, zu der das Fahrzeug
            in der Werkstatt abgeholt werden soll.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="row d-flex justify-content-center mb-2">
              <div className="col-12 col-sm-6">
                <label htmlFor="date" className="fw-semibold">
                  Datum
                </label>
                <input
                  id="date"
                  name="abholdatum"
                  value={formData.abholdatum}
                  type="date"
                  onChange={handleChange}
                  className="form-control"
                />
              </div>

              <div className="col-12 col-sm-6">
                <label htmlFor="time" className="fw-semibold">
                  Uhrzeit
                </label>

                <input
                  id="time"
                  name="abholzeit"
                  value={formData.abholzeit}
                  type="time"
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <div>
              <label htmlFor="notiz" className="fw-semibold">
                Notiz
              </label>
              <textarea
                id="notiz"
                name="werknotiz"
                className="form-control"
                value={formData.werknotiz}
                onChange={handleChange}
                rows="3"
              ></textarea>
            </div>

            <button type="submit" className="btn btn-sm btn-primary mt-3">
              Speichern
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AbholungsInfoModal;
