import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const AuthRouter = () => {
  const { userRole, user } = useSelector((state) => state.auth);
  const location = useLocation();

  return userRole === "ADMIN" ? (
    <Navigate to="/" state={{ from: location }} />
  ) : userRole === "FAHRER" ? (
    <Navigate to={`tagestourmodal/${user.id}`} state={{ from: location }} />
  ) : userRole === "GAST" ? (
    <Navigate to={`personalbogen`} state={{ from: location }} />
  ) : (
    <Outlet />
  );
};
