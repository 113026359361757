import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";

const FahrzeugTable = ({ handleDelete, handleEdit, submittedData }) => {
  const columns = [
    { field: "kfzstatus", headerName: "Status", width: 200 },
    { field: "kfznutzungsstatus", headerName: "Nutzungsstatus", width: 200 },
    { field: "kfzmarke", headerName: "Marke", width: 200 },
    { field: "kfzmodel", headerName: "Model", width: 200 },
    { field: "kfzerstzulassung", headerName: "Erstzulassung", width: 200 },
    { field: "kfzkennzeichen", headerName: "Kennzeichen", width: 200 },
    { field: "kfzneupreis", headerName: "Neupreis", width: 200 },
    { field: "kfzkaufpreis", headerName: "Kaufpreis", width: 200 },
    { field: "kfztuvdatum", headerName: "TÜV Datum", width: 200 },
    { field: "kfzuvvdatum", headerName: "UVV Datum", width: 200 },
    { field: "kfzschlusselnr", headerName: "Schlüsselnummer HSN", width: 200 },
    {
      field: "kfzschlusselnrtsn",
      headerName: "Schlüsselnummer TSN",
      width: 200,
    },
    {
      field: "kfzidentifizierungsnummer",
      headerName: "Identifikationsnummer",
      width: 200,
    },
    {
      field: "kfzfahrzeugkilometer",
      headerName: "Kilometerstand",
      width: 200,
    },
    {
      field: "kfzfahrzeuginspektionkm",
      headerName: "Inspektionsleistung",
      width: 200,
    },
    {
      field: "kfzfahrzeugreifenkm",
      headerName: "Reifenleistung",
      width: 200,
    },
    { field: "kfzreifen", headerName: "Reifengröße", width: 200 },
    { field: "kfzleistungkw", headerName: "Leistung", width: 200 },
    { field: "kfzhubraum", headerName: "Hubraum", width: 200 },
    { field: "kfzkraftstoff", headerName: "Kraftstoff", width: 200 },
    {
      field: "kfzversicherungsname",
      headerName: "Versicherungsname",
      width: 200,
    },
    {
      field: "kfzversicherungsnr",
      headerName: "Versicherungsnummer",
      width: 200,
    },
    {
      field: "kfzanschaffungsart",
      headerName: "Anschaffungsart",
      width: 200,
    },
    {
      field: "kfzvertragsbeginn",
      headerName: "Vertragsbeginn",
      width: 200,
    },
    { field: "kfzvertragsende", headerName: "Vertragsende", width: 200 },
    { field: "kfzmonkosten", headerName: "Monatskosten", width: 200 },
    { field: "kfznotiz", headerName: "Notiz", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <div className="d-flex justify-content-center align-items-center gap-3">
          <FaEdit
            className="text-primary"
            onClick={() => handleEdit(params.row.id)}
          />
          <RiDeleteBin6Fill
            className="text-danger"
            onClick={() => handleDelete(params.row.id)}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="card mb-4 mt-2">
        {/* HEADER */}
        <div className="card-header text-white bg-primary">
          Fahrzeug Übersicht
        </div>

        {/* DEFAULT BUTTONS */}
        <div className="card-body">
          <div>
            <DataGrid
              rows={submittedData}
              columns={columns}
              style={{ maxHeight: 400, width: "100%", overflowX: "auto" }}
              GridToolbar
              hideFooterPagination
              components={{
                Toolbar: () => (
                  <div className="d-flex align-items-center">
                    <GridToolbar />
                  </div>
                ),
              }}
              density="compact"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FahrzeugTable;
