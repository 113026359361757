import AgbModal from "../../components/modals/AgbModal";
import DatenschutzModal from "../../components/modals/DatenschutzModal";
import {
  useAddBewerberMutation,
  useGetBewerberQuery,
} from "../../app/services/register/registerApi";
import useAuthCall from "../../hooks/useAuthCall";

import ErfolgreicheAntragstellung from "./ErfolgreicheAntragstellung";


import { useSelector } from "react-redux";
import { validationRules } from "./bewerberValidation";
import { LuLogOut } from "react-icons/lu";
import  "./style.css";
import Select from 'react-select';
import { useRef, useState } from "react";

const Bewerbungsbogen = () => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#999' : 'black',
      fontStyle: state.isDisabled ? 'italic' : 'normal',
      opacity: state.isDisabled ? 0.7 : 1,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#999' : 'black',
    }),
  };
  const options = [
    { value: '', label: 'Wählen Sie eine Option aus', isDisabled: true },
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'D', label: 'D' },
  ];
  
  const currentDate = new Date();
  const formattedDate =
    currentDate.getFullYear() +
    "-" +
    (currentDate.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    currentDate.getDate().toString().padStart(2, "0");


    // useEffect(() => {
    //   const option = selectRef.current.querySelector('.optioncolor');
    //   if (option) {
    //     option.style.color = '#e2e2e2';  // Gri renk
    //     option.style.fontStyle = 'italic';  // Eğik yazı stili
    //      option.style.opacity = '0.5';  // Şeffaflık
    //   }
    // }, []);
  const initialValue = {
    bewerbervorname: "",
    bewerbernachname: "",
    bewerberstr: "",
    bewerberplz: "",
    bewerberort: "",
    bewerbertelefon: "",
    bewerberemail: "",
    bewerbergebdatum: "",
    bewerbergeschlecht: "",
    isbehindert: "",
    behinderungart: "",
    bewerberarbeitstage: [],
    fuhrerscheinklasse: "",
    bewerberarbeitsart: "",
    eintrittsdatum: formattedDate,
    bewerbernotiz: "",
  };
  const { token } = useSelector((state) => state.auth);
  const { data: bewerberData, refetch } = useGetBewerberQuery(token);

  const [addBewerber] = useAddBewerberMutation();
  const [formData, setFormData] = useState(initialValue);
  const [acceptAgb, setAcceptAgb] = useState(false);
  const [acceptDatenschutz, setAcceptDatenschutz] = useState(false);
  const [warning, setWarning] = useState(null);
  const [errors, setErrors] = useState({});
  const [arbeitstageError, setArbeitstageError] = useState(null);
  const inputFocus = useRef(null);
  const [modal, setModal] = useState(false);
  const [openDatenschutzModal, setOpenDatenschutzModal] = useState(false);
  const [openAgbModal, setOpenAgbModal] = useState(false);
  const { logout } = useAuthCall();
  const [tableData, setTableData] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  const showMessage = (msg) => {
    setWarning(msg);
    setTimeout(() => {
      setWarning(null);
    }, 3000);
  };
  const handleAcceptAgbChange = () => {
    setAcceptAgb(!acceptAgb);
  };

  const handleAcceptDatenschutzChange = () => {
    setAcceptDatenschutz(!acceptDatenschutz);
  };

  const handleDatenschutzLabelClick = () => {
    setOpenDatenschutzModal(true);
  };

  const handleCloseDatenschutzModal = () => {
    setOpenDatenschutzModal(false);
  };

  const handleAgbLabelClick = () => {
    setOpenAgbModal(true);
  };

  const handleCloseAgbModal = () => {
    setOpenAgbModal(false);
  };

  const handleExit = () => {
    logout();
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    const prevDataValue = formData[name] || [];

    if (type === "checkbox" && name === "bewerberarbeitstage") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked
          ? [...prevDataValue, value]
          : prevDataValue.filter((day) => day !== value),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    const rules = validationRules[name];

    if (rules) {
      if (rules.required && !value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `Dieses Feld darf nicht leer sein`,
        }));
      } else if (rules.pattern && !rules.pattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: rules.errorMessage,
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
      }
    }

    if (name === "isbehindert" && value === "nein") {
      setFormData((prevData) => ({
        ...prevData,
        behinderungart: "",
      }));
    }

    if (name === "bewerberemail") {
      if (bewerberData.some((bewerber) => bewerber.bewerberemail === value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: rules.emailErrorMessage,
        }));
      }
    }

    if (name === "bewerbergebdatum") {
      const age = calculateAge(value);
      if (age < 18) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Die Bewerber müssen 18 Jahre oder älter sein.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: null,
        }));
      }
    }

    if (name === "eintrittsdatum") {
      const selectedDate = new Date(value);
      const today = new Date();
      if (selectedDate < today) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Startwunsch kann nicht früher als heute sein.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: null,
        }));
      }
    }
  };

  const calculateAge = (birthdate) => {
    const today = new Date();
    const birthdateObj = new Date(birthdate);
    let age = today.getFullYear() - birthdateObj.getFullYear();
    const monthDiff = today.getMonth() - birthdateObj.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthdateObj.getDate())
    ) {
      age--;
    }

    return age;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isAtLeastOneDaySelected = formData.bewerberarbeitstage.length > 0;

    if (!isAtLeastOneDaySelected) {
      setArbeitstageError(true);
      setTimeout(() => {
        setArbeitstageError(null);
      }, 2000);
      return;
    }

    const age = calculateAge(formData.bewerbergebdatum);
    if (age < 18) {
      return;
    }

    const hasErrors = Object.values(errors).some((error) => error !== null);
    if (hasErrors) {
      showMessage("Bitte alle Pflichtfelder ausfüllen");
      return;
    }

    const requiredFields = Object.keys(validationRules).filter(
      (field) => validationRules[field].required
    );

    const missingRequiredFields = requiredFields.filter((field) => {
      const fieldValue = formData[field];

      if (Array.isArray(fieldValue)) {
        return fieldValue.length === 0;
      } else if (
        fieldValue !== undefined &&
        fieldValue !== null &&
        fieldValue.trim
      ) {
        return !fieldValue.trim();
      }
      return true;
    });
    if (missingRequiredFields.length > 0) {
      showMessage("Bitte alle Pflichtfelder ausfüllen");
      return;
    }

    if (
      bewerberData.some(
        (bewerber) => bewerber.bewerberemail === formData.bewerberemail
      )
    ) {
      return;
    }

    try {
      await addBewerber(formData);
      setTableData([...tableData, formData]);
      setFormData(initialValue);
      showMessage(true);
      setModal(true);
      refetch();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="container mt-2 mb-5 ">
      <div className="card ">
        <div className="card-header bg-primary text-white">
          JOB APPLIKATION FORM
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit} className="position-relative">
            {warning && (
              <div className="row d-flex justify-content-center">
                <div className="col col-sm-8">
                  <span className="text-danger" style={{ fontSize: "0.8rem" }}>
                    {warning}
                  </span>
                </div>
              </div>
            )}
            <div className="d-flex flex-column gap-2">
              {/* Vorname & Nachname */}
              <div className="row d-flex flex-column flex-sm-row justify-content-left">
                <div className="col- col-sm-4">
                  <label htmlFor="bewerbervorname" className="fw-semibold">
                    Vorname <span className="text-danger">*</span>
                  </label>
                  <input
                    ref={inputFocus}
                    className={`form-control  ${
                      errors.bewerbervorname ? "border border-danger" : ""
                    }`}
                    type="text"
                    id="bewerbervorname"
                    name="bewerbervorname"
                    value={formData.bewerbervorname}
                    onChange={handleInputChange}
                  />
                  {errors.bewerbervorname && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      {errors.bewerbervorname}
                    </div>
                  )}
                </div>
                <div className="col col-sm-4">
                  <label htmlFor="bewerbernachname" className="fw-semibold">
                    Nachname <span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control  ${
                      errors.bewerbernachname ? "border border-danger" : ""
                    }`}
                    type="text"
                    id="bewerbernachname"
                    name="bewerbernachname"
                    value={formData.bewerbernachname}
                    onChange={handleInputChange}
                  />

                  {errors.bewerbernachname && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      {errors.bewerbernachname}
                    </div>
                  )}
                </div>
              </div>
              {/* Email & Telefon */}
              <div className="row d-flex flex-column flex-sm-row justify-content-left">
                <div className="col col-sm-4">
                  <label htmlFor="bewerberemail" className="fw-semibold">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control  ${
                      errors.bewerberemail ? "border border-danger" : ""
                    }`}
                    type="text"
                    id="bewerberemail"
                    name="bewerberemail"
                    value={formData.bewerberemail}
                    onChange={handleInputChange}
                    placeholder="muster@email.com"
                    // required
                  />
                  {errors.bewerberemail && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      {errors.bewerberemail}
                    </div>
                  )}
                </div>

                <div className="col col-sm-4">
                  <label htmlFor="bewerbertelefon" className="fw-semibold">
                    Telefon <span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control  ${
                      errors.bewerbertelefon ? "border border-danger" : ""
                    }`}
                    type="text"
                    id="bewerbertelefon"
                    name="bewerbertelefon"
                    value={formData.bewerbertelefon}
                    onChange={handleInputChange}
                    placeholder="0123 456 789 11 / +49 123 456 789 11"
                    // required
                  />
                  {errors.bewerbertelefon && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      {errors.bewerbertelefon}
                    </div>
                  )}
                </div>
              </div>
              {/* Strasse & PLZ */}
              <div className="row d-flex flex-column flex-sm-row justify-content-left">
                <div className="col col-sm-4">
                  <label htmlFor="bewerberstr" className="fw-semibold">
                    Straße-HausNr <span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control  ${
                      errors.bewerberstr ? "border border-danger" : ""
                    }`}
                    type="text"
                    id="bewerberstr"
                    name="bewerberstr"
                    value={formData.bewerberstr}
                    onChange={handleInputChange}
                    placeholder="musterstraße 12"
                    // required
                  />
                  {errors.bewerberstr && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      {errors.bewerberstr}
                    </div>
                  )}
                </div>
                <div className="col col-sm-4">
                  <label htmlFor="bewerberplz" className="fw-semibold">
                    PLZ <span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control  ${
                      errors.bewerberplz ? "border border-danger" : ""
                    }`}
                    type="text"
                    id="bewerberplz"
                    name="bewerberplz"
                    value={formData.bewerberplz}
                    onChange={handleInputChange}
                    placeholder="1234 / 12345"
                  />
                  {errors.bewerberplz && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      {errors.bewerberplz}
                    </div>
                  )}
                </div>
              </div>
              {/* Wohnort & Geburtsdatum */}
              <div className="row d-flex flex-column flex-sm-row justify-content-left">
                <div className="col col-sm-4">
                  <label htmlFor="bewerberort" className="fw-semibold">
                    Wohnort <span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control  ${
                      errors.bewerberort ? "border border-danger" : ""
                    }`}
                    type="text"
                    id="bewerberort"
                    name="bewerberort"
                    value={formData.bewerberort}
                    onChange={handleInputChange}
                  />
                  {errors.bewerberort && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      {errors.bewerberort}
                    </div>
                  )}
                </div>

                <div className="col col-sm-4 ">
                  <label htmlFor="bewerbergebdatum" className="fw-semibold">
                    Geburtsdatum <span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control  ${
                      errors.bewerbergebdatum ? "border border-danger" : ""
                    }`}
                    type="date"
                    id="bewerbergebdatum"
                    name="bewerbergebdatum"
                    value={formData.bewerbergebdatum}
                    onChange={handleInputChange}
                  />
                  {errors.bewerbergebdatum && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      {errors.bewerbergebdatum}
                    </div>
                  )}
                </div>
              </div>
              {/* Geschlecht */}
              <div className="row justify-content-left">
                <div className="col col-sm-8  d-flex justify-content-start  align-items-center gap-3">
                  <label className="fw-semibold">
                    Geschlecht <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex gap-1">
                    <input
                      type="radio"
                      name="bewerbergeschlecht"
                      id="männlich"
                      value="männlich"
                      checked={formData.bewerbergeschlecht === "männlich"}
                      onChange={handleInputChange}
                      required
                    />
                    <label htmlFor="männlich">männlich</label>
                  </div>
                  <div className="d-flex gap-1">
                    <input
                      type="radio"
                      name="bewerbergeschlecht"
                      value="weiblich"
                      id="weiblich"
                      checked={formData.bewerbergeschlecht === "weiblich"}
                      onChange={handleInputChange}
                      required
                    />
                    <label htmlFor="weiblich">weiblich</label>
                  </div>
                  <div className="d-flex gap-1">
                    <input
                      type="radio"
                      name="bewerbergeschlecht"
                      value="diverse"
                      id="diverse"
                      checked={formData.bewerbergeschlecht === "diverse"}
                      onChange={handleInputChange}
                      required
                    />
                    <label htmlFor="diverse">diverse</label>
                  </div>
                </div>
              </div>
              {/* Wochenarbeitszeit & Eintrittsdatum  */}
              <div className="row d-flex flex-column flex-md-row justify-content-left align-items-start">
                <div className="col-sm-8 col-md-4 mx-sm-auto mx-md-0">
                  <div className="col">
                    <label htmlFor="fuhrerscheinklasse" className="fw-semibold">
                      Führerscheinklasse <span className="text-danger">*</span>
                    </label>
                    <Select options={options} styles={customStyles} placeholder="Wählen Sie eine Option aus" />
                    {/* <select
                    placeholder="Wählen Sie eine Option aus"
                      name="fuhrerscheinklasse"
                      id="fuhrerscheinklasse"
                      value={formData.fuhrerscheinklasse}
                      onChange={handleInputChange}
                      required
                    
                      className={`form-select ${
                        errors.fuhrerscheinklasse ? "border border-danger" : ""
                      }`}
                    >
                      <option value="" className="optioncolor"> Wählen Sie eine Option aus</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                    </select> */}
                  </div>
                </div>
                <div className="col-sm-8 col-md-4 mx-sm-auto mx-md-0">
                  <label htmlFor="eintrittsdatum" className="fw-semibold">
                    Startwunsch <span className="text-danger">*</span>
                  </label>
                  <input
                    id="eintrittsdatum"
                    type="date"
                    className={`form-control ${
                      errors.eintrittsdatum ? "border border-danger" : ""
                    }`}
                    value={
                      formData.eintrittsdatum
                        ? new Date(formData.eintrittsdatum).toISOString().split("T")[0]
                        : ""
                    }
                    name="eintrittsdatum"
                    onChange={handleInputChange}
                  />
                  {errors.eintrittsdatum && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      {errors.eintrittsdatum}
                    </div>
                  )}
                </div>
              </div>
              {/* Arbeitstage & Beschäftigungsart  */}
              <div className="row d-flex flex-column flex-sm-row justify-content-left">
                <div className="col-sm-8 col-md-4 mx-sm-auto mx-md-0">
                  <label className="fw-semibold  mb-2">
                    Wochenarbeitstage <span className="text-danger">*</span>
                  </label>

                  <div className="d-flex flex-wrap gap-3"> 
                    {["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"].map((day) => (
                      <label key={day}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="bewerberarbeitstage"
                          value={day}
                          checked={formData.bewerberarbeitstage.includes(day)}
                          onChange={handleInputChange}
                        />
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                      </label>
                    ))}
                  </div>
                  {arbeitstageError && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      Bitte mindestens einen Arbeitstag auswählen.
                    </div>
                  )}
                </div>
                <div className="col-sm-8 col-md-4 mx-sm-auto mx-md-0">
                  <div className="col  d-flex flex-column gap-2">
                    <label className="fw-semibold">
                      Beschäftigungsart <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex gap-2">
                      <div className="d-flex gap-1">
                        <label htmlFor="aushilfe">Aushilfe</label>
                        <input
                          checked={formData.bewerberarbeitsart === "aushilfe"}
                          value="aushilfe"
                          name="bewerberarbeitsart"
                          type="radio"
                          className="form-check-input"
                          id="aushilfe"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="d-flex gap-1">
                        <label htmlFor="teilzeit">Teilzeit</label>
                        <input
                          checked={formData.bewerberarbeitsart === "teilzeit"}
                          value="teilzeit"
                          name="bewerberarbeitsart"
                          type="radio"
                          className="form-check-input"
                          id="teilzeit"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="d-flex gap-1">
                        <label htmlFor="vollzeit">Vollzeit</label>
                        <input
                          checked={formData.bewerberarbeitsart === "vollzeit"}
                          value="vollzeit"
                          name="bewerberarbeitsart"
                          type="radio"
                          className="form-check-input"
                          id="vollzeit"
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Behinderung & Notizen */}
              <div className="row justify-content-left">
                <div className="col-sm-8 col-lg-4 mx-sm-auto mx-md-0">
                  <div className="col d-flex gap-4">
                    <label className="fw-semibold">
                      Schwerbehindert <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex gap-1">
                      <input
                        id="ja"
                        type="radio"
                        name="isbehindert"
                        value="ja"
                        checked={formData.isbehindert === "ja"}
                        onChange={handleInputChange}
                        required
                      />
                      <label htmlFor="ja"> Ja</label>
                    </div>

                    <div className="d-flex gap-1">
                      <input
                        id="nein"
                        type="radio"
                        name="isbehindert"
                        value="nein"
                        checked={formData.isbehindert === "nein"}
                        onChange={handleInputChange}
                        required
                      />
                      <label htmlFor="nein"> Nein</label>
                    </div>
                  </div>
                  <div className="col">
                    <textarea
                      name="behinderungart"
                      className="form-control"
                      placeholder="Bitte beschreiben Sie kurz."
                      value={formData.behinderungart}
                      onChange={handleInputChange}
                      disabled={formData.isbehindert !== "ja"}
                    ></textarea>
                  </div>
                </div>

                <div className="col-sm-8 col-lg-4 mx-sm-auto mx-md-0">
                  <label className="fw-semibold" htmlFor="bewerbernotiz">
                    Notizen
                  </label>
                  <textarea
                    id="bewerbernotiz"
                    name="bewerbernotiz"
                    className="form-control"
                    value={formData.bewerbernotiz}
                    onChange={handleInputChange}
                    placeholder="Hier können Sie Ihre Notizen eingeben."
                  ></textarea>
                </div>
              </div>
              {/* Submit Button */}
              <div className="row d-flex flex-column flex-sm-row justify-content-left">
                  <div className="col col-sm-8 col-lg-4 mx-sm-auto mx-md-0">
                    <div className="form-check mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="acceptAgb"
                        checked={acceptAgb}
                        onChange={handleAcceptAgbChange}
                        required
                      />
                      <label className="form-check-label" htmlFor="acceptAgb">
                        <a href="path/to/AGB.pdf" download>AGB</a> akzeptieren
                      </label>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="acceptDatenschutz"
                        checked={acceptDatenschutz}
                        onChange={handleAcceptDatenschutzChange}
                        required
                      />
                      <label className="form-check-label" htmlFor="acceptDatenschutz">
                        <a href="path/to/Datenschutz.pdf" download>Datenschutz</a> akzeptieren
                      </label>
                    </div>
                  <button className="btn btn-primary" type="submit">
                    Senden
                  </button>
                  <LuLogOut className="text-primary" onClick={handleLogout} />
                </div>
              </div>
            </div>
          </form>
          <DatenschutzModal
            open={openDatenschutzModal}
            onClose={handleCloseDatenschutzModal}
          />
          <AgbModal open={openAgbModal} onClose={handleCloseAgbModal} />
        </div>
      </div>
      {modal && <ErfolgreicheAntragstellung />}
    </div>
  );
};
export default Bewerbungsbogen;
