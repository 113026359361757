import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { BiErrorCircle } from 'react-icons/bi';
import { BsInfoCircle } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';
import { MdWarning } from 'react-icons/md';

const alertTypes = {
  error: {
    title: 'Oh snap! You got an error!',
    variant: 'danger',
    icon: <BiErrorCircle />,
    defaultContent: 'Something went wrong. Please try again later.',
  },
  success: {
    title: 'Well done! You successfully did it!',
    variant: 'success',
    icon: <FaCheckCircle />,
    defaultContent: 'You successfully did it.',
  },
  info: {
    title: 'Heads up! This is an info alert!',
    variant: 'info',
    icon: <BsInfoCircle />,
    defaultContent: 'This is an info alert.',
  },
  warning: {
    title: 'Warning! Better check yourself, you are not looking too good.',
    variant: 'warning',
    icon: <MdWarning />,
    defaultContent: 'Better check yourself, you are not looking too good.',
  },
};
const getAlertType = (type) => alertTypes[type] || alertTypes.error;

function CustomAlertDismissible({ message, type, isShow }) {
  const [show, setShow] = useState(isShow);
  const alertType = getAlertType(type);

  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>
          {alertType.icon} {alertType.title}
        </Alert.Heading>
        <div>{message || alertType.defaultContent}</div>
      </Alert>
    );
  }
  return <></>;
}

export { CustomAlertDismissible };
