import React from "react";
import DataTable from "react-data-table-component";
import ActionColumnCell from "./ActionColumnCell";
// import DataTableExtensions from "react-data-table-component-extensions";

const ZertifikatTable = ({
  userCertificates,
 
  editZertifikat,
  deleteZertifikat,
}) => {
  console.log(userCertificates);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const columns = [
    { name: "ID", selector: (row) => row.id, sortable: true },
    {
      name: "FAHRER",
      selector: (row) =>
        row.driver ? row.driver.name + " " + row.driver.nachname : "NO_DRIVER",
      sortable: true,
    },
    { name: "KURS", selector: (row) => row.value, sortable: true },
    {
      name: "ERWERBDATUM",
      selector: (row) => formatDate(row.erwerbdatum),

      sortable: true,
    },
    {
      name: "ENDBDATUM",
      selector: (row) => formatDate(row.enddatum),

      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => "action",
      sortable: false,
      right: "true",
      cell: (row) => (
        <ActionColumnCell
          row={row}
          onEdit={editZertifikat}
          onDelete={deleteZertifikat}
        />
      ),
    },
  ];

 
  return (
    <div className="card my-4">
      <div className="card-header bg-primary text-white">Zertifikat Table</div>
      <div className="card-body">
        <DataTable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          pagination
          paginationPerPage={3}
          columns={columns}
          data={userCertificates}
          responsive={true}
          highlightOnHover={false}
          dense={false}
          selectableRows
          selectableRowsHighlight={true}
          fixedHeader={true}
        />
      </div>
    </div>
  );
};

export default ZertifikatTable;
