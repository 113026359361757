import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";

// Coğrafi Kodlayıcı Kontrolü Ekleme
function Geocoder() {
  const map = useMap();

  useEffect(() => {
    const geocoder = L.Control.geocoder({
      defaultMarkGeocode: false,
      placeholder: "Adresse eingeben...",
    })
      .on("markgeocode", function (e) {
        const bbox = e.geocode.bbox;
        const poly = L.polygon([
          bbox.getSouthEast(),
          bbox.getNorthEast(),
          bbox.getNorthWest(),
          bbox.getSouthWest(),
        ]).addTo(map);
        map.fitBounds(poly.getBounds());
        const center = e.geocode.center;
        map.flyTo(center, 15);
      })
      .addTo(map);

    return () => map.removeControl(geocoder);
  }, [map]);

  return null;
}

const Map = ({
  kundenData,
  selectedKunden,
  selectedRowData,
  selectedTableData,
}) => {
  const [position, setPosition] = useState([
    51.18784663376083, 7.049109687267121,
  ]);
  const mapRef = useRef();
  const markerRefs = useRef([]);
  const selectedTableDataRef = useRef(null);

  // Custom Icon
  const customIcon = L.icon({
    iconUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  useEffect(() => {
    // Automatically open popups for each marker when kundenData changes
    markerRefs.current.forEach((ref, index) => {
      if (ref) {
        ref.openPopup();
      }
    });
  }, [kundenData]);

  useEffect(() => {
    // Fly to selectedKunden if exists
    if (selectedKunden && mapRef.current) {
      const { lat, lng } = selectedKunden;
      mapRef.current.flyTo([lat, lng], 15);
    }
  }, [selectedKunden]);

  useEffect(() => {
    // Open popup for the selectedRowData if exists
    if (
      selectedRowData &&
      markerRefs.current &&
      markerRefs.current[selectedRowData.id]
    ) {
      markerRefs.current[selectedRowData.id].openPopup();
    }
  }, [selectedRowData]);

  useEffect(() => {
    // selectedTableData için popup'ı aç
    if (selectedTableDataRef.current) {
      selectedTableDataRef.current.openPopup();
    }
  }, [selectedTableData]);

  const registerMarkerRef = (ref, rowData) => {
    if (ref) {
      // Ensure that mapRef.current is defined and then fly to the marker
      const latLng = [
        parseFloat(rowData[8].split(",")[0]),
        parseFloat(rowData[8].split(",")[1]),
      ];
      ref.on("popupopen", () => {
        if (mapRef.current) {
          mapRef.current.flyTo(latLng, 18);
        }
      });
      markerRefs.current[rowData.id] = ref;
    }
  };

  return (
    <div className="p-4">
      <MapContainer
        center={position}
        zoom={35}
        style={{ height: "400px", width: "100%" }}
        whenCreated={(mapInstance) => (mapRef.current = mapInstance)}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {kundenData.map((kunde, i) => (
          <Marker
            key={kunde.id}
            position={[kunde.kundengps.lat, kunde.kundengps.lng]}
            icon={customIcon}
          >
            <Popup>
              <div>
                <strong>{kunde.kundenname}</strong>
                <br />
                <span className="text-danger">Kundennummer:</span>{" "}
                {kunde.kundennummer}
                <br />
                <span>{kunde.kundenstr}</span>
                <br />
                <span>
                  {kunde.kundenplz} {kunde.kundenort}
                </span>
              </div>
            </Popup>
            <Tooltip className="" direction="bottom" permanent>
              {kunde.kundennummer} - {kunde.anlaufpunkt}
            </Tooltip>
          </Marker>
        ))}
        {selectedRowData && (
          <Marker
            position={[
              parseFloat(selectedRowData[8].split(",")[0]),
              parseFloat(selectedRowData[8].split(",")[1]),
            ]}
            icon={customIcon}
            ref={(ref) => registerMarkerRef(ref, selectedRowData)}
          >
            <Popup permanent>
              <div
                className="bg-warning p-3 rounded-2 m-0"
                style={{ margin: "-1rem" }}
              >
                <strong>{selectedRowData[1]}</strong>
                <br />
                <strong className="text-danger">Kundennummer:</strong>{" "}
                {selectedRowData[7]}
                <br />
                <span>{selectedRowData[2]}</span>
                <br />
                <span>
                  {selectedRowData[3]} {selectedRowData[4]}
                </span>
              </div>
            </Popup>

            <Tooltip className="p-0" direction="bottom" permanent>
              <span className="bg-dark text-white p-2">
                <strong>
                  {selectedRowData[7]} - {selectedRowData[5]}{" "}
                </strong>
              </span>
            </Tooltip>
          </Marker>
        )}
        {selectedTableData && (
          <Marker
            position={[
              parseFloat(selectedTableData.kundengps.lat),
              parseFloat(selectedTableData.kundengps.lng),
            ]}
            icon={customIcon}
            ref={selectedTableDataRef} // Burada ref'i Marker'a atıyoruz
          >
            <Popup>
              <div className="bg-warning p-3 rounded-2">
                <strong>{selectedTableData.kundenname}</strong>
                <br />
                <span className="text-primary">Kundennummer:</span>{" "}
                {selectedTableData.kundennummer}
                <br />
                <span>{selectedTableData.kundenstr}</span>
                <br />
                <span>
                  {selectedTableData.kundenplz} {selectedTableData.kundenort}
                </span>
              </div>
            </Popup>
          </Marker>
        )}
        <Geocoder />
      </MapContainer>
    </div>
  );
};

export default Map;
