import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinFill } from "react-icons/ri";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { BootyCheckbox, BootyPagination } from "../../../../components";

const KundenTable = ({ onDelete, onEdit, kundenData = [], onKundenSelect }) => {
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleRowClick = (row) => {
    setSelectedRowId(row.id);
    onKundenSelect(row);
  };

  // Satırların stillerini koşullu olarak belirle
  const conditionalRowStyles = [
    {
      when: (row) => row.id === selectedRowId,
      style: {
        backgroundColor: "#E6E6FA ", // Seçili satırın arka plan rengi
        color: "red",
        fontWeight: "bold",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const actionBtn = [
    { name: "Copy", className: "btn btn-info" },
    { name: "Excel", className: "btn btn-success" },
    { name: "PDF", className: "btn btn-danger" },
    { name: "YAZICI", className: "btn btn-outline-secondary" },
    { name: "Titel", className: "btn btn-outline-secondary" },
    { name: "SearchPanes", className: "btn btn-outline-secondary" },
  ];

  const columns = [
    {
      name: "Tour",
      selector: (row) => row.kundentournr,
      sortable: true,
      width: "90px",
    },
    {
      name: "KundenNummer",
      selector: (row) => row.kundennummer,
      sortable: true,
      width: "150px",
    },
    {
      name: "ALP",
      selector: (row) => row.anlaufpunkt,
      sortable: true,
      width: "120px",
    },
    {
      name: "ALP-Neu",
      selector: (row) => row.anlaufneu,
      sortable: true,
      width: "120px",
    },
    {
      name: "Name",
      selector: (row) => row.kundenname,
      sortable: true,
      width: "120px",
    },
    {
      name: "Strasse",
      selector: (row) => row.kundenstr,
      sortable: true,
      width: "200px",
    },
    {
      name: "PLZ",
      selector: (row) => row.kundenplz,
      sortable: true,
      width: "90px",
    },
    {
      name: "Ort",
      selector: (row) => row.kundenort,
      sortable: true,
      width: "120px",
    },
    {
      name: "Telefon",
      selector: (row) => row.kundentel,
      sortable: true,
      width: "120px",
    },

    {
      name: "Action",
      selector: (row) => "action",
      sortable: false,
      right: "true",
      cell: (row) => (
        <div>
          <button
            onClick={() => onEdit(row)}
            className="btn btn-link text-primary"
          >
            <FiEdit />
          </button>
          <button
            onClick={() => onDelete(row.id)}
            className="btn btn-link text-danger"
          >
            <RiDeleteBinFill />
          </button>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: kundenData,
  };

  return (
    <>
      <style>
        {`
          .rdt_TableRow:hover {
            background-color: #F0F8FF !important;
            color: red;
            cursor: pointer;
            font-weight: bold;
          }
        `}
      </style>
      <div className="card mb-4">
        <div className="card-header ml-2 fs-5 text-white bg-primary p-3">
          Kunden Übersicht
        </div>
        <div className="card-body">
          <div className="d-flex flex-wrap gap-2 mb-2">
            {actionBtn.map((action, i) => (
              <button key={i} type="button" className={action.className}>
                {action.name}
              </button>
            ))}
          </div>
          <div
            className="table-responsive overflow-scroll"
            style={{ height: "400px" }}
          >
            <DataTableExtensions {...tableData}>
              <DataTable
                columns={columns}
                data={kundenData}
                noHeader
                defaultSortField="id"
                pagination
                paginationComponent={BootyPagination}
                highlightOnHover={false}
                dense={false}
                selectableRows
                selectableRowsHighlight={true}
                selectableRowsComponent={BootyCheckbox}
                expandableRows
                fixedHeader={true}
                responsive={false}
                onRowClicked={handleRowClick}
                conditionalRowStyles={conditionalRowStyles}
              ></DataTable>
            </DataTableExtensions>
          </div>
        </div>
      </div>
    </>
  );
};

export default KundenTable;
