import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import { MdTransferWithinAStation } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";

const BewerberTable = ({
  modifiedData,
  handleDelete,
  handleEdit,
  handleTransfer,
}) => {
  const column = [
    { field: "bewerbervorname", headerName: "Vorname", width: 150 },
    { field: "bewerbernachname", headerName: "Nachname", width: 150 },
    { field: "bewerberstr", headerName: "Straße", width: 150 },
    { field: "bewerberplz", headerName: "PLZ", width: 150 },
    { field: "bewerberort", headerName: "Ort", width: 150 },
    { field: "bewerbertelefon", headerName: "Telefon", width: 150 },
    { field: "bewerberemail", headerName: "Email", width: 150 },
    { field: "bewerbergebdatum", headerName: "Geburtsdatum", width: 150 },
    { field: "bewerbergeschlecht", headerName: "Geschlecht", width: 150 },
    { field: "isbehindert", headerName: "Behindert", width: 150 },
    { field: "behinderungart", headerName: "Behinderung", width: 150 },
    { field: "bewerberarbeitstage", headerName: "Arbeitstage", width: 150 },
    {
      field: "fuhrerscheinklasse",
      headerName: "Führerscheinklasse",
      width: 150,
    },
    { field: "bewerberarbeitsart", headerName: "Arbeitsart", width: 150 },
    { field: "eintrittsdatum", headerName: "Eintrittsdatum", width: 150 },
    { field: "bewerbernotiz", headerName: "Notiz", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-3">
            <MdTransferWithinAStation
              className="text-success"
              onClick={() => handleTransfer(params.row)}
            />
            <CiEdit
              className="text-primary"
              onClick={() => handleEdit(params.row)}
            />
            <RiDeleteBin6Line
              className="text-danger"
              onClick={() => handleDelete(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className={`card my-3 ${modifiedData.length === 0 && "d-none"}`}>
      <div className="card-header bg-primary text-white ">Übersicht</div>
      <div className="card-body">
        <DataGrid
          rows={modifiedData}
          columns={column}
          style={{ maxHeight: 400, width: "100%", overflowX: "auto" }}
          GridToolbar
          hideFooterPagination
          components={{
            Toolbar: () => (
              <div className="d-flex align-items-center">
                <GridToolbar />
              </div>
            ),
          }}
          density="compact"
        />
      </div>
    </div>
  );
};

export default BewerberTable;
