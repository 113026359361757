import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { InputFeedback } from '../../../components';
import { useId } from 'react';

function RegionFormikHandler({ createRegionHandler, actionLoading, region }) {
  const generatedId = useId();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ regionName: region?.regionname || '' }}
      validationSchema={Yup.object().shape({
        regionName: Yup.string()
          .required('Region Name ist erforderlich')
          .min(3, 'Region Name muss mindestens 3 Zeichen lang sein')
          .max(50, 'Region Name darf maximal 50 Zeichen lang sein'),
      })}
      onSubmit={(values, actions) => createRegionHandler(values, actions)}
    >
      {({ handleSubmit, errors }) => (
        <form
          onSubmit={handleSubmit}
          className="d-sm-flex flex-column flex-wrap gap-2"
          style={{ maxWidth: '576px' }}
        >
          <div className="form-group">
            <div className="form-floating">
              <Field
                name="regionName"
                type="text"
                className="form-control"
                aria-describedby="RegionNameHelp"
                id={`${generatedId}regionNameField`}
              />
              <label htmlFor={`${generatedId}regionNameField`} className="form-label">
                Region Name
              </label>
            </div>
            <InputFeedback error={errors.regionName} />
          </div>

          {/* Submit */}
          <div>
            <button type="submit" className="btn btn-primary" disabled={actionLoading}>
              {region?.id ? 'Änderungen Speichern' : 'Region Hinzufügen'}
              {actionLoading && (
                <span className="spinner-border spinner-border-sm me-2"></span>
              )}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default RegionFormikHandler;
