import React, { useEffect, useState } from 'react';
import { formikValidation, initialValues } from './formElementsTourplanen';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

export const TourPlannenForm = ({
  onSubmit,
  editData,
  fahrerOptions,
  fahrzeugOptions,
  setEditData,
  tourId,
  lastTourData,
}) => {
  const validate = formikValidation(Yup);
  const [currentTourId, setCurrentTourId] = useState(editData?.tourId || '');
  
  // initialFormValues now set properly
  const initialFormValues = {
    ...initialValues,
    ...editData,
  };

  const handleTourIdChange = (tourId, setFieldValue) => {
    setCurrentTourId(tourId);

    // Check if tourId exists in lastTourData
    if (tourId && lastTourData[tourId]) {
      const lastData = lastTourData[tourId];
      setFieldValue(
        'tourendatum',
        new Date(new Date(lastData.tourendatum).getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]
      );
      setFieldValue('fahrer', lastData.fahrer);
      setFieldValue('touruhrzeit', lastData.touruhrzeit);
      setFieldValue('fahrzeugId', lastData.fahrzeugId);
    } else {
      // Reset fields to initial values if tourId doesn't exist
      setFieldValue('tourendatum', initialValues.tourendatum);
      setFieldValue('fahrer', initialValues.fahrer);
      setFieldValue('touruhrzeit', initialValues.touruhrzeit);
      setFieldValue('fahrzeugId', initialValues.fahrzeugId);
      setFieldValue('tourennotiz', initialValues.tourennotiz);
    }
  };

  return (
    <div className="card">
      <div className="card-header bg-primary text-white">Tour Planen Form</div>
      <div className="card-body">
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={validate}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values, resetForm);
          }}
        >
          {({ resetForm, values, setFieldValue }) => (
            <TourFormContent
              values={values}
              setFieldValue={setFieldValue}
              handleTourIdChange={handleTourIdChange}
              tourId={tourId}
              fahrerOptions={fahrerOptions}
              fahrzeugOptions={fahrzeugOptions}
              editData={editData}
              setEditData={setEditData}
              resetForm={resetForm}
            />
          )}
        </Formik>
      </div>
    </div>
  );
};

const TourFormContent = ({
  values,
  setFieldValue,
  handleTourIdChange,
  tourId,
  fahrerOptions,
  fahrzeugOptions,
  editData,
  setEditData,
  resetForm,
}) => {
  useEffect(() => {
    if (values.tourId) {
      handleTourIdChange(values.tourId, setFieldValue);
    }
  }, [values.tourId, handleTourIdChange, setFieldValue]);

  return (
    <Form className="d-flex flex-column gap-3">
      <div className="row">
        <div className="col-12 col-md-4">
          <label htmlFor="tourId" className="fw-semibold">
            Tour
          </label>
          <Field
            id="tourId"
            as="select"
            className="form-select"
            name="tourId"
            onChange={(e) => {
              const tourId = e.target.value;
              setFieldValue('tourId', tourId);
              handleTourIdChange(tourId, setFieldValue);
            }}
          >
            <option value=""></option>
            {tourId.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Field>
          <ErrorMessage name="tourId" component="div" className="text-danger" />
        </div>
        <div className="col-12 col-md-4">
          <label htmlFor="tourendatum" className="fw-semibold">
            Datum
          </label>
          <Field id="tourendatum" type="date" name="tourendatum" className="form-control" />
          <ErrorMessage name="tourendatum" component="div" className="text-danger" />
        </div>
        <div className="col-12 col-md-4">
          <label htmlFor="fahrer" className="fw-semibold">
            Fahrer
          </label>
          <Field id="fahrer" as="select" name="fahrer" className="form-select">
            <option value=""></option>
            {fahrerOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Field>
          <ErrorMessage name="fahrer" component="div" className="text-danger" />
        </div>
        <div className="col-12 col-md-4">
          <label htmlFor="touruhrzeit" className="fw-semibold">
            Startzeit
          </label>
          <Field id="touruhrzeit" type="time" name="touruhrzeit" className="form-control" />
          <ErrorMessage name="touruhrzeit" component="div" className="text-danger" />
        </div>
        <div className="col-12 col-md-4">
          <label htmlFor="fahrzeugId" className="fw-semibold">
            Fahrzeug
          </label>
          <Field id="fahrzeugId" as="select" name="fahrzeugId" className="form-select">
            <option value=""></option>
            {fahrzeugOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Field>
          <ErrorMessage name="fahrzeugId" component="div" className="text-danger" />
        </div>
        <div className="col-12 col-md-4">
          <label htmlFor="tourennotiz" className="fw-semibold">
            Notiz
          </label>
          <Field id="tourennotiz" as="textarea" name="tourennotiz" className="form-control p-0" />
          <ErrorMessage name="tourennotiz" component="div" className="text-danger" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex gap-2">
          <button type="submit" className="btn btn-primary">
            {editData ? 'Aktualisieren' : 'Hinzufügen'}
          </button>
          {editData ? (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setEditData(null);
                resetForm();
              }}
            >
              Eingeben
            </button>
          ) : null}
        </div>
      </div>
    </Form>
  );
};

export default TourPlannenForm;
