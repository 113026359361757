import React from "react";
import TourStatus from "../tourstatus/TourStatus";
import ReifenWechselnKm from "./fahrzeugReifenKontrolle/ReifenWechselnKm";
import FahrzeugsInspektion from "./fahrzeugInspektion/FahrzeugsInspektion";

const AdminDashboard = () => {
  return (
    <div className="container">
      <TourStatus />
       <FahrzeugsInspektion />
      <ReifenWechselnKm />
     
    </div>
  );
};

export default AdminDashboard;
