import React, { useEffect, useState } from "react";
import axios from "axios";
import { number, object, string } from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useGetKundensQuery } from "../../../app/services/tagesTour/tagesTourApi";
import { toastSuccessNotify } from "../../../helper/ToastNotify";
import { useGetToursQuery } from "../../leistung/core/leistungApi";
import KundenTable from "./core/KundenTable";
import KundenForm from "./core/KundenForm";
import Map from "./core/Map";
import AutoFüllerTable from "./core/AutoFüllerTable";

const Kunden = () => {
  const { token } = useSelector((state) => state.auth);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}kundens?access_token=${token}`;

  const [coordinates, setCoordinates] = useState([
    51.18784663376083, 7.049109687267121,
  ]);

  const [parsedData, setParsedData] = useState("");
  const [selectedKunden, setSelectedKunden] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedTableData, setSelectedTableData] = useState(null);

  const handleKundenSelect = (selectedKunden) => {
    setSelectedTableData(selectedKunden);
    console.log(selectedKunden);
  };
  

  const handleCoordinatesChange = (newCoordinates) => {
    setCoordinates(newCoordinates);
  };

  const {
    data: kundenData,
    isLoading,
    error,
    refetch,
  } = useGetKundensQuery(token);
  const { data: toursData } = useGetToursQuery(token);
  console.log("kundenData: ", kundenData);

  useEffect(() => {
    if (kundenData && kundenData.length > 0) {
      setSelectedKunden(kundenData[0]);
    }
  }, [kundenData]);

  const initialValues = {
    kundennummer: "",
    kundentournr: "",
    anlaufpunkt: "",
    anlaufneu: "",
    kundenname: "",
    kundentel: "",
    kundenplz: "",
    kundenort: "",
    kundenstr: "",
    kundengps: { lat: "0", lng: "0" },
    kundenmenu: "",
    kundenschlussel: "",
    kundennotiz: "",
  };

  const kundenSchema = object({
    kundennummer: string(),
    kundentournr: string(),
    anlaufpunkt: string(),
    anlaufneu: string(),
    kundenname: string().required("Kundenname ist erforderlich"),
    kundentel: string(),
    kundenplz: string(),
    kundenort: string(),
    kundenstr: string(),
    kundengps: object({
      lat: number().nullable(true), // Sayısal değer, boş bırakılabilir
      lng: number().nullable(true), // Sayısal değer, boş bırakılabilir
    }),
    kundenmenu: string(),
    kundenschlussel: string(),
    kundennotiz: string(),
  });

  const formik = useFormik({
    initialValues,
    kundenSchema,
    onSubmit: async (values) => {
      console.log(`${token}`);
      try {
        let response;

        if (values.id) {
          // If 'id' exists in values, it means it's an update operation
          response = await axios.put(
            `${BASE_URL}kundens/${values.id}?access_token=${token}`,
            values
          );
          toastSuccessNotify("Erfolgreich aktualisiert");
        } else {
          // If 'id' doesn't exist, it means it's a create operation
          const isUnique = !kundenData.some((kunde) => kunde.id === values.id);

          if (isUnique) {
            response = await axios.post(url, values);
            toastSuccessNotify("Erfolgreich gesendet");
          } else {
            console.error("Duplicate Data");
            return;
          }
        }

        // await createLeistung(values);
        // console.log(res.data);
        await refetch();
        console.log("Kunde eklendi:", values);
        formik.resetForm();
        console.log(formik.values);
      } catch (error) {
        console.error("Error in addKunde:", error);
      }
    },
    validate: (values) => {
      const errors = {};

      if (!values.kundenname) {
        errors.kundenname = "Kundenname erforderlich";
      } else if (!values.kundenplz) {
        errors.kundenplz = "Kundenplz erforderlich";
      } else if (!values.kundengps) {
        errors.kundengps = "Kundengps in der richtigen Format erforderlich";
      }

      return errors;
    },
  });

  const handleChange = (e) => {
    formik.handleChange(e);
  };

  const onDelete = async (id) => {
    try {
      // await deleteKunde(id);
      await axios.delete(`${BASE_URL}kundens/${id}?access_token=${token}`);
      toastSuccessNotify("Erfolgreich gelöscht!");
      await refetch();
    } catch (error) {
      console.error("Error Delete", error);
    }
  };

  const onEdit = (kunden) => {
    // Düzenlenecek öğenin tüm verilerini ve önemlisi ID'yi formik değerlerine ata
    formik.setValues({ ...kunden });
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>ERROR{error.message}</p>;
  }

  const handlePaste = (event) => {
    // Yapıştırılan içeriği al
    const pasteContent = event.clipboardData.getData("text");

    // Yapıştırılan içeriği satırlara ayır
    const rows = pasteContent.split("\n").filter((row) => row.length);

    // Satırları ve her bir satırdaki hücreleri işle
    const tableData = rows.map((row) => {
      return row.split("\t").map((cell) => {
        return cell.trim();
      });
    });

    // İşlenen veriyi state'e aktar (bu örnekte tableData'nın state'i güncellendiği varsayıliyor)
    setParsedData(tableData);

    // Olayın varsayılan yapıştırma işlemini önle
    event.preventDefault();
  };

  // İki GPS koordinatı arasındaki mesafeyi hesaplayan fonksiyon
  function getDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }

const handleRowClick = (rowData) => {
  // Eğer rowData boş ise, işlem yapmamalı.
  if (!rowData) return;

  const kundennummer = rowData[rowData.length - 2];
  const existingKunden = kundenData.find(
    (kunde) => kunde.kundennummer === kundennummer
  );

  if (existingKunden) {
    formik.setValues({ ...existingKunden });
    setSelectedKunden(existingKunden);
  } else {
    // Alanları eşleme
    const fieldsMapping = {
      kundentournr: 0,
      kundenname: 1,
      kundenstr: 2,
      kundenplz: 3,
      kundenort: 4,
      anlaufpunkt: 5,
      kundentel: 6,
      kundennummer: 7,
      kundengps: 8,
    };

    setSelectedRowData(rowData);

    // Alanları formik değerlerine ayarlama
    Object.keys(fieldsMapping).forEach((field) => {
      const valueIndex = fieldsMapping[field];
      if (field === "kundengps" && rowData[valueIndex]) {
        const gpsData = rowData[valueIndex].split(",");
        if (gpsData.length === 2) {
          const [lat, lng] = gpsData.map(Number);
          if (!isNaN(lat) && !isNaN(lng)) {
            formik.setFieldValue("kundengps.lat", lat);
            formik.setFieldValue("kundengps.lng", lng);
          }
        }
      } else {
        formik.setFieldValue(field, rowData[valueIndex]);
      }
    });
  }

  // GPS verisini ayrıştırma ve kontrol etme
  const latLngString = rowData[8];
  if (!latLngString) {
    console.error("GPS verisi bulunamadı.");
    return;
  }
  const latLngArray = latLngString.split(",");

  const selectedLat = parseFloat(latLngArray[0]);
  const selectedLng = parseFloat(latLngArray[1]);

  if (isNaN(selectedLat) || isNaN(selectedLng)) {
    console.error("Geçersiz GPS verisi.");
    return;
  }

  // Mesafe hesaplaması ve en yakın iki müşteriyi bulma
  let distances = kundenData
    .map((kunde) => ({
      ...kunde,
      distance: getDistance(
        selectedLat,
        selectedLng,
        parseFloat(kunde.kundengps.lat),
        parseFloat(kunde.kundengps.lng)
      ),
    }))
    .sort((a, b) => a.distance - b.distance);

  let averageAnlaufpunkt = 0;
  if (distances.length === 1) {
    // Eğer sadece bir müşteri varsa, anlaufpunkt'u 10 arttır
    averageAnlaufpunkt = parseFloat(distances[0].anlaufpunkt) + 10;
  } else if (distances.length > 1) {
    // En yakın iki müşteriyi al ve ortalama anlaufpunkt hesapla
    const closestTwo = distances.slice(0, 2);
    averageAnlaufpunkt =
      closestTwo.reduce((acc, curr) => acc + parseFloat(curr.anlaufpunkt), 0) /
      closestTwo.length;
  }

  // formik değerini güncelle
  formik.setFieldValue("anlaufpunkt", averageAnlaufpunkt);
};

  return (
    <div className="container-fluid">
      <KundenTable
        kundenData={kundenData}
        toursData={toursData}
        onDelete={onDelete}
        onEdit={onEdit}
        onCoordinatesChange={handleCoordinatesChange}
        onKundenSelect={handleKundenSelect}
      />
      <Map
        coordinates={coordinates}
        kundenData={kundenData}
        selectedKunden={selectedKunden}
        onRowClick={handleRowClick}
        autoFüllerData={parsedData}
        selectedRowData={selectedRowData}
        selectedTableData={selectedTableData}
      />
      <div className="rounded-2 overflow-hidden mt-2">
        <div className="card-header ml-2 fs-5 text-white bg-primary p-3">
          Kunden
        </div>
        <>
          <div className="form-group my-5">
            <label className="fw-semibold" htmlFor="kundennotiz">
              Auto Füller Für Kunden{" "}
            </label>
            <textarea
              name=""
              className="form-control"
              id=""
              rows="3"
              value=""
              onPaste={handlePaste}
            ></textarea>
            <AutoFüllerTable
              formik={formik}
              autoFüllerData={parsedData}
              kundenData={kundenData}
              onRowClick={handleRowClick}
            />
          </div>
        </>
        <KundenForm
          formik={formik}
          handleChange={handleChange}
          kundenData={kundenData}
          toursData={toursData}
        />
      </div>
    </div>
  );
};

export default Kunden;
