import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";

const ReifenWechselnKm = () => {
  const { token } = useSelector((state) => state.auth);
  const url = process.env.REACT_APP_BASE_URL;

  const [fahrzeugDatei, setFahrzeugDatei] = useState([]);
  const [leistungsData, setLeistungsData] = useState([]);
  const [werkstattService, setWerkstattService] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fahrzeugsResponse = await axios.get(
          `${url}fahrzeuges?access_token=${token}`
        );
        setFahrzeugDatei(fahrzeugsResponse.data);

        const leistungsResponse = await axios.get(
          `${url}leistungs?access_token=${token}`
        );
        setLeistungsData(leistungsResponse.data);

        const werkstattsResponse = await axios.get(
          `${url}werkstatts?access_token=${token}`
        );
        setWerkstattService(werkstattsResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token, url]);

  const uniqueFahrzeugs = Array.from(
    new Set(fahrzeugDatei.map((fahrzeug) => fahrzeug.id))
  ).map((id) => {
    return fahrzeugDatei.find((fahrzeug) => fahrzeug.id === id);
  });

  const combinedData = uniqueFahrzeugs.map((fahrzeug) => {
    const matchingLeistung = leistungsData
      .filter((item) => item.fahrzeugId === fahrzeug.id && item.endzeit)
      .sort((a, b) => b.endzeit - a.endzeit)[0];

    const lastTour = matchingLeistung
      ? matchingLeistung._tour.tourname
      : "Keine Letzte Tour";

    const matchingServiceWithReifen = werkstattService
      .filter((item) => item.fahrzeugId === fahrzeug.id)
      .filter((item) => item.serviceart.includes("Reifen"));

    let summeWithReifen = 0;
    matchingServiceWithReifen.forEach((service) => {
      summeWithReifen += service.reifenRestWeg;
    });

    if (matchingServiceWithReifen.length > 0) {
      const matchingLeistung1 = leistungsData
        .filter((item) => item.fahrzeugId === fahrzeug.id)
        .map((item) => item.endkm)
        .sort()
        .reverse()[0];
      const werkKm =
        matchingServiceWithReifen[matchingServiceWithReifen.length - 1]
          .werkkilometer;

      summeWithReifen =
        Number(werkKm) +
        Number(fahrzeug.kfzfahrzeugreifenkm) -
        Number(matchingLeistung1);
    }

    const matchingServiceWithoutReifen = werkstattService
      .filter((item) => item.fahrzeugId === fahrzeug.id)
      .filter((item) => !item.serviceart.includes("Reifen"));

    let summeWithoutReifen = 0;
    matchingServiceWithoutReifen.forEach((service) => {
      summeWithoutReifen += service.reifenRestWeg;
    });

    if (matchingServiceWithoutReifen.length > 0) {
      const matchingLeistung2 = leistungsData
        .filter((item) => item.fahrzeugId === fahrzeug.id)
        .map((item) => item.endkm)
        .sort()
        .reverse()[0];

      summeWithoutReifen =
        Number(fahrzeug.kfzfahrzeugkilometer) +
        Number(fahrzeug.kfzfahrzeugreifenkm) -
        Number(matchingLeistung2);
    }

    const reifenRestWeg = summeWithReifen
      ? summeWithReifen
      : summeWithoutReifen;

    const matchingServiceWithInspektion = werkstattService
      .filter((item) => item.fahrzeugId === fahrzeug.id)
      .filter((item) => item.serviceart.includes("Inspektion"));

    let summeWithInspektion = 0;
    matchingServiceWithInspektion.forEach((service) => {
      summeWithInspektion += service.inspektionRestWeg;
    });

    if (matchingServiceWithInspektion.length > 0) {
      const matchingLeistung3 = leistungsData
        .filter((item) => item.fahrzeugId === fahrzeug.id)
        .map((item) => item.endkm)
        .sort()
        .reverse()[0];

      const werkKm =
        matchingServiceWithInspektion[matchingServiceWithInspektion.length - 1]
          .werkkilometer;

      summeWithInspektion =
        Number(werkKm) +
        Number(fahrzeug.kfzfahrzeuginspektionkm) -
        Number(matchingLeistung3);
    }

    const matchingServiceWithoutInspektion = werkstattService
      .filter((item) => item.fahrzeugId === fahrzeug.id)
      .filter((item) => !item.serviceart.includes("Inspektion"));

    let summeWithoutInspektion = 0;
    matchingServiceWithoutInspektion.forEach((service) => {
      summeWithoutInspektion += service.inspektionRestWeg;
    });

    if (matchingServiceWithoutInspektion.length > 0) {
      const matchingLeistung4 = leistungsData
        .filter((item) => item.fahrzeugId === fahrzeug.id)
        .map((item) => item.endkm)
        .sort()
        .reverse()[0];

      summeWithoutInspektion =
        Number(fahrzeug.kfzfahrzeugkilometer) +
        Number(fahrzeug.kfzfahrzeuginspektionkm) -
        Number(matchingLeistung4);
    }

    const inspektionRestWeg = summeWithInspektion
      ? summeWithInspektion
      : summeWithoutInspektion;

    return {
      id: fahrzeug.id,
      kennzeichen: fahrzeug.kfzkennzeichen,
      lastTourName: lastTour,
      reifenRestWeg: isNaN(reifenRestWeg) ? "---" : reifenRestWeg,
       inspektionRestWeg: isNaN(inspektionRestWeg) ? "---" : inspektionRestWeg,
    };
  });

  const columns = [
    {
      name: "Fahrzeugs",
      selector: (row) => `${row.kennzeichen} ---> ${row.lastTourName}`,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        if (rowA.kennzeichen > rowB.kennzeichen) return 1;
        if (rowA.kennzeichen < rowB.kennzeichen) return -1;
        return 0;
      },
    },
    {
      name: "ReifenWechseInKm",
      selector: (row) => row.reifenRestWeg,
      sortable: true,
      cell: (row) => (
        <div
          style={{
            backgroundColor:
              parseInt(row.reifenRestWeg) < 1500 && row.reifenRestWeg !== 0
                ? "red"
                : "transparent",
            padding: "5px",
          }}
        >
          {row.reifenRestWeg}
        </div>
      ),
    },
    {
      name: "InspektionRestWeg",
      selector: (row) => row.inspektionRestWeg,
      sortable: true,
      cell: (row) => (
        <div
          style={{
            backgroundColor:
              parseInt(row.inspektionRestWeg) < 1500 && row.inspektionRestWeg !== 0
                ? "red"
                : "transparent",
            padding: "5px",
          }}
        >
          {row.inspektionRestWeg}
        </div>
      ),
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mt-3">
      <div className="card">
        <div className="card-header bg-primary text-white">
          Reifen Wechseln-Inspektion
        </div>
        <div className="card-body">
          <DataTable
            columns={columns}
            data={combinedData}
            defaultSortFieldId="Fahrzeugs"
            pagination
            paginationPerPage={6}
            paginationRowsPerPageOptions={[6,8,10,20]}
            highlightOnHover
            customStyles={{
              rows: {
                style: {
                  minHeight: '52px',
                },
              },
              headCells: {
                style: {
                  fontWeight: 'bold',
                  fontSize: '16px',
                },
              },
              cells: {
                style: {
                  padding: '8px',
                  fontSize: '14px',
                },
              },
            }}
          
          />
        </div>
      </div>
      </div>
  )}
  export default ReifenWechselnKm

