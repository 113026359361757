import React, { useEffect, useId, useState } from 'react'
import { Field, Form, Formik } from 'formik';
import useAxios from '../../../hooks/useAxios';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

const FormikForm = ({ createOptionHandler, option, actionLoading }) => {
  const [users, setUsers] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const { axiosWithToken } = useAxios();
  const generatedId = useId();


  useEffect(() => {
    const getUsers = async () => {
      const usersFromServer = await fetchUsers();
      setUsers(usersFromServer);
    };
    getUsers();
  }, []);
  const fetchUsers = async () => {
    // use axios to fetch users
    const { data } = await axiosWithToken.get("optionens");
    // return data
    return data;
  };
  return (
   
   

    <Formik
  enableReinitialize={true}
  initialValues={{
    id: option.id || null,
    key: option.key || "",
    value: option.value || "",
    kategorie: option.kategorie || "",
    status: option.status || false,
  }}
  validationSchema={Yup.object().shape({
    value: Yup.string()
      .required('Value ist erforderlich')
      .min(3, 'Value muss mindestens 3 Zeichen lang sein')
      .max(500, 'Value darf maximal 500 Zeichen lang sein'),
  })}
  onSubmit={(values, actions) => {
    createOptionHandler(values, actions);
  }}
>
  {({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue
    /* and other goodies */
  }) => (
    <Form onSubmit={handleSubmit} className="mt-5 p-3">
      <div className="row">
        <div className="col-md-6">
          {/* KATEGORIE*/}
          <div className="form-group">
            <label htmlFor="kategorie">KATEGORIE</label>
            <Field
              as="select"
              id="kategorie"
              className="form-select form-control"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.kategorie}
              name="kategorie"
            >
             <option value="" disabled>
             Entscheide dich
              </option>
              {users.map((user) => (
                <option key={user.id} value={user.kategorie}>
                  {user.kategorie}
                </option>
              ))}
            </Field>
          </div>

          {/* VALUE */}
          <div className="form-group">
            <label htmlFor="value">VALUE</label>
            <Field
              id="value"
              className={`form-select form-control ${errors.value && touched.value ? 'is-invalid' : ''}`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.value}
              name="value"
            />
             {errors.value && touched.value && (
      <div className="invalid-feedback">{errors.value}</div>
    )}
          </div>
        </div>

        <div className="col-md-6">
         {/* KEY */}
         <div className="form-group">
            <label htmlFor="key">KEY</label>
            <Field
              as="select"
              id="key"
              className="form-select form-control"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.key}
              name="key"
            >
             <option value="" disabled>
             Entscheide dich
              </option>

              {users.map((user) => (
                <option key={user.id} value={user.key}>
                  {user.key}
                </option>
              ))}
            </Field>
          </div>
         

          {/* STATUS */}
          <div className="form-group form-switch">
            <label htmlFor="key">STATUS</label>
            <Field name="status">
              {({ field }) => (
                <input
                  {...field}
                  className="form-control form-check-input"
                  aria-describedby="pflegenotizHelp"
                  type="checkbox"
                   role="switch"
                  id="status"
                  checked={values.status}
                  onChange={(e) => setFieldValue("status", e.target.checked)}
                />
              )}
            </Field>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary btn-sm d-block mt-2"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Form>
  )}
</Formik>
  )
}
export default FormikForm