import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { FiRefreshCcw } from "react-icons/fi";

const CustomToolbar = ({ onRefresh }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        variant="contained"
        color="primary"
        startIcon={<FiRefreshCcw />}
        onClick={onRefresh}
        style={{ marginLeft: 8 }}
      >
        Refresh
      </Button>
    </GridToolbarContainer>
  );
};
export default CustomToolbar