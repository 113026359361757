import React from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinFill } from "react-icons/ri";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { BootyCheckbox, BootyPagination } from "../../../components";
import { useSelector } from "react-redux";

const LeistungTable = ({
  onDelete,
  onEdit,
  leistungData = [],
  fahrerData = [],
  fahrzeugesData = [],
  toursData = [],
}) => {
  const { user } = useSelector((state) => state.auth);
  const isAdmin = user?.role === "ADMIN";

  const actionBtn = [
    { name: "Copy", className: "btn btn-info" },
    { name: "Excel", className: "btn btn-success" },
    { name: "PDF", className: "btn btn-danger" },
    { name: "YAZICI", className: "btn btn-outline-secondary" },
    { name: "Titel", className: "btn btn-outline-secondary" },
    { name: "SearchPanes", className: "btn btn-outline-secondary" },
  ];

  const columns = [
    {
      name: "Datum",
      selector: (row) => {
        const date = new Date(row.dbdatum);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
        return formattedDate;
      },
      sortable: true,
      width: "130px",
    },
    {
      name: "Tour",
      selector: (row) => row._tour?.tourname || "Unbekannt",
      sortable: true,
      width: "120px",
    },
    {
      name: "Fahrer",
      selector: (row) => {
        const matchingFahrer = Array.isArray(fahrerData)
          ? fahrerData.find((fahrer) => fahrer.id === row.fahrer)
          : undefined;
        return matchingFahrer ? matchingFahrer.realm : row.fahrer;
      },
      sortable: true,
      width: "120px",
      sortFunction: (rowA, rowB) => {
        const realmA =
          fahrerData.find((f) => f.id === rowA.fahrer)?.realm || "Unbekannt";
        const realmB =
          fahrerData.find((f) => f.id === rowB.fahrer)?.realm || "Unbekannt";
        return realmA.localeCompare(realmB, "tr", { sensitivity: "base" });
      },
    },
    {
      name: "Start-KM",
      selector: (row) => row.startkm,
      sortable: true,
      width: "100px",
    },
    {
      name: "End-KM",
      selector: (row) => row.endkm,
      sortable: true,
      width: "100px",
    },
    {
      name: "Kennz.",
      selector: (row) => {
        
        const matchingFahrzeug = fahrzeugesData.find(
          (fahrzeug) => fahrzeug.id === row.fahrzeugId
        );
       
        return matchingFahrzeug
          ? matchingFahrzeug.kfzkennzeichen
          : row.fahrzeugId;
      },
      sortable: true,
      width: "120px",
    },
    {
      name: "Fahrt",
      selector: (row) => row.sonderfahrt,
      sortable: true,
      width: "120px",
    },
    {
      name: "Notiz",
      selector: (row) => row.notiz,
      sortable: true,
      width: "250px",
      sortFunction: (a, b) => {
        const notizA = a.notiz ? a.notiz : "";
        const notizB = b.notiz ? b.notiz : "";
        return notizA.localeCompare(notizB, "tr", { sensitivity: "base" });
      },
    },
    {
      name: "Action",
      selector: (row) => "action",
      sortable: false,
      right: "true",
      cell: (row) => (
        <div className="d-flex">
          <button
            onClick={() => onEdit(row)}
            className="btn btn-link text-primary"
          >
            <FiEdit />
          </button>
          <button
            onClick={() => onDelete(row.id)}
            className="btn btn-link text-danger"
          >
            <RiDeleteBinFill />
          </button>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: leistungData,
  };
  return (
    <div className="card mb-4">
      <div className="card-header ml-2 fs-5 text-white bg-primary p-3">
        Leistungs Übersicht
      </div>
      <div className="card-body">
        <div className="d-flex flex-wrap gap-2 mb-2">
          {isAdmin
            ? actionBtn.map((action, i) => (
                <button key={i} type="button" className={action.className}>
                  {action.icon} {action.name}
                </button>
              ))
            : null}
        </div>

        <div
          className="table-responsive overflow-scroll"
          style={{ height: "400px" }}
        >
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={leistungData}
              noHeader
              defaultSortField="id"
              pagination
              paginationComponent={BootyPagination}
              highlightOnHover={false}
              dense={false}
              selectableRowsHighlight={true}
              selectableRowsComponent={BootyCheckbox}
              fixedHeader={true}
              responsive={false}
            ></DataTable>
          </DataTableExtensions>
        </div>
      </div>
    </div>
  );
};

export default LeistungTable;


