import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const personalBogenApi = createApi({
  reducerPath: "personalBogenApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
 

  endpoints: (builder) => ({
    
    getPersonalBogen: builder.query({
      query: (token) => `personalbogens?access_token=${token}`,
    }),
    getUsers: builder.query({
      query: (token) => `users?access_token=${token}`,
    }),

     updatePersonal: builder.mutation({
      query: ({ id, updatedPersonal }) => ({
        url: `personalbogens/${id}`,
        method: "PUT",
        body: updatedPersonal,
      }),
    }),


   
    deletePersonal: builder.mutation({
      query: (id) => ({
        url: `personalbogens/${id}`,
        method: "DELETE",
      }),
    }),
   
    
    

  
  }),
});

export const {
  
  useGetUsersQuery,
  useGetPersonalBogenQuery,
  useUpdatePersonalMutation,
  useDeletePersonalMutation,

} = personalBogenApi;
