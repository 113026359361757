import React from "react";
import load from "../assets/loading.gif";

const Dokumantation = () => {
  return (
    <div className="d-flex align-content-center justify-content-center">
      <h1>DOKUMANTATION</h1>
      <div>
        <img src={load} alt="loading" width={300} />
      </div>
    </div>
  );
};

export default Dokumantation;
