import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { FaSave } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { reinigungsOpt, zahlungsOpt } from "./formElements";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";

const CarwashTable = ({
  modifiedData,
  onEdit,
  onDelete,
  fahrerOptions,
  fahrzeugOptions,
  getCarwashData,
}) => {
  const url = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.auth);
  const [updatingRowId, setUpdatingRowId] = useState(false);
  const fahrerOptLabel = fahrerOptions.map((f) => f.label);
  const fahrzeugOptLabel = fahrzeugOptions.map((f) => f.label);

  console.log(reinigungsOpt, "reinigungsOpt");

  const column = [
    {
      field: "pflegedatum",
      headerName: "Datum",
      width: 150,
      editable: true,
      type: "date",
      valueGetter: (params) => {
        const pflegedatum = params.value;
        if (!pflegedatum) {
          return "";
        }
        return new Date(pflegedatum);
      },
    },
    {
      field: "fahrer",
      headerName: "Fahrer",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: fahrerOptLabel,
    },
    {
      field: "fahrzeugId",
      headerName: "Kennzeichen",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: fahrzeugOptLabel,
    },
    {
      field: "pflegeart",
      headerName: "Reinigung",
      width: 150,
      editable: true,
      renderEditCell: (params) => {
        const valueArray =
          typeof params.value === "string"
            ? params.value.split(",").map((item) => item.trim())
            : params.value || [];

        const handleChange = (event) => {
          params.api.setEditCellValue({
            id: params.id,
            field: "pflegeart",
            value: event.target.value,
          });
        };

        return (
          <FormControl fullWidth>
            <Select
              multiple
              value={valueArray}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              {reinigungsOpt.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={valueArray.includes(option.value)} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "pflegezahlungsart",
      headerName: "Zahlung",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: zahlungsOpt,
    },
    {
      field: "pflegebetrag",
      headerName: "Betrag",
      width: 150,
      editable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "pflegenotiz",
      headerName: "Notiz",
      width: 150,
      editable: true,
      type: "text",
    },
    {
      field: "actions",
      headerName: "Aktionen",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex gap-3">
            <FaSave
              onClick={() => handleTableSubmit(params.row)}
              className="text-success"
            />
            <CiEdit
              className="text-primary"
              onClick={() => onEdit(params.row)}
            />
            <RiDeleteBin6Fill
              className="text-danger"
              onClick={() => onDelete(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  const handleTableSubmit = async (row) => {
    setUpdatingRowId(row.id); // Güncelleme başladığında güncellenen satırı belirt

    if (row.pflegedatum instanceof Date) {
      const utcDate = new Date(
        Date.UTC(
          row.pflegedatum.getFullYear(),
          row.pflegedatum.getMonth(),
          row.pflegedatum.getDate()
        )
      );
      row.pflegedatum = utcDate.toISOString().split("T")[0];
    }

    const updatedRow = {
      ...row,
      fahrer: fahrerOptions.find((fahrer) => fahrer.label === row.fahrer)
        ?.value,
      fahrzeugId: fahrzeugOptions.find(
        (fahrzeug) => fahrzeug.label === row.fahrzeugId
      )?.value,
      pflegedatum: new Date(row.pflegedatum).toISOString(),
    };

    await axios.put(
      `${url}autopfleges/${row.id}?access_token=${token}`,
      updatedRow
    );

    getCarwashData();
    setUpdatingRowId(null); // Güncelleme tamamlandığında null yaparak tabloyu aktif hale getir
  };

  const [selectedRowData, setSelectedRowData] = useState([]);

  const handleRowSelection = (selectionModel) => {
    const selectedRowsData = modifiedData.filter((row) =>
      selectionModel.includes(row.id)
    );

    setSelectedRowData(selectedRowsData);
  };

  React.useEffect(() => {
    if (selectedRowData.length > 0) {
    }
  }, [selectedRowData]);

  return (
    <div className="card my-3">
      <div className="card-header bg-primary text-white">Carwash Übersicht</div>
      <div className="card-body">
        <DataGrid
          rows={modifiedData}
          columns={column}
          style={{ maxHeight: 400, width: "100%", overflowX: "auto" }}
          hideFooterPagination
          density="compact"
          components={{
            Toolbar: GridToolbar,
          }}
          isRowSelectable={(params) =>
            !updatingRowId || params.id !== updatingRowId
          } // Güncelleme sırasında sadece güncellenen satır seçilebilir
          disableSelectionOnClick={!!updatingRowId} // Güncelleme sırasında satır seçimi devre dışı
          disableColumnMenu={true} // Kolon menüsünü devre dışı bırak
          onSelectionModelChange={handleRowSelection}
        />
      </div>
    </div>
  );
};

export default CarwashTable;
