import { Navigate, Outlet, useLocation } from "react-router-dom";
import { MainLayout } from "../layouts/MainLayout";
import { useSelector } from "react-redux";
import { useGetMeQuery } from "../app/services/users/usersApi";

export const PrivateRouter = () => {
  const { userRole, token, user } = useSelector((state) => state.auth);
  const location = useLocation();

  useGetMeQuery({ userId: user?.id, token });

  return userRole === "ADMIN" || userRole === "FAHRER" ? (
    <MainLayout>
      <Outlet context={{ userRole }} />
    </MainLayout>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
