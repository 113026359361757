import { useState } from "react";
import {
  FaCar,
  FaCertificate,
  FaPhone,
  FaRegCalendarAlt,
  FaRoute,
  FaTasks,
  FaUserFriends,
  FaUserInjured,
  FaUserPlus,
  FaUsers,
} from "react-icons/fa";
import {
  MdBusinessCenter,
  MdCarRepair,
  MdIncompleteCircle,
  MdOutlineTour,
} from "react-icons/md";
import { RiCarWashingFill, RiGridFill } from "react-icons/ri";

import useAuthCall from "../hooks/useAuthCall";
import { useDispatch, useSelector } from "react-redux";
import MenuListItemForm from "./MenuListItemForm";
import {
  clearSelectedData,
  setKundeCount,
  setMenuCount,
  setSelectedTagesTour,
} from "../app/services/tagesTour/tagesTourSlice";
import { IoMdPersonAdd } from "react-icons/io";
import { FaTruckPlane } from "react-icons/fa6";

// MenuListItem component definition
const MenuListItem = ({ open, setOpen }) => {
  // A flag to determine if the user is an admin
  const { userRole, user } = useSelector((state) => state.auth);

  // Destructuring properties from useAuthCall hook
  const { logout } = useAuthCall();

  // State for managing submenu visibility
  const [isMenuOpen, setMenuOpen] = useState(false);

  // Function to toggle submenu visibility
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  // List of menu items
  const ListItems = [
    ...(userRole === "FAHRER"
      ? [
          {
            name: "Terminplaner",
            href: `terminplaner/${user.id}`,
            icon: <FaRegCalendarAlt />,
          },
        ]
      : []),
    { name: "Erledigte", href: "erledigte", icon: <MdIncompleteCircle /> },
    { name: "Leistung", href: "leistung", icon: <FaRoute /> },
    { name: "TagesTour", href: "tagestourdetails", icon: <MdOutlineTour /> },

    { name: "RMA", href: "reklamation", icon: <MdBusinessCenter /> },
    // { name: "Tourplan", href: "tourplan", icon: <FaRegCalendarAlt /> },
    { name: "Car-Wash", href: "carwash", icon: <RiCarWashingFill /> },
    // { name: "Doku", href: "dokumantation", icon: <FaFolderClosed /> },
    ...(userRole === "ADMIN"
      ? [{ name: "Admin Panel", href: null, icon: <FaUserPlus /> }]
      : []),
  ];

  // Admin Panel and submenu items
  const AdminPanelItems = [
    { name: "Dashboard", href: "admindashboard", icon: <RiGridFill /> },
    // { name: "Abrechnung", href: "abrechnung", icon: <FaCartArrowDown /> },
    { name: "Ausgaben", href: "ausgaben", icon: <FaTasks /> },
    { name: "Benutzer", href: "benutzer", icon: <FaUserFriends /> },
    { name: "Bewerber", href: "bewerber", icon: <IoMdPersonAdd /> },
    { name: "Fahrzeug", href: "fahrzeug", icon: <FaCar /> },
    { name: "Kunden", href: "kunden", icon: <FaUserFriends /> },
    { name: "Partner", href: "partner", icon: <FaUserInjured /> },
    { name: "Personal", href: "personal", icon: <FaUsers /> },
    { name: "Telefon", href: "telefon", icon: <FaPhone /> },
     { name: "Tourplanen", href: "tourplanen", icon: <FaTruckPlane /> },
    { name: "Touren", href: "touren", icon: <FaRoute /> },
    { name: "Werkstatt", href: "werkstatt", icon: <MdCarRepair /> },
    { name: "Region", href: "region", icon: <FaRoute /> },
    {
      name: "Firmeninformation",
      href: "firmeninformation",
      icon: <FaUserFriends />,
    },
    { name: "Zertifikat", href: "zertifikat", icon: <FaCertificate /> },
  ];

  // Function to handle mouse enter event
  const handleMouseEnter = () => {
    setOpen(true);
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  // Function to handle logout click
  const handleLogoutClick = () => {
    dispatch(clearSelectedData());
    dispatch(setKundeCount(0));
    dispatch(setMenuCount(0));
    dispatch(setSelectedTagesTour(null));

    // Call the logout function when "Abmelden" is clicked
    logout();
  };

  // Rendering the component
  return (
    <div
      className="bg-dark h-100 d-flex flex-column gap-5 z-3"
      style={{
        width: open ? "180px" : "60px",
        zIndex: 99,
        transition: "width .3s ease",
        // boxShadow: "4px 7px 10px rgba(0,0,0,.4)",
        position: "relative",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <MenuListItemForm
        open={open}
        toggleMenu={toggleMenu}
        ListItems={ListItems}
        AdminPanelItems={AdminPanelItems}
        handleLogoutClick={handleLogoutClick}
        isAdmin={userRole === "ADMIN"}
        isMenuOpen={isMenuOpen}
      />
    </div>
  );
};

export default MenuListItem;
