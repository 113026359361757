import { Modal, Button } from "react-bootstrap";
import "./modals.css"

const AgbModal = ({ open, onClose }) => {
  return (
    <Modal
      show={open}
      onHide={onClose}
      dialogClassName="custom-modal"
      contentClassName="custom-modal-content"
    >
      <Modal.Header>
        <h3 className="font-semibold">
          Allgemeine Geschäftsbedingungen (AGB) für den Kurierdienst
        </h3>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-3">§ 1 Geltungsbereich und Vertragsgegenstand </p>
        <p className="mb-3">
          (1) Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") gelten
          für die Nutzung der Dienste des Kurierdienstes "OKAY Computer"
          (nachfolgend "Wir" oder "der Kurierdienst") durch die registrierten
          Nutzer.{" "}
        </p>
        <p className="mb-3">
          (2) Der Kurierdienst bietet Dienstleistungen zur Lieferung von
          Lebensmitteln und anderen Waren an private Kunden an.
        </p>
        <p className="mb-3">§ 2 Registrierung und Datennutzung</p>
        <p className="mb-3">
          (1) Zur Nutzung der Dienste des Kurierdienstes ist die Registrierung
          als Fahrer erforderlich. Während des Registrierungsprozesses sind die
          Fahrer verpflichtet, genaue und vollständige Informationen zur
          Verfügung zu stellen.{" "}
        </p>
        <p className="mb-3">
          (2) Die vom Fahrer bereitgestellten Informationen werden für die
          Verwaltung der Arbeitsverträge und die behördlichen Anmeldungen
          verwendet.{" "}
        </p>
        <p className="mb-3">
          (3) Der Fahrer erklärt sich damit einverstanden, dass seine Daten
          gemäß den geltenden Datenschutzbestimmungen verwendet werden.
        </p>
        <p className="mb-3">§ 3 Nutzung der Liefer-App</p>
        <p className="mb-3">
          (1) Die Fahrer sind verpflichtet, die zur Verfügung gestellte
          Liefer-App gemäß den Anweisungen und Vorschriften des Kurierdienstes
          zu verwenden.{" "}
        </p>
        <p className="mb-3">
          (2) Die Liefer-App kann auch den Zugriff auf die Standortdaten des
          Fahrers erfordern, um Lieferungen effizient durchzuführen. Der Fahrer
          erklärt sich mit der Erfassung und Nutzung dieser Daten einverstanden.
        </p>
        <p className="mb-3">§ 4 Berechtigung zur Schlüsselübergabe</p>
        <p className="mb-3">
          (1) Der Kurierdienst ist berechtigt, im Einvernehmen mit dem Kunden
          die Zustellung der Waren auch im Innenbereich der Wohnung oder des
          Hauses vorzunehmen.{" "}
        </p>
        <p className="mb-3">
          (2) Die Fahrer sind dazu angehalten, sorgfältig und respektvoll mit
          den Räumlichkeiten und dem Eigentum der Kunden umzugehen.
        </p>
        <p className="mb-3">§ 5 Akzeptanz der AGB</p>
        <p className="mb-3">
          (1) Jeder Fahrer ist verpflichtet, diese AGB zu akzeptieren, bevor er
          die Dienste des Kurierdienstes nutzen kann.
        </p>
        <p className="mb-3">
          (2) Die AGB regeln das Vertragsverhältnis zwischen dem Fahrer und dem
          Kurierdienst.
        </p>
        <p className="mb-3">§ 6 Umgang mit Schlüsseln </p>
        <p className="mb-3">
          (1) Schlüssel, z.B. Haustürschlüssel von Kunden, sind sorgfältig
          aufzubewahren und vor unbefugtem Zugriff zu schützen.{" "}
        </p>
        <p className="mb-3">
          (2) Schlüssel dürfen nur für die Vertragserfüllung genutzt und an
          vertrauenswürdige Mitarbeiter übergeben werden.{" "}
        </p>
        <p className="mb-3">
          (3) Verlust oder missbräuchliche Nutzung von Schlüsseln ist zu
          verhindern.{" "}
        </p>
        <p className="mb-3">
          (4) Ein Schlüsselverlust ist dem Auftraggeber unverzüglich zu melden.
        </p>
        <p className="mb-3">
          (5) Schlüssel sind bei Aufforderung oder spätestens bei Vertragsende
          zurückzugeben.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant="primary">
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AgbModal;
