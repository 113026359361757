import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAddPersonalMutation } from "../../../../app/services/register/registerApi";
import {
  useGetPersonalBogenQuery,
  useUpdatePersonalMutation,
} from "../../../../app/services/personalBogen/personalBogenApi";
import PersonalBogenForm from "./PersonalBogenForm";

import PersonalBogenModal from "./PersonalBogenModal";
import * as Yup from "yup";
import { formValidation, initialValue } from "./formElements";
import useAuthCall from "../../../../hooks/useAuthCall";
import axios from "axios";
const PersonalBogen = () => {
  const [formData, setFormData] = useState([]);
  const { token, user } = useSelector((state) => state.auth);
  const [addPersonal] = useAddPersonalMutation();
  const [updatePersonal] = useUpdatePersonalMutation();
  const { data: personalData } = useGetPersonalBogenQuery(token);
  const [openModal, setOpenModal] = useState(null);
  const { logout } = useAuthCall();
  const url = process.env.REACT_APP_BASE_URL;

  console.log("personalData", personalData);
  const matchData = useMemo(() => {
    return (
      Array.isArray(personalData) &&
      personalData
        .filter((item) => item.userId === user.id)
        .map((item2) => item2)
    );
  }, [personalData, user.id]);

  useEffect(() => {
    if (matchData && matchData.length > 0) {
      const matchedData = matchData[0];

      console.log("matchedData", matchedData);

      setFormData((prevData) => ({
        ...prevData,
        personvorname: matchedData.personvorname || "",
        personnachname: matchedData.personnachname || "",
        personstr: matchedData.personstr || "",
        personplz: matchedData.personplz || "",
        festtelefon: matchedData.festtelefon || "",
        mobiltelefon: matchedData.mobiltelefon || "",
        personort: matchedData.personort || "",
        persongebdatum:
          new Date(matchedData.persongebdatum).toISOString().split("T")[0] ||
          "",
        persongeschlecht: matchedData.persongeschlecht || "",
        personversnummer: matchedData.personversnummer || "",
        familienstand: matchedData.familienstand || "",
        gebort: matchedData.gebort || "",
        gebland: matchedData.gebland || "",
        staatsangehorigkeit: matchedData.staatsangehorigkeit || "",
        behindert: matchedData.behindert || "",
        personbankname: matchedData.personbankname || "",
        personiban: matchedData.personiban || "",
        wochenarbeitstage: matchedData.wochenarbeitstage.split(",") || [],
        steueridentnummer: matchedData.steueridentnummer || "",
        steuerklasse: matchedData.steuerklasse || "",
        konfession: matchedData.konfession || "",
        krankenkasse: matchedData.krankenkasse || "",
        krankenkassevnr: matchedData.krankenkassevnr || "",
        bewerbernotiz: matchedData.bewerbernotiz || "",
      }));
    }
  }, [matchData]);

  const validate = formValidation(Yup);

  const handleSubmit = async (values) => {
    values.bewerberarbeitstage = (values.bewerberarbeitstage ?? [])
      .sort((a, b) => {
        const weekdays = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
        return weekdays.indexOf(a) - weekdays.indexOf(b);
      })
      .join(",");

    console.log("values", values);

    if (matchData && matchData.length > 0) {
      const { data } = await axios.put(
        `${url}personalbogens/${matchData[0].id}?access_token=${token}`,
        { ...values, userId: user.id }
      );
      console.log("data", data);
    }
    if (matchData.length === 0) {
      const { data } = await axios.post(
        `${url}personalbogens?access_token=${token}`,
        { ...values, userId: user.id }
      );
      console.log("data", data);
    }
    setOpenModal(true);
    setTimeout(() => {
      logout();
    }, 5000);
  };

  return (
    <div
      className="container mt-3"
      style={{
        marginBottom: "100px",
      }}
    >
      <div className="card">
        <div className="card-header text-white bg-primary">
          Personalbogen - 1
        </div>
        <div className="card-body">
          <PersonalBogenForm
            initialValue={initialValue}
            formData={formData}
            handleSubmit={handleSubmit}
            validate={validate}
          />
        </div>
      </div>
      {openModal && <PersonalBogenModal />}
    </div>
  );
};

export default PersonalBogen;
