import React from 'react'
import load from "../../assets/loading.gif"

const Abrechnung = () => {
  return (
    <div className="d-flex align-content-center justify-content-center">
      <h1>Abrechnung</h1>
      <div>
        <img src={load} alt="loading" width={300} />
      </div>
    </div>
  );
}

export default Abrechnung
