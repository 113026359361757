import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import { toastErrorNotify } from "../helper/ToastNotify";
import { useDispatch, useSelector } from "react-redux";
import useAuthCall from "../hooks/useAuthCall";
import { loginSuccess } from "../features/authSlice";


function Login() {
  const { login } = useAuthCall();   
  const { email } = useSelector((state) => state.auth || {});
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await login({ email, password }).catch((error) => {
        dispatch(loginSuccess(response));
        console.log(error);
        toastErrorNotify("Entgültige E-Mail oder Password");
      });
    } catch (error) {
      console.error("Bei der Anmeldung ist ein Fehler aufgetreten", error);
    }
  };
  return (
    <section>
      <div className="bg-light py-3 py-md-5">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
              <div className="bg-white p-4 p-md-5 rounded shadow-sm">
                <form onSubmit={handleSubmit}>
                  <div className="row gy-3 gy-md-4 overflow-hidden">
                    <div className="col-12">
                      <label htmlFor="email" className="form-label fw-bold">
                        Email <span className="text-danger">*</span>
                      </label>

                      <div className="input-group">
                        <div className="input-group-text">
                          <MdEmail className="fs-4" />
                        </div>
                        <input
                          type="email"
                          id="email"
                          className="form-control form-control-lg "
                          placeholder="Geben sie eine gültige E-Mail-Adresse an"
                          value={email}
                          onChange={(e) =>
                            dispatch(
                              loginSuccess({ user: { email: e.target.value } })
                            )
                          }
                          required
                          autoFocus
                          onPaste={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="password" className="form-label fw-bold">
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <RiLockPasswordFill className="fs-4" />
                        </div>
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="form-control form-control-lg"
                          placeholder="Passwort eingeben"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          onPaste={(e) => e.preventDefault()}
                        />
                        <span
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={handlePasswordToggle}
                        >
                          {showPassword ? (
                            <MdVisibility className="fs-4" />
                          ) : (
                            <MdVisibilityOff className="fs-4" />
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="d-grid">
                        <button className="btn btn-primary btn-lg" type="submit">
                          ANMELDEN
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                <div className="row">
                  <div className="col-12">
                    <hr className="mt-5 mb-4 border-secondary-subtle" />
                    <div className="d-flex justify-content-center">
                      
                      <Link
                        to="/bewerbungsbogen"
                        className="link-lightblue text-decoration-none"
                      >
                        Bewerbungsform
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
