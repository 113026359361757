import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore, combineReducers } from "@reduxjs/toolkit";

// reducers
import tagesTourReducer from "./services/tagesTour/tagesTourSlice";
import optionSlice from "../components/OptionModel/option/optionSlice";
import registerReducer from "./services/register/registerSlice";
import werkstattSlice from "./services/werkstatt/werkstattSlice";
import terminplanerSlice from "../pages/terminplaner/terminplanerSlice";
import tourSlice from "../pages/Touren/core/tourSlice";
import SettingsSlice from "../features/SettingsSlice";
import regionsSlice from "./services/region/regionSlice";
import authSlice from "../features/authSlice";

// api services
import { partnerApi } from "./services/partner/partnerApi";
import { werkstattApi } from "./services/werkstatt/werkstattApi";
import { tourApi } from "../pages/Touren/core/tourApi";
import { telefonApi } from "../pages/adminPanel/telefon/telefonApi";
import { reklamationApi } from "../pages/reklamation/services/reklamationApi";
import { kundenApi } from "../pages/adminPanel/kunden/core/kundenApi";
import { personalBogenApi } from "./services/personalBogen/personalBogenApi";
import { optionApi } from "../components/OptionModel/option/optionApi";
import { registerApi } from "./services/register/registerApi";
import { leistungApi } from "../pages/leistung/core/leistungApi";
import { regionApi } from "./services/region/regionApi";
import { rmaAuswahlApi } from "./services/rmaAuswahl/rmaAuswahlApi";
import { tagesTourApi } from "./services/tagesTour/tagesTourApi";
import { usersApi } from "./services/users/usersApi";

const persistConfig = {
  key: "logistikv2",
  storage,
  // blacklist: [
  //   `${regionApi.reducerPath}`,
  //   "region",
  //   `${carwashApi.reducerPath}`,
  //   "carwash",
  //   `${optionApi.reducerPath}`,
  //   "option",
  // ],
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: authSlice,

 
  option: optionSlice,
  tour: tourSlice,
  settings: SettingsSlice,
  region: regionsSlice,
  tagesTourSlice: tagesTourReducer,
  registerSlice: registerReducer,
  werkstattSlice: werkstattSlice,
  terminplanerSlice: terminplanerSlice,
  // apis
  [leistungApi.reducerPath]: leistungApi.reducer,
  [reklamationApi.reducerPath]: reklamationApi.reducer,
  [registerApi.reducerPath]: registerApi.reducer,
  [personalBogenApi.reducerPath]: personalBogenApi.reducer,
  [kundenApi.reducerPath]: kundenApi.reducer,
  [werkstattApi.reducerPath]: werkstattApi.reducer,
 
  [tourApi.reducerPath]: tourApi.reducer,
  [optionApi.reducerPath]: optionApi.reducer,
  [tagesTourApi.reducerPath]: tagesTourApi.reducer,
  [regionApi.reducerPath]: regionApi.reducer,
  [telefonApi.reducerPath]: telefonApi.reducer,
  [rmaAuswahlApi.reducerPath]: rmaAuswahlApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [
  tagesTourApi.middleware,
  regionApi.middleware,
  rmaAuswahlApi.middleware,
  optionApi.middleware,
  registerApi.middleware,
  leistungApi.middleware,
  kundenApi.middleware,
  personalBogenApi.middleware,
  reklamationApi.middleware,
  partnerApi.middleware,
  telefonApi.middleware,
  werkstattApi.middleware,
  tourApi.middleware,
  usersApi.middleware,
];

const store = configureStore({
  reducer: persistedReducer,
  devTools: true, //process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
});

export let persistor = persistStore(store);

export default store;