import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const TourStatus = () => {
  const { token } = useSelector((state) => state.auth);

  const [logData, setLogData] = useState([]);
  const [fahrzeugesData, setFahrzeugesData] = useState([]);
  const [tagesTourData, setTagesTourData] = useState([]);
  const [user, setUser] = useState([]);
  const url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      const logRes = await axios.get(`${url}logs?access_token=${token}`);
      setLogData(logRes.data);
      const fahrzeugesRes = await axios.get(
        `${url}fahrzeuges?access_token=${token}`
      );
      setFahrzeugesData(fahrzeugesRes.data);
      const tagesTourRes = await axios.get(
        `${url}tagestours?access_token=${token}`
      );
      setTagesTourData(tagesTourRes.data);
      const userRes = await axios.get(`${url}users?access_token=${token}`);
      setUser(userRes.data);
    };

    fetchData();
  }, [token, url]);

  const uniqueSortedTours = [
    ...new Set(tagesTourData.map((tour) => tour.t_tour)),
  ].sort();

  const rows = uniqueSortedTours.map((tourName, index) => {
    const tour = tagesTourData.find((t) => t.t_tour === tourName);
    const log = logData.find((l) => l.tourId === tour?.id);
    const fahrzeug = fahrzeugesData.find(
      (f) => f.id === log?.fahrzeugId
    )?.kfzkennzeichen;
    const userName = user.find((u) => u.id === log?.userId)?.username;

    // Aktif turların filtrelenmesi

    return {
      id: index,
      tourName,
      fahrzeug: fahrzeug || "---",
      userName: userName || "---",
    };
  });

  const activeTours = rows.filter(
    (row) => row.fahrzeug !== "---" && row.userName !== "---"
  );

  // Inaktif turların filtrelenmesi
  const inactiveTours = rows.filter(
    (row) => row.fahrzeug === "---" || row.userName === "---"
  );

  const columns = [
    { field: "tourName", headerName: "Tour", flex: 1 },
    { field: "fahrzeug", headerName: "Fahrzeug", flex: 1 },
    { field: "userName", headerName: "User", flex: 1 },
  ];

  return (
    <div className="container">
      <div className="card">
        <div className="card-header bg-primary text-white">
          Tagestour Status
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-evenly m-3">
            <div
              style={{ width: "150px", height: "150px" }}
              className="rounded-circle shadow d-flex justify-content-center align-items-center bg-success text-white"
            >
              <div className="text-center">
                <h5>ACTIVE</h5>
                <span className="fw-semibold fs-4">{activeTours.length}</span>
              </div>
            </div>
            <div
              style={{ width: "150px", height: "150px" }}
              className="rounded-circle shadow d-flex justify-content-center align-items-center bg-danger text-white"
            >
              <div className="text-center">
                <h5>INACTIVE</h5>
                <span className="fw-semibold fs-4">{inactiveTours.length}</span>
              </div>
            </div>
          </div>
          <DataGrid
            rows={rows}
            columns={columns}
            hideFooterPagination
            density="compact"
            components={{
              Toolbar: GridToolbar,
            }}
            getRowClassName={(params) =>
              params.row.fahrzeug !== "---" && params.row.userName !== "---"
                ? "text-success"
                : "text-danger"
            }
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                fontWeight: 400,
                background: "var(--primary-selected, rgba(33, 150, 243, 0.08))",
              },
            }}
            style={{
              height: 400,
              width: "100%",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TourStatus;
