import React from "react";

const AutoFüllerTable = ({ autoFüllerData, kundenData = [], onRowClick }) => {
  const columnTitel = [
    "kundentournr",
    "kundenname",
    "kundenstr",
    "kundenplz",
    "kundenort",
    "anlaufpunkt",
    "kundentel",
    "kundennummer",
    "kundengps",
  ];

  // kundenData içindeki kundennummer'ları bir set olarak toplar
  const kundenNummernSet = new Set(kundenData.map((item) => item.kundennummer));

  // kundennummer'a göre eşleşme kontrolü ve uygun class ataması için bir fonksiyon
  const getRowClass = (kundennummer) => {
    return kundenNummernSet.has(kundennummer) ? "text-danger" : "";
  };

  return (
    <>
      {autoFüllerData && autoFüllerData.length > 0 ? (
        <div className="card mt-2">
          <div className="card-body">
            <div
              className="table-responsive overflow-scroll"
              style={{ height: "300px" }}
            >
              <table className="table table-hover">
                <thead className="table-dark position-sticky top-0">
                  <tr>
                    {columnTitel.map((titel, i) => (
                      <th key={i} scope="col">
                        {titel}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {autoFüllerData.map((row, rowIndex) => {
                    // kundennummer değeri sondan bir önceki sütunda olduğu için
                    const kundennummer = row[row.length - 2]; // Sondan bir önceki sütunu kundennummer olarak kabul ediyoruz
                    const rowClass = getRowClass(kundennummer);
                    return (
                      <tr
                        key={rowIndex}
                        onClick={() => onRowClick(row)}
                        className={rowClass}
                      >
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex} className={rowClass}>
                            {cell}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AutoFüllerTable;
