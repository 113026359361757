import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const leistungApi = createApi({
  reducerPath: "leistungApi",
  baseQuery: fetchBaseQuery({ baseUrl }),

  endpoints: (builder) => ({
    getLeistungs: builder.query({
      query: ({ token, userRole, userId }) => {
        const endpoint =
          userRole === "FAHRER" ? `users/${userId}/leistungs` : `leistungs`;
        return `${endpoint}?access_token=${token}`;
      },
    }),
    getFahrer: builder.query({
      query: ({ token, userRole, userId }) => {
        const endpoint = userRole === "FAHRER" ? `users/${userId}` : `users`;
        return `${endpoint}?access_token=${token}`;
      },
    }),
    getTours: builder.query({
      query: (token) => `tours?access_token=${token}`,
    }),
    getFahrzeuges: builder.query({
      query: (token) => `fahrzeuges?access_token=${token}`,
    }),

    createLeistung: builder.mutation({
      query: (newData) => ({
        url: "leistungs",
        method: "POST",
        body: newData,
      }),
    }),

    updateLeistung: builder.mutation({
      query: ({ id, updatedData }) => ({
        url: `leistungs/${id}`,
        method: "PUT",
        body: updatedData,
      }),
    }),

    deleteLeistung: builder.mutation({
      query: (id) => ({
        url: `leistungs/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});


export const {
  useGetLeistungsQuery,
  useGetFahrerQuery,
  useGetToursQuery,
  useGetFahrzeugesQuery,
  useCreateLeistungMutation,
  useUpdateLeistungMutation,
  useDeleteLeistungMutation,
} = leistungApi;
