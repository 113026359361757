import { useState } from "react";

const Form = () => {
  const [records, setRecords] = useState([]);

  const [formData, setFormData] = useState({
    Anrede: "",
    Name: "",
    Nachname: "",
    Email: "",
    Loginname: "",
    Passwort: "",
    Kürzel: "",
    Rolle: "",
    Region: []
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Handle checkbox input separately
      const updatedRegions = checked
        ? [...formData.Region, name]
        : formData.Region.filter(region => region !== name);

      setFormData({
        ...formData,
        Region: updatedRegions
      });
    } else {
      // Handle other input types
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };
const handleSubmit = (e) => {
  e.preventDefault();

  if (formData.Rolle === "" || formData.Region === "") {
  alert("Bitte wählen Sie Rolle und Region.");
  return;
  }

  const newRecord = { ...formData };
  setRecords([...records, newRecord]);
  setFormData({
    Anrede: "",
    Name: "",
    Nachname: "",
    Email: "",
    Loginname: "",
    Passwort: "",
    Kürzel: "",
    Rolle: "",
    Region: []
  });
};
  return (
    <div className="card">
      <div className="card-header text-white bg-primary">Benutzer</div>
      <div className="card-body">
      <form className="d-sm-flex flex-wrap gap-2" onSubmit={handleSubmit}>
          {/* Anrede: */}
          <div className="form-group flex-fill">
            <label htmlFor="txtAnrede">Anrede </label>
            <input name="anrede" type="text" className="form-control" id="txtAnrede" required="" onChange={handleInputChange}/>
          </div>
          {/* Name */}
          <div className="form-group flex-fill">
            <label htmlFor="txtName">Name </label>
            <input name="name" type="text" className="form-control" id="txtName" required="" onChange={handleInputChange}/>
          </div>
          {/* Nachname */}
          <div className="form-group flex-fill">
            <label htmlFor="txtNachname">Nachname </label>
            <input name="nachname" type="text" className="form-control" id="txtNachname" required="" onChange={handleInputChange}/>
          </div>
          {/* Email */}
          <div className="form-group flex-fill">
            <label htmlFor="txtEmail">Email </label>
            <input name="email" type="email" className="form-control" id="txtEmail" required="" onChange={handleInputChange}/>
          </div>
          {/* Loginname */}
          <div className="form-group flex-fill">
            <label htmlFor="txtLoginname">Loginname </label>
            <input name="loginname" type="text" className="form-control" id="txtLoginname" required="" onChange={handleInputChange}/>
          </div>
          {/* Passwort */}
          <div className="form-group flex-fill">
            <label htmlFor="txtPasswort">Passwort </label>
            <input name="passwort" type="password" className="form-control" id="txtPasswort" required="" onChange={handleInputChange}/>
          </div>
          {/* Kürzel */}
          <div className="form-group flex-fill">
            <label htmlFor="txtKuerzel">Kürzel </label>
            <input name="kuerzel" type="text" className="form-control" id="txtKuerzel" required="" onChange={handleInputChange}/>
          </div>
          {/* Rolle */}
          <div className="form-group flex-fill">
            <label htmlFor="txtRolle">Rolle </label>
            {/* select */}
            <select name="txtRolle" id="txtRolle" className="form-select form-control">
              <option >Wählen Sie eine Option aus!</option>
              <option value="Gast">Gast</option>
              <option value="Admin">Admin</option>
              <option value="Fahrer">Fahrer</option>
              <option value="Kunden">Passiv</option>
            </select>
          </div>
          {/* Region Chekbox*/}
          <div className="flex-fill">
            <label>Region</label>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="Region 1" id="flexCheckRegion1" />
              <label className="form-check-label" htmlFor="flexCheckRegion1">
                Region 1
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="Region 2" id="flexCheckRegion2" />
              <label className="form-check-label" htmlFor="flexCheckRegion2">
                Region 2
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="Region 3" id="flexCheckRegion3" />
              <label className="form-check-label" htmlFor="flexCheckRegion3">
                Region 3
              </label>
            </div>
          </div>
        </form>      
        {/* Save */}
        <button type="submit" className="btn btn-primary">Speichern</button>
      </div>
    </div>
  );
};
export default Form;
