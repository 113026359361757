import React, { useEffect, useState } from "react";

const KundenForm = ({
  formik,
  handleChange,
  onCoordinatesChange,
  toursData = [],
  kundenData = [],
}) => {
  const [tourData, setToursData] = useState(toursData);
  const [inputValue, setInputValue] = useState("");
 

  const handleCoordinatesUpdate = (lat, lng) => {
    formik.setFieldValue("kundengps.lat", lat);
    formik.setFieldValue("kundengps.lng", lng);
    // Üst bileşene koordinatları aktarma işlemi, sadece fonksiyon geçirilmişse yapılır
    if (typeof onCoordinatesChange === "function") {
      onCoordinatesChange([lat, lng]);
    }
  };

  // formik.values.kundentournr ile seçili turu senkronize eder
  useEffect(() => {
    setInputValue(formik.values.kundentournr || "");
  }, [formik.values.kundentournr]);

  // Yeni veya mevcut bir tur seçildiğinde çağrılır
  const handleSelectChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue("kundentournr", value);
  };

  // Yeni tur eklemek için kullanılan input'un değerini günceller
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue); // Güncel değeri doğrudan kullan

    // Kontrolü güncel input değeri ile yap
    if (newValue && !tourData.find((tour) => tour.tourname === newValue)) {
      const newTour = { tourname: newValue };
      setToursData((prevToursData) => [...prevToursData, newTour]);
      formik.setFieldValue("kundentournr", newValue);
    }
  };

  // Kundenschlüssel input alanı için özel onChange handler
  const handleSchlusselChange = (event) => {
    const { value, name } = event.target;
    const schlusselNum = Number(value); // Girdiyi sayıya çevir

    // Giriş değerini kontrol et
    if (schlusselNum !== 0 && schlusselNum !== 1 && schlusselNum !== 2) {
      // Hemen bir hata mesajı ayarla
      formik.setFieldError(name, "Nur 0, 1 oder 2");
    } else {
      // Hata yoksa, değeri güncelle ve herhangi bir hatayı temizle
      formik.setFieldValue(name, value);
      formik.setFieldError(name, "");
    }
  };

  // Koordinatlar için özel handleChange fonksiyonu
  const handleCoordinateChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("kundengps", value);
    const [lat, lng] = value.split(",").map(Number);
    if (!isNaN(lat) && !isNaN(lng)) {
      handleCoordinatesUpdate(lat, lng);
    }
  };

  // Koordinat değerlerinin gösterimi için düzenlenmiş kontrol
  const kundengpsValue =
    formik.values.kundengps.lat !== undefined &&
    formik.values.kundengps.lng !== undefined
      ? `${formik.values.kundengps.lat},${formik.values.kundengps.lng}`
      : ""; // Eğer lat veya lng undefined ise, boş bir string göster

  const formRows = [
    [
      {
        label: "Tour",
        type: "select",
        name: "kundentournr",
        options: toursData,
        value: formik.values.kundentournr,
        onChange: handleSelectChange,
        customInput: (
          <div className="d-flex flex-column">
            <span className="text-center text-danger fw-semibold">oder</span>
            <input
              type="text"
              className="form-control"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Neue Tour hinzufügen"
            />
          </div>
        ),
      },
      { label: "Kundennummer", type: "text", name: "kundennummer" },
      { label: "Anlaufpunkt", type: "text", name: "anlaufpunkt" },
      { label: "AnlaufPunktNeu", type: "text", name: "anlaufneu" },
    ],
    [
      { label: "Name", type: "text", name: "kundenname", required: true },
      { label: "Strasse", type: "text", name: "kundenstr" },
      { label: "PLZ", type: "text", name: "kundenplz", required: true },
      { label: "Ort", type: "text", name: "kundenort" },
    ],
    [
      { label: "Telefon", type: "text", name: "kundentel" },
      {
        label: "Koordinaten (lat,lng)",
        type: "text",
        name: "kundengps",
        value: kundengpsValue,
        onChange: handleCoordinateChange,
      },
      { label: "Menü", type: "text", name: "kundenmenu" },
      {
        label: "Schlüssel",
        type: "text",
        name: "kundenschlussel",
        placeholder: "Nur 0, 1 oder 2",
      },
    ],
  ];
  

  return (
    <>
      <form
        className="border rounded-2 overflow-hidden px-2"
        onSubmit={formik.handleSubmit}
      >
        {formRows.map((row, rowIndex) => (
          <div className="row px-2 py-2" key={rowIndex}>
            {row.map((field, index) => (
              <div className={`form-group col-12 col-md-3 mb-2`} key={index}>
                <label className="fw-semibold" htmlFor={field.name}>
                  {field.label}{" "}
                  {field.required && <span className="text-danger">*</span>}
                </label>
                {field.type === "select" ? (
                  <>
                    <select
                      name={field.name}
                      className="form-select form-control"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Tour Auswählen</option>
                      {field.options.map((option, optionIndex) => (
                        <option key={optionIndex} value={option.tourname}>
                          {option.tourname}
                        </option>
                      ))}
                    </select>
                    {field.customInput}
                  </>
                ) : (
                  <input
                    type={field.type}
                    className="form-control"
                    name={field.name}
                    id={field.name}
                    placeholder={field.placeholder}
                    value={
                      field.value ? field.value : formik.values[field.name]
                    }
                    onChange={
                      field.name === "kundenschlussel"
                        ? handleSchlusselChange
                        : handleChange
                    }
                    onBlur={formik.handleBlur}
                  />
                )}
                {formik.touched[field.name] && formik.errors[field.name] && (
                  <div className="error text-danger">
                    {formik.errors[field.name]}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
        <div className="form-group mx-2">
          <label className="fw-semibold" htmlFor="kundennotiz">
            Notiz{" "}
          </label>
          <textarea
            name="kundennotiz"
            className="form-control"
            id="kundennotiz"
            rows="3"
            value={formik.values.kundennotiz}
            onChange={handleChange}
            onBlur={formik.handleBlur}
          ></textarea>
        </div>
        <div className="d-flex gap-2 ms-2 my-2">
          <button type="submit" className="btn btn-primary">
            Senden
          </button>
        </div>
      </form>
    </>
  );
};

export default KundenForm;
