import { Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import ReactDOMServer from "react-dom/server";

const PersonalTable = ({ onDelete, onEdit, modifiedData }) => {
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const sendEmail = () => {
    const selectedData = modifiedData
      .filter((data) => rowSelectionModel.includes(data.id))
      .map((data) => data);

    // Mailto link oluştur
    const emailBody = selectedData
      .map((data) => {
        return (
          <table>
            <thead>
              <tr>
                <th className="text-danger">Vorname</th>
                <th>Nachname</th>
                <th>Mobiltelefon</th>
                <th>Straße</th>
                <th>PLZ</th>
                <th>Ort</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data.personvorname}</td>
                <td>{data.personnachname}</td>
                <td>{data.mobiltelefon}</td>
                <td>{data.personstr}</td>
                <td>{data.personplz}</td>
                <td>{data.personort}</td>
              </tr>
            </tbody>
          </table>
        );
      })
      .join("\n");

    const emailBodyString = emailBody
      .map((element) => ReactDOMServer.renderToStaticMarkup(element))
      .join("");

    const mailtoLink = `mailto:?body=${encodeURIComponent(emailBodyString)}`;

    window.location.href = mailtoLink;
  };

  const column = [
    { field: "personvorname", headerName: "Vorname", width: 150 },
    { field: "personnachname", headerName: "Nachname", width: 150 },
    { field: "mobiltelefon", headerName: "Mobiltelefon", width: 150 },
    { field: "personstr", headerName: "Straße", width: 150 },
    { field: "personplz", headerName: "PLZ", width: 150 },
    { field: "personort", headerName: "Ort", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-center align-items-center gap-3">
            <CiEdit
              className="text-primary"
              onClick={() => onEdit(params.row)}
            />
            <RiDeleteBin6Line
              className="text-danger"
              onClick={() => onDelete(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="card my-3">
      <div className="card-header text-white bg-primary ">
        Personal Übersicht
      </div>
      <div className="card-body">
        <DataGrid
          rows={modifiedData}
          columns={column}
          style={{ maxHeight: 400, width: "100%", overflowX: "auto" }}
          GridToolbar
          checkboxSelection
          hideFooterPagination
          components={{
            Toolbar: () => (
              <div className="d-flex align-items-center">
                <GridToolbar />
                <Button color="primary" onClick={sendEmail}>
                  Email
                </Button>
              </div>
            ),
          }}
          density="compact"
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
        />
      </div>
    </div>
  );
};

export default PersonalTable;
