import * as React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaSave } from "react-icons/fa";
import axios from "axios";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
} from "@mui/material";
import CustomToolbar from "./CustomToolbar";

export default function WerkstattTable({
  handleEdit,
  handleDelete,
  modifiedData,
  fahrerOptions,
  partnerOptions,
  fahrzeugOptions,
  serviceOptions,
  serviceartOptions,
  statusOptions,
  userData,
  partnerData,
  fahrzeugsData,
  setSubmitMsg,
  setEditData,
}) {
  const { token } = useSelector((state) => state.auth);
  const url = process.env.REACT_APP_BASE_URL;

  const [tableMsg, setTableMsg] = useState(null);

  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: statusOptions,
      cellClassName: (params) =>
        params.value === "Bestätigt"
          ? "text-warning"
          : params.value === "Erledigt"
          ? "text-success"
          : params.value === "Terminanfordern"
          ? "text-danger"
          : params.value === "in Arbeit"
          ? "text-primary"
          : " ",
    },
    {
      field: "serviceart",
      headerName: "Serviceart",
      width: 150,
      editable: true,
      renderEditCell: (params) => {
        const valueArray =
          typeof params.value === "string"
            ? params.value.split(",").map((item) => item.trim())
            : params.value || [];

        const handleChange = (event) => {
          params.api.setEditCellValue({
            id: params.id,
            field: "serviceart",
            value: event.target.value,
          });
        };

        return (
          <FormControl fullWidth>
            <Select
              multiple
              value={valueArray}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              {serviceartOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={valueArray.includes(option)} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "fahrer",
      headerName: "Fahrer",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: fahrerOptions.map((item) => ({
        value: item.id,
        label: item.name, // Her bir şoförün ismi
      })),
    },
    {
      field: "partnerId",
      headerName: "Partner",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: partnerOptions.map((item) => ({
        value: item.id,
        label: item.partnername, // Her bir partnerin ismi
      })),
    },
    {
      field: "werkkosten",
      headerName: "Kosten",
      width: 150,
      editable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "fahrzeugId",
      headerName: "Fahrzeug",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueGetter: (params) => params.row.kennzeichen 
    },
    {
      field: "werkkilometer",
      headerName: "Kilometer",
      width: 150,
      editable: true,
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "werkdatum",
      headerName: "Termin",
      width: 150,
      editable: true,
      type: "date",
      valueGetter: (params) => {
        return new Date(params.value);
      },
    },

    {
      field: "werkuhrzeit",
      headerName: "Uhrzeit",
      width: 150,
      editable: true,
    },
    {
      field: "abholdatum",
      headerName: "Abholdatum",
      width: 150,
      editable: true,
      type: "date",
      valueGetter: (params) => {
        const abholdatum = params.value;
        if (!abholdatum) {
          return "";
        }
        return new Date(abholdatum);
      },
    },
    {
      field: "abholzeit",
      headerName: "Abholzeit",
      width: 150,
      editable: true,
      // type: "time",
    },

    { field: "werknotiz", headerName: "Notiz", width: 150, editable: true },
    {
      field: "service",
      headerName: "Service",
      width: 150,
      editable: true,
      renderEditCell: (params) => {
        const valueArray =
          typeof params.value === "string"
            ? params.value.split(",").map((item) => item.trim())
            : params.value || [];

        const handleChange = (event) => {
          params.api.setEditCellValue({
            id: params.id,
            field: "service",
            value: event.target.value,
          });
        };

        return (
          <FormControl fullWidth>
            <Select
              multiple
              value={valueArray}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              {serviceOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={valueArray.includes(option)} />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <div className="d-flex gap-3">
          <FaSave
            onClick={() => handleTableSubmit(params.row)}
            className="text-success"
          />
          <FaEdit
            className="text-primary"
            onClick={() => handleEdit(params.row)}
          />
          <RiDeleteBin6Fill
            className="text-danger"
            onClick={() => handleDelete(params.row.id)}
          />
        </div>
      ),
    },
  ];

  const handleTableSubmit = async (values) => {
    try {
      const rowValues = { ...values };
      const selectedFahrerID =
        Array.isArray(userData) &&
        userData
          .filter((user) => user.username === rowValues.fahrer)
          .map((user) => user.id)[0];

      const selectedPartnerID =
        Array.isArray(partnerData) &&
        partnerData
          .filter((partner) => partner.partnername === rowValues.partnerId)
          .map((partner) => partner.id)[0];

      const selectedFahrzeugID =
        Array.isArray(fahrzeugsData) &&
        fahrzeugsData
          .filter(
            (fahrzeug) => fahrzeug.kfzkennzeichen === rowValues.fahrzeugId
          )
          .map((fahrzeug) => fahrzeug.id)[0];

      const selectedServiceart = Array.isArray(rowValues.serviceart)
        ? rowValues.serviceart.map((item) => item)
        : rowValues.serviceart.split(",").map((item) => item.trim());

      const selectedService = Array.isArray(rowValues.service)
        ? rowValues.service.map((item) => item)
        : rowValues.service.split(",").map((item) => item.trim());

      rowValues.fahrer = selectedFahrerID;
      rowValues.partnerId = selectedPartnerID;
      rowValues.fahrzeugId = selectedFahrzeugID;
      rowValues.serviceart = selectedServiceart;
      rowValues.service = selectedService;

      if (rowValues.werkdatum instanceof Date) {
        const utcDate = new Date(
          Date.UTC(
            rowValues.werkdatum.getFullYear(),
            rowValues.werkdatum.getMonth(),
            rowValues.werkdatum.getDate()
          )
        );
        rowValues.werkdatum = utcDate.toISOString().split("T")[0];
      }

      if (rowValues.abholdatum instanceof Date) {
        const utcDate = new Date(
          Date.UTC(
            rowValues.abholdatum.getFullYear(),
            rowValues.abholdatum.getMonth(),
            rowValues.abholdatum.getDate()
          )
        );
        rowValues.abholdatum = utcDate.toISOString().split("T")[0];
      }

      await axios.patch(
        `${url}werkstatts/${values.id}?access_token=${token}`,
        rowValues
      );

      setTableMsg("Data updated successfully");
      setTimeout(() => {
        setTableMsg(null);
      }, 1000);
      setEditData(null);
    } catch (error) {
      console.log("Form validation errors:", error);
    }
  };

  const [selectedRowData, setSelectedRowData] = useState([]);

  const handleRowSelection = (selectionModel) => {
    const selectedRowsData = modifiedData.filter((row) =>
      selectionModel.includes(row.id)
    );

    setSelectedRowData(selectedRowsData);
  };

  React.useEffect(() => {
    if (selectedRowData.length > 0) {
    }
  }, [selectedRowData]);

  return (
    <div className="card my-3">
      <div className="card-header bg-primary text-white">
        Werkstatt Übersicht
      </div>
      {tableMsg && (
        <div className="card-header bg-success text-white">{tableMsg}</div>
      )}
      <div className="card-body">
        <div>
          <DataGrid
            rows={modifiedData}
            columns={columns}
            style={{ maxHeight: 400, width: "100%", overflowX: "auto" }}
            GridToolbar
            hideFooterPagination
            density="compact"
            // components={{
            //   Toolbar: () => (
            //     <div className="d-flex align-items-center">
            //       <GridToolbar />
            //       {/* <button
            //         className="border-0 bg-white d-flex gap-2"
            //         style={{ color: "#1976D2" }}
            //         // onClick={handleSendEmail}
            //       >
            //         <IoMailOutline className="fw-bold fs-5" />
            //         MAIL
            //       </button> */}
            //     </div>
            //   ),
            // }}
            components={{
              Toolbar: <CustomToolbar  />,
            }}
            onSelectionModelChange={handleRowSelection}
          />
        </div>
      </div>
    </div>
  );
}
