import React from "react";
import { BiLogInCircle } from "react-icons/bi";
import useAuthCall from "../../hooks/useAuthCall";

const ErfolgreicheAntragstellung = () => {
  const { logout } = useAuthCall();

  const goLoginPage = () => {
    logout();
  };
  return (
    <div className="row d-flex justify-content-center align-items-center position-absolute w-100 h-100 top-0 start-0  bg-light">
      <div className="col-10 col-sm-8 col-md-6 col-xl-4  rounded-5 bg-light d-flex flex-column justify-content-center align-items-center p-5 shadow m-2">
        <div className="p-1 p-sm-2 p-md-3">
          "Wir freuen uns, Ihnen mitteilen zu können, dass Ihre Bewerbung
          erfolgreich war. Herzlichen Dank für Ihr Interesse an der
          ausgeschriebenen Position. Aufgrund des großen Bewerbungsaufkommens
          benötigt die Überprüfung der Bewerbungen einige Arbeitstage. Wir
          bitten um Ihr Verständnis und Geduld während dieses Prozesses. Sie
          werden so bald wie möglich über den Status Ihrer Bewerbung
          informiert."
        </div>
        <div
          className="d-flex justify-content-center align-items-center gap-2 "
          style={{ fontSize: ".6rem" }}
        >
          <div>
            "Indem Sie auf diesen Link klicken, können Sie zur Anmeldeseite
            gelangen."
          </div>

          <BiLogInCircle className="fs-5 text-primary" onClick={goLoginPage} />
        </div>
      </div>
    </div>
  );
};

export default ErfolgreicheAntragstellung;
