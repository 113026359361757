import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const reklamationApi = createApi({
  reducerPath: "reklamationApi",
  baseQuery: fetchBaseQuery({ baseUrl }),

  endpoints: (builder) => ({
    getReklamations: builder.query({
      query: ({ token, userRole, userId }) => {
        const endpoint =
          userRole === "FAHRER"
            ? `users/${userId}/reklamations`
            : `reklamations`;
        return `${endpoint}?access_token=${token}`;
      },
    }),
    getKunden: builder.query({
      query: (token) => `kundens?access_token=${token}`,
    }),

    createReklamation: builder.mutation({
      query: (newData) => ({
        url: "reklamations",
        method: "POST",
        body: newData,
      }),
    }),

    updateReklamation: builder.mutation({
      query: ({ id, updatedData }) => ({
        url: `reklamations/${id}`,
        method: "PUT",
        body: updatedData,
      }),
    }),

    deleteReklamation: builder.mutation({
      query: (id) => ({
        url: `reklamations/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetReklamationsQuery,
  useGetKundenQuery,
  useCreateReklamationMutation,
  useUpdateReklamationMutation,
  useDeleteReklamationMutation,
} = reklamationApi;
