import React, { useState, useEffect } from "react";
import ZertifikatForm from "./ZertifikatForm";
import useAxios from "../../../hooks/useAxios";
import ZertifikatTable from "./ZertifikatTable";
import { toastSuccessNotify } from "../../../helper/ToastNotify";

const getUsers = async (axiosWithToken) => {
  return axiosWithToken.get("/users?filter[where][role]=FAHRER");
};

const loadUserCertificates = async (axiosWithToken) => {
  const resCertificates = await axiosWithToken.get("/zertifikats");
  const certificates = resCertificates.data;
  const mappedCertificates = [];
  for (const certificate of certificates) {
    if (!certificate.fahrer) {
      certificate.driver = null;
      continue;
    }
    try {
      const getDriver = await axiosWithToken.get(
        `/users/${certificate.fahrer}`
      );
      certificate.driver = getDriver.data;
      certificate.driverName = getDriver.data.name || "";
    } catch (error) {
      certificate.driver = null;
    }
    mappedCertificates.push(certificate);
  }
  return mappedCertificates;
};

const ZertifikatHome = () => {
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const { axiosWithToken } = useAxios();
  const [users, setUsers] = useState([]);
  const [userCertificates, setUserCertificates] = useState([]);
  const [editingCertificate, setEditingCertificate] = useState(null);

  useEffect(() => {
    getUsers(axiosWithToken).then((response) => setUsers(response.data));
    loadUserCertificates(axiosWithToken).then((data) =>
      setUserCertificates(data)
    );
  }, [fetchTrigger]);

  const handleAddCertificate = async (newCertificate) => {
    await axiosWithToken.post("/zertifikats", newCertificate);
    setFetchTrigger((prev) => prev + 1);
    toastSuccessNotify("Erfolgreich gesendet");
  };

  const handleEditZertifikate = (certificateId) => {
    const editedCertificate = userCertificates.find(
      (certificate) => certificate.id === certificateId
    );
    if (editedCertificate) {
      setEditingCertificate(editedCertificate);
    } else {
      console.error("Düzenlenecek sertifika bulunamadı!");
    }
  };

  const handleUpdateCertificate = async (updatedCertificate) => {
    try {
      await axiosWithToken.put(
        `/zertifikats/${updatedCertificate.id}`,
        updatedCertificate
      );
      setUserCertificates(await loadUserCertificates(axiosWithToken));
      setEditingCertificate(null);
      toastSuccessNotify("Erfolgreich aktualisiert");
    } catch (error) {
      console.error("Sertifika güncellenirken bir hata oluştu:", error);
    }
  };

  const deleteZertifikat = async (id) => {
    try {
      await axiosWithToken.delete(`/zertifikats/${id}`);
      setUserCertificates((prevCertificates) =>
        prevCertificates.filter((certificate) => certificate.id !== id)
      );
      toastSuccessNotify("Erfolgreich gelöscht!");
    } catch (error) {
      console.error("Zertifikat silinirken bir hata oluştu:", error);
    }
  };

  return (
    <>
      <ZertifikatTable
        userCertificates={userCertificates}
        editZertifikat={handleEditZertifikate}
        deleteZertifikat={deleteZertifikat}
      />
      {editingCertificate && (
        <div className="card my-4 mb-5 mt-5">
          <div className="card-header bg-primary text-white">
            Edit Zertifikat
          </div>
          <div className="card-body">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateCertificate(editingCertificate);
              }}
            >
              {/* Edit form fields */}
              <label>
                Fahrer:
                <input
                  type="text"
                  value={editingCertificate.driverName || ""}
                  readOnly
                />
              </label>
              <label>
                Kurs:
                <input
                  type="text"
                  value={editingCertificate.value || ""}
                  onChange={(e) =>
                    setEditingCertificate({
                      ...editingCertificate,
                      value: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Erwerbdatum:
                <input
                  type="date"
                  value={editingCertificate.erwerbdatum || ""}
                  onChange={(e) =>
                    setEditingCertificate({
                      ...editingCertificate,
                      erwerbdatum: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Enddatum:
                <input
                  type="date"
                  value={editingCertificate.enddatum || ""}
                  onChange={(e) =>
                    setEditingCertificate({
                      ...editingCertificate,
                      enddatum: e.target.value,
                    })
                  }
                />
              </label>
              <button type="submit">Update</button>
              <button type="button" onClick={() => setEditingCertificate(null)}>
                Cancel
              </button>
            </form>
          </div>{" "}
        </div>
      )}
      <ZertifikatForm users={users} onAddCertificate={handleAddCertificate} />
    </>
  );
};

export default ZertifikatHome;
