import { forwardRef, useId } from 'react';

export const BootyCheckbox = forwardRef(({ onClick, ...rest }, ref) => {
  const id = useId();
  return (
    <div className="form-check">
      <input
        id={id}
        type="checkbox"
        className="form-check-input"
        ref={ref}
        onClick={onClick}
        {...rest}
      />
      <label className="form-check-label" htmlFor={id} />
    </div>
  );
});
