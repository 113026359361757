import React from "react";
import Anmeldung from "../components/Anmeldung";

const Home = () => {

  return (
    <div className="container">
    <Anmeldung /> 
    </div>
  );
};

export default Home;
