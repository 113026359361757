import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobAppForm: [],
};

const registerSlice = createSlice({
  name: "registerSlice",
  initialState,
  reducers: {
    setJobAppForm: (state, action) => {
      state.jobAppForm = action.payload;
    },

    clearJobAppForm: (state) => {
      state.jobAppForm = [];
    },
  },
});

export const { setJobAppForm, clearJobAppForm } = registerSlice.actions;
export default registerSlice.reducer;
