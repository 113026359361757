import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const tagesTourApi = createApi({
  reducerPath: "tagesTourApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  // prepareHeaders: (headers, { getState }) => {
  //   const token = getState().auth.token;
  //   if (token) {
  //     headers.set("Authorization", `Bearer ${token}`);
  //   }
  //   return headers;
  // },

  endpoints: (builder) => ({
    getTagesTours: builder.query({
      query: (user, token) =>
        `users/${user.id}/tagestour?access_token=${token}`,
    }),
    getLog: builder.query({
      query: (token) => `logs?access_token=${token}`,
    }),
    getLeistungs: builder.query({
      query: (token) => `leistungs?access_token=${token}`,
    }),
    getTours: builder.query({
      query: (token) => `tours?access_token=${token}`,
    }),
    getFahrzeuge: builder.query({
      query: (token) => `fahrzeuges?access_token=${token}`,
    }),
    getUsers: builder.query({
      query: (token) => `users?access_token=${token}`,
    }),
    getKundens: builder.query({
      query: (token) => `kundens?access_token=${token}`,
    }),

    addLog: builder.mutation({
      query: (newLog) => ({
        url: "logs",
        method: "POST",
        body: newLog,
      }),
    }),
    deleteLog: builder.mutation({
      query: (id) => ({
        url: `logs/${id}`,
        method: "DELETE",
      }),
    }),
    addLeistungs: builder.mutation({
      query: (newData) => ({
        url: "leistungs",
        method: "POST",
        body: newData,
      }),
    }),

    updateLeistungs: builder.mutation({
      query: ({ id, updatedData }) => ({
        url: `leistungs/${id}`,
        method: "PUT",
        body: updatedData,
      }),
    }),

    deleteLeistungs: builder.mutation({
      query: (id) => ({
        url: `leistungs/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetTagesToursQuery,
  useGetLogQuery,
  useAddLogMutation,
  useDeleteLogMutation,
  useGetLeistungsQuery,
  useGetToursQuery,
  useGetFahrzeugeQuery,
  useGetUsersQuery,
  useGetKundensQuery,
  useAddLeistungsMutation,
  useUpdateLeistungsMutation,
  useDeleteLeistungsMutation,
} = tagesTourApi;
