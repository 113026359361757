import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const Logs = () => {
  const { token, user } = useSelector((state) => state.auth);
  const url = process.env.REACT_APP_BASE_URL;
  const [logData, setLogData] = React.useState([]);

  const fetchLogs = async () => {
    const { data } = await axios.get(`${url}logs?access_token=${token}`);
    setLogData(data);
    console.log(data, "data");
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const handleDelete = async (id) => {
    await axios.delete(`${url}logs/${id}?access_token=${token}`);
    fetchLogs();
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Del</th>
          </tr>
        </thead>
        <tbody>
          {logData.map((log) => (
            <tr key={log.id}>
              <td>{log.id}</td>
              <td>
                <button onClick={() => handleDelete(log.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Logs;
