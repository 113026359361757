import { createSlice } from '@reduxjs/toolkit';
import { regionApi } from './regionApi';

export const regionSlice = createSlice({
  name: 'region',
  initialState: {
    regions: [],
    region: {},
    refetchTrigger: 0,
    uniqueRegionsList: [],
  },
  reducers: {
    regionForEdit: (state, action) => {
      state.region = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(regionApi.endpoints.getRegions.matchFulfilled, (state, action) => {
      state.regions = action.payload;
    });

    // builder.addMatcher(regionApi.endpoints.createRegion.matchFulfilled, (state, action) => {
    //   state.regions = [...state.regions, action.payload];
    // });

    builder.addMatcher(regionApi.endpoints.fetchUsers.matchFulfilled, (state, action) => {
      state.uniqueRegionsList = action.payload;
    });
  },
});

export const { regionForEdit } = regionSlice.actions;

export default regionSlice.reducer;
