import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toggleSettingsModal } from "../../../features/SettingsSlice";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Region from "../../../pages/region/Region";
import RmaAuswahl from "../../../pages/rmaauswahl/RmaAuswahl";
import OptionModels from "../../../components/OptionModel/OptionModels";


const SettingsModal = () => {
  const dispatch = useDispatch();
  const [rmaClick, setRmaClick] = useState(false);
  const [regionClick, setRegionClick] = useState(false);

  const handleRmaClick = () => {
    setRmaClick(true);
    setRegionClick(false);
  };
  const handleRegionClick = () => {
    setRegionClick(true);
    setRmaClick(false);
  };
  
  return (
    <Modal
      size="xl"
      show={toggleSettingsModal}
      onHide={() => dispatch(toggleSettingsModal())}
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Einstellungen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
        
      >
        <OptionModels/>
        {/* <table className="table table-bordered table-secondary text-center">
          <thead>
            <tr className="row ">
              <th className="col ">
                <Link
                  className="text-decoration-none fw-semibold text-black"
                  onClick={handleRmaClick}
                >
                  Reklamation
                </Link>
              </th>
              <th className="col">
                <Link
                  className="text-decoration-none fw-semibold text-black"
                  onClick={handleRegionClick}
                >
                  Region
                </Link>
              </th>
            </tr>
          </thead>
        </table>

        {rmaClick && <RmaAuswahl />}
        {regionClick && <Region />} */}
      </Modal.Body>
    </Modal>
  );
};

export default SettingsModal;
