// data provides access to your row data
// const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { InputFeedback } from '../../../components';
import { useId } from 'react';

function ExpandedComponent({ data, updateRegionStatus }) {
  const generatedId = useId();
  console.log('ExpandedComponent: ', data);
  return (
    <div className="p-4 d-grid">
      <Formik
        enableReinitialize={true}
        initialValues={{ regionName: data.regionname, status: !!data.status }}
        validationSchema={Yup.object().shape({
          regionName: Yup.string()
            .required('Region Name ist erforderlich')
            .min(3, 'Region Name muss mindestens 3 Zeichen lang sein')
            .max(50, 'Region Name darf maximal 50 Zeichen lang sein'),
        })}
        onSubmit={(values, actions) =>
          updateRegionStatus(data.id, values.status, values.regionName)
        }
      >
        {({ handleSubmit, errors }) => (
          <form
            onSubmit={handleSubmit}
            className="row flex-wrap gap-4 align-items-center"
          >
            <div className="form-group col-sm-12 col-md-6 col-lg-4">
              <div class="form-floating ">
                <Field
                  name="regionName"
                  type="text"
                  className="form-control"
                  aria-describedby="RegionNameHelp"
                  id={`${generatedId}regionNameField`}
                />
                <label htmlFor={`${generatedId}regionNameField`} className="form-label">
                  Region Name
                </label>
              </div>
              <InputFeedback error={errors.regionName} />
            </div>

            <div className="form-group col-auto">
              <div class="form-check form-switch">
                <Field
                  name="status"
                  type="checkbox"
                  role="switch"
                  className="form-check-input"
                  aria-describedby="RegionStatusHelp"
                  id={`${generatedId}flexSwitchCheckDefault`}
                />
                <label
                  class="form-check-label"
                  htmlFor={`${generatedId}flexSwitchCheckDefault`}
                >
                  Status
                </label>
              </div>
            </div>

            {/* Submit */}
            <div className='col-auto'>
              <button type="submit" className="btn btn-success btn-sm">
                Speichern
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ExpandedComponent;
