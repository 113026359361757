import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { toast } from 'react-toastify';

import {
  useDeleteRegionMutation,
  useUpdateRegionMutation,
} from '../../../app/services/region/regionApi';
import { regionForEdit } from '../../../app/services/region/regionSlice';
import { BootyCheckbox, BootyPagination } from '../../../components';
import ActionColumnCell from './ActionColumnCell';
import ExpandedComponent from './ExpandedComponent';
import StatusColumnCell from './StatusColumnCell';

const RegionTable = ({ refetchRegions }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { regions, uniqueRegionsList } = useSelector((state) => state.region);

  const [deleteRegionMutation] = useDeleteRegionMutation();
  const [updateRegionMutation] = useUpdateRegionMutation();

  const editRegion = (id) => {
    const regionToEdit = regions.find((region) => region.id === id);
    dispatch(regionForEdit(regionToEdit));
  };

  const deleteRegion = async (id) => {
    const findUniqueRegion = uniqueRegionsList.find((region) => region.id === id);
    if (findUniqueRegion) {
      toast.error('Region kann nicht gelöscht werden, da sie verwendet wird.');
      return;
    }
    await deleteRegionMutation({ id, token });
    refetchRegions();
  };

  const updateRegionStatus = async (id, status, regionname) => {
    await updateRegionMutation({ id, token, status, regionname });
    refetchRegions();
  };
  
  // Handle click on checkbox from custom header and also from rows
  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', selectedRows);
  };

  // Table columns
  const columns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: 'Regionname',
      selector: (row) => row.regionname,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => <StatusColumnCell row={row} onUpdate={updateRegionStatus} />,
    },
    {
      name: 'Action',
      selector: (row) => "action",
      sortable: false,
      right: 'true',
      cell: (row) => (
        <ActionColumnCell row={row} onEdit={editRegion} onDelete={deleteRegion} />
      ),
    },
  ];

  // Table data to render extensions
  const tableData = {
    columns,
    data: regions,
  };

  return (
    <div className="card">
      <div className="card-header bg-light">Regionen</div>
      <div className="card-body">
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={regions}
            noHeader
            defaultSortField="id"
            defaultSortAsc={true}
            pagination
            paginationComponent={BootyPagination}
            highlightOnHover={false}
            dense={false}
            selectableRows
            selectableRowsHighlight={true}
            onSelectedRowsChange={handleChange}
            selectableRowsComponent={BootyCheckbox}
            expandableRows
            expandableRowsComponent={({ data }) => (
              <ExpandedComponent data={data} updateRegionStatus={updateRegionStatus} />
            )}
            fixedHeader={true}
            responsive={true}
          />
        </DataTableExtensions>
      </div>
    </div>
  );
};
export default RegionTable;
