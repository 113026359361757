import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const telefonApi = createApi({
  reducerPath: "telefonApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),

  endpoints: (builder) => ({
    getTelefon: builder.query({
      query: (token) => `telefones?access_token=${token}`,
    }),
    getTours: builder.query({
      query: (token) => `tours?access_token=${token}`,
    }),

    addTelefon: builder.mutation({
      query: (newTelefon) => ({
        url: "telefones",
        method: "POST",
        body: newTelefon,
      }),
    }),

    updatedTelefon: builder.mutation({
      query: ({ id, updateTelefon }) => ({
        url: `telefones/${id}`,
        method: "PUT",
        body: updateTelefon,
      }),
    }),

    deleteTelefon: builder.mutation({
      query: (id) => ({
        url: `telefones/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetTelefonQuery,
  useAddTelefonMutation,
  useUpdatedTelefonMutation,
  useDeleteTelefonMutation,
} = telefonApi;
