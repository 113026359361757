const formElements = [
  { label: "Vorname", name: "personvorname", type: "text", order: 1 },
  { label: "Nachname", name: "personnachname", type: "text", order: 2 },
  { label: "Strasse", name: "personstr", type: "text", order: 3 },
  { label: "PLZ", name: "personplz", type: "text", order: 4 },
  { label: "Wohnort", name: "personort", type: "text", order: 5 },
  { label: "Mobiltelefon", name: "mobiltelefon", type: "text", order: 6 },
  { label: "Festtelefon", name: "festtelefon", type: "text", order: 7 },
  { label: "Geburtsdatum", name: "persongebdatum", type: "date", order: 8 },
  {
    label: "Geschlecht",
    name: "persongeschlecht",
    type: "radio",
    options: ["männlich", "weiblich", "diverse"],
    order: 9,
  },
  {
    label: "Familienstand",
    name: "familienstand",
    type: "select",
    options: ["", "verheiratet", "ledig", "verwitwet", "geschieden"],
    order: 10,
  },
  { label: "Geburtsort", name: "gebort", type: "text", order: 11 },
  { label: "Geburtsland", name: "gebland", type: "text", order: 12 },
  {
    label: "Staatsangehörigkeit",
    name: "staatsangehorigkeit",
    type: "text",
    order: 13,
  },
  {
    label: "Behindert",
    name: "behindert",
    type: "radio",
    options: ["ja", "nein"],
    order: 14,
  },
  { label: "Bankname", name: "personbankname", type: "text", order: 15 },
  { label: "IBAN", name: "personiban", type: "text", order: 16 },
  {
    label: "Wochenarbeitstage",
    name: "wochenarbeitstage",
    type: "checkbox",
    options: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    order: 17,
  },
  {
    label: "Steueridentifikationsnummer",
    name: "steueridentnummer",
    type: "text",
    placeholder: "12345678912",
    order: 18,
  },
  {
    label: "Sozialversicherungsnummer",
    name: "personversnummer",
    placeholder: "12345678D234",
    type: "text",
    order: 19,
  },
  {
    label: "Steuerklasse",
    name: "steuerklasse",
    type: "select",
    options: ["", "1", "2", "3", "4", "5", "6"],
    order: 20,
  },
  { label: "Konfession", name: "konfession", type: "text", order: 21 },
  { label: "Krankenkasse", name: "krankenkasse", type: "text", order: 22 },
  {
    label: "Krankenkassevnr",
    name: "krankenkassevnr",
    type: "text",
    order: 23,
  },
  {
    label: "Notiz",
    name: "bewerbernotiz",
    type: "text",
    order: 24,
  },
];

const initialValue = {
  personvorname: "",
  personnachname: "",
  personstr: "",
  personplz: "",
  festtelefon: "",
  mobiltelefon: "",
  personort: "",
  persongebdatum: "",
  persongeschlecht: "",
  personversnummer: "",
  familienstand: "",
  gebort: "",
  gebland: "",
  staatsangehorigkeit: "",
  behindert: "",
  behinderungsart: "",
  personbankname: "",
  personiban: "",
  wochenarbeitstage: [],
  steueridentnummer: "",
  steuerklasse: "",
  konfession: "",
  krankenkasse: "",
  krankenkassevnr: "",
  bewerbernotiz: "",
};

const formValidation = (Yup) => {
  const validate = Yup.object({
    personvorname: Yup.string().required("Pflichtfeld"),
    personnachname: Yup.string().required("Pflichtfeld"),
    personstr: Yup.string().required("Pflichtfeld"),
    personplz: Yup.string()
      .required("Pflichtfeld")
      .matches(/^[0-9]{4,5}$/, "PLZ muss 4 oder 5 Zahlen haben"),
    personort: Yup.string().required("Pflichtfeld"),
    mobiltelefon: Yup.string().required("Pflichtfeld"),
    // festtelefon: Yup.string().required("Pflichtfeld"),
    persongebdatum: Yup.string()
      .required("Pflichtfeld")
      .test("age", "Bewerber muss mindestens 18 Jahre alt sein", (value) => {
        const today = new Date();
        const birthDate = new Date(value);
        const age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (
          month < 0 ||
          (month === 0 && today.getDate() < birthDate.getDate())
        ) {
          return age - 1 >= 18;
        }
        return age >= 18;
      }),
    persongeschlecht: Yup.string().required("Pflichtfeld"),
    familienstand: Yup.string().required("Pflichtfeld"),
    gebort: Yup.string().required("Pflichtfeld"),
    gebland: Yup.string().required("Pflichtfeld"),
    staatsangehorigkeit: Yup.string().required("Pflichtfeld"),
    behindert: Yup.string().required("Pflichtfeld"),
    personbankname: Yup.string().required("Pflichtfeld"),
    personiban: Yup.string().required("Pflichtfeld"),
    wochenarbeitstage: Yup.array().min(1, "Pflichtfeld"),
    steueridentnummer: Yup.string().required("Pflichtfeld"),
    steuerklasse: Yup.string().required("Pflichtfeld"),
    // konfession: Yup.string().required("Pflichtfeld"),
    krankenkasse: Yup.string().required("Pflichtfeld"),
    krankenkassevnr: Yup.string().required("Pflichtfeld"),
  });

  return validate;
};

export { formElements, formValidation, initialValue };
