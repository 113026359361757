import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const baseUrl = process.env.REACT_APP_BASE_URL;


export const optionApi = createApi({
  reducerPath: 'optionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),

  endpoints: (builder) => ({
    getOptions: builder.query({
      query: (token) => `optionens?access_token=${token}`,
    }),

    updateOptions: builder.mutation({
      query: (params) => {
        console.log('api mutation', params )
        return  {
          method: 'PUT',
          url: `optionens/${params.id}?access_token=${params.token}`,
          body: params.updateOptions, 
        }
      }
    }),
    deleteOptions: builder.mutation({
      query: (body) => ({
        url: `optionens/${body.id}?access_token=${body.token}`,
        method: 'DELETE',
      }),
    }),

    createOptions: builder.mutation({
      query: (body) => ({
        url: `optionens?access_token=${body.token}`,
        method: 'POST',
        body: body.formData,
      }),
    }),

    UpdateOptionStatus: builder.mutation({
      query: ({ id, token, ...patch }) => ({
        url: `optionens/${id}?access_token=${token}`,
        method: "PATCH",
        body: patch,
      }),
    }),

  }),
});

export const {
    useGetOptionsQuery,
    useCreateOptionsMutation,
    useUpdateOptionsMutation,
    useDeleteOptionsMutation,
    useUpdateOptionStatusMutation
} = optionApi;