import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

// Schema for the form validation
const CertificateSchema = Yup.object().shape({
  fahrer: Yup.string().required("Fahrer is required"),
  kurs: Yup.string().max(25, "Maximal 25 Zeichen erlaubt"),
  erwerbdatum: Yup.date()
    .required("Erwerbdatum is required")
    .when("enddatum", (enddatum, schema) => {
      return schema.test({
        test: (erwerbdatum) =>
          !enddatum ||
          !erwerbdatum ||
          new Date(erwerbdatum) <= new Date(enddatum),
        message: "Erwerbdatum darf nicht nach dem Enddatum liegen",
      });
    }),
  enddatum: Yup.date().required("Enddatum is required"),
});

const ZertifikatForm = ({ users, onAddCertificate }) => {
  return (
    <div className="container rounded-2 overflow-hidden col-lg-6 col-md-12 col-sm-12">
      <Formik
        initialValues={{
          fahrer: "",
          kurs: "",
          erwerbdatum: "",
          enddatum: "",
        }}
        validationSchema={CertificateSchema}
        onSubmit={(values, { resetForm }) => {
          onAddCertificate(values);
          resetForm();
        }}
      >
        {({ errors, touched }) => (
          <Form className="border rounded-2 overflow-hidden p-4">
            <legend>Zertifikat</legend>
            <div className="mb-3">
              <label htmlFor="fahrer" className="form-label">
                Fahrer
              </label>
              <Field as="select" name="fahrer" className="form-select">
                <option value="">Select Fahrer</option>
                {users.map((user, index) => (
                  <option key={index} value={user.id}>
                    {user.name} {user.nachname}
                  </option>
                ))}
              </Field>
              {errors.fahrer && touched.fahrer && (
                <div className="text-danger">{errors.fahrer}</div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="kurs" className="form-label">
                Kurs
              </label>
              <Field type="text" name="kurs" className="form-control" />
              {errors.kurs && touched.kurs ? (
                <div className="text-danger">{errors.kurs}</div>
              ) : null}
            </div>

            <div className="mb-3">
              <label htmlFor="erwerbdatum" className="form-label">
                Erwerbdatum
              </label>
              <Field type="date" name="erwerbdatum" className="form-control" />
              {errors.erwerbdatum && touched.erwerbdatum && (
                <div className="text-danger">{errors.erwerbdatum}</div>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="enddatum" className="form-label">
                Enddatum
              </label>
              <Field type="date" name="enddatum" className="form-control" />
              {errors.enddatum && touched.enddatum && (
                <div className="text-danger">{errors.enddatum}</div>
              )}
            </div>

            <button type="submit" className="btn btn-primary">
              Hinzufügen
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ZertifikatForm;
