import React, { useState } from 'react';


const ResetBtn = () => {

  const [clicked, setClicked] = useState(false);

  const handleReset = () => {
    // Local storage'u temizle
    localStorage.clear();
       // Butonun tıklandığını işaretle
       setClicked(true);
  };

  if (clicked) {
    // Sayfayı yenile
    window.location.reload();
  }
  
  return (
    <button className='btn btn-danger btn-sm display-1' style={{ height: "25px", alignItems:"center", paddingBottom:"3px" }} onClick={handleReset}>
  Reset Site
    </button>
  );
};

export default ResetBtn;