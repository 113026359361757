import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuthCall from "../../hooks/useAuthCall";
import {
  clearSelectedData,
  setKundeCount,
  setMenuCount,
  setSelectedData,
  setSelectedTagesTour,
} from "../../app/services/tagesTour/tagesTourSlice";
import axios from "axios";
import BestaetigungsModal from "./BestaetigungsModal";
import TerminPlanerIsDataModal from "./TerminPlanerIsDataModal";
import TerminPlanerIsNotDataModal from "./TerminPlanerIsNotDataModal";
import { useNavigate } from "react-router-dom";
import AbholungsInfoModal from "./AbholungsInfoModal";

const TerminPlaner = () => {
  const { user, token } = useSelector((state) => state.auth);
  const url = process.env.REACT_APP_BASE_URL;
  const { selectedData, selectedTagesTour } = useSelector(
    (state) => state.tagesTourSlice
  );

  const [terminData, setTerminData] = useState([]);
  const [fahrzeugsData, setFahrzeugsData] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [logData, setLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [abholungsInfoModal, setAbholungsInfoModal] = React.useState(false);

  const fahrerName = user.username;

  const fetchWerkstatt = async () => {
    const { data } = await axios.get(`${url}werkstatts?access_token=${token}`);
    setTerminData(data);
  };

  const fetchFahrzeugs = async () => {
    const { data } = await axios.get(`${url}fahrzeuges?access_token=${token}`);
    setFahrzeugsData(data);
  };

  const fetchPartner = async () => {
    const { data } = await axios.get(`${url}partners?access_token=${token}`);
    setPartnerData(data);
  };

  const fetchLog = async () => {
    const { data } = await axios.get(`${url}logs?access_token=${token}`);
    setLogData(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchWerkstatt(),
        fetchFahrzeugs(),
        fetchPartner(),
        fetchLog(),
      ]);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const fahrzeugIdFromLogData = logData
    .filter((l) => l.userId === user.id)
    .map((l) => l.fahrzeugId)[0];

  const selectedFahrzeug = fahrzeugsData
    .filter((f) => f.id === fahrzeugIdFromLogData)
    .map((f) => f.kfzkennzeichen)[0];

  const matchID = fahrzeugsData
    .filter((f) => f.kfzkennzeichen === selectedFahrzeug)
    .map((item) => item.id)[0];

  const matchData = terminData.filter((f) => f.fahrzeugId === matchID);

  const isBestaetigt = matchData.filter((f) => f.status === "Bestätigt");

  const currentDate = isBestaetigt
    .map((f) => f.werkdatum)
    .filter((d) => d.split("T")[0] === new Date().toISOString().split("T")[0])
    .map((d) => d.split("T")[0])[0];

  const isBestaetigtAndCurrentDate = isBestaetigt.filter(
    (f) => f.werkdatum?.split("T")[0] === currentDate
  );

  const bestaetigtPartnerIds = isBestaetigt.map((f) => f.partnerId);

  const uniquePartnerIds = new Set(bestaetigtPartnerIds);

  const matchingPartnerNames = Array.from(uniquePartnerIds).map(
    (id) => partnerData.find((p) => p.id === id)?.partnername
  )[0];

  const matchingPartnerStr = Array.from(uniquePartnerIds).map(
    (id) => partnerData.find((p) => p.id === id)?.partnerstr
  )[0];
  const matchingPartnerPLZ = Array.from(uniquePartnerIds).map(
    (id) => partnerData.find((p) => p.id === id)?.partnerplz
  )[0];
  const matchingPartnerOrt = Array.from(uniquePartnerIds).map(
    (id) => partnerData.find((p) => p.id === id)?.partnerort
  )[0];

  const handleWeiter = (e) => {
    e.preventDefault();

    navigate(`/`);
  };

  const navigate = useNavigate();

  const handleZurück = () => {
    navigate(`/tagestouransicht/${user.id}`);
  };
  const dispatch = useDispatch();
  const { logout } = useAuthCall();
  const handleLogout = () => {
    dispatch(setKundeCount(0));
    dispatch(setMenuCount(0));
    dispatch(setSelectedTagesTour(null));
    logout();
  };

  const [isOnTheWay, setIsOnTheWay] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(null);
  const handleClickFahren = () => {
    setIsOnTheWay(true);
    setIsModalOpen(true);
    const destination = `${matchingPartnerStr} ${matchingPartnerPLZ} ${matchingPartnerOrt}`;
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destination
    )}&travelmode=driving`;
    window.open(mapsUrl, "_blank");
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % isBestaetigtAndCurrentDate.length
    );
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + isBestaetigtAndCurrentDate.length) %
        isBestaetigtAndCurrentDate.length
    );
  };

  const sortingData = isBestaetigtAndCurrentDate.sort((a, b) => {
    // Saat ve dakikaları ayrıştır
    const [hoursA, minutesA] = a.werkuhrzeit.split(":").map(Number);
    const [hoursB, minutesB] = b.werkuhrzeit.split(":").map(Number);

    if (hoursA < hoursB) return -1;
    if (hoursA > hoursB) return 1;

    if (minutesA < minutesB) return -1;
    if (minutesA > minutesB) return 1;

    return 0;
  });

  if (isLoading) {
    return (
      <div
        className="
      position-absolute top-50 start-50 translate-middle  text-center 
      "
      >
        <button class="btn btn-primary" type="button" disabled>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </button>
      </div>
    ); // Veriler yüklenene kadar yüklenme durumunu göster
  }

  return (
    <div>
      {sortingData.length > 0 ? (
        <TerminPlanerIsDataModal
          isBestaetigtAndCurrentDate={isBestaetigtAndCurrentDate}
          currentIndex={currentIndex}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          handleZurück={handleZurück}
          handleWeiter={handleWeiter}
          handleClickFahren={handleClickFahren}
          fahrerName={fahrerName}
          selectedFahrzeug={selectedFahrzeug}
          matchingPartnerNames={matchingPartnerNames}
          matchingPartnerStr={matchingPartnerStr}
          matchingPartnerPLZ={matchingPartnerPLZ}
          matchingPartnerOrt={matchingPartnerOrt}
          isOnTheWay={isOnTheWay}
          handleLogout={handleLogout}
          sortingData={sortingData}
        />
      ) : (
        <TerminPlanerIsNotDataModal handleWeiter={handleWeiter} />
      )}

      {isModalOpen && (
        <BestaetigungsModal
          setIsModalOpen={setIsModalOpen}
          isBestaetigt={isBestaetigtAndCurrentDate}
          terminData={terminData}
          setTerminData={setTerminData}
          sortingData={sortingData}
          setAbholungsInfoModal={setAbholungsInfoModal}
        />
      )}

      {abholungsInfoModal && (
        <AbholungsInfoModal
          setAbholungsInfoModal={setAbholungsInfoModal}
          sortingData={sortingData}
        />
      )}
    </div>
  );
};

export default TerminPlaner;
