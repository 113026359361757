import React from "react";

const FahrzeugForm = ({
  formData,
  handleChange,
  handleSubmit,
  inputFocus,
  isMieteSelected,
  isEigentumSelected,
  warningMessage,
  setWarningMessage,
  handleCancel,
}) => {
  return (
    <div>
      <div className="card">
        <div className="card-header text-white bg-primary">Fahrzeuge</div>
        {warningMessage && (
          <span className="text-danger mt-2 ms-3">{warningMessage}</span>
        )}
        <div className="card-body">
          <form className="d-flex flex-column gap-2" onSubmit={handleSubmit}>
            {/* Anschaffungsart - Nutzungsstatus - Status */}
            <div className="row d-flex flex-wrap gap-3 justify-content-md-start justify-content-center">
              {/* Anschaffungsart */}
              <div className="form-group col d-xl-flex flex-xl-column flex-xxl-row   gap-xl-1 gap-xxl-3 gap-3">
                <label htmlFor="kfzanschaffungsart" className="fw-semibold">
                  Anschaffungsart
                </label>
                <div
                  className="d-lg-flex  gap-3 col-6 col-md-4"
                  style={{ width: "120px" }}
                >
                  <div className="d-flex justify-content-between">
                    <label htmlFor="miete">Miete</label>
                    <input
                      checked={formData.kfzanschaffungsart === "miete"}
                      value="miete"
                      name="kfzanschaffungsart"
                      type="radio"
                      className="form-check-input ms-2"
                      id="miete"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <label htmlFor="leasing">Leasing</label>

                    <input
                      checked={formData.kfzanschaffungsart === "leasing"}
                      value="leasing"
                      name="kfzanschaffungsart"
                      type="radio"
                      className="form-check-input ms-2"
                      id="leasing"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <label htmlFor="eigentum">Eigentum</label>

                    <input
                      checked={formData.kfzanschaffungsart === "eigentum"}
                      value="eigentum"
                      name="kfzanschaffungsart"
                      type="radio"
                      className="form-check-input ms-2"
                      id="eigentum"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Nutzungsstatus */}
              <div
                className="form-group col d-xl-flex flex-xl-column flex-xxl-row   gap-xl-1 gap-xxl-3 gap-3"
                required
              >
                <label htmlFor="kfznutzungsstatus" className="fw-semibold">
                  Nutzungsstatus
                </label>
                <div className="d-lg-flex  gap-3" style={{ width: "120px" }}>
                  <div className="d-flex justify-content-between">
                    <label htmlFor="geschäftlich">Geschäftlich</label>
                    <input
                      checked={formData.kfznutzungsstatus === "geschäftlich"}
                      value="geschäftlich"
                      name="kfznutzungsstatus"
                      type="radio"
                      className="form-check-input ms-2"
                      id="geschäftlich"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <label htmlFor="privat">Privat</label>
                    <input
                      checked={formData.kfznutzungsstatus === "privat"}
                      value="privat"
                      name="kfznutzungsstatus"
                      type="radio"
                      className="form-check-input ms-2"
                      id="privat"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              {/* Status */}
              <div className="form-group col-12  col-md-4">
                <label htmlFor="kfzstatus" className="fw-semibold">
                  Status
                </label>

                <select
                  value={formData.kfzstatus}
                  name="kfzstatus"
                  id="kfzstatus"
                  className="form-select form-control"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                >
                  <option value="AKTIV">AKTIV</option>
                  <option value="INAKTIV">INAKTIV</option>
                </select>
              </div>
            </div>

            {/* Marke - Model - Erstzulassung - Kennzeichen*/}
            <div className="row">
              <div className="form-group col-md-3 col-12">
                <label htmlFor="kfzmarke" className="fw-semibold">
                  Marke
                </label>
                <input
                  ref={inputFocus}
                  value={formData.kfzmarke}
                  name="kfzmarke"
                  type="text"
                  className="form-control"
                  id="kfzmarke"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group col-md-3 col-12">
                <label htmlFor="kfzmodel" className="fw-semibold">
                  Model
                </label>
                <input
                  value={formData.kfzmodel}
                  name="kfzmodel"
                  type="text"
                  className="form-control"
                  id="kfzmodel"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group col-md-3 col-12">
                <label htmlFor="kfzerstzulassung" className="fw-semibold">
                  Erstzulassung
                </label>
                <input
                  value={formData.kfzerstzulassung}
                  name="kfzerstzulassung"
                  type="date"
                  className="form-control"
                  id="kfzerstzulassung"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
              <div className="form-group col-md-3 col-12">
                <label htmlFor="kfzkennzeichen" className="fw-semibold">
                  Kennzeichen
                </label>
                <input
                  value={formData.kfzkennzeichen}
                  name="kfzkennzeichen"
                  type="text"
                  className="form-control"
                  id="kfzkennzeichen"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            {/* Neupreis - Kaufpreis */}
            <div className="row">
              <div className="form-group col-md-6 col-12">
                <label htmlFor="kfzneupreis" className="fw-semibold">
                  Neupreis
                </label>
                <input
                  value={
                    formData.kfzanschaffungsart === "miete"
                      ? ""
                      : formData.kfzneupreis
                  }
                  name="kfzneupreis"
                  type="text"
                  className="form-control"
                  id="kfzneupreis"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
              <div className="form-group col-md-6 col-12">
                <label htmlFor="kfzkaufpreis" className="fw-semibold">
                  Kaufpreis
                </label>
                <input
                  value={formData.kfzkaufpreis}
                  name="kfzkaufpreis"
                  type="text"
                  className="form-control"
                  id="kfzkaufpreis"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
            </div>

            {/* Tüv Datum - Uvv Datum */}
            <div className="row">
              <div className="form-group col-md-6 col-12">
                <label htmlFor="kfztuvdatum" className="fw-semibold">
                  TÜV Datum
                </label>
                <input
                  value={formData.kfztuvdatum}
                  name="kfztuvdatum"
                  type="date"
                  className="form-control"
                  id="kfztuvdatum"
                  placeholder="TT.MM.JJJJ"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
              <div className="form-group col-md-6 col-12">
                <label htmlFor="kfzuvvdatum" className="fw-semibold">
                  UVV Datum
                </label>
                <input
                  value={formData.kfzuvvdatum}
                  name="kfzuvvdatum"
                  type="date"
                  className="form-control"
                  id="kfzuvvdatum"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
            </div>

            {/* HSN - TSN - Identifikationsnummer */}
            <div className="row d-flex align-items-end ">
              <div className="form-group col-md-4 col-12">
                <label htmlFor="kfzschlusselnr" className="fw-semibold">
                  Herstellercode Schlüsselnummer HSN (2.1)
                </label>
                <input
                  value={formData.kfzschlusselnr}
                  name="kfzschlusselnr"
                  type="text"
                  className="form-control"
                  id="kfzschlusselnr"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group col-md-4 col-12">
                <label htmlFor="kfzschlusselnrtsn" className="fw-semibold">
                  Herstellertypecode Schlüsselnummer TSN (2.2)
                </label>
                <input
                  value={formData.kfzschlusselnrtsn}
                  name="kfzschlusselnrtsn"
                  type="text"
                  className="form-control"
                  id="kfzschlusselnrtsn"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group col col-md-4 col-12">
                <label
                  htmlFor="kfzidentifizierungsnummer"
                  className="fw-semibold"
                >
                  Identifikationsnummer (E)
                </label>
                <input
                  value={formData.kfzidentifizierungsnummer}
                  name="kfzidentifizierungsnummer"
                  type="text"
                  className="form-control"
                  id="kfzidentifizierungsnummer"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            {/* Kilometerstand - Inspektionsleistung in Km - Reifenleistung in Km - Reifengröße */}
            <div className="row d-flex align-items-end">
              <div className="form-group col-md-3 col-12">
                <label htmlFor="kfzfahrzeugkilometer" className="fw-semibold">
                  Kilometerstand
                </label>
                <input
                  value={formData.kfzfahrzeugkilometer}
                  name="kfzfahrzeugkilometer"
                  type="text"
                  className="form-control"
                  id="kfzfahrzeugkilometer"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group col-md-3 col-12">
                <label
                  htmlFor="kfzfahrzeuginspektionkm"
                  className="fw-semibold"
                >
                  Inspektionsleistung in Km
                </label>
                <input
                  value={formData.kfzfahrzeuginspektionkm}
                  name="kfzfahrzeuginspektionkm"
                  type="text"
                  className="form-control"
                  id="kfzfahrzeuginspektionkm"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
              <div className="form-group col-md-3 col-12">
                <label htmlFor="kfzfahrzeugreifenkm" className="fw-semibold">
                  Reifenleistung in Km
                </label>
                <input
                  value={formData.kfzfahrzeugreifenkm}
                  name="kfzfahrzeugreifenkm"
                  type="text"
                  className="form-control"
                  id="kfzfahrzeugreifenkm"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
              <div className="form-group col-md-3 col-12">
                <label htmlFor="kfzreifen" className="fw-semibold">
                  Reifengröße
                </label>
                <input
                  value={formData.kfzreifen}
                  name="kfzreifen"
                  type="text"
                  className="form-control"
                  id="kfzreifen"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
            </div>

            {/* Leistung - Hubraum */}
            <div className="row">
              <div className="form-group col-md-6 col-12">
                <label htmlFor="kfzleistungkw" className="fw-semibold">
                  Leistung(P.2)(kW)
                </label>
                <input
                  value={formData.kfzleistungkw}
                  name="kfzleistungkw"
                  type="text"
                  className="form-control"
                  id="kfzleistungkw"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
              <div className="form-group col-md-6 col-12">
                <label htmlFor="kfzhubraum" className="fw-semibold">
                  Hubraum(P1)
                </label>
                <input
                  value={formData.kfzhubraum}
                  name="kfzhubraum"
                  type="text"
                  className="form-control"
                  id="kfzhubraum"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
            </div>

            {/* Versicherungsname - Versicherungsnummer */}
            <div className="row">
              <div className="form-group col-md-6 col-12">
                <label htmlFor="kfzversicherungsname" className="fw-semibold">
                  Versicherungsname
                </label>
                <input
                  value={formData.kfzversicherungsname}
                  name="kfzversicherungsname"
                  type="text"
                  className="form-control"
                  id="kfzversicherungsname"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
              <div className="form-group col-md-6 col-12">
                <label htmlFor="kfzversicherungsnr" className="fw-semibold">
                  Versicherungsnummer
                </label>
                <input
                  value={formData.kfzversicherungsnr}
                  name="kfzversicherungsnr"
                  type="text"
                  className="form-control"
                  id="kfzversicherungsnr"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
            </div>

            {/* Kraftstoff - Monatskosten */}
            <div className="row d-flex justify-content-center align-items-center">
              {/* Kraftstoff */}
              <div className="form-group d-sm-flex flex-md-column flex-lg-row gap-3 gap-md-1 gap-lg-5 my-2 col-md-6 col-12">
                <label htmlFor="kfzkraftstoff" className="fw-semibold">
                  Kraftstoff
                </label>
                <div style={{ width: "120px" }} className="d-md-flex  gap-3">
                  <div className="d-flex justify-content-between">
                    <label htmlFor="biodiesel">Biodiesel</label>
                    <input
                      checked={formData.kfzkraftstoff === "biodiesel"}
                      value="biodiesel"
                      name="kfzkraftstoff"
                      type="radio"
                      className="form-check-input ms-2"
                      id="biodiesel"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <label htmlFor="diesel">Diesel</label>

                    <input
                      checked={formData.kfzkraftstoff === "diesel"}
                      value="diesel"
                      name="kfzkraftstoff"
                      type="radio"
                      className="form-check-input ms-2"
                      id="diesel"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <label htmlFor="benzin">Benzin</label>

                    <input
                      checked={formData.kfzkraftstoff === "benzin"}
                      value="benzin"
                      name="kfzkraftstoff"
                      type="radio"
                      className="form-check-input ms-2"
                      id="benzin"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-group col-md-6 col-12">
                <label htmlFor="kfzmonkosten" className="fw-semibold">
                  Monatskosten
                </label>
                <input
                  value={formData.kfzmonkosten}
                  name="kfzmonkosten"
                  type="text"
                  className="form-control"
                  id="kfzmonkosten"
                  onChange={handleChange}
                  disabled={isMieteSelected}
                  required={!isMieteSelected}
                />
              </div>
            </div>

            {/* Vertragsbeginn - Vertragsende */}
            <div className="row">
              <div className="form-group col-md-6 col-12">
                <label htmlFor="kfzvertragsbeginn" className="fw-semibold">
                  Vertragsbeginn
                </label>
                <input
                  value={formData.kfzvertragsbeginn}
                  name="kfzvertragsbeginn"
                  type="date"
                  className="form-control"
                  id="kfzvertragsbeginn"
                  onChange={handleChange}
                  // disabled={isMieteSelected}
                  required
                  disabled={isEigentumSelected}
                />
              </div>

              <div className="form-group col-md-6 col-12">
                <label htmlFor="kfzvertragsende" className="fw-semibold">
                  Vertragsende
                </label>
                <input
                  value={formData.kfzvertragsende}
                  name="kfzvertragsende"
                  type="date"
                  className="form-control"
                  id="kfzvertragsende"
                  onChange={handleChange}
                  // disabled={isMieteSelected}
                  required
                  disabled={isEigentumSelected}
                />
              </div>
            </div>

            {/* Notiz  */}
            <div className="form-group">
              <label htmlFor="kfznotiz" className="fw-semibold">
                Notiz
              </label>
              <textarea
                name="kfznotiz"
                className="form-control"
                id="kfznotiz"
                rows="3"
                onChange={handleChange}
                value={formData.kfznotiz}
                disabled={isMieteSelected}
                required={!isMieteSelected}
              ></textarea>
            </div>

            <div className="d-flex gap-3">
              <button type="submit" className="btn btn-primary">
                Speichern
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Abbrechen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FahrzeugForm;
