import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";

const PersonalForm = ({
  editData,
  setEditData,
  initialValues,
  validate,
  onSubmit,
  formRows,
  fillFormData,
  submittedMsg,
}) => {
  return (
    <div className="card">
      <div className="card-header bg-primary text-white">
        Personal Übersicht
      </div>
      <div className="card-body">
        <Formik
          enableReinitialize={true}
          initialValues={editData || initialValues}
          validationSchema={validate}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values, resetForm);
          }}
        >
          {({ resetForm, values, setFieldValue }) => (
            <Form>
              {formRows.map((row, index) => (
                <div className="row mb-3" key={index}>
                  {row.map((field, idx) => (
                    <div
                      className={`col-12 ${
                        field.type === "checkbox"
                          ? "col-md-5"
                          : field.name === "arbeitsstartuhr"
                          ? "col-md-2"
                          : field.name === "behindert"
                          ? "col-md-5"
                          : field.name === "personalnotiz"
                          ? "col-12"
                          : "col-md-4"
                      } `}
                      key={`field-${index}-${idx}`}
                    >
                      <label
                        htmlFor={field.name}
                        className="form-label fw-semibold"
                      >
                        {field.label}
                      </label>
                      {field.type === "select" && field.name !== "userId" ? (
                        <Field
                          as="select"
                          name={field.name}
                          className="form-select"
                          value={values[field.name] || ""}
                        >
                          {field.options.map((option, idx) => (
                            <option key={idx} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Field>
                      ) : field.type === "select" && field.name === "userId" ? (
                        <Field
                          as="select"
                          name="userId"
                          className="form-select"
                          onChange={(event) => {
                            const selectedUserId = event.target.value;
                            setFieldValue("userId", selectedUserId);
                            fillFormData(selectedUserId);
                          }}
                          value={values.userId || ""}
                        >
                          <option value="">Personal auswählen</option>
                          {field.options.map((user, idx) => (
                            <option key={idx} value={user.value}>
                              {user.label}
                            </option>
                          ))}
                        </Field>
                      ) : field.type === "radio" ? (
                        <div className="d-flex">
                          {field.options.map((option, idx) => (
                            <div>
                              <div className="d-flex align-items-center me-3">
                                <label
                                  key={idx}
                                  className="form-check form-check-inline "
                                >
                                  <Field
                                    type="radio"
                                    name={field.name}
                                    value={option.value}
                                    className="form-check-input"
                                  />
                                  {option.label}
                                </label>
                              </div>
                            </div>
                          ))}
                          {field.name === "behindert" &&
                          values.behindert === "ja" ? (
                            <Field
                              as="textarea"
                              name="behinderungart"
                              className="form-control"
                              placeholder="Behinderungart"
                              value={values.behinderungart || ""}
                            />
                          ) : null}
                        </div>
                      ) : field.type === "checkbox" ? (
                        <div>
                          {field.options.map((option, idx) => (
                            <label
                              key={idx}
                              className="form-check form-check-inline"
                            >
                              <Field
                                type="checkbox"
                                name={field.name}
                                value={option}
                                className="form-check-input"
                              />
                              {option}
                            </label>
                          ))}
                        </div>
                      ) : field.type === "textarea" ? (
                        <Field
                          as="textarea"
                          name={field.name}
                          className="form-control"
                          placeholder={field.placeholder}
                          value={values[field.name] || ""}
                        />
                      ) : (
                        <Field
                          type={field.type}
                          name={field.name}
                          className="form-control"
                          placeholder={field.placeholder}
                          value={values[field.name] || ""}
                        />
                      )}
                      <ErrorMessage
                        name={field.name}
                        component="div"
                        className="text-danger"
                        style={{ fontSize: "0.8rem" }}
                      />
                    </div>
                  ))}
                </div>
              ))}
              <div className="d-flex mt-2 gap-2 align-items-center">
                <button type="submit" className="btn btn-primary">
                  {editData ? "Aktualisieren" : "Senden"}
                </button>
                {editData ? (
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    onClick={() => {
                      setEditData(null);
                      resetForm();
                    }}
                  >
                    Abbrechen
                  </button>
                ) : null}
                {submittedMsg ? (
                  <div className="text-success">{submittedMsg}</div>
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default PersonalForm;
