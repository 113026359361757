import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import DataTableExtensions from "react-data-table-component-extensions";
import useAxios from "../../../hooks/useAxios";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinFill } from "react-icons/ri";
import { useDeleteTourMutation } from "./tourApi";
import { useSelector, useDispatch } from "react-redux";
import { tourForEdit } from "./tourSlice";

const TableTur = ({refetch}) => {
  const dispatch = useDispatch();
  const { token, user} = useSelector((state) => state.auth);
  const [pending, setPending] = useState(true);
  const data = useSelector((state) => state.tour.tours);
  
  const isAdmin = user?.role === "ADMIN";
  const [deleteTours] = useDeleteTourMutation();

  useEffect(() => {
    const timeout = setTimeout(() => {
     
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

 

  const onDelete = async (id) => {
    if(isAdmin){
      try {
        await deleteTours({ id, token });
        refetch();
      } catch (error) {
        console.error("Error Delete", error);
      }
    } 
  };

  const onEdit = (id) => {
    if(isAdmin){
      const tourToEdit = data.find((tour) => tour.id === id);
      dispatch(tourForEdit(tourToEdit));
    }
  };

  const columns = [
    // {
    //   name: "Region",
    //   selector: (row) => row.region,
    //   sortable: true,
    // style: {
		// 	backgroundColor: 'rgba(187, 204, 221, 1)',
		// },
    // },
    {
      name: "Tourname",
      selector: (row) => row.tourname,
      sortable: true,
    },
    {
      name: "Fahrtbeginndatum",
      selector: (row) => row.fahrtbeginndatum,
      sortable: true,
      style: {
			display: 'none',
		},
    },
    {
      name: "Startort",
      selector: (row) => row.fahrtbeginnort,
      sortable: true,
    },
    {
      name: "Endort",
      selector: (row) => row.fahrtendeort,
      sortable: true,
    },
    {
      name: "Startzeit",
      selector: (row) => row.fahrtbeginnuhr,
      sortable: true,
    },
    {
      name: "Endzeit",
      selector: (row) => row.fahrtendeuhr,
      sortable: true,
    },
    {
      name: "Fahrtdauer",
      selector: (row) => row.fahrtdauer,
      sortable: true,
    },
    {
      name: "Preis/h",
      selector: (row) => row.preistype,
      sortable: true,
    },
    {
      name: "Tele-Link",
      selector: (row) => row.telematiklink,
      sortable: true,
    },
    {
      name: "Tele-Mandant",
      selector: (row) => row.telematikmandant,
      sortable: true,
    },
    {
      name: "Tele-Unternehmen",
      selector: (row) => row.telematikfirma,
      sortable: true,
    },
    {
      name: "Tele-Kürzel",
      selector: (row) => row.telematikkurzel,
      sortable: true,
    },
    {
      name: "Tele-Fahrzeug",
      selector: (row) => row.telematikfahrzeug,
      sortable: true,
    },
    {
      name: "Notiz",
      selector: (row) => row.tournotiz,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => "action",
      sortable: false,
      right: "true",
      cell: (row) => (
        <div>
          <button
            onClick={() => onEdit(row.id)}
            className="btn btn-link text-primary"
          >
            <FiEdit />
          </button>
          <button
            onClick={() => onDelete(row.id)}
            className="btn btn-link text-danger"
            // disabled={deleteDisabled[row.id]}
          >
            <RiDeleteBinFill />
          </button>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: data,
  };

  return (
    <div className="card my-4">
      <div className="card-header bg-primary text-white">Tour Übersicht</div>
      <div className="card-body">
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={tableData.columns}
            data={tableData.data}
            noHeader
            pagination
            responsive={true}
            highlightOnHover
            dense
            progressPending={pending}
            selectableRowsHighlight={true}
        
          />
        </DataTableExtensions>
      </div>
    </div>
  );
};
export default TableTur;
