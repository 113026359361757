import axios from "axios";
import { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinFill } from "react-icons/ri";
import { useSelector } from "react-redux";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Partner = () => {
  const { token } = useSelector((state) => state.auth);
  const [partners, setPartners] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  // const [isNewContactVisible, setIsNewContactVisible] = useState(false);

  const [formData, setFormData] = useState({
    partnername: "",
    partnerkdnr: "",
    dbdatum: new Date().toISOString().slice(0, 10),
    partnerstr: "",
    partnerplz: "",
    partnerort: "",
    partneransprech: "",
    partnernotiz: "",
    partnerwebsite: "",
    partnerpasswort: "",
    partnertels: [{ name: "", telefonnummer: "", partnertelabteilung: "" }],
    partnerhandy: "",
    partneremail: "",
    partneremails: [{ name: "", email: "", partnermailabteilung: "" }],
    // id: 0,
  });

  useEffect(() => {
    const getPartners = async () => {
      try {
        const response = await axios.get(
          "https://dev.zone-edv.de/api/partners"
        );

        if (response.status === 200) {
          setPartners(response.data);
        } else {
          console.error("Failed to fetch partners:", response.statusText);
        }
      } catch (error) {
        console.error("An error occurred during data fetching:", error);
      }
    };

    getPartners();
  }, []); // Boş dependency array, sadece bir kere çalışmasını sağlar

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getPartner = async (id) => {
    const url = `${BASE_URL}partners/${id}?access_token=${token}`;

    try {
      const response = await axios.get(url);
      const partnerData = response.data;
      setFormData({
        partnername: partnerData.partnername,
        partnerkdnr: partnerData.partnerkdnr,
        dbdatum: partnerData.dbdatum,
        partnerstr: partnerData.partnerstr,
        partnerplz: partnerData.partnerplz,
        partnerort: partnerData.partnerort,
        partneransprech: partnerData.partneransprech,
        partnernotiz: partnerData.partnernotiz,
        partnerwebsite: partnerData.partnerwebsite,
        // partnerzugang: partnerData.partnerzugang,
        partnerpasswort: partnerData.partnerpasswort,
        partnertels: partnerData.partnertels || [
          { name: "", telefonnummer: "", partnertelabteilung: "" },
        ],
        partnerhandy: partnerData.partnerhandy,
        // partnerfax: partnerData.partnerfax,
        partneremail: partnerData.partneremail,
        partneremails: partnerData.partneremails || [
          { name: "", email: "", partnermailabteilung: "" },
        ],
        id: partnerData.id,
      });

      console.log("Selected partner data:", partnerData);
    } catch (error) {
      console.error("GET request failed:", error.response.data);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Updated data:", formData);

    try {
      let response;

      if (isEditMode) {
        // Edit modunda isek, güncelleme işlemini yapiyoruz
        response = await axios.put(
          `https://dev.zone-edv.de/api/partners/${formData.id}`,
          formData
        );
      } else {
        // Yeni veri ekleniyorsa, ekleme işlemini yapiyoruz
        response = await axios.post(
          "https://dev.zone-edv.de/api/partners",
          formData
        );
      }

      if (response.status === 200) {
        console.log("Form submitted successfully!");

        if (isEditMode) {
          // Edit modunda isek, partners state'ini güncelliyoruz
          setPartners((prevPartners) =>
            prevPartners.map((partner) =>
              partner.id === formData.id ? response.data : partner
            )
          );
        } else {
          // Yeni veri ekleniyorsa, partners state'ini güncelliyoruz
          setPartners([...partners, response.data]);
        }

        setFormData({
          partnername: "",
          partnerkdnr: "",
          dbdatum: new Date().toISOString().slice(0, 10),
          partnerstr: "",
          partnerplz: "",
          partnerort: "",
          partneransprech: "",
          partnernotiz: "",
          partnerwebsite: "",
          partnerpasswort: "",
          partnertels: [
            { name: "", telefonnummer: "", partnertelabteilung: "" },
          ],
          partnerhandy: "",
          partneremail: "",
          partneremails: [{ name: "", email: "", partnermailabteilung: "" }],
          id: 0,
        });

        setIsEditMode(false); // Edit modunu kapat
        // setIsNewContactVisible(false); // Close new contact input fields
      } else {
        console.error(
          "Das Absenden des Formulars ist fehlgeschlagen:",
          response.statusText
        );
      }
    } catch (error) {
      console.error(
        "Beim Absenden des Formulars ist ein Fehler aufgetreten:",
        error
      );
    }
  };

  const handleEditClick = (id) => {
    // Edit butonuna tıklandığında, seçili partnerin verilerini getir
    getPartner(id);
    setIsEditMode(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      const response = await axios.delete(
        `https://dev.zone-edv.de/api/partners/${id}`
      );

      if (response.status === 200) {
        console.log("Partner successfully deleted!");
        setPartners(partners.filter((partner) => partner.id !== id));
      } else {
        console.error("Failed to delete partner:", response.statusText);
      }
    } catch (error) {
      console.error("An error occurred during partner deletion:", error);
    }
  };


  //? Ektra Partner Information
  const getDefaultDetails = () => {
    return {
      name: "",
      telefonnummer: "",
      partnertelabteilung: "",
      email: "",
    };
  };

  const [detailsType] = useState("partnertels");

  const handleContactDetailsChange = (index, field, value) => {
    const updatedDetails = [...formData[detailsType]];
    updatedDetails[index][field] = value;
    setFormData({ ...formData, [detailsType]: updatedDetails });
    // Inside handleContactDetailsChange
    // console.log("Handling Change:", detailsType, index, field, value);
  };

  const handleAddContactDetails = () => {
    setFormData({
      ...formData,
      partnertels: [...formData.partnertels, getDefaultDetails()],
      partneremails: [...formData.partneremails, getDefaultDetails()],
    });
    console.log("Updated State:", formData);
    // Inside handleAddContactDetails
    console.log("Adding Details:", detailsType);
    console.log("Updated State:", formData);
  };

  const handleDeleteContact = (index) => {
    const updatedContacts = [...formData[detailsType]];
    updatedContacts.splice(index, 1); // Remove the contact at the specified index
    setFormData({
      ...formData,
      [detailsType]: updatedContacts,
    });
    // Inside handleDeleteContact
    console.log("Deleting Details:", detailsType, index);
  };

  return (
    <div className="card p-1 border-0">
      <div className="card mb-2">
        <h1 className="card-header ml-2 fs-5 text-white bg-primary p-3">
          Partnerübersicht
        </h1>
        <div className="card-body">
          <div className="d-flex flex-wrap gap-2 mb-2">
            <button type="button" className="btn btn-info">
              Copy
            </button>
            <button type="button" className="btn btn-success">
              Excel
            </button>
            <button type="button" className="btn btn-danger">
              PDF
            </button>
            <button type="button" className="btn btn-outline-secondary">
              YAZICI
            </button>
            <button type="button" className="btn btn-outline-secondary">
              Titel
            </button>
            <button type="button" className="btn btn-outline-secondary">
              SearchPanes
            </button>
          </div>
          <div
            className="table-responsive overflow-scroll max-height-400"
            style={{ maxHeight: "300px" }}
          >
            <table className="table table-striped table-hover overflow-scroll mb-4 border-2 rounded-2">
              <thead className="table-dark sticky-top">
                <tr>
                  <th scope="col">KD-Nr</th>
                  <th scope="col">Firma</th>
                  <th scope="col">Adresse</th>
                  <th scope="col">Tel</th>
                  <th scope="col">E-Mail</th>
                  <th scope="col">
                    <FiEdit />
                    <div className="d-none d-xl-inline"> Edit</div>
                  </th>
                  <th scope="col">
                    <RiDeleteBinFill />
                    <div className="d-none d-xl-inline"> Del</div>
                  </th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {partners &&
                  partners.map((data) => (
                    <tr key={data.id}>
                      <th scope="row">{data.partnerkdnr}</th>
                      <td>{data.partnername}</td>
                      <td>{data.partnerstr}</td>
                      <td>
                        {data.partnerhandy}
                        {data.partnertels &&
                          data.partnertels.map((tel, telIndex) => (
                            <div key={telIndex}>
                              <div>{tel.telefonnummer}</div>
                              <div>
                                ({tel.name} - {tel.partnertelabteilung})
                              </div>
                            </div>
                          ))}
                      </td>
                      <td>
                        {data.partneremail}
                        {data.partnertels &&
                          data.partnertels.map((email) => (
                            <div key={email.email}>{email.email}</div>
                          ))}
                      </td>

                      <th>
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          onClick={() => handleEditClick(data.id)}
                        >
                          {" "}
                          <FiEdit />
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDeleteClick(data.id)}
                        >
                          <RiDeleteBinFill />
                        </button>
                      </th>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="rounded-2 overflow-hidden">
        <form
          className="border rounded-2 overflow-hidden"
          onSubmit={handleSubmit}
        >
          <h1 className="ml-2 fs-5 text-white bg-primary p-3">
            Partner erfassen
          </h1>
          <div className="row px-2 py-2">
            <div className="col-sm-12 col-md-6">
              <div className="col mb-4 p-2">
                <div className="col mb-3">
                  <div className="form-outline">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="partnername"
                    >
                      Firmenname <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="partnername"
                      name="partnername"
                      placeholder="Firmenname eingeben"
                      className="form-control"
                      value={formData.partnername}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col mb-3">
                  <div className="form-outline">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="partnerkdnr"
                    >
                      Kundennummer
                    </label>
                    <input
                      type="text"
                      id="partnerkdnr"
                      name="partnerkdnr"
                      placeholder="Kundennummer eingeben"
                      className="form-control"
                      value={formData.partnerkdnr}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col mb-3">
                  <div className="form-outline">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="dbdatum"
                    >
                      Datum
                    </label>
                    <input
                      type="date"
                      id="dbdatum"
                      name="dbdatum"
                      placeholder="Datum eingeben"
                      className="form-control"
                      value={formData.dbdatum}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col mb-3">
                  <div className="form-outline">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="partnerstr"
                    >
                      Straße/Nr <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="partnerstr"
                      name="partnerstr"
                      placeholder="Straße eingeben"
                      className="form-control"
                      value={formData.partnerstr}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-outline col-md-6 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="partnerplz"
                    >
                      PLZ <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="partnerplz"
                      name="partnerplz"
                      placeholder="PLZ eingeben"
                      className="form-control"
                      value={formData.partnerplz}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-outline col-md-6 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="partnerort"
                    >
                      Ort <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="partnerort"
                      name="partnerort"
                      placeholder="Ort eingeben"
                      className="form-control"
                      value={formData.partnerort}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="form-outline">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="partneransprech"
                    >
                      Ansprechpartner
                    </label>
                    <input
                      type="text"
                      id="partneransprech"
                      name="partneransprech"
                      placeholder="Ansprechpartner eingeben"
                      className="form-control"
                      value={formData.partneransprech}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row"></div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="p-2">
                <div className="col mb-3">
                  <div className="form-outline">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="partnerwebsite"
                    >
                      x Webseite
                    </label>
                    <input
                      type="text"
                      id="partnerwebsite"
                      name="partnerwebsite"
                      placeholder="www.myweb.com"
                      className="form-control"
                      value={formData.partnerwebsite}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-outline mb-3">
                  <label
                    className="form-label mb-0 fw-semibold"
                    htmlFor="partneremail"
                  >
                    Zugangsdaten: Email Adresse{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    id="partneremail"
                    name="partneremail"
                    placeholder="Email eingeben"
                    className="form-control"
                    value={formData.partneremail}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-outline mb-3">
                  <label
                    className="form-label mb-0 fw-semibold"
                    htmlFor="partnerpasswort"
                  >
                    Zugangsdaten: Passwort
                  </label>
                  <input
                    type="password"
                    id="partnerpasswort"
                    name="partnerpasswort"
                    placeholder="Passwort eingeben"
                    className="form-control"
                    value={formData.partnerpasswort}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-outline mb-3">
                  <label
                    className="form-label mb-0 fw-semibold"
                    htmlFor="partnerhandy"
                  >
                    Phone <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="partnerhandy"
                    name="partnerhandy"
                    placeholder="Telefax eingeben"
                    className="form-control"
                    value={formData.partnerhandy}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col mb-3">
                  <div className="form-outline">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="partnernotiz"
                    >
                      Notiz
                    </label>
                    <textarea
                      type="text"
                      id="partnernotiz"
                      name="partnernotiz"
                      placeholder="Notiz eingeben"
                      className="form-control"
                      value={formData.partnernotiz}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span
                className="btn bg-success fw-semibold text-white ms-2 mb-2"
                onClick={handleAddContactDetails}
              >
                Neu Kontakt / Email
              </span>
            </div>
            <div className="row">
              {formData[detailsType].map((contact, index) => (
                <div
                  key={index}
                  className="d-flex align-content-center justify-content-between"
                >
                  <div className="col-11 d-flex flex-column flex-md-row gap-1 p-2">
                    <div className="col-sm-3 mb-3">
                      <div className="form-outline">
                        <label
                          className="form-label mb-0 fw-semibold"
                          htmlFor={`contactName${index}`}
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          id={`contactName${index}`}
                          name={`contactName${index}`}
                          placeholder="Name eingeben"
                          className="form-control"
                          value={contact.name}
                          onChange={(e) =>
                            handleContactDetailsChange(
                              index,
                              "name",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 mb-3">
                      <label
                        className="form-label mb-0 fw-semibold"
                        htmlFor={`contactTel${index}`}
                      >
                        Tel
                      </label>
                      <input
                        type="text"
                        id={`contactTel${index}`}
                        name={`contactTel${index}`}
                        placeholder="Tel eingeben"
                        className="form-control"
                        value={contact.telefonnummer}
                        onChange={(e) =>
                          handleContactDetailsChange(
                            index,
                            "telefonnummer",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-3 mb-3">
                      <label
                        className="form-label mb-0 fw-semibold"
                        htmlFor={`contactAbteilung${index}`}
                      >
                        Abteilung
                      </label>
                      <input
                        type="text"
                        id={`contactAbteilung${index}`}
                        name={`contactAbteilung${index}`}
                        placeholder="Abteilung eingeben"
                        className="form-control"
                        value={contact.partnertelabteilung}
                        onChange={(e) =>
                          handleContactDetailsChange(
                            index,
                            "partnertelabteilung",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-3 mb-3">
                      <label
                        className="form-label mb-0 fw-semibold"
                        htmlFor={`contactEmail${index}`}
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id={`contactEmail${index}`}
                        name={`contactEmail${index}`}
                        placeholder="Email eingeben"
                        className="form-control"
                        value={contact.email}
                        onChange={(e) =>
                          handleContactDetailsChange(
                            index,
                            "email",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="mt-4">
                      <span
                        className="btn btn-danger"
                        onClick={() => handleDeleteContact(index)}
                      >
                        DEL
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <button
                type="submit"
                className="btn bg-primary ms-2 mt-3 fw-semibold text-white"
              >
                Eingeben
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Partner;
