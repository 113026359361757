const initialValues = {
  pflegedatum: new Date().toISOString().split("T")[0],
  pflegeart: [],
  pflegezahlungsart: "",
  pflegebetrag: "",
  pflegenotiz: "",
  fahrzeugId: "",
  fahrer: "",
};

const reinigungsOpt = [
  { value: "Innenreinigung", label: "Innenreinigung" },
  { value: "Ofenreinigung", label: "Ofenreinigung" },
  { value: "Aussenreinigung", label: "Aussenreinigung" },
];

const zahlungsOpt = [
  { value: "Barzahlung", label: "Barzahlung" },
  { value: "Kartenzahlung", label: "Kartenzahlung" },
  { value: "keine Zahlung", label: "keine Zahlung" },
];

const formikValidation = (Yup) => {
  return Yup.object().shape({
    pflegedatum: Yup.date().required("Required"),
    pflegeart: Yup.array().min(1, "Pflichtfeld"),

    pflegezahlungsart: Yup.string().required("Required"),
    pflegebetrag: Yup.number().required("Required"),
    pflegenotiz: Yup.string().required("Required"),
    fahrzeugId: Yup.string().required("Required"),
    fahrer: Yup.string().required("Required"),
  });
};

export { initialValues, formikValidation, reinigungsOpt, zahlungsOpt };
