import { Badge } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { FaTimes } from 'react-icons/fa';
import { optionForEdit } from './optionSlice';




function OptionFormHeader(props) {
  const dispatch = useDispatch();
  const { option } = props;

  return (
    <>
      {option?.id ? (
        <div className="d-flex flex-row justify-content-between">
          {/* Region Status */}
          <div>
            Option <Badge bg={'dark'}>EDIT</Badge>
          </div>
          {/* Cancel Edit Button */}
          <button
            className="btn btn-sm btn-light d-flex gap-1 align-items-center"
            onClick={() => dispatch(optionForEdit({}))}
          >
            <FaTimes /> Abbrechen
          </button>
        </div>

      ) : (
        <div>
         Option <Badge bg={'primary'}>NEW</Badge>
        </div>

      )}
    </>
  );
}

export default OptionFormHeader;