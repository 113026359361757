import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Erledigt = () => {
  const { token } = useSelector((state) => state.auth);
  const url = process.env.REACT_APP_BASE_URL;
  const [terminData, setTerminData] = useState([]);
  const [fahrzeugData, setFahrzeugData] = useState([]);

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (fahrzeugData.length > 0) {
      fetchWerkstatt();
    }
  }, [fahrzeugData]);

  const fetchInitialData = async () => {
    await fetchFahrzeug();
    fetchWerkstatt();
  };

  const fetchFahrzeug = async () => {
    const { data } = await axios.get(`${url}fahrzeuges?access_token=${token}`);
    setFahrzeugData(data);
  };

  const today = new Date().toISOString().split("T")[0];

  const fetchWerkstatt = async () => {
    const { data } = await axios.get(`${url}werkstatts?access_token=${token}`);

    const isAbholDatumTodayUndInArbeit = data.filter(
      (d) =>
        new Date(d.abholdatum).toISOString().split("T")[0] === today &&
        d.status === "in Arbeit"
    );

    const modifiedData = isAbholDatumTodayUndInArbeit.map((d) => {
      const fahrzeug = fahrzeugData.find((f) => f.id === d.fahrzeugId);
      return {
        ...d,
        fahrzeugId: fahrzeug ? fahrzeug.kfzkennzeichen : "",
        abholdatum: new Date(d.abholdatum)
          .toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join("-"),
      };
    });

    const sortedModifiedData = modifiedData.sort((a, b) => {
      const aTime = a.abholzeit ? a.abholzeit.split(":") : [0, 0];
      const bTime = b.abholzeit ? b.abholzeit.split(":") : [0, 0];
      return aTime[0] - bTime[0] || aTime[1] - bTime[1];
    });

    setTerminData(sortedModifiedData);
  };

  const handleErledigt = async (id) => {
    await axios.patch(`${url}werkstatts/${id}?access_token=${token}`, {
      status: "Erledigt",
    });
    fetchWerkstatt();
  };

  const columns = [
    { field: "status", headerName: "Status", flex: 1 },
    { field: "fahrzeugId", headerName: "Fahrzeug", flex: 1 },
    { field: "abholdatum", headerName: "Abholdatum", flex: 1 },
    { field: "abholzeit", headerName: "Abholzeit", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <button
              className="btn btn-sm btn-primary"
              onClick={() => handleErledigt(params.id)}
            >
              Erledigt
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="container">
      <div className="card">
        <div className="card-header bg-primary text-white">Status Wechseln</div>
      </div>
      <DataGrid
        rows={terminData}
        columns={columns}
        hideFooterPagination
        density="compact"
      />
    </div>
  );
};

export default Erledigt;
