export const validationRules = {
  bewerbervorname: {
    required: true,
    pattern: /^[a-zA-ZäöüÄÖÜß]{3,}(?: [a-zA-ZäöüÄÖÜß]{2,}){0,2}$/,
    errorMessage: "Ungültiges Vorname-Format.",
  },
  bewerbernachname: {
    required: true,
    pattern: /^[a-zA-ZäöüÄÖÜß]{3,}(?: [a-zA-ZäöüÄÖÜß]{2,}){0,2}$/,
    errorMessage: "Ungültiges Nachname-Format.",
  },
  bewerberemail: {
    required: true,
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    errorMessage: "Ungültiges Email-Format. ",
    emailErrorMessage: "Email already exist",
  },
  bewerbertelefon: {
    required: true,
    pattern: /^(?:\+\d{1,2}\s?|0)(?:[0-9]\s?){6,11}[0-9]$/,
    errorMessage: "Ungültiges Telefon-Format.",
  },
  bewerberstr: {
    required: true,
    pattern:/^[\s\S]*$/,
    errorMessage: "Ungültiges Straße-Format. ",
  },
  bewerberplz: {
    required: true,
    pattern: /^\d{4,5}$/,
    errorMessage: "Ungültiges Postleitzeil-Format. ",
  },
  bewerberort: {
    required: true,
    pattern: /^[a-zA-ZäöüÄÖÜß]{3,}$/,
    errorMessage: "Ungültiges Wohnort-Format.",
  },
  bewerberarbeitsart: {
    required: true,
    errorMessage: "Ungültiges Beschäftigung-Format.",
  },
  bewerberarbeitstage: {
    required: true,
    errorMessage: "Bitte mindestens einen Arbeitstag auswählen",
  },
  bewerbergebdatum: {
    required: true,
    errorMessage: "Geburtsdatum darf nicht unter 18 Jahren liegen",
  },
  bewerbergeschlecht: {
    required: true,
    errorMessage: "Ungültiges Geschlecht-Format.",
  },
  fuhrerscheinklasse: {
    required: true,
    errorMessage: "Ungültiges Führerscheinklasse-Format.",
  },
  eintrittsdatum: {
    required: true,
    errorMessage: "Ungültiges Eintrittsdatum-Format. ",
  },
};
