import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const registerApi = createApi({
  reducerPath: "registerApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },

  endpoints: (builder) => ({
    getBewerber: builder.query({
      query: (token) => `bewerbers?access_token=${token}`,
    }),
    getPersonal: builder.query({
      query: (token) => `personalbogens?access_token=${token}`,
    }),

    addUser: builder.mutation({
      query: (newUser) => ({
        url: "users",
        method: "POST",
        body: newUser,
      }),
    }),
    addBewerber: builder.mutation({
      query: (newBewerber) => ({
        url: "bewerbers",
        method: "POST",
        body: newBewerber,
      }),
    }),
    addPersonal: builder.mutation({
      query: (newPersonal) => ({
        url: "personalbogens",
        method: "POST",
        body: newPersonal,
      }),
    }),
    updateBewerber: builder.mutation({
      query: ({ id, updatedData }) => ({
        url: `bewerbers/${id}`,
        method: "PUT",
        body: updatedData,
      }),
    }),
    deleteBewerber: builder.mutation({
      query: (id) => ({
        url: `bewerbers/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddUserMutation,
  useAddPersonalMutation,
  useGetPersonalBogen,
  useGetBewerberQuery,
  useAddBewerberMutation,
  useUpdateBewerberMutation,
  useDeleteBewerberMutation,
} = registerApi;
