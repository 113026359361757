import React, { useState, useEffect } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsImages } from "react-icons/bs";
import { MdAccountBalance } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { BsInfoCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import axios from "axios";

const FirmenInfoConfirmation = () => {
  const [newData, setNewData] = useState([]);

  const getFirmenDaten = async () => {
    const url = "https://dev.zone-edv.de/api/firmendatens";
    try {
      const response = await axios.get(url);
      setNewData(response.data[response.data.length - 1]);
      console.log(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("GET isteği başarısız:", error.response.data);
    }
  };

  useEffect(() => {
    getFirmenDaten();
  }, []);


  return (
    <>
      <div className="row m-2">
        <div className="bg-dark px-3 py-3 d-flex align-items-center justify-content-between rounded-2 mb-2">
          <div className="d-flex align-items-center gap-2">
            <IoSettingsOutline
              size={24}
              className="text-white cursor-pointer"
            />{" "}
            <span className="fs-4 text-info fw-semibold">Einstellungen</span>
            <BsInfoCircle className="text-white cursor-pointer" />
          </div>
          <div className="d-flex align-items-center gap-3">
            <Link
              to={{
                pathname: "/firmenInformation",
                state: { formData: newData, logo: newData.firmenlogo },
              }}
              className="btn btn-warning text-danger fw-semibold"
            >
              Bearbeiten
            </Link>
          </div>
        </div>

        <div className="">
          <div className="row rounded-2 py-3 mx-auto border border-2">
            <div className="col-sm-12 col-md-6 rounded">
              <div className="bg-white rounded-2 border pb-2 overflow-hidden">
                <div className="col d-flex align-content-center px-5 mb-3 bg-primary">
                  <FaMapMarkerAlt className="me-2 mt-2 text-white" />
                  <h1 className="bg-primary ml-2 font-semibold fs-5 p-2 rounded-2 text-white">
                    Anschrift
                  </h1>
                </div>
                <ul className="d-flex flex-column gap-3">
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">Firmenname :</li>
                    <span>{newData.firmenname}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">Inhaber :</li>
                    <span>{newData.firmeninhaber}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">E-Mail :</li>
                    <span>{newData.firmenemail}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">Phone :</li>
                    <span>{newData.firmentelefon}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">Handy :</li>
                    <span>{newData.firmenhandy}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">Web :</li>
                    <span>{newData.firmenweb}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">Fax :</li>
                    <span>{newData.firmenfax}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">Straße :</li>
                    <span>{newData.firmenstr}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">PLZ :</li>
                    <span>{newData.firmenplz}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">Ort :</li>
                    <span>{newData.firmenort}</span>
                  </div>
                </ul>
              </div>
            </div>

            <div className="col-sm-12 col-md-6 rounded-2">
              <div className="bg-white rounded-2 border pb-2 overflow-hidden">
                <div className="d-flex align-content-center px-5 mb-3 bg-primary">
                  <MdAccountBalance className="me-2 mt-2 text-white" />
                  <h1 className="ml-2 font-semibold fs-5 p-2 rounded-2 text-white">
                    Bank & Steuerinformation
                  </h1>
                </div>
                <ul>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">Bank :</li>
                    <span>{newData.firmenbankname}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">IBAN :</li>
                    <span>{newData.firmeniban}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">BIC :</li>
                    <span>{newData.firmenbic}</span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">USt-Nr. :</li>
                    <span>{newData.firmenust}</span>
                  </div>

                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">USt-IdNr. :</li>
                    <span>{newData.firmenustid}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">Finanzamt :</li>
                    <span>{newData.firmenfinanzamt}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between pe-3">
                    <li className="py-2 list-unstyled">Steuernummer :</li>
                    <span>{newData.firmeneori}</span>
                  </div>
                </ul>
              </div>
              <div className="bg-white mt-3 rounded-2 border pb-4 overflow-hidden">
                <div className="d-flex align-content-center px-5 mb-3 bg-primary">
                  <BsImages className="mt-3 text-white" />
                  <h1 className="bg-primary ml-2 font-semibold fs-5 p-2 rounded-2 text-white">
                    Logo
                  </h1>
                </div>
                <div className="d-flex align-items-center justify-content-center mx-auto">
                  <img
                    className="py-3"
                    width={150}
                    height={150}
                    src={
                      newData.firmenlogo ||
                      "https://img.freepik.com/vektoren-kostenlos/branding-identity-corporate-vector-logo-a-design_460848-8717.jpg?size=626&ext=jpg&ga=GA1.1.258947574.1676464177&semt=sph"
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirmenInfoConfirmation;
