import { Badge } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FaTimes } from "react-icons/fa";
import { tourForEdit } from "./tourSlice";

function TourFormHeader(props) {
  const dispatch = useDispatch();
  const { tour } = props;

  return (
    <>
      {tour?.id ? (
        <div className="d-flex flex-row justify-content-between">
          <div>
            Tour <Badge bg={"dark"}>EDIT</Badge>
          </div>
          {/* Cancel Edit Button */}
          <button
            className="btn btn-sm btn-light d-flex gap-1 align-items-center"
            onClick={() => dispatch(tourForEdit({}))}
          >
            <FaTimes /> Abbrechen
          </button>
        </div>
      ) : (
        <div>
          Tour <Badge bg={"white"} text={"primary"}>NEW</Badge>
        </div>
      )}
    </>
  );
}

export default TourFormHeader;
