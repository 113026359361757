import React, {  useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const TelefonForm = ({
  formik,
 handleChange,
 toursData = [],
 handleBlur
 
}) => {
  const [statusOptions] = useState(["Aktif", "Passiv"]);
  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <div className="rounded-2 overflow-hidden">
      <div></div>
      <div className="card-header ml-2 fs-5 text-white bg-primary p-3">
        Telefon erfassen
      </div>
      <form
        className="border rounded-2 overflow-hidden"
        onSubmit={formik.handleSubmit}
      >

        <div className="row">
          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="telefonnummer">
              Telefonnr
            </label>
            <input
              name="telefonnummer"
              type="text"
              className="form-control"
              id="telefonnummer"
              value={formik.values.telefonnummer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
            {formik.touched.telefonnummer && formik.errors.telefonnumer ? (
              <div className="error">{formik.errors.telefonnummer}</div>
            ) : null}
          </div>
    
          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="telefonanbieter">
              Anbieter
            </label>
            <input
              name="telefonanbieter"
              type="text"
              className="form-control"
              id="telefonanbieter"
              value={formik.values.telefonanbieter}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />  
          </div>
          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="tourId">
              Tour
            </label>
            <select
              name="tourId"
              id="tourId"
              className="form-select form-control"
              value={formik.values.tourId}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              required
            >
              <option value=" " defaultValue>
                Tour auswählen
              </option>
              {toursData ? (
                toursData.map((tour) => (
                  <option key={tour.id} value={tour.id}>
                    {tour.tourname}
                  </option>
                ))
              ) : (
                <option>Loading...</option>
              )}
            </select>
          </div>
 

          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="telefonpin1">
              PIN1
            </label>
            <input
              name="telefonpin1"
              type="text"
              className="form-control"
              id="telefonpin1"
              value={formik.values.telefonpin1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="telefonpin2">
              PIN2
            </label>
            <input
              name="telefonpin2"
              type="text"
              className="form-control"
              id="telefonpin2"
              value={formik.values.telefonpin2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="telefonpuk1">
              PUK1
            </label>
            <input
              name="telefonpuk1"
              type="text"
              className="form-control"
              id="telefonpuk1"
              value={formik.values.telefonpuk1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="telefonpuk2">
              PUK2
            </label>
            <input
              name="telefonpuk2"
              type="text"
              className="form-control"
              id="telefonpuk2"
              value={formik.values.telefonpuk2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="telefonemail">
              E-Mail
            </label>
            <input
              name="telefonemail"
              type="text"
              className="form-control"
              id="telefonemail"
              value={formik.values.telefonemail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="telemailpasswort">
              Passwort
            </label>
            <input
              name="telemailpasswort"
              type="text"
              className="form-control"
              id="telemailpasswort"
              value={formik.values.telemailpasswort}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="telefonimei1">
              IMEI1
            </label>
            <input
              name="telefonimei1"
              type="text"
              className="form-control"
              id="telefonimei1"
              value={formik.values.telefonimei1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="telefonimei2">
              IMEI2
            </label>
            <input
              name="telefonimei2"
              type="text"
              className="form-control"
              id="telefonimei2"
              value={formik.values.telefonimei2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="col-12 col-md-2 mb-3">
            <label className="fw-semibold" htmlFor="telefonsimnr">
              SIM-Nr
            </label>
            <input
              name="telefonsimnr"
              type="text"
              className="form-control"
              id="simnr"
              value={formik.values.telefonsimnr}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="col-12 col-md-2 mb-3">
          <label className="fw-semibold" htmlFor="vertragsbeginn">
            Vertragsbeginn
          </label>
          <DatePicker
            name="vertragsbeginn"
            selected={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
              formik.setFieldValue("vertragsbeginn", date);
            }}
            onBlur={formik.handleBlur}
            className="form-control"
            id="vertragsbeginn"
            dateFormat="dd.MM.yyyy"
            required
          />
        </div>
          <div className="col-12 col-md-2 mb-3">
          <label className="fw-semibold" htmlFor="status">
            Status
          </label>
          <select
            name="status"
            className="form-select"
            id="status"
            value={formik.values.status}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          >
            {statusOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
       

        <div className="d-flex gap-2 ms-2 mt-2">
          <button type="submit" className="btn btn-primary">
            Eingeben
          </button>
        </div>
        </div>
      </form>
    </div>
    
  );
};

export default TelefonForm;
