import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

function Anmeldung() {
  const { userRole } = useSelector((state) => state.auth);
  return (
    <Container>
      <div className="p-5 mb-4 bg-light rounded-3">
        <div className="container-fluid py-5">
          <h1 className="display-5 fw-bold">Logistik Projesine Hoşgeldiniz</h1>
          <p className="col-md-8 fs-4">
            Projenin amacı, lojistik süreçlerini optimize etmek ve verimliliği
            artırmaktır.
          </p>
          {userRole === "admin" ? (
            <Link
              to="/firmeninformation"
              className="btn btn-primary btn-lg"
              type="button"
            >
              Firmeninformation
            </Link>
          ) : null}
        </div>
      </div>
    </Container>
  );
}

export default Anmeldung;

/*
<div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold">Custom jumbotron</h1>
        <p class="col-md-8 fs-4">Using a series of utilities, you can create this jumbotron, just like the one in previous versions of Bootstrap. Check out the examples below for how you can remix and restyle it to your liking.</p>
        <button class="btn btn-primary btn-lg" type="button">Example button</button>
      </div>
    </div>
*/
