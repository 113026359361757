const initialValues = {
  id: undefined,
  dbdatum: new Date().toISOString().split("T")[0],
  tourendatum: "",
  touruhrzeit: "",
  tourId: "",
  fahrer: "",
  tourennotiz: "",
  fahrzeugId: "",
};

const formikValidation = (Yup) => {
  return Yup.object().shape({
    tourId: Yup.string().required("Tour ID is required"),
    tourendatum: Yup.date().required("Datum is required"),
    fahrer: Yup.string().required("Fahrer is required"),
    touruhrzeit: Yup.string().required("Startzeit is required"),
    fahrzeugId: Yup.string().required("Fahrzeug ID is required"),
    tourennotiz: Yup.string(),
  });
};

export { initialValues, formikValidation };
