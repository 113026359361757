import React, { useEffect, useState } from "react";
import WerkstattForm from "./WerkstattForm";
import WerkstattTable from "./WerkstattTable";
import { useSelector } from "react-redux";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";

const Werkstatt = () => {
  const { token } = useSelector((state) => state.auth);
  const url = process.env.REACT_APP_BASE_URL;

  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  const initialValues = {
    serviceart: "",
    status: "",
    werkdatum: formattedDate,
    werkuhrzeit: "",
    werkkilometer: null,
    service: [],
    werknotiz: "",
    werkkosten: null,
    fahrer: 0,
    fahrzeugId: null,
    partnerId: null,
    abholdatum: "",
    abholzeit: null,
  };
  const [optionsData, setOptionsData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [fahrzeugsData, setFahrzeugsData] = useState([]);
  const [leistungsData, setLeistungsData] = useState([]);
  const [submitMsg, setSubmitMsg] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [editData, setEditData] = useState(null);

  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Pflichtfeld"),
    serviceart: Yup.array().min(1, "Pflichtfeld").required("Pflichtfeld"),
    werkuhrzeit: Yup.string().required("Pflichtfeld"),
    service: Yup.array().min(1, "Pflichtfeld"),
    werkkilometer: Yup.number().min(0, "Pflichtfeld").required("Pflichtfeld"),
    fahrzeugId: Yup.object()
      .shape({
        label: Yup.string().required("Pflichtfeld"),
        value: Yup.string().required("Pflichtfeld"),
      })
      .required("Pflichtfeld"),

    partnerId: Yup.object()
      .shape({
        label: Yup.string().required("Pflichtfeld"),
        value: Yup.string().required("Pflichtfeld"),
      })
      .required("Pflichtfeld"),
  });

  useEffect(() => {
    const fetchOptions = async () => {
      const { data } = await axios.get(`${url}optionens?access_token=${token}`);
      setOptionsData(data);
    };

    const fetchFahrer = async () => {
      const { data } = await axios.get(`${url}users?access_token=${token}`);
      setUserData(data);
    };

    const fetchPartners = async () => {
      const { data } = await axios.get(`${url}partners?access_token=${token}`);
      console.log("first" , data)
      setPartnerData(data);
    };

    const fetchFahrzeugs = async () => {
      const { data } = await axios.get(
        `${url}fahrzeuges?access_token=${token}`
      );
      setFahrzeugsData(data);
    };

    const fetchLeistungs = async () => {
      const { data } = await axios.get(`${url}leistungs?access_token=${token}`);
      setLeistungsData(data);
    };

    const fetchWerkstatt = async () => {
      const { data } = await axios.get(
        `${url}werkstatts?access_token=${token}`
      );
      setTableData(data);
    };

    fetchWerkstatt();
    fetchLeistungs();
    fetchFahrzeugs();
    fetchPartners();
    fetchFahrer();
    fetchOptions();
  }, []);

  const serviceOptions = [
    "Auswuchten",
    "Bremsbelege",
    "Dieselfilter",
    "Heizung",
    "Ersatzteil",
    "Karosserie",
    "Klimaanlage",
    "Luftfilter",
    "Ölfilter",
    "Ölwechsel",
    "Reifen/Felgenservice",
    "Scheibe",
  ];

  const statusOptions = optionsData
    ?.filter((option) => option.key === "Status")
    .map((option) => option.value);

  const serviceartOptions = optionsData
    ?.filter((option) => option.key === "Serviceart")
    .map((option) => option.value);

  const fahrerOptions = userData
    ?.filter((user) => user.role === "FAHRER")
    .map((user) => user.username);

  const partnerOptions = partnerData?.map((partner) => partner);

  const fahrzeugOptions = fahrzeugsData?.map(
    (fahrzeug) => fahrzeug.kfzkennzeichen
  );

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${url}werkstatts/${id}?access_token=${token}`);
      setTableData((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (rowData) => {
    if (typeof rowData.serviceart === "string") {
      rowData.serviceart = rowData.serviceart.split(",").map((item) => {
        return { value: item, label: item };
      });
    }
    rowData.fahrer = rowData.fahrer?.split(",").map((item) => {
      return { value: item, label: item };
    });

    rowData.fahrzeugId = rowData.fahrzeugId
      ? { value: rowData.fahrzeugId, label: rowData.fahrzeugId }
      : null;
    rowData.partnerId = rowData.partnerId
      ? { value: rowData.partnerId, label: rowData.partnerId }
      : null;

    if (rowData.werkdatum) {
      const date = new Date(rowData.werkdatum);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      rowData.werkdatum = `${year}-${month}-${day}`;
    }
    if (rowData.abholdatum) {
      const date = new Date(rowData.abholdatum);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      rowData.abholdatum = `${year}-${month}-${day}`;
    }

    rowData.service = rowData.service?.split(",").map((item) => item);

    setEditData(rowData);
  };

  const handleSubmit = async (values, resetForm, setFieldValue) => {
    const apiValues = { ...values };

    if (apiValues.fahrer && apiValues.fahrer.value) {
      const selectedFahrerID = Array.isArray(userData)
        ? userData
            .filter((user) => user.username === apiValues.fahrer.value)
            .map((user) => user.id)[0]
        : null;
      apiValues.fahrer = selectedFahrerID;
    } else {
      apiValues.fahrer = null;
    }

    const selectedPartnerID =
      partnerData && Array.isArray(partnerData)
        ? partnerData
            .filter(
              (partner) =>
                partner.partnername ===
                (apiValues.partnerId ? apiValues.partnerId.value : undefined)
            )
            .map((partner) => partner.id)[0]
        : null;

    const selectedFahrzeugID =
      fahrzeugsData && Array.isArray(fahrzeugsData)
        ? fahrzeugsData
            .filter(
              (fahrzeug) =>
                fahrzeug.kfzkennzeichen ===
                (apiValues.fahrzeugId ? apiValues.fahrzeugId.value : undefined)
            )
            .map((fahrzeug) => fahrzeug.id)[0]
        : null;

    const selectedServiceart =
      apiValues.serviceart &&
      Array.isArray(apiValues.serviceart) &&
      apiValues.serviceart.map((item) => item.value);

    apiValues.partnerId = selectedPartnerID;
    apiValues.fahrzeugId = selectedFahrzeugID;
    apiValues.serviceart = selectedServiceart;

    if (apiValues.werkdatum && !isNaN(Date.parse(apiValues.werkdatum))) {
      apiValues.werkdatum = new Date(apiValues.werkdatum)
        .toISOString()
        .split("T")[0];
    } else {
      apiValues.werkdatum = null;
    }

    if (apiValues.abholdatum && !isNaN(Date.parse(apiValues.abholdatum))) {
      apiValues.abholdatum = new Date(apiValues.abholdatum)
        .toISOString()
        .split("T")[0];
    } else {
      apiValues.abholdatum = null;
    }

    try {
      await validationSchema.validate(values, { abortEarly: false });
      if (editData) {
        const { data } = await axios.patch(
          `${url}werkstatts/${editData.id}?access_token=${token}`,
          apiValues
        );
        console.log(data, "data");
        const updatedData = tableData.map((item) =>
          item.id === data.id ? data : item
        );

        setTableData(updatedData);
        setSubmitMsg("Data updated successfully");
        setTimeout(() => {
          setSubmitMsg(null);
        }, 3000);
        setEditData(null);
        resetForm({
          values: {
            ...initialValues,
            werkkilometer: "",
            werkkosten: "",
            abholzeit: "",
          },
        });
      } else {
        const { data } = await axios.post(
          `${url}werkstatts?access_token=${token}`,
          apiValues
        );
        setTableData((prev) => [...prev, data]);
        setSubmitMsg("Data added successfully");
        setTimeout(() => {
          setSubmitMsg(null);
        }, 2000);
      }
      resetForm({
        values: {
          ...initialValues,
          werkkilometer: "",
          werkkosten: "",
          abholzeit: "",
        },
      });
      setFieldValue("partnerId", 0);
      setFieldValue("fahrzeugId", 0);
    } catch (errors) {
      const formErrors = {};
      if (errors.inner) {
        errors.inner.forEach((error) => {
          formErrors[error.path] = error.message;
        });
      }
      console.log("Form validation errors:", formErrors);
    }
  };

  const modifiedData = tableData.map((item) => ({
    ...item,
    fahrer: userData.find((user) => user.id === item.fahrer)?.username,
    partnerId: partnerData.find((partner) => partner.id === item.partnerId)
      ?.partnername,
    fahrzeugId: fahrzeugsData.find(
      (fahrzeug) => fahrzeug.id === item.fahrzeugId
    )?.kfzkennzeichen,
  }));

  return (
    <div className="container">
      <Formik
        enableReinitialize={true}
        initialValues={editData || initialValues}
        onSubmit={(values, { resetForm, setFieldValue }) =>
          handleSubmit(values, resetForm, setFieldValue)
        }
        validationSchema={validationSchema}
      >
        {() => (
          <>
            <WerkstattTable
              tableData={tableData}
              handleDelete={handleDelete}
              modifiedData={modifiedData}
              handleEdit={handleEdit}
              setEditData={setEditData}
              editData={editData}
              serviceartOptions={serviceartOptions}
              fahrerOptions={fahrerOptions}
              statusOptions={statusOptions}
              partnerOptions={partnerOptions}
              fahrzeugOptions={fahrzeugOptions}
              serviceOptions={serviceOptions}
              userData={userData}
              partnerData={partnerData}
              fahrzeugsData={fahrzeugsData}
              leistungsData={leistungsData}
              submitMsg={submitMsg}
              setSubmitMsg={setSubmitMsg}
            />

            <WerkstattForm
              statusOptions={statusOptions}
              serviceartOptions={serviceartOptions}
              fahrerOptions={fahrerOptions}
              partnerOptions={partnerOptions}
              fahrzeugOptions={fahrzeugOptions}
              fahrzeugsData={fahrzeugsData}
              partnerData={partnerData}
              leistungsData={leistungsData}
              editData={editData}
              submitMsg={submitMsg}
              serviceOptions={serviceOptions}
              setEditData={setEditData}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default Werkstatt;
