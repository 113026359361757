import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinFill } from 'react-icons/ri';

function ActionColumnCell({ row, onEdit, onDelete }) {
  return (
    <div>
      <button
        role="link"
        type="button"
        onClick={() => onEdit(row.id)}
        className="btn btn-link text-primary"
      >
        <FiEdit />
      </button>
      <></>
      <button
        role="link"
        type="button"
        onClick={() => onDelete(row.id)}
        className="btn btn-link text-danger"
      >
        <RiDeleteBinFill />
      </button>
    </div>
  );
}

export default ActionColumnCell;
