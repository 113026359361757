import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logValuesData: [],
  tourDetailsData: [],
  tourStatusTagesTour: [],
  activeDriver: [],
  tagesTourData: [],
  selectedData: [],
  matchingKundenItems: [],
  kundeCount: 0,
  menuCount: 0,
  selectedAddress: null,
  isModalOpen: false,
  clickedDiv: "",
  selectedDivValue: {},
  backgroundRow: null,
  selectedTagesTour: [],
};

const tagesTourSlice = createSlice({
  name: "tagesTourSlice",
  initialState,
  reducers: {
    setSelectedTagesTour: (state, action) => {
      state.selectedTagesTour = action.payload;
    },

    setLogValuesData: (state, action) => {
      state.logValuesData = action.payload;
    },
    setTourDetailsData: (state, action) => {
      state.tourDetailsData = action.payload;
    },
    setTagesTourData: (state, action) => {
      state.tagesTourData = action.payload;
    },
    setSelectedData: (state, action) => {
      state.selectedData = action.payload;
    },

    setMatchingKundenItems: (state, action) => {
      state.matchingKundenItems = action.payload;
    },
    clearSelectedData: (state) => {
      state.selectedData = [];
    },
    setKundeCount: (state, action) => {
      state.kundeCount = action.payload;
    },
    setMenuCount: (state, action) => {
      state.menuCount = action.payload;
    },
    setMapUrl: (state, action) => {
      state.mapUrl = action.payload;
    },
    setSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload.isOpen;
      state.rowId = action.payload.rowId;
    },
    setClickedDiv: (state, action) => {
      state.clickedDiv = action.payload;
    },
    setSelectedDivValue: (state, action) => {
      const { rowId, value } = action.payload;
      state.selectedDivValue[rowId] = value;
    },
    setBackgroundRow: (state, action) => {
      state.backgroundRow = action.payload;
    },
    setActiveDriver: (state, action) => {
      state.activeDriver.push(action.payload);
    },
    setTourStatusTagesTour: (state, action) => {
      state.tourStatusTagesTour = action.payload;
    },
  },
});

export const {
  setLogValuesData,
  setTourDetailsData,
  setTagesTourData,
  setSelectedData,
  clearSelectedData,
  setMatchingKundenItems,
  setTourAndFahrzeug,
  setKundeCount,
  setMenuCount,
  setSelectedAddress,
  setClickedDiv,
  setSelectedDivValue,
  setIsModalOpen,
  setBackgroundRow,
  setActiveDriver,
  setTourStatusTagesTour,
  setSelectedTagesTour,
} = tagesTourSlice.actions;
export default tagesTourSlice.reducer;
