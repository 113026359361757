import React from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinFill } from "react-icons/ri";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { BootyCheckbox, BootyPagination } from "../../../components";
import {
  FaCopy,
  FaEnvelope,
  FaFileExcel,
  FaFilePdf,
  FaHeading,
  FaPrint,
  FaWhatsapp,
} from "react-icons/fa";
import { useSelector } from "react-redux";

const ReklamationTable = ({
  reklamationData,
  onEdit,
  onDelete,
  fahrerData = [],
  tourData = [],
  kundenData = [],
  rmaData = [],
}) => {
  const { user } = useSelector((state) => state.auth);
  const currentUserId = user?.id;
  const isAdmin = user?.role === "ADMIN";

  const actionBtn = [
    { name: "Copy", icon: <FaCopy />, className: "btn btn-info" },
    { name: "Excel", icon: <FaFileExcel />, className: "btn btn-success" },
    { name: "PDF", icon: <FaFilePdf />, className: "btn btn-danger" },
    {
      name: "YAZICI",
      icon: <FaPrint />,
      className: "btn btn-outline-secondary",
    },
    {
      name: "Titel",
      icon: <FaHeading />,
      className: "btn btn-outline-secondary",
    },
    { name: "SearchPanes", icon: "", className: "btn btn-outline-secondary" },
    { name: "Whatsapp", icon: <FaWhatsapp />, className: "btn btn-success" },
    { name: "Tobit", icon: <FaEnvelope />, className: "btn btn-info" },
    { name: "Email", icon: <FaEnvelope />, className: "btn btn-primary" },
  ];

  const columns = [
    {
      name: "Datum",
      selector: (row) => {
        const date = new Date(row.dbdatum);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
        return formattedDate;
      },
      sortable: true,
      width: "130px",
    },
    {
      name: "Tour",
      selector: (row) => row.tourId.toString().padStart(4, "0"),
      sortable: true,
      width: "120px",
    },
    {
      name: "Fahrer",
      selector: (row) => {
        // Find the fahrer with matching id in fahrerData
        const matchingFahrer =
          Array.isArray(fahrerData) &&
          fahrerData.find((fahrer) => fahrer.id === row.fahrer);
        console.log("fahrerData", fahrerData);

        // Use matchingFahrer.realm if found, otherwise use row.fahrer directly
        return matchingFahrer ? matchingFahrer.realm : row.fahrer;
      },
      sortable: true,
      width: "120px",
      sortFunction: (rowA, rowB) => {
        const realmA =
          fahrerData.find((f) => f.id === rowA.fahrer)?.realm || "Unbekannt";
        const realmB =
          fahrerData.find((f) => f.id === rowB.fahrer)?.realm || "Unbekannt";
        return realmA.localeCompare(realmB, "de", { sensitivity: "base" });
      },
    },
    {
      name: "KundenNr",
      selector: (row) => row.rmakundennummer,
      sortable: true,
      width: "150px",
      sortFunction: (a, b) => {
        const rmakundennummerA = Number(a.rmakundennummer) || 0;
        const rmakundennummerB = Number(b.rmakundennummer) || 0;

        return rmakundennummerA - rmakundennummerB;
      },
    },
    {
      name: "KundenName",
      selector: (row) => row.rmakundenname,
      sortable: true,
      width: "150px",
      sortFunction: (a, b) => {
        const rmakundennameA = a.rmakundenname ? a.rmakundenname : "";
        const rmakundennameB = b.rmakundenname ? b.rmakundenname : "";
        return rmakundennameA.localeCompare(rmakundennameB, "tr", {
          sensitivity: "base",
        });
      },
    },
    {
      name: "Uhrzeit",
      selector: (row) => row.rmauhrzeit,
      sortable: true,
      width: "120px",
      sortFunction: (a, b) => {
        const rmauhrzeitA = a.rmauhrzeit ? a.rmauhrzeit : "";
        const rmauhrzeitB = b.rmauhrzeit ? b.rmauhrzeit : "";
        return rmauhrzeitA.localeCompare(rmauhrzeitB, "tr", {
          sensitivity: "base",
        });
      },
    },
    {
      name: "RMA-1",
      selector: (row) => row.rmanotiz,
      sortable: true,
      width: "120px",
      sortFunction: (a, b) => {
        const rmanotizA = a.rmanotiz ? a.rmanotiz : "";
        const rmanotizB = b.rmanotiz ? b.rmanotiz : "";
        return rmanotizA.localeCompare(rmanotizB, "tr", {
          sensitivity: "base",
        });
      },
    },
    {
      name: "RMA-2",
      selector: (row) => row.rmanotiz2,
      sortable: true,
      width: "250px",
      sortFunction: (a, b) => {
        const rmanotiz2A = a.rmanotiz2 ? a.rmanotiz2 : "";
        const rmanotiz2B = b.rmanotiz2 ? b.rmanotiz2 : "";
        return rmanotiz2A.localeCompare(rmanotiz2B, "tr", {
          sensitivity: "base",
        });
      },
    },
    {
      name: "Action",
      selector: (row) => "action",
      sortable: false,
      right: "true",
      cell: (row) => {
        // Fahrer email bilgisini bul
        const fahrerEmail = Array.isArray(fahrerData)
          ? fahrerData.find((fahrer) => fahrer.id === row.fahrer)?.email
          : undefined;

        // Kullanıcı yetkisini kontrol et (isAdmin doğrudan yetki verir)
        const hasPermission = isAdmin || user.email === fahrerEmail;

        return (
          <div>
            <button
              onClick={() => onEdit(row)}
              className="btn btn-link text-primary"
              disabled={!hasPermission}
            >
              <FiEdit />
            </button>
            <button
              onClick={() => onDelete(row.id)}
              className="btn btn-link text-danger"
              disabled={!hasPermission}
            >
              <RiDeleteBinFill />
            </button>
          </div>
        );
      },
    },
  ];

  const tableData = {
    columns,
    data: reklamationData,
  };

  return (
    <div className="card mb-4">
      <div className="card-header ml-2 fs-5 text-white bg-primary p-3">
        Reklamation Übersicht
      </div>
      <div className="card-body">
        <div className="d-flex flex-wrap gap-2 mb-2">
          {isAdmin
            ? actionBtn.map((action, i) => (
                <button key={i} type="button" className={action.className}>
                  {action.icon} {action.name}
                </button>
              ))
            : null}
        </div>
        <div
          className="table-responsive overflow-scroll"
          style={{ height: "400px" }}
        >
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={reklamationData}
              noHeader
              defaultSortField="id"
              pagination
              paginationComponent={BootyPagination}
              highlightOnHover={false}
              dense={false}
              selectableRows
              selectableRowsHighlight={true}
              selectableRowsComponent={BootyCheckbox}
              expandableRows
              fixedHeader={true}
              responsive={false}
            ></DataTable>
          </DataTableExtensions>
        </div>
      </div>
    </div>
  );
};

export default ReklamationTable;
