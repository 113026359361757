import { createSlice } from '@reduxjs/toolkit';
import { tourApi } from './tourApi';

 

export const tourSlice = createSlice({
  name: 'tour',
  initialState: {
    tours: [],
    tour: {},
  },
  reducers: {
    tourForEdit: (state, action) => {
      state.tour = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(tourApi.endpoints.getTour.matchFulfilled, (state, action) => {
      console.log('action.payload', action.payload);
      state.tours = action.payload;
    });
  },
});
export const { tourForEdit } =tourSlice.actions;

export default tourSlice.reducer;