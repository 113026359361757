import React from "react";
import { IoExitOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { setShowInfo } from "../../../app/services/werkstatt/werkstattSlice";

const PartnerInfoModal = () => {
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(setShowInfo(false));
  };

  const { partnerInfo } = useSelector((state) => state.werkstattSlice);
  console.log(partnerInfo);
  return (
    <div
      className="position-absolute  z-1 rounded "
      style={{ backgroundColor: "rgba(52, 73, 94)", width: "300px" }}
    >
      <div className="position-relative p-5 text-white">
        <div className="position-absolute top-0 end-0 m-1">
          <IoExitOutline onClick={handleModalClose} />
        </div>

        {Array.isArray(partnerInfo) &&
          partnerInfo.map((item, index) => (
            <div key={index}>
              <div> {item.partnername}</div>
              <div> {item.partneransprech}</div>
              <div> {item.partnerhandy}</div>
              <div> {item.partneremail}</div>
              <div> {item.partnerstr}</div>
              <div>
                {item.partnerplz} {item.partnerort}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PartnerInfoModal;
