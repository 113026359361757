import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinFill } from "react-icons/ri";

function ActionColumnCell({ row, onEdit, onDelete }) {


   const handleDelete = () => {
     onDelete(row.id);
   };
    const handleEdit = () => {
      onEdit(row.id)
      
    };
  return (
    <div>
      <button
        role="link"
        type="button"
        onClick={handleEdit}
        className="btn btn-link text-primary"
      >
        <FiEdit />
      </button>

      <></>
      <button
        role="link"
        type="button"
        onClick={handleDelete}
        className="btn btn-link text-danger"
      >
        <RiDeleteBinFill />
      </button>

    </div>
  );
}

export default ActionColumnCell;
