import React from "react";

const PersonalBogenModal = () => {
  return (
    <div
      className="row position-absolute top-0 start-0 w-100 bg-dark bg-opacity-75 d-flex justify-content-center align-items-center"
      style={{ height: "110%" }}
    >
      <div className="col-8 col-sm-6 col-md-4 col-lg-3 h-25 bg-white rounded shadow d-flex justify-content-center align-items-center text-center fw-semibold px-5">
        Ihre Anmeldung wurde erfolgreich empfangen. In 5 Sekunden werden Sie auf
        die Anmeldeseite werden Sie weitergeleitet.
      </div>
    </div>
  );
};

export default PersonalBogenModal;
