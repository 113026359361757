import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./notfound.css"; 
import { useSelector } from "react-redux";

const NotFound = () => {
  const navigate = useNavigate();
  const { userRole } = useSelector((state) => state.auth);
  const handleGoHome = () => {
    if (userRole === "ADMIN") {
      navigate("/");
    } else if (userRole === "FAHRER") {
      navigate(`/`);
    } else if (userRole === "GAST") {
      navigate("/personalbogen");
    } else {
      navigate("/login");
    }
  };
  return (
    <div className="not-found-container text-white text-center d-flex align-items-center justify-content-center">
      <Row>
        <Col className="not-found-text">
          <h1 className="display-1 font-weight-bold">404</h1>
          <p className="lead">LOOKS LIKE YOU ARE LOST IN SPACE</p>
          <div className="not-found-button">
            <Button
              variant="primary"
              className="btn-not-found"
              onClick={handleGoHome}
            >
              GO BACK HOME
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NotFound;
