import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchFail,
  fetchStart,
  loginSuccess,
  logoutSuccess,
  registerSuccess,
} from "../features/authSlice";
import { toastErrorNotify, toastSuccessNotify } from "../helper/ToastNotify";

const useAuthCall = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, isAdmin, isFahrer, isNewUser, user } = useSelector(
    (state) => state.auth
  );

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const login = async (userInfo) => {
    dispatch(fetchStart());
    try {
      const { data } = await axios.post(
        `${BASE_URL}users/login?include=user`,
        userInfo
      );
      const accessTokenWithUser = data;
      dispatch(loginSuccess(accessTokenWithUser));

      toastSuccessNotify("Login erfolgreich!");

      // if (isAdmin) {
      //   navigate("/");
      // } else if (isFahrer) {
      //   navigate(`/tagestourmodal/${user.id}`);
      // } else if (isNewUser) {
      //   navigate("/bewerbungsbogen");
      // } else {
      //   navigate("/login");
      // }
    } catch (error) {
      dispatch(fetchFail());
      toastErrorNotify("Entgültige E-Mail oder Password");
    }
  };

  const logout = async () => {
    dispatch(fetchStart());
    try {
      if (token) {
        // Check if the token is available before making the request
        await axios.post(`${BASE_URL}users/logout?access_token=${token}`, null);
      }
      dispatch(logoutSuccess());
      toastSuccessNotify("Logout erfolgreich!");
      navigate("/login");
    } catch (error) {
      dispatch(fetchFail());
      toastErrorNotify("Logout erfolglos!");
    }
  };

  const register = async (userInfo) => {
    dispatch(fetchStart());
    try {
      const { data } = await axios.post(`${BASE_URL}users`, userInfo);
      dispatch(registerSuccess(data));
      toastSuccessNotify("Register erfolgreich!");
      navigate("/");
    } catch (error) {
      dispatch(fetchFail());
      toastErrorNotify("Register erfolglos!");
    }
  };

  return { login, logout, register };
};

export default useAuthCall;
