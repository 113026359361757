import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IoExitOutline } from "react-icons/io5";
import useAuthCall from "../../hooks/useAuthCall";
import {
  clearSelectedData,
  setKundeCount,
  setMenuCount,
  setSelectedData,
  setSelectedTagesTour,
} from "../../app/services/tagesTour/tagesTourSlice";

import axios from "axios";

const TagesTourAnsicht = () => {
  const dispatch = useDispatch();
  const { user, token } = useSelector((state) => state.auth);
  const { selectedTagesTour } = useSelector((state) => state.tagesTourSlice);

  const url = process.env.REACT_APP_BASE_URL;

  const [terminData, setTerminData] = useState([]);
  const [fahrzeugsData, setFahrzeugsData] = useState([]);
  const [tagesTourData, setTagesTourData] = useState([]);

  const [logData, setLogData] = useState([]);

  const fetchLog = async () => {
    const { data } = await axios.get(`${url}logs?access_token=${token}`);
    setLogData(data);
  };

  const fetchTagesTour = async () => {
    const { data } = await axios.get(`${url}tagestours?access_token=${token}`);
    setTagesTourData(data);
  };

  const fetchWerkstatt = async () => {
    const { data } = await axios.get(`${url}werkstatts?access_token=${token}`);
    setTerminData(data);
  };

  const fetchFahrzeugs = async () => {
    const { data } = await axios.get(`${url}fahrzeuges?access_token=${token}`);
    setFahrzeugsData(data);
  };

  useEffect(() => {
    fetchWerkstatt();
    fetchFahrzeugs();
    fetchTagesTour();
    fetchLog();
  }, []);

  const selectedFahrzeug =
    selectedTagesTour &&
    Array.isArray(fahrzeugsData) &&
    fahrzeugsData
      .filter((f) => selectedTagesTour.fahrzeugId === f.id)
      .map((f) => f.kfzkennzeichen)[0];

  const selectedTour = selectedTagesTour && tagesTourData
    .filter((t) => t.id === selectedTagesTour.tourId)
    .map((t) => t.t_tour)[0];

  const matchID =
    selectedFahrzeug &&
    fahrzeugsData
      .filter((f) => f.kfzkennzeichen === selectedFahrzeug)
      .map((item) => item.id)[0];

  const matchingData = terminData.filter((f) => f.fahrzeugId === matchID);

  const isBestaetigt = matchingData.filter((f) => f.status === "Bestätigt");

  const currentDate = isBestaetigt
    .map((f) => f.werkdatum)
    .filter((d) => d.split("T")[0] === new Date().toISOString().split("T")[0])
    .map((d) => d.split("T")[0])[0];

  const isBestaetigtAndCurrentDate = isBestaetigt.filter(
    (f) => f.werkdatum?.split("T")[0] === currentDate
  );

  // Menü hesaplama

  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];

  const matchedUserData = logData.filter(
    (item) =>
      item.userId === user.id &&
      new Date(item.timestamp).toISOString().split("T")[0] === formattedDate
  );
  const selectedTagesTourId = matchedUserData.map((i) => i.tourId)[0];
  const matchedTourName = tagesTourData
    .filter((t) => t.id === selectedTagesTourId)
    .map((t) => t.t_tour)[0];

  const tourDatas = tagesTourData.filter((t) => t.t_tour === matchedTourName);
  const completedTours = tourDatas.filter((t) => t.t_status !== null);
  const uncompletedTours = tourDatas.filter((t) => t.t_status === null);
  const kundeCount = tourDatas.length - completedTours.length;
  const menues = uncompletedTours.map((t) => t.t_menues);
  const countMenus = () => {
    let normalMenuCount = 0;
    let tkMenuCount = 0;

    menues.forEach((menu) => {
      menu.split("\n").forEach((item) => {
        const parts = item.split(" ");
        const menuCode = parts[1];
        if (menuCode.startsWith("4") && menuCode.length === 4) {
          normalMenuCount += parseInt(parts[0], 10);
        } else {
          tkMenuCount += parseInt(parts[0], 10);
        }
      });
    });

    return { normalMenuCount, tkMenuCount };
  };

  const { normalMenuCount, tkMenuCount } = countMenus();

  dispatch(setKundeCount(kundeCount));
  dispatch(setMenuCount(normalMenuCount + tkMenuCount));

  const handleWeiter = (e) => {
    e.preventDefault();

    if (isBestaetigtAndCurrentDate.length > 0) {
      navigate(`/terminplaner/${user.id}`);
    } else {
      navigate("/");
    }
  };

  const navigate = useNavigate();

  const handleZurück = () => {
    navigate(`/tagestourmodal/${user.id}`);
  };

  const { logout } = useAuthCall();
  const handleLogout = () => {
    dispatch(setKundeCount(0));
    dispatch(setMenuCount(0));
    dispatch(setSelectedTagesTour(null));
    logout();
  };

  return (
    <Modal show={true} size="sm" centered>
      <Modal.Body>
        <div
          className="d-flex flex-column text-center gap-4 position-relative "
          style={{ height: "500px" }}
        >
          <div className="d-flex flex-column gap-3 align-items-center">
            <div className="w-100 d-flex flex-column align-items-center gap-2">
              <div className="border border-2 border-primary bg-primary-subtle rounded w-75  py-3 mt-5">
                {selectedTour}
              </div>
              <div className="border border-2 border-primary bg-primary-subtle rounded w-75  py-3">
                {selectedFahrzeug}
              </div>
            </div>

            <div className="border border-2 border-primary bg-primary-subtle rounded w-75  py-3 mt-5 px-1">
              <h6>ÜBERSICHT</h6>
              <div className="d-flex flex-column   mx-auto">
                <div className="d-flex justify-content-between">
                  Kunde
                  <span>{kundeCount}</span>
                </div>

                <div className="d-flex justify-content-between">
                  Menu
                  <span> {normalMenuCount} </span>
                </div>
                <div className="d-flex justify-content-between">
                  TK Menu
                  <span> {tkMenuCount}</span>
                </div>
                <div className="d-flex justify-content-between">
                  Total
                  <span>{normalMenuCount + tkMenuCount}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center position-absolute fixed-bottom">
            <button onClick={handleZurück} className="btn btn-secondary">
              Zurück
            </button>
            <button
              type="submit"
              className="btn btn-primary "
              onClick={handleWeiter}
            >
              Weiter
            </button>
          </div>
          <Link
            onClick={handleLogout}
            className="position-absolute top-0 end-0 cursor-pointer fs-5"
          >
            <IoExitOutline />
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TagesTourAnsicht;
