import axios from "axios";
import { useSelector } from "react-redux";

const useAxios = () => {
  const { token } = useSelector((state) => state.auth); //& tokeni stateden okumak için axios instanceları custom hook içerinde tanımladık.
  //+ birden fazla instance oluşturabiliriz. instance ı tanımladığımız isimle kullanabiliriz.

  const axiosWithToken = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // token add as param
    params: { access_token: token },
  });

  const axiosWithPublic = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });
  
  return { axiosWithToken, axiosWithPublic };
};

export default useAxios;
