import { useFetchUsersQuery, useGetRegionsQuery } from '../../app/services/region/regionApi';
import { useSelector } from 'react-redux';
import RegionTable from './table/RegionTable';
import RegionForm from './form/RegionForm';

const Region = () => {
  const { token } = useSelector((state) => state.auth);
  const { refetch } = useGetRegionsQuery(token);
  useFetchUsersQuery(token);

  return (
    <div className="container">
      <div className="d-flex flex-column gap-4">
        <RegionTable refetchRegions={refetch} />
        <RegionForm refetchRegions={refetch} />
      </div>
    </div>
  );
};

export default Region;
