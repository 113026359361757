import { useId } from "react";
import Formmformik from "./core/Formmformik";
import TourFormHeader from "./core/TourFormHeader";
import { useDispatch, useSelector } from "react-redux";
import { useCreateTourMutation, useUpdateTourMutation } from "./core/tourApi";
import { toast } from "react-toastify";
import { tourForEdit } from "./core/tourSlice";


const FormTour = ({ refetch }) => {
  const { tour } = useSelector((state) => state.tour);
  const { token } = useSelector((state) => state.auth);
  const generatedId = useId();
  const dispatch = useDispatch();
  const [createTour, createTourResult] = useCreateTourMutation();
  const [updateTour, updateTourResult] = useUpdateTourMutation();
  const actionLoading =
    createTourResult.isLoading || updateTourResult.isLoading;

    const formatDate = (inputDate) => {
      const date = new Date(inputDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

  const handleTour = async (values, actions) => {
    if (tour?.id) {
      const updateTours = {
        id:values.id,
        region: values.region,
        dbdatum: values.dbdatum,
        tourname: values.tourname,
        fahrtbeginndatum: new Date(values.fahrtbeginndatum).toISOString(),
        fahrtbeginnort: values.fahrtbeginnort,
        fahrtendeort: values.fahrtendeort,
        fahrtbeginnuhr: values.fahrtbeginnuhr,
        fahrtendeuhr: values.fahrtendeuhr,
        fahrtdauer: values.fahrtdauer,
        fahrtkm: values.fahrtkm,
        fahrzeugtype: values.fahrzeugtype,
        vkpreis: values.vkpreis,
        tourfixpreis: values.tourfixpreis,
        preistype: values.preistype,
        telematiklink: values.telematiklink,
        telematikmandant: values.telematikmandant,
        telematikfirma: values.telematikfirma,
        telematikkurzel: values.telematikkurzel,
        telematikfahrzeug: values.telematikfahrzeug,
        tournotiz: values.tournotiz,
        tankkarte: values.tankkarte,
      };
      await updateTour({ id: values.id, token, updateTours })
        .unwrap()
        .then((res) => {
          toast.success("Tour erfolgreich aktualisiert");
          dispatch(tourForEdit({}));
          refetch();
          actions.setFieldValue();
        })
        .catch((err) => {
          toast.error("Option konnte nicht aktualisiert werden");
        });
    } else {
      const body = {
        token: token,
        formData: {
          region: values.region,
          dbdatum: values.dbdatum,
          tourname: values.tourname,
          fahrtbeginndatum: new Date(values.fahrtbeginndatum).toISOString(),
          fahrtbeginnort: values.fahrtbeginnort,
          fahrtendeort: values.fahrtendeort,
          fahrtbeginnuhr: values.fahrtbeginnuhr,
          fahrtendeuhr: values.fahrtendeuhr,
          fahrtdauer: values.fahrtdauer,
          fahrtkm: values.fahrtkm,
          fahrzeugtype: values.fahrzeugtype,
          vkpreis: values.vkpreis,
          tourfixpreis: values.tourfixpreis,
          preistype: values.preistype,
          telematiklink: values.telematiklink,
          telematikmandant: values.telematikmandant,
          telematikfirma: values.telematikfirma,
          telematikkurzel: values.telematikkurzel,
          telematikfahrzeug: values.telematikfahrzeug,
          tournotiz: values.tournotiz,
          tankkarte: values.tankkarte,
        },
      };
      await createTour(body)
        .unwrap()
        .then((res) => {
          refetch();
          actions.setFieldValue();
          toast.success("Tour erfolgreich hinzugefügt");
          actions.resetForm();
        })
        .catch((err) => {
          toast.error("Tour konnte nicht hinzugefügt werden");
        });
    }
  };

  return (
    <div className="card my-4">
      <div className="card-header bg-primary text-white">
        <TourFormHeader tour={tour} />
      </div>
      <div className="card-body">
        <div>
          <Formmformik
            key={tour?.id || `cretaeTourForm_${generatedId}`}
            tour={tour}
            handleTour={handleTour}
            actionLoading={actionLoading}
            refetch={refetch}
            formatDate={formatDate}
          />
        </div>
      </div>
    </div>
  );
};
export default FormTour;
