import React from "react";
import { Modal } from "react-bootstrap";

const TerminPlanerIsNotDataModal = ({ handleWeiter }) => {
  return (
    <div>
      <Modal show={true} size="sm" centered>
        <Modal.Body>
          <div style={{ height: "100px" }}>
            <div className="text-center fw-semibold">
              Heute sind für Sie keine Werkstatt-Termine verfügbar.
            </div>
            <div className="text-center mb-2 position-absolute fixed-bottom">
              <button
                type="submit"
                className="btn btn-primary "
                onClick={handleWeiter}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TerminPlanerIsNotDataModal;
