import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  terminplanerData: [],
  bestaetigung: null,
};

const terminplanerSlice = createSlice({
  name: "terminplanerSlice",
  initialState,
  reducers: {
    setTerminPlanerData: (state, action) => {
      state.terminplanerData = action.payload;
    },
    setBestaetigung: (state, action) => {
      state.bestaetigung = action.payload;
    },
  },
});

export const { setTerminPlanerData, setBestaetigung } =
  terminplanerSlice.actions;
export default terminplanerSlice.reducer;
