import React, { useId } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import OptionFormHeader from "./option/OptionFormHeader";
import { optionForEdit } from "./option/optionSlice";
import { toast } from "react-toastify";
import FormikForm from "./option/FormikForm";
import { useCreateOptionsMutation, useUpdateOptionsMutation } from "./option/optionApi";

const FormOption = ({ refetch }) => {
  const { token } = useSelector((state) => state.auth);
  const generatedId = useId();
  const dispatch = useDispatch();
  const [createOptions, createOptionsResult] = useCreateOptionsMutation();
  const [updatedOption, updateOptionsResult] = useUpdateOptionsMutation();
  const { option } = useSelector((state) => state.option);
  const actionLoading = createOptionsResult.isLoading || updateOptionsResult.isLoading;


  const createOptionHandler = async (values, actions) => {
    if (option?.id) {
      const updateOptions = {
        id: values.id,
        key: values.key,
        value: values.value,
        kategorie: values.kategorie,
        status: values.status,
      };
      await updatedOption({ id: values.id, token, updateOptions })
        .unwrap()
        .then((res) => {
          // make ıt toast or success action
          toast.success("Option erfolgreich aktualisiert");
          // setFieldValue('regionName', '', false);
          dispatch(optionForEdit({}));
          refetch();
          actions.setFieldValue();
        })
        .catch((err) => {
          toast.error("Option konnte nicht aktualisiert werden");
        });
    } else {
      const body = {
        token: token,
        formData: {
          id: null,
          key: values.key,
          value: values.value,
          kategorie: values.kategorie,
          status: values.status,
        }
      }
      await createOptions(body)
        .unwrap()
        .then((res) => {
          refetch();
          actions.setFieldValue();
          toast.success('Option erfolgreich hinzugefügt');
          actions.resetForm();
        })

        .catch((err) => {
          toast.error('Option konnte nicht hinzugefügt werden');
        });
    }
  };

  return (
    <div className="card my-4">
      <div className="card-header bg-primary text-white">OPTİON FORM</div>
      <div className="card-body">
        <OptionFormHeader option={option} />
        <div>
          <FormikForm
            key={option?.id || `cretaeOptionForm_${generatedId}`}
            option={option}
            createOptionHandler={createOptionHandler}
            actionLoading={actionLoading}
          />
        </div>
      </div>
    </div>
  );
};
export default FormOption;
