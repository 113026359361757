import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const partnerApi = createApi({
  reducerPath: "partnerApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),

  endpoints: (builder) => ({
    getPartner: builder.query({
      query: (token) => `partners?access_token=${token}`,
    }),
  }),
});

export const { useGetPartnerQuery } = partnerApi;
