import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { read, utils } from "xlsx";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { IoNavigate } from "react-icons/io5";
import { GrStatusGood } from "react-icons/gr";
import { SlInfo } from "react-icons/sl";
import Modal from "react-bootstrap/Modal";
import {
  setKundeCount,
  setMenuCount,
} from "../../app/services/tagesTour/tagesTourSlice";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const modalCSS = {
  background: "rgba(0, 0, 0, 0.5)",
};

function TagesTourDetails() {
  const [tableData, setTableData] = useState([]);
  const [logData, setLogData] = useState([]);
  const [tagesTourData, setTagesTourData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token, user, userRole } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const url = process.env.REACT_APP_BASE_URL;

  const fetchLog = async () => {
    const { data } = await axios.get(`${url}logs?access_token=${token}`);
    setLogData(data);
  };

  const fetchData = async () => {
    const { data } = await axios.get(
      `https://dev.zone-edv.de/api/tagestours?access_token=${token}`
    );

    setTagesTourData(data);
    setTableData(data);
  };
  useEffect(() => {
    fetchData();
    fetchLog();
    setLoading(false);
  }, [token, user.id]);

  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];

  const tourDataFromLoggedId = logData
    .filter(
      (item) =>
        item.userId === user.id &&
        new Date(item.timestamp).toISOString().split("T")[0] === formattedDate
    )
    .map((i) => i.tourId)[0];

  const matchingTour = tagesTourData
    .filter((item) => item.id === tourDataFromLoggedId)
    .map((i) => i.t_tour)[0];

  const dataForFahrer = tagesTourData.filter(
    (item) => item.t_tour === matchingTour
  );

  const onDelete = async () => {
    await axios.delete(
      `${BASE_URL}users/${user.id}/tagestour?access_token=${token}`
    );
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const workbook = read(e.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = utils.sheet_to_json(worksheet);

      const mappedData = jsonData.map((row, index) => ({
        id: row.id || index,
        t_tour: row.Tour,
        t_name: row.Name,
        t_strasse: row.Strasse,
        t_plz: row.PLZ,
        t_ort: row.Ort,
        t_alp: row.Reihenfolge,
        t_tel: row.ExtField04,
        t_kundennr: row.Kundennummer,
        t_menueart: row.ExtField09,
        t_menues: row.Fahrzeuggruppe,
        t_info: row.Info,
        t_info1: row.Zusatz02,
        t_status: row.Status,
      }));

      setTableData(mappedData);

      const dataWithoutId = mappedData.map(({ id, ...rest }) => rest);
      await onDelete();
      await axios.post(
        `https://dev.zone-edv.de/api/users/${user.id}/tagestour?access_token=${token}`,
        dataWithoutId
      );
    };

    reader.readAsBinaryString(file);
  }, []);

  const [infoContent, setInfoContent] = useState("");
  const [infoModal, setInfoModal] = useState(null);

  const handleInfoClick = (rowId) => {
    const selectedRow = tagesTourData.find((item) => item.id === rowId);
    console.log(selectedRow.t_info);
    setInfoContent(selectedRow.t_info + " " + selectedRow.t_info1);
    setInfoModal(rowId);
  };

  const onCloseInfoModal = () => {
    setInfoModal(null);
  };

  const [selectedID, setSelectedID] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(null);

  const options = [
    { id: 1, msg: "Lieferung erfolgreich, keine Besonderheiten" },
    { id: 2, msg: "Lieferung erfolgreich, Menü vor Wohnungstür abgestellt" },
    { id: 3, msg: "Lieferung nicht erfolgreich, Annahme verweigert" },
    {
      id: 4,
      msg: "Lieferung nicht erfolgreich, TG nicht persönlich angetroffen",
    },
    { id: 5, msg: "TG gesundheitlich beeinträchtigt, Notfall" },
  ];

  const openModal = (id) => {
    setSelectedID(id);
    setIsModalOpen(true);
  };

  const handleSelectedMsg = async (msg) => {
    let updatedTagesTourData = [...tagesTourData];

    updatedTagesTourData = updatedTagesTourData.map((item) => {
      if (item.id === selectedID) {
        return { ...item, t_status: msg };
      }
      return item;
    });

    const updatedItem = updatedTagesTourData.find(
      (item) => item.id === selectedID
    );

    if (updatedItem) {
      try {
        await axios.put(
          `${BASE_URL}tagestours/${updatedItem.id}?access_token=${token}`,
          updatedItem
        );
        dispatch(setKundeCount(kundeCount));
        dispatch(setMenuCount(normalMenuCount + tkMenuCount));
        await fetchData();
      } catch (error) {
        console.error("Güncelleme sırasında bir hata oluştu", error);
      }
    }
    setIsModalOpen(false);
  };

  const columns = [
    { field: "t_tour", headerName: "Tour", width: 100 },
    { field: "t_name", headerName: "Name", width: 200 },
    { field: "t_strasse", headerName: "Strasse", width: 200 },
    { field: "t_plz", headerName: "PLZ", width: 100 },
    { field: "t_ort", headerName: "Ort", width: 100 },
    { field: "t_alp", headerName: "Alp", width: 20 },
    { field: "t_tel", headerName: "Tel", width: 130 },
    { field: "t_kundennr", headerName: "Kundennummer", width: 130 },
    { field: "t_menueart", headerName: "Menuart", width: 150 },
    { field: "t_menues", headerName: "Menues", width: 350 },
    {
      field: "t_info",
      headerName: "Info",
      width: 50,
      renderCell: (params) => {
        return (
          <div>
            <SlInfo onClick={() => handleInfoClick(params.id)} />
            {infoModal && (
              <Modal
                show={true}
                onHide={onCloseInfoModal}
                size="sm"
                centered
                className={`bg-light ${modalCSS}`}
              >
                <Modal.Body className="text-center">{infoContent}</Modal.Body>
              </Modal>
            )}
          </div>
        );
      },
    },

    {
      field: "navigate",
      headerName: "Navi",
      width: 50,
      renderCell: (params) => {
        return (
          <IoNavigate
            className="cursor-pointer d-inline-block"
            onClick={() => handleClickFahren(params.row)}
            style={{ cursor: "pointer" }}
          />
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 400,
      renderCell: (params) => {
        return (
          <div>
            {params.row.t_status ? (
              <div onClick={() => openModal(params.id)}>
                {params.row.t_status}{" "}
                {/* Her satır kendi t_status değerini kullanıyor */}
              </div>
            ) : (
              <GrStatusGood
                className="cursor-pointer"
                onClick={() => openModal(params.id)}
              />
            )}

            <Modal
              show={isModalOpen}
              size="sm"
              centered
              onHide={() => setIsModalOpen(false)}
              className="bg-light"
            >
              <Modal.Body>
                <div className="d-flex flex-column text-center gap-3 my-auto align-self-center position-relative">
                  {options.map((item) => (
                    <div
                      key={item.id}
                      className="bg-secondary-subtle rounded d-flex align-items-center justify-content-center"
                      style={{
                        fontSize: "0.8rem",
                        height: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelectedMsg(item.msg)}
                    >
                      {item.msg}
                    </div>
                  ))}
                </div>
              </Modal.Body>
            </Modal>
          </div>
        );
      },
    },
  ];

  const handleClickFahren = (row) => {
    const destination = `${row.t_strasse} ${row.t_plz} ${row.t_ort}`;
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      destination
    )}&travelmode=driving`;
    window.open(mapsUrl, "_blank");
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const matchedUserData = logData.filter((item) => item.userId === user.id);
  const selectedTagesTourId = matchedUserData.map((i) => i.tourId)[0];
  const matchingTourName = tagesTourData
    .filter((t) => t.id === selectedTagesTourId)
    .map((t) => t.t_tour)[0];

  const tourDatas = tagesTourData.filter((t) => t.t_tour === matchingTourName);
  const completedTours = tourDatas.filter((t) => t.t_status !== null);
  const uncompletedTours = tourDatas.filter((t) => t.t_status === null);
  const kundeCount = tourDatas.length - completedTours.length;
  const menues = uncompletedTours.map((t) => t.t_menues);
  const countMenus = () => {
    let normalMenuCount = 0;
    let tkMenuCount = 0;

    menues.forEach((menu) => {
      menu.split("\n").forEach((item) => {
        const parts = item.split(" ");
        const menuCode = parts[1];
        if (menuCode.startsWith("4") && menuCode.length === 4) {
          normalMenuCount += parseInt(parts[0], 10);
        } else {
          tkMenuCount += parseInt(parts[0], 10);
        }
      });
    });

    return { normalMenuCount, tkMenuCount };
  };

  const { normalMenuCount, tkMenuCount } = countMenus();

  dispatch(setKundeCount(kundeCount));
  dispatch(setMenuCount(normalMenuCount + tkMenuCount));

  if (loading) {
    return (
      <div
        className="
      position-absolute top-50 start-50 translate-middle  text-center 
      "
      >
        <button class="btn btn-primary" type="button" disabled>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </button>
      </div>
    ); // Veriler yüklenene kadar yüklenme durumunu göster
  }

  return (
    <div className="container mt-3">
      {userRole === "ADMIN" && (
        <div className="card mb-3">
          <div className="card-header bg-primary text-white">Excel Upload</div>
          <div className="card-body">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div
                className="bg-light rounded d-flex justify-content-center align-items-center"
                style={{ height: "80px" }}
              >
                Ziehen Sie einige Dateien hierher, oder klicken Sie, um Dateien
                auszuwählen.
              </div>
            </div>
          </div>
        </div>
      )}

      {userRole === "FAHRER" && (
        <div className="card mb-3">
          <div className="card-header bg-primary text-white">
            Verbleibende Tourdaten
          </div>
          <div className="card-body ">
            <div className="d-flex flex-column gap-3 flex-md-row text-center  justify-content-around">
              <div
                className="shadow rounded-circle mx-auto d-flex justify-content-center align-items-center"
                style={{ width: "150px", height: "150px" }}
              >
                <div>
                  <h5>Kunden</h5>
                  <p className="fw-semibold text-danger">{kundeCount}</p>
                </div>
              </div>

              <div
                className="shadow rounded-circle mx-auto d-flex justify-content-center align-items-center"
                style={{ width: "150px", height: "150px" }}
              >
                <div>
                  <h5>Menues</h5>
                  <p className="fw-semibold text-danger">{normalMenuCount}</p>
                </div>
              </div>

              <div
                className="shadow rounded-circle mx-auto d-flex justify-content-center align-items-center"
                style={{ width: "150px", height: "150px" }}
              >
                <div>
                  <h5>Tk Menues</h5>
                  <p className="fw-semibold text-danger">{tkMenuCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="card">
        <div className="card-header bg-primary text-white">Übersicht</div>
        <div className="card-body">
          {/* {isModalOpen && <LieferungModal />} */}
          <DataGrid
            rows={userRole === "ADMIN" ? tableData : dataForFahrer}
            columns={columns}
            style={{
              height: `${userRole === "FAHRER" ? "600px" : "400px"}`,
            }}
            components={{
              Toolbar: GridToolbar,
            }}
            density="compact"
          />
        </div>
      </div>
    </div>
  );
}

export default TagesTourDetails;
