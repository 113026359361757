import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { GastLayout } from "../layouts/GastLayout";

export const GastRouter = () => {
  const { userRole } = useSelector((state) => state.auth);
  const location = useLocation();

  return userRole === "GAST" ? (
    <GastLayout>
      <Outlet />
    </GastLayout>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
