import axios from "axios";
import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

const BestaetigungsModal = ({
  setIsModalOpen,
  sortingData,
  setAbholungsInfoModal,
}) => {
  const { token, user } = useSelector((state) => state.auth);
  const url = process.env.REACT_APP_BASE_URL;

  const handleJaClick = async () => {
    try {
      const updatedData = {
        ...sortingData[0],
        status: "in Arbeit",
        fahrer: `${user.id}`,
      };
      await axios.put(`${url}werkstatts/${sortingData[0].id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsModalOpen(null);
      setAbholungsInfoModal(true);
    } catch (error) {
      console.error("Veri güncelleme hatası:", error);
    }
  };

  const handleNeinClick = () => {
    setIsModalOpen(null);
  };

  return (
    <div>
      <Modal show={true} size="sm" centered className="text-center">
        <Modal.Body>
          <p>Wurde das Fahrzeug in die Werkstatt gebracht?</p>
          <div className="d-flex justify-content-center gap-2">
            <button
              className="btn btn-primary badge"
              style={{ width: "50px" }}
              onClick={handleJaClick}
            >
              Ja
            </button>
            <button
              className="btn btn-secondary badge"
              style={{ width: "50px" }}
              onClick={handleNeinClick}
            >
              Nein
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BestaetigungsModal;
