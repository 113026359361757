import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";

const FahrzeugsInspektion = () => {
  const { token } = useSelector((state) => state.auth);
  const url = process.env.REACT_APP_BASE_URL;
  const [fahrzeugDatei, setFahrzeugDatei] = useState([]);
  const [leistungsData, setLeistungsData] = useState([]);
  const [werkstattDatei, setWerkstattDatei] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fahrzeugsResponse, leistungsResponse, werkstattsResponse] = await Promise.all([
          axios.get(`${url}fahrzeuges?access_token=${token}`),
          axios.get(`${url}leistungs?access_token=${token}`),
          axios.get(`${url}werkstatts?access_token=${token}`),
        ]);

        setFahrzeugDatei(fahrzeugsResponse.data);
        setLeistungsData(leistungsResponse.data);
        setWerkstattDatei(werkstattsResponse.data);

        setLoading(false);
      } catch (error) {
        console.error("Veri çekilirken hata oluştu:", error);
      }
    };

    fetchData();
  }, [token, url]);

  const uniqueFahrzeugs = Array.from(
    new Set(fahrzeugDatei.map((fahrzeug) => fahrzeug.id))
  ).map((id) => {
    return fahrzeugDatei.find((fahrzeug) => fahrzeug.id === id);
  });

  const combinedData = uniqueFahrzeugs
    .map((fahrzeug) => {
      const matchingService = werkstattDatei
        .filter((item) => item.fahrzeugId === fahrzeug.id)
        .filter((item) => item.serviceart.includes("Inspektion"))
        .filter((item) => item.status === "Erledigt");

      const matchingLeistung = leistungsData
        .filter((item) => item.fahrzeugId === fahrzeug.id && item.endzeit)
        .sort((a, b) => b.endzeit - a.endzeit)
        .find((item) => item);

      const lastTour = matchingLeistung
        ? matchingLeistung._tour.tourname
        : "Keine Letzte Tour";

      const matchingServiceWithInspektion = werkstattDatei
        .filter((item) => item.fahrzeugId === fahrzeug.id)
        .filter((item) => item.serviceart.includes("Inspektion"));

      let summeWithInspektion = 0;
      matchingServiceWithInspektion.forEach((service) => {
        summeWithInspektion += service.inspektionRestWeg;
      });

      if (matchingServiceWithInspektion.length > 0) {
        const matchingLeistung = leistungsData
          .filter((item) => item.fahrzeugId === fahrzeug.id)
          .map((item) => item.endkm)
          .sort()
          .reverse()[0];

        const werkKm =
          matchingServiceWithInspektion[
            matchingServiceWithInspektion.length - 1
          ].werkkilometer;

        summeWithInspektion =
          Number(werkKm) +
          Number(fahrzeug.kfzfahrzeuginspektionkm) -
          Number(matchingLeistung);
      }

      const matchingServiceWithoutInspektion = werkstattDatei
        .filter((item) => item.fahrzeugId === fahrzeug.id)
        .filter((item) => !item.serviceart.includes("Inspektion"));

      let summeWithoutInspektion = 0;
      matchingServiceWithoutInspektion.forEach((service) => {
        summeWithoutInspektion += service.inspektionRestWeg;
      });

      if (matchingServiceWithoutInspektion.length > 0) {
        const matchingLeistung = leistungsData
          .filter((item) => item.fahrzeugId === fahrzeug.id)
          .map((item) => item.endkm)
          .sort()
          .reverse()[0];

        summeWithoutInspektion =
          Number(fahrzeug.kfzfahrzeugkilometer) +
          Number(fahrzeug.kfzfahrzeuginspektionkm) -
          Number(matchingLeistung);
      }

      const inspektionRestWeg = summeWithInspektion
        ? summeWithInspektion
        : summeWithoutInspektion;

      const werkTermin = werkstattDatei
        .filter((item) => item.fahrzeugId === fahrzeug.id)
        .filter((item) => item.serviceart.includes("Inspektion"))
        .map((item) => {
          const date = new Date(item.werkdatum);
          const options = { year: "numeric", month: "2-digit", day: "2-digit" };
          return date.toLocaleDateString("de-DE", options);
        });

      if (matchingService.length === 0) {
        return {
          id: fahrzeug.id,
          kennzeichen: fahrzeug.kfzkennzeichen,
          lastTourName: lastTour,
          inspektionRestWeg: isNaN(inspektionRestWeg)
            ? "---"
            : inspektionRestWeg,
          termin: werkTermin.length > 0 ? werkTermin[0] : "---",
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  const columns = [
    {
      name: "Fahrzeugs",
      selector: row => row.kennzeichen, // Sütun seçici olarak fonksiyon kullanıyoruz
      sortable: true,
      cell: (row) => (
        <div>
          {row.kennzeichen && row.lastTourName
            ? `${row.kennzeichen} - ${row.lastTourName}`
            : "---"}
        </div>
      ),
      grow: 2,
    },
    {
      name: "InspektionRestWeg",
      selector: row => row.inspektionRestWeg, // Sütun seçici olarak fonksiyon kullanıyoruz
      sortable: true,
      center: true,
      cell: (row) => (
        <div
          className={`text-center ${
            parseInt(row.inspektionRestWeg) < 1500 &&
            row.inspektionRestWeg !== 0
              ? "bg-danger"
              : ""
          }`}
        >
          {row.inspektionRestWeg !== 0
            ? row.inspektionRestWeg
            : "---"}
        </div>
      ),
    },
    {
      name: "Termin",
      selector: row => row.termin, // Sütun seçici olarak fonksiyon kullanıyoruz
      sortable: true,
      center: true,
      cell: (row) => (
        <div
          className={`text-center ${
            parseInt(row.inspektionRestWeg) < 1500 &&
            row.inspektionRestWeg !== 0
              ? "bg-danger"
              : ""
          }`}
        >
          {row.termin !== "---" ? row.termin : "---"}
        </div>
      ),
    },
  ];

  if (loading) {
    return <div>Yükleniyor...</div>;
  }
console.log(combinedData)
  return (
    <div className="container mt-3">
      <div className="card">
        <div className="card-header bg-primary text-white">
          FahrzeugsInspektion
        </div>
        <div className="card-body">
          <DataTable
            columns={columns}
            data={combinedData.filter((item) => item.inspektionRestWeg < 5000)}
            pagination
            highlightOnHover
            paginationPerPage={6}
            paginationRowsPerPageOptions={[6, 8, 10, 20]}
            responsive
            striped
            customStyles={{
              rows: {
                style: {
                  minHeight: '52px',
                },
              },
              headCells: {
                style: {
                  fontWeight: 'bold',
                  fontSize: '16px',
                },
              },
              cells: {
                style: {
                  padding: '8px',
                  fontSize: '14px',
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default FahrzeugsInspektion;
