import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpenSettingsModal: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    toggleSettingsModal: (state) => {
      state.isOpenSettingsModal = !state.isOpenSettingsModal;
    },
  },
});

export const { toggleSettingsModal } = settingsSlice.actions;
export default settingsSlice.reducer;
