export const InputFeedback = ({ error }) => {
  return error ? (
    <div
      className="text-danger"
      style={{ fontSize: '0.875rem', fontWeight: '600', opacity: 0.8 }}
    >
      {error}
    </div>
  ) : null;
};
