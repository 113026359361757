import React, { useEffect } from "react";
import LeistungForm from "./core/LeistungForm";
import LeistungTable from "./core/LeistungTable";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { number, object, ref, string } from "yup";
import {
  useGetLeistungsQuery,
  useGetFahrerQuery,
  useGetToursQuery,
  useGetFahrzeugesQuery,
} from "./core/leistungApi";
import { useGetKundenQuery } from "../reklamation/services/reklamationApi";
import axios from "axios";
import { toastSuccessNotify, toastWarnNotify } from "../../helper/ToastNotify";

const Leistung = () => {
  const { token, userRole, currentUser } = useSelector((state) => state.auth);
  const BASE_URL = "https://dev.zone-edv.de/api/";
  // const url = `${BASE_URL}leistungs?access_token=${token}`;

  // console.log("token: " + token);
  // console.log("userRole: " + userRole);
  // console.log("currentUser: " + currentUser);

  // console.log("token: " + token);
  // console.log("userRole: " + userRole);
  // console.log("currentUser: " + currentUser);

  const {
    data: leistungData,
    isLoading,
    error,
    refetch,
  } = useGetLeistungsQuery({ token, userRole, userId: currentUser });

  const { data: fahrerData } = useGetFahrerQuery({
    token,
    userRole,
    userId: currentUser,
  });

  const { data: toursData } = useGetToursQuery(token);

  const { data: fahrzeugesData } = useGetFahrzeugesQuery(token);

  const { data: kundenData } = useGetKundenQuery(token);
  // const [createLeistung] = useCreateLeistungMutation();
  // const [updateLeistung] = useUpdateLeistungMutation();
  // const [deleteLeistung] = useDeleteLeistungMutation();

  // console.log("leistungData: ", leistungData);
  // console.log("toursData: ", toursData);
  // console.log("fahrzeugesData: ", fahrzeugesData);
  // console.log("fahrerData: ", fahrerData);
  // console.log("kundenData: ", kundenData);

  const startZeit = () => {
    if (leistungData?.length > 0) {
      const lastEntryWithFahrzeugId = leistungData
        .slice()
        .reverse()
        .find((entry) => entry.fahrzeugId === formik.values.fahrzeugId);

      if (lastEntryWithFahrzeugId) {
        formik.setFieldValue("startzeit", lastEntryWithFahrzeugId.endzeit);
      }
    }
  };

  useEffect(() => {
    checkExistingRegularfahrt();
    startZeit();
  }, [token, leistungData]);

  const initialValues = {
    dbdatum: new Date().toISOString().slice(0, 10),
    sonderfahrt: "Regulärfahrt",
    startkm: 0,
    endkm: 0,
    startzeit: "08:30",
    endzeit: "",
    anzahltg: 0,
    menues: 0,
    kita: 0,
    tiefkuehl: 0,
    menuefix: 0,
    fahrzeugId: 0,
    mikrowelle: 0,
    tkwuerfel: 0,
    notiz: "",
    kundennumer: "",
    _tour: {},
    fahrer: 0,
  };

  const leistungSchema = object({
    dbdatum: string().required("Datum ist erforderlich"),
    sonderfahrt: string("Regulärfahrt").required(
      "Sonderfahrt ist erforderlich"
    ),
    startkm: number().required("Start KM ist erforderlich"),
    endkm: number()
      .moreThan(ref("startkm"), "End KM muss größer als Start KM sein")
      .required("End KM ist erforderlich"),
    startzeit: string("08:30").required("Startzeit ist erforderlich"),
    endzeit: string().required("Endzeit ist erforderlich"),
    anzahltg: number(),
    menues: number(),
    kita: number(),
    tiefkuehl: number(),
    menuefix: number(),
    fahrzeugId: number().required("Fahrzeug ist erforderlich"),
    mikrowelle: number(),
    tkwuerfel: number(),
    notiz: string(),
    kundennumer: "",
    _tour: object().required("Tour ist erforderlich"),
    fahrer: number().required("Fahrer ist erforderlich"),
  });

  const formik = useFormik({
    initialValues,
    leistungSchema,
    onSubmit: async (values) => {
      console.log(`${token}`);
      try {
        let response;

        // Construct the base URL dynamically based on the user role
        let apiUrl = BASE_URL;
        if (userRole === "FAHRER") {
          apiUrl += `users/${currentUser}/leistungs`;
        } else {
          apiUrl += `leistungs`;
        }
        apiUrl += `?access_token=${token}`;

        if (values.id) {
          // For updating existing record
          let fullUrl =
            userRole === "FAHRER"
              ? `${apiUrl}/${values.id}`
              : `${apiUrl}/${values.id}`; // Adjust if needed for FAHRER
          response = await axios.put(fullUrl, values);
          toastSuccessNotify("Erfolgreich aktualisiert");
        } else {
          // For creating new record
          const isUnique = !leistungData.some(
            (leist) => leist.id === values.id
          );

          if (isUnique) {
            response = await axios.post(apiUrl, values);
            toastSuccessNotify("Erfolgreich gesendet");
          } else {
            console.error("Duplicate Data");
            return;
          }
        }
        await refetch();
        console.log("Leistung eklendi:", values);
        formik.resetForm();
      } catch (error) {
        console.error("Error in addLeistung:", error);
      }
    },
    validate: (values) => {
      const errors = {};

      const startTime = values.startzeit.split(":");
      const endTime = values.endzeit.split(":");
      const startDate = new Date(
        0,
        0,
        0,
        parseInt(startTime[0], 10),
        parseInt(startTime[1], 10)
      );
      const endDate = new Date(
        0,
        0,
        0,
        parseInt(endTime[0], 10),
        parseInt(endTime[1], 10)
      );

      if (endDate <= startDate) {
        errors.endzeit = "Endzeit muss nach Startzeit sein.";
      }
      if (values.endkm < values.startkm) {
        errors.endkm = "End-Km muss größer oder gleich Start-Km sein.";
      }
      if (!values.endzeit) {
        errors.endzeit = "Endzeit ist erforderlich";
      }

      return errors;
    },
  });

  const handleChange = (e) => {
    // console.log("handleChange", e.target);
    const { name, value } = e.target;
    formik.handleChange(e);
    if (name === "_tour") {
      const selectedTour = toursData.find((tour) => tour.tourname === value);
      formik.setFieldValue("_tour", selectedTour || {});
    } else if (name === "fahrzeugId") {
      formik.setFieldValue("fahrzeugId", Number(value));
    }
  };

  const checkExistingRegularfahrt = () => {
    // Kullanıcının daha önce "Regularfahrt" seçip seçmediğini kontrol et
    const existingRegularfahrt =
      Array.isArray(leistungData) &&
      leistungData.find(
        (entry) =>
          entry.sonderfahrt === "Regularfährt" &&
          new Date(entry.dbdatum).toDateString() ===
            new Date(formik.values.dbdatum).toDateString()
      );

    // Eğer daha önce "Regularfahrt" seçilmişse, seçeneği devre dışı bırak
    const regularfahrtRadio = document.getElementById("flexRadioDefault1");

    if (existingRegularfahrt && regularfahrtRadio) {
      regularfahrtRadio.disabled = true;
      // toastWarnNotify("Regular");
    } else if (regularfahrtRadio) {
      regularfahrtRadio.disabled = false;
    }
  };

  useEffect(() => {
    const fahrzeugId = formik.values.fahrzeugId; // Get fahrzeugId from formik's current values
    if (fahrzeugId && fahrzeugesData?.length > 0) {
      const selectedVehicle = fahrzeugesData.find(
        (vehicle) => vehicle.id === fahrzeugId
      );
      const relatedLeistungen = leistungData.filter(
        (leistung) => leistung.fahrzeugId === fahrzeugId
      );

      if (relatedLeistungen.length > 0) {
        const lastLeistung = relatedLeistungen.reduce((prev, current) =>
          new Date(prev.dbdatum) > new Date(current.dbdatum) ? prev : current
        );
        formik.setFieldValue("startkm", lastLeistung.endkm);
      } else if (selectedVehicle) {
        formik.setFieldValue("startkm", selectedVehicle.startkm || 0); // Default to 0 or a starting km on the vehicle
      }
    }
  }, [formik.values.fahrzeugId, fahrzeugesData, leistungData]);


  const handleBlur = (e) => {
    formik.handleBlur(e);

    const { name, value } = e.target;
    const enteredValue = parseInt(value, 10);

    if (name === "endkm" && toursData && toursData.length > 0) {
      const lastTour = toursData[toursData.length - 1];
      const maxEndKm = formik.values.startkm + 50 + Number(lastTour.fahrtkm);

      if (enteredValue < formik.values.startkm) {
        toastWarnNotify("End-Km muss größer oder gleich Start-Km sein.");
        formik.setFieldValue("endkm", formik.values.startkm);
      } else if (enteredValue > maxEndKm) {
        toastWarnNotify(`End-Km darf höchstens ${maxEndKm} sein.`);
        formik.setFieldValue("endkm", maxEndKm);
      }
    }
  };

  const onDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}leistungs/${id}?access_token=${token}`);
      toastSuccessNotify("Erfolgreich gelöscht!");
      await refetch();
    } catch (error) {
      console.error("Error Delete", error);
    }
  };

  const onEdit = (leistung) => {
    // console.log("Edit", leistung);
    formik.setValues({ ...leistung });
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>ERROR{error.message}</p>;
  }

  return (
    <div className="container-fluid ">
      <LeistungTable
        leistungData={leistungData}
        fahrerData={fahrerData}
        fahrzeugesData={fahrzeugesData}
        toursData={toursData}
        onDelete={onDelete}
        onEdit={onEdit}
      />
      <LeistungForm
        formik={formik}
        handleChange={handleChange}
        handleBlur={handleBlur}
        fahrerData={fahrerData}
        toursData={toursData}
        fahrzeugesData={fahrzeugesData}
        kundenData={kundenData}
        userRole={userRole}
      />
    </div>
  );
};

export default Leistung;
