import { Field } from "formik";
import React from "react";

export const DateInput = ({ name, label, ...props }) => {
  return (
    <div className="col-12 col-md-6 col-lg-3">
      <label className="fw-semibold">{label}</label>
      <Field
        as="input"
        type="date"
        className="form-control"
        name={name}
        {...props}
      />
    </div>
  );
};

export const TimeInput = ({ name, label, ...props }) => {
  return (
    <div>
      <label className="fw-semibold">{label}</label>
      <Field
        as="input"
        type="time"
        className="form-control"
        name={name}
        {...props}
      />
    </div>
  );
};

export const NumberInput = ({ name, label, ...props }) => {
  return (
    <div >
      <label className="fw-semibold">{label}</label>
      <Field
        as="input"
        type="number"
        className="form-control"
        name={name}
        {...props}
      />
    </div>
  );
};

export const TextAreaInput = ({ name, label, ...props }) => {
  return (
    <div className="col">
      <label className="fw-semibold">{label}</label>
      <Field as="textarea" className="form-control" name={name} {...props} />
    </div>
  );
};

export const CheckboxInput = ({ name, label, options, ...props }) => {
  return (
    <div className="col">
      <label className="fw-semibold">{label}</label>
      <div className="row">
        {options?.map((option, index) => (
          <div key={index} className="col-6 col-md-3 d-flex gap-1 ">
            <Field
              type="checkbox"
              className="form-check-input"
              name={name}
              value={option}
              {...props}
            />
            {option}
          </div>
        ))}
      </div>
    </div>
  );
};

export const RadioInput = ({ name, label, options, ...props }) => {
  return (
    <div className="col">
      <label className="fw-semibold">{label}</label>
      <div className="d-flex flex-wrap gap-3 gap-md-5">
        {options?.map((option, index) => (
          <div key={index} className="d-flex gap-1 ">
            <Field
              type="radio"
              className="form-check-input"
              name={name}
              value={option}
              {...props}
            />
            {option}
          </div>
        ))}
      </div>
    </div>
  );
};
