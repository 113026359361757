import React from "react";
import {
  formikValidation,
  initialValues,
  reinigungsOpt,
  zahlungsOpt,
} from "./formElements";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const CarwashForm = ({
  onSubmit,
  editData,
  fahrerOptions,
  fahrzeugOptions,
  setEditData,
}) => {
  const validate = formikValidation(Yup);

  return (
    <div className="card">
      <div className="card-header bg-primary text-white">Carwash Form</div>
      <div className="card-body">
        <Formik
          enableReinitialize={true}
          initialValues={editData || initialValues}
          validationSchema={validate}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values, resetForm);
          }}
        >
          {({ resetForm, values, setFieldValue }) => (
            <Form className="d-flex flex-column gap-3">
              {/* First Row  */}
              <div className="row">
                <div className="col-12 col-md-4">
                  <label htmlFor="pflegedatum" className="fw-semibold">
                    Datum
                  </label>
                  <Field
                    id="pflegedatum"
                    type="date"
                    name="pflegedatum"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="pflegedatum"
                    component="div"
                    className="text-danger"
                  />
                </div>

                <div className="col-12 col-md-4">
                  <label htmlFor="fahrer" className="fw-semibold">
                    Fahrer
                  </label>
                  <Field
                    id="fahrer"
                    as="select"
                    name="fahrer"
                    className="form-select"
                    value={values.fahrer || ""}
                  >
                    <option value=""></option>
                    {fahrerOptions.map((option) => (
                      <option key={option.value} value={parseInt(option.value)}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fahrer"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label htmlFor="fahrzeugId" className="fw-semibold">
                    Kennzeichen
                  </label>
                  <Field
                    id="fahrzeugId"
                    as="select"
                    name="fahrzeugId"
                    className="form-select"
                    value={values.fahrzeugId || ""}
                  >
                    <option value=""></option>
                    {fahrzeugOptions.map((option) => (
                      <option key={option.value} value={parseInt(option.value)}>
                        {option.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="fahrzeugId"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              {/* Second Row  */}
              <div className="row">
                <div className="col-12 col-md-4">
                  <label htmlFor="pflegeart" className="fw-semibold">
                    Reinigungsart
                  </label>
                  {reinigungsOpt.map((option) => (
                    <div key={option.value}>
                      <Field
                        type="checkbox"
                        name="pflegeart"
                        value={option.value}
                        className="form-check-input me-2"
                      />
                      {option.label}
                    </div>
                  ))}
                  <ErrorMessage
                    name="pflegeart"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label htmlFor="pflegezahlungsart" className="fw-semibold">
                    Zahlungsart
                  </label>
                  {zahlungsOpt.map((option) => (
                    <div key={option.value}>
                      <Field
                        type="radio"
                        name="pflegezahlungsart"
                        value={option.value}
                        className="form-check-input me-2"
                      />
                      {option.label}
                    </div>
                  ))}
                  <ErrorMessage
                    name="pflegezahlungsart"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label htmlFor="pflegebetrag" className="fw-semibold">
                    Betrag
                  </label>
                  <Field
                    id="pflegebetrag"
                    type="number"
                    name="pflegebetrag"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="pflegebetrag"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              {/* Third Row  */}

              <div className="row">
                <div className="col-12">
                  <label htmlFor="pflegenotiz" className="fw-semibold">
                    Notiz
                  </label>
                  <Field
                    id="pflegenotiz"
                    as="textarea"
                    name="pflegenotiz"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="pflegenotiz"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              {/* Buttons  */}
              <div className="row">
                <div className="col-12 d-flex gap-2">
                  <button type="submit" className="btn btn-primary">
                    {editData ? "Aktualisieren" : "Hinzufügen"}
                  </button>
                  {editData ? (
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => {
                        setEditData(null);
                        resetForm();
                      }}
                    >
                      Abbrechen
                    </button>
                  ) : null}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CarwashForm;
