import React from "react";
import {
  toastSuccessNotify,
  toastWarnNotify,
} from "../../../helper/ToastNotify";

import "./LeistungsForm.css";

const LeistungForm = ({
  formik,
  handleChange,
  fahrerData = [],
  toursData = [],
  fahrzeugesData = [],
  kundenData = [],
  handleBlur,
  userRole,
}) => {
  // Define a helper function to check if "Regulärfahrt" can be selected
  const canSelectRegularFahrt = () => {
    const isAlreadySelected = formik.values.sonderfahrt === "Regularfährt";
    const isAlreadySubmitted = formik.values.submitted;
    const isSameDayAsSubmission =
      isAlreadySubmitted &&
      formik.values.dbdatum === new Date().toISOString().slice(0, 10);

    return !(isAlreadySelected && isSameDayAsSubmission);
  };

  const saveLocalData = () => {
    // Check if the form has been submitted before saving
    if (formik.values && !formik.values.submitted) {
      localStorage.setItem("savedValues", JSON.stringify(formik.values));
      toastSuccessNotify("Formulardaten lokal gespeichert.");
      formik.resetForm();
    } else {
      // Clear local storage if the form has been submitted
      localStorage.removeItem("savedValues");
      console.log("Local storage data cleared.");
    }
  };

  const loadLocalData = () => {
    const savedData = localStorage.getItem("savedValues");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      formik.setValues(parsedData);
      toastSuccessNotify("Aus dem lokalen Speicher geladene Formulardaten.");
    } else {
      toastWarnNotify("Keine gespeicherten Formulardaten gefunden.");
    }
  };

  return (
    <div className="rounded-2 overflow-hidden custom-width;">
      <div></div>
      <form
        className="border rounded-2 overflow-hidden "
        onSubmit={formik.handleSubmit} 
      >
        <div className="ml-2 fs-5 text-white bg-primary p-3">Leistungseintrag</div>
        <div className="row px-2 py-2">
          <div className="col-sm-12 container-sm">
            <div className="col p-2">
              <div className="row">
                {/* Fahrtype: radiobutton */}
                <div className="col-12 col-md-2 mb-3">
                  <label className="fw-semibold">Fahrtype </label>
                  <div className="form-check">
                    <div></div>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sonderfahrt"
                      id="flexRadioDefault1"
                      value="Regularfährt"
                      checked={formik.values.sonderfahrt === "Regularfährt"}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!canSelectRegularFahrt()}
                      required
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      Regularfährt
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sonderfahrt"
                      id="flexRadioDefault2"
                      value="Sonderfahrt"
                      checked={formik.values.sonderfahrt === "Sonderfahrt"}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      Sonderfahrt
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="sonderfahrt"
                      id="flexRadioDefault3"
                      value="Beifahrer"
                      checked={formik.values.sonderfahrt === "Beifahrer"}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault3"
                    >
                      Beifahrer
                    </label>
                  </div>
                </div>
                {/* Datum */}
                <div className="col-6 col-md-2 mb-3">
                  <label className="fw-semibold" htmlFor="dbdatum">
                    Datum{" "}
                  </label>
                  <input
                    name="dbdatum"
                    type="date"
                    className="form-control"
                    id="dbdatum"
                    value={formik.values.dbdatum.toString().slice(0, 10)}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.dbdatum && formik.errors.dbdatum ? (
                    <div className="error">{formik.errors.dbdatum}</div>
                  ) : null}
                </div>
                {/* Fahrer */}
                <div className="col-6 col-md-2 mb-3">
                  <label className="fw-semibold" htmlFor="fahrer">
                    Fahrer{" "}
                  </label>
                  
                  <select
                    name="fahrer"
                    id="fahrer"
                    className="form-select form-control"
                    value={formik.values.fahrer}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    readOnly={userRole === "FAHRER"}
                    disabled={userRole === "FAHRER"}
                    required
                  >
                    <option value=""  defaultValue>
                    {fahrerData.name} {fahrerData.nachname}
                    </option>
                    {Array.isArray(fahrerData) && fahrerData.length > 0 ? (
                      fahrerData.map((fahrer) => (
                        <option key={fahrer.id} value={fahrer.id}>
                          {fahrer.realm}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                </div>
                {/* Tour */}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="_tour">
                    Tour{" "}
                  </label>
                  {/* select */}
                  <select
                    name="_tour"
                    id="_tour"
                    className="form-select form-control"
                    value={formik.values._tour.tourname}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    required
                  >
                    <option value="" defaultValue>
                      Tour auswählen
                    </option>
                    {toursData ? (
                      toursData.map((tour) => (
                        <option key={tour.tourname} value={tour.tourname}>
                          {tour.tourname}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                </div>
                {/* Fahrzeug */}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="fahrzeugId">
                    Fahrzeug
                  </label>
                  {/* select */}
                  <select
                    name="fahrzeugId"
                    id="fahrzeugId"
                    className="form-select form-control"
                    value={formik.values.fahrzeugId}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    required
                  >
                    <option value="" defaultValue>
                      Fahrzeug auswählen
                      
                    </option>
                    {fahrzeugesData ? (
                      fahrzeugesData.map((fahrzeug) => (
                        <option key={fahrzeug.id} value={fahrzeug.id}>
                          {fahrzeug.kfzkennzeichen}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="row">
                {/* Start-Km Number */}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="startkm">
                    Start-Km {formik.values.startkm > 0 ? <span className="text-danger fw-semibold">{formik.values.startkm} empfohlen</span> : null}
                  </label>
                  <input
                    name="startkm"
                    type="number"
                    min={0}
                    className="form-control"
                    id="startkm"
                    value={formik.values.startkm}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.dbdatum && formik.errors.dbdatum ? (
                    <div className="error">{formik.errors.dbdatum}</div>
                  ) : null}
                </div>
                {/* End-Km Number */}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="endkm">
                    End-Km{" "}
                  </label>
                  <input
                    name="endkm"
                    type="number"
                    min={0}
                    className="form-control"
                    id="endkm"
                    value={formik.values.endkm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  {formik.touched.endkm && formik.errors.endkm ? (
                    <div className="error text-danger">
                      {formik.errors.endkm}
                    </div>
                  ) : null}
                </div>
                {/* Start Zeit : Datetime - Hour */}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="startzeit">
                    Start-Zeit{" "}
                  </label>
                  <input
                    name="startzeit"
                    type="time"
                    className="form-control"
                    id="startzeit"
                    value={formik.values.startzeit}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.startzeit && formik.errors.startzeit ? (
                    <div className="error text-danger">
                      {formik.errors.startzeit}
                    </div>
                  ) : null}
                </div>
                {/* Ende Zeit : Datetime - Hour*/}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="endzeit">
                    End-Zeit{" "}
                  </label>
                  <input
                    name="endzeit"
                    type="time"
                    className={`form-control ${formik.touched.endzeit && formik.errors.endzeit
                      ? "is-invalid"
                      : ""
                      }`}
                    id="endzeit"
                    value={formik.values.endzeit}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.endzeit && formik.errors.endzeit ? (
                    <div className="error text-danger">
                      {formik.errors.endzeit}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                {/* Anzahl Tischgäste : Number */}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="anzahltg">
                    Anzahl Tischgäste{" "}
                  </label>
                  <input
                    name="anzahltg"
                    type="number"
                    min={0}
                    className="form-control"
                    id="anzahltg"
                    value={formik.values.anzahltg}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {/* Kindergarten :Number */}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="kita">
                    Kindergarten{" "}
                  </label>
                  <input
                    name="kita"
                    type="number"
                    min={0}
                    className="form-control"
                    id="kita"
                    value={formik.values.kita}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {/* Tiefkühlmenu : Number */}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="tiefkuehl">
                    Tiefkühlmenu{" "}
                  </label>
                  <input
                    name="tiefkuehl"
                    type="number"
                    min={0}
                    className="form-control"
                    id="tiefkuehl"
                    value={formik.values.tiefkuehl}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {/* Menü-Anzahl : Number*/}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="menues">
                    Menü-Anzahl{" "}
                  </label>
                  <input
                    name="menues"
                    type="number"
                    min={0}
                    className="form-control"
                    id="menues"
                    value={formik.values.menues}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div className="row">
                {/* Menüfix : Number*/}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="menuefix">
                    Menüfix{" "}
                  </label>
                  <input
                    name="menuefix"
                    type="number"
                    min={0}
                    className="form-control"
                    id="menuefix"
                    value={formik.values.menuefix}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {/* Mikrowelle : Number*/}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="mikrowelle">
                    Mikrowelle{" "}
                  </label>
                  <input
                    name="mikrowelle"
                    type="number"
                    min={0}
                    className="form-control"
                    id="mikrowelle"
                    value={formik.values.mikrowelle}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="tkwuerfel">
                    TK-Würfel{" "}
                  </label>
                  <input
                    name="tkwuerfel"
                    type="number"
                    min={0}
                    className="form-control"
                    id="tkwuerfel"
                    value={formik.values.tkwuerfel}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {/* Kundennummer : Number*/}
                <div className="col-6 col-md-3 mb-3">
                  <label className="fw-semibold" htmlFor="kundennumer">
                    Kundennummer{" "}
                  </label>
                  {/* select */}
                  <select
                    name="kundennumer"
                    id="kundennumer"
                    className="form-select form-control"
                    value={formik.values.kundennumer}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    required
                  >
                    <option value="" defaultValue>
                      Nummer auswählen
                    </option>
                    {kundenData ? (
                      kundenData.map((kunden) => (
                        <option key={kunden.id} value={kunden.kundennummer}>
                          {kunden.kundennummer}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                </div>
              </div>

              {/* Notiz input:textarea */}
              <div className="form-group">
                <label className="fw-semibold" htmlFor="notiz">
                  Notiz{" "}
                </label>
                <textarea
                  name="notiz"
                  className="form-control"
                  id="notiz"
                  value={formik.values.notiz}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 ms-2 mt-2 center-buttons ">
            <button type="submit" className="btn btn-primary">
              Abschließen
            </button>

            <button
              type="button"
              onClick={saveLocalData}
              className="btn btn-info"
            >
              ZwischernSpeichern
            </button>
            <button
              type="button"
              onClick={loadLocalData}
              className="btn btn-warning"
            >
              Laden
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default LeistungForm;
