import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",

  initialState: {
    currentUser: null,
    loading: false, // const [loading,setLoading] = useState(false)
    error: false,
    userRole: null,
    ttl: null,
    created: null,
    token: null,
    user: null,
    email: null,
    isAuthenticated: false,
  },

  reducers: {
    fetchStart: (state) => {
      state.loading = true; // setLoading(true)
      state.error = false;
    },
    loginSuccess: (state, { payload }) => {
      state.loading = false;
      state.currentUser = payload?.userId;
      state.userRole = payload.user.role;
      state.token = payload?.id;
      state.ttl = payload?.ttl;
      state.created = payload?.created;
      state.user = payload?.user;
      state.email = payload?.user?.email;
      state.isAuthenticated = true;
    },
    // prop drilling
    logoutSuccess: (state, { payload }) => {
      state.loading = false;
      state.currentUser = null;
      state.ttl = null;
      state.created = null;
      state.token = null;
      state.user = null;
      state.userRole = null;
      state.email = null;
      state.isAuthenticated = false;
    },
    registerSuccess: (state, { payload }) => {
      state.loading = false;
      state.currentUser = payload?.id;
      state.email = payload?.email;
      // state.ttl = payload?.ttl;
      // state.created = payload?.created;
      // state.token = payload?.id;
      state.error = false;
    },
    fetchFail: (state) => {
      state.loading = false;
      state.error = true;
      state.token = null;
      state.userRole = null;
      state.email = null;
    },
  },
});

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated; // Selectör tanımı

export const {
  fetchStart,
  loginSuccess,
  logoutSuccess,
  registerSuccess,
  fetchFail,
} = authSlice.actions;
export default authSlice.reducer;

// async-thunk yerine manuel dispatclerle yapıyoruz. extra reducerlarla yapmadan da bu şekilde yapabiliyoruz. İki yönteminde avantajı ve dezavantajı var.
