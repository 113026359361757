/*import React from "react";
const TelefonTable = () => {
    return (
        <div>
            Table
        </div>
    )
}
export default TelefonTable;*/

import React from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinFill } from "react-icons/ri";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { BootyCheckbox, BootyPagination } from "../../../components";

const TelefonTable = ({ onDelete, onEdit, telefonData = [], toursData= [] }) => {
  const actionBtn = [
    { name: "Copy", className: "btn btn-info" },
    { name: "Excel", className: "btn btn-success" },
    { name: "PDF", className: "btn btn-danger" },
    { name: "YAZICI", className: "btn btn-outline-secondary" },
    { name: "Titel", className: "btn btn-outline-secondary" },
    { name: "SearchPanes", className: "btn btn-outline-secondary" },
  ];

  const columns = [
    {
      name: "Telefonnr",
      selector: (row) => row.telefonnummer,
      sortable: true,
      width: "200px",
    },
    {
      name: "Anbieter",
      selector: (row) => row.telefonanbieter,
      sortable: true,
      width: "200px",
    },
    {
      name: "Tour",
      selector: (row) => {
        const matchingTour = toursData.find((tour) => tour.id === row.tourId);
        return matchingTour ? matchingTour.tourname : row.tour;
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "Tel-Email",
      selector: (row) => row.telefonemail,
      sortable: true,
      width: "200px",
    },
    {
      name: "Vertragsbeginn",
      selector: (row) => row.vertragsbeginn,
      sortable: true,
      width: "200px",
    },
    
    {
      name:"EDIT    DELETE",
      selector: (row) => "action",
      sortable: false,
      right: "true",
      cell: (row) => (
        <div>
          <button
            onClick={() => onEdit(row)}
            className="btn btn-link text-primary"
          >
            <FiEdit />
          </button>
          <button
            onClick={() => onDelete(row.id)}
            className="btn btn-link text-danger"
          >
            <RiDeleteBinFill />
          </button>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: telefonData,
  };

  return (
    <div className="card mb-4">
      <div className="card-header ml-2 fs-5 text-white bg-primary p-3">
        Telefon Übersicht
      </div>
      <div className="card-body">
        <div className="d-flex flex-wrap gap-2 mb-2">
          {actionBtn.map((action, i) => (
            <button key={i} type="button" className={action.className}>
              {action.name}
            </button>
          ))}
        </div>
        <div
          className="table-responsive overflow-scroll"
          style={{ height: "400px" }}
        >
          <DataTableExtensions {...tableData}>
            <DataTable
              columns={columns}
              data={telefonData}
              noHeader
              defaultSortField="id"
              pagination
              paginationComponent={BootyPagination}
              highlightOnHover={false}
              dense={false}
              selectableRows
              selectableRowsHighlight={true}
              selectableRowsComponent={BootyCheckbox}
              expandableRows
              fixedHeader={true}
              responsive={false}
            ></DataTable>
          </DataTableExtensions>
        </div>
      </div>
    </div>
  );
};

export default TelefonTable;
