import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import DataTableExtensions from 'react-data-table-component-extensions';
import useAxios from "../../hooks/useAxios";


const Tuv = ({ fixedHeader, fixedHeaderScrollHeight }) => {
  const [pending, setPending] = useState(true);
  const [data, setData] = useState([])
  const { axiosWithToken } = useAxios();


  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchCars();
      setPending(false);
    }, 1000);
    return () => clearTimeout(timeout);

  }, []);


  const fetchCars = async () => {
    const carsData = await axiosWithToken.get("/fahrzeuges");
    const carsdt = carsData.data;
    console.log(carsdt)
    const kmData = await axiosWithToken.get("/leistungs");
    const datakm = kmData.data;
    console.log(datakm)
    const mergedData = carsdt?.map(dataItem => {

      const matchingKfzData = datakm?.find(kfzItem =>  dataItem.id === kfzItem.fahrzeugId);

      if (matchingKfzData) {
        const mergedItem = {
          id:dataItem.id,
          kfzkennzeichen: dataItem.kfzkennzeichen,
          kfztuvdatum: addYearsToDate(dataItem.kfztuvdatum, 2),
          kfzuvvdatum: addYearsToDate(dataItem.kfzuvvdatum, 1),
          endkm: matchingKfzData.endkm,
        };
        return mergedItem;
      }
    })

    const filteredMergedData = mergedData.filter(item => item !== undefined);
    setData(filteredMergedData);
    console.log("Merged Data:", filteredMergedData);
  };


  function addYearsToDate(dateString, yearsToAdd) {
    const originalDate = new Date(dateString);
    const newDate = new Date(originalDate);

    // Önce yılı ekleyelim
    newDate.setFullYear(originalDate.getFullYear() + yearsToAdd);

    // Eğer başlangıç tarihi 29 Şubat ise ve 2 yıl ekledikten sonra 29 Şubat olmayacaksa,
    // sonraki günü kullanalım (1 gün ekleyerek)
    if (originalDate.getDate() === 29 && originalDate.getMonth() === 1 && newDate.getDate() !== 29) {
      newDate.setDate(1); // 29 Şubat'dan bir sonraki gün
    }

    // Tarihi 'YYYY-MM-DD' formatına çevirme
    const year = newDate.getFullYear();
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }



  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Kennzeichen",
      selector: (row) => row.kfzkennzeichen,
      sortable: true,
    },
    {
      name: "TUV Finish",
      selector: (row) => row.kfztuvdatum,
      sortable: true,
      conditionalCellStyles: [
        {
          when: row => {
            const originalDate = new Date();
            const year = originalDate.getFullYear();
            const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
            const day = originalDate.getDate().toString().padStart(2, '0');
            const formattedDate = new Date(`${year}-${month}-${day}`);
            const tuvBitisDate = new Date(addYearsToDate(row.kfztuvdatum, 0));

            // İki tarih arasındaki farkı milisaniye cinsinden al
            const dateDifference = tuvBitisDate.getTime() - formattedDate.getTime();
            // Milisaniye cinsinden farkı gün cinsine çevir
            const dayDifference2 = Math.floor(dateDifference / (1000 * 60 * 60 * 24));
            return dayDifference2 < 30;
          },
          style: {
            backgroundColor: 'red',
            color: 'white',
          },
        }
      ]
    },
    {
      name: "UVV Finish",
      selector: (row) => row.kfzuvvdatum,
      sortable: true,
      conditionalCellStyles: [
        {
          when: row => {
            const originalDate = new Date();
            const year = originalDate.getFullYear();
            const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
            const day = originalDate.getDate().toString().padStart(2, '0');
            const formattedDate = new Date(`${year}-${month}-${day}`);
            const uuvBitisDate = new Date(addYearsToDate(row.kfzuvvdatum, 0));
            // İki tarih arasındaki farkı milisaniye cinsinden al
            const dateDifference = uuvBitisDate.getTime() - formattedDate.getTime();

            // Milisaniye cinsinden farkı gün cinsine çevir
            const dayDifference = Math.floor(dateDifference / (1000 * 60 * 60 * 24));
            return dayDifference < 30;
          },
          style: {
            backgroundColor: 'red',
            color: 'white',
          },
        },

      ]
    },
    {
      name: "Aktuel KM",
      selector: (row) => row.endkm,
      sortable: true,
    },
  ];

  const tableData = {
    columns,
    data: data,
  };
  const customSort = (rows, selector, kfztuvdatum) => {
    return rows.sort((rowA, rowB) => {
     // use the selector function to resolve your field names by passing the sort comparitors
     const dateA = new Date(selector(rowA));
    const dateB = new Date(selector(rowB));

    let comparison = 0;

    if (dateA > dateB) {
      comparison = 1;
    } else if (dateA < dateB) {
      comparison = -1;
    }
     return kfztuvdatum ===  'desc' ? comparison * -1 : comparison;
    });
   };
   const sortedRows = customSort(data, (row) => row.kfztuvdatum, 'asc');
console.log(sortedRows);

  return (
    <div className="card my-4">
      <div className="card-header bg-primary text-white">TÜV-UVV Übersicht</div>
      <div className="card-body">
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={tableData.columns}
            data={tableData.data}
            noHeader
            sortFunction={sortedRows}
            pagination
            responsive={true}
            highlightOnHover
            dense
            progressPending={pending}
            selectableRowsHighlight={true}
            fixedHeader={fixedHeader}
            fixedHeaderScrollHeight={fixedHeaderScrollHeight}
          />
        </DataTableExtensions>
      </div>
    </div>
  )
}

export default Tuv


