import React, { useState } from "react";
import { Field, Form, useFormikContext } from "formik";
import Select from "react-select";

import {
  NumberInput,
  DateInput,
  TimeInput,
  RadioInput,
  TextAreaInput,
} from "./WerkstattInputModels";
import { useDispatch, useSelector } from "react-redux";
import {
  setLastTour,
  setPartnerInfo,
  setShowInfo,
} from "../../../app/services/werkstatt/werkstattSlice";
import { FaCircleInfo } from "react-icons/fa6";
import PartnerInfoModal from "./PartnerInfoModal";

const WerkstattForm = ({
  statusOptions,
  serviceartOptions,
  fahrerOptions,
  partnerOptions,
  fahrzeugOptions,
  fahrzeugsData,
  leistungsData,
  partnerData,
  editData,
  submitMsg,
  setEditData,
}) => {
  const {
    setFieldValue,
    handleChange,
    errors,
    touched,
    values,
    resetForm,
  } = useFormikContext();

  const dispatch = useDispatch();
  const [showIcon, setShowIcon] = useState(null);
  const handlePartnerInfo = (selectedOption) => {
    const selectedPartner = selectedOption.value;
    const Info = partnerData.filter(
      (partner) => partner.partnername === selectedPartner
    );

    if (selectedPartner) {
      setShowIcon(true);
      dispatch(setPartnerInfo(Info));
    } else {
      setShowIcon(null);
      dispatch(setPartnerInfo(null));
    }
  };

  const handleFahrzeugChange = async (selectedOption) => {
    const selectedFahrzeug = selectedOption.value;

    // Secilen plaka üzerinden, fahrzeug id'sini bul
    const matchingID = fahrzeugsData
      .filter((f) => f.kfzkennzeichen === selectedFahrzeug)
      .map((f) => f.id);

    // Secilen fahrzeug id'si ile son leistungs tarihini bul
    const lastTime = leistungsData
      .filter((l) => l.fahrzeugId === matchingID[0])
      .map((l) => l.endzeit);

    // leistungs tarihlerinden en büyüğünü bul
    const bigLastTime = lastTime.reduce(
      (latest, current) => {
        if (latest.endzeit < current) {
          return {
            endzeit: current,
            id: leistungsData.find((l) => l.endzeit === current).id,
          };
        }
        return latest;
      },
      { endzeit: "00:00", id: "" }
    );

    // Bitis saatine 30 dk ekle
    const options = { hour12: false, hour: "2-digit", minute: "2-digit" };
    const newEndTime = new Date("1970-01-01T" + bigLastTime.endzeit);
    newEndTime.setMinutes(newEndTime.getMinutes() + 30);
    const formattedTime = newEndTime.toLocaleTimeString("en-US", options);

    // Son yapilan tour u bul
    const lastTour = leistungsData
      .filter((l) => l.id === bigLastTime.id)
      .map((l) => l._tour.tourname);

    // Son yapilan km bul

    const lastKm = leistungsData
      .filter((l) => l.id === bigLastTime.id)
      .map((l) => l.endkm);

    dispatch(setLastTour(lastTour[0]));

    await setFieldValue(
      "werkuhrzeit",
      formattedTime ? formattedTime : null,
      true
    );
    await setFieldValue("werkkilometer", lastKm[0] ? lastKm[0] : null, true);
  };

  const serviceOptions = [
    "Auswuchten",
    "Bremsbelege",
    "Dieselfilter",
    "Heizung",
    "Ersatzteil",
    "Karosserie",
    "Klimaanlage",
    "Luftfilter",
    "Ölfilter",
    "Ölwechsel",
    "Reifen/Felgenservice",
    "Scheibe",
  ];

  const { showInfo } = useSelector((state) => state.werkstattSlice);

  const handleInfoClick = () => {
    dispatch(setShowInfo(true));
  };

  return (
    <div className="card">
      <div className="card-header bg-primary text-white">Werkstatt Form</div>
      {submitMsg && (
        <div className="card-header bg-success text-white">{submitMsg}</div>
      )}

      <div className="card-body">
        <Form className="d-flex flex-column gap-2">
          <div className="row">
            <RadioInput name="status" label="Status" options={statusOptions} />
            {touched.status && errors.status && (
              <div
                className="error text-danger fw-semibold"
                style={{ fontSize: "0.8rem" }}
              >
                {errors.status}
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <label className="fw-semibold">Serviceart</label>
              <Field name="serviceart">
                {({ field }) => (
                  <Select
                    {...field}
                    options={serviceartOptions.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    value={values.serviceart}
                    isMulti
                    onChange={(selectedOptions) => {
                      setFieldValue(
                        field.name,
                        selectedOptions.map((option) => ({
                          value: option.value,
                          label: option.label,
                        }))
                      );
                    }}
                    onBlur={field.onBlur}
                  />
                )}
              </Field>

              {touched.serviceart && errors.serviceart && (
                <div
                  className="error text-danger fw-semibold"
                  style={{ fontSize: "0.8rem" }}
                >
                  {errors.serviceart}
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <label className="fw-semibold">Fahrer</label>
              <Field name="fahrer">
                {({ field }) => (
                  <Select
                    {...field}
                    options={fahrerOptions.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    value={values.fahrer}
                    onChange={(option) => {
                      setFieldValue(field.name, option);
                    }}
                    onBlur={field.onBlur}
                  />
                )}
              </Field>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <label className="d-flex gap-1 fw-semibold">
                Partner
                {showIcon && (
                  <span>
                    <FaCircleInfo onClick={handleInfoClick} className="mb-1" />
                  </span>
                )}
              </label>
              {showInfo && <PartnerInfoModal />}

              <Field name="partnerId">
            {({ field }) => (
              <Select
                {...field}
                options={partnerOptions}
                value={values.partnerId} // `Formik`'in sakladığı nesne doğrudan kullanılabilir
                onChange={(option) => {
                  setFieldValue(field.name, option); // Değeri nesne olarak ayarlayın
                  handlePartnerInfo(option);
                }}
                // onBlur={() => setFieldTouched(field.name, true)} // Alanı dokunulmuş olarak işaretle
              />
            )}
          </Field>
              {touched.partnerId && errors.partnerId && (
                <div
                  className="error text-danger fw-semibold"
                  style={{ fontSize: "0.8rem" }}
                >
                  {errors.partnerId}
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <NumberInput name="werkkosten" label="Kosten" />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <label className="fw-semibold">Fahrzeug</label>

              <Field name="fahrzeugId">
                {({ field }) => (
                  <Select
                    {...field}
                    options={fahrzeugOptions?.map((option) => ({
                      value: option,
                      label: option,
                    }))}
                    value={values.fahrzeugId}
                    onChange={(option) => {
                      setFieldValue(field.name, option);
                      handleFahrzeugChange(option);
                    }}
                    onBlur={field.onBlur}
                  />
                )}
              </Field>
              {touched.fahrzeugId && errors.fahrzeugId && (
                <div
                  className="error text-danger fw-semibold"
                  style={{ fontSize: "0.8rem" }}
                >
                  {errors.fahrzeugId}
                </div>
              )}
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <NumberInput
                name="werkkilometer"
                label="Kilometer"
                onChange={(e) => handleChange(e)}
              />
              {touched.werkkilometer && errors.werkkilometer && (
                <div
                  className="error text-danger fw-semibold"
                  style={{ fontSize: "0.8rem" }}
                >
                  {errors.werkkilometer}
                </div>
              )}
            </div>

            <DateInput name="werkdatum" label="Termin" />
            <div className="col-12 col-md-6 col-lg-3">
              <TimeInput
                name="werkuhrzeit"
                label="Uhrzeit"
                onChange={(e) => handleChange(e)}
              />
              {touched.werkuhrzeit && errors.werkuhrzeit && (
                <div
                  className="error text-danger fw-semibold"
                  style={{ fontSize: "0.8rem" }}
                >
                  {errors.werkuhrzeit}
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <label className="fw-semibold">Abholdatum</label>
              <input
                type="date"
                name="abholdatum"
                value={values.abholdatum}
                onChange={(e) => handleChange(e)}
                className="form-control"
              />
            </div>

            <div className="col-12 col-md-6">
              <TimeInput
                name="abholzeit"
                label="Abholzeit"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>

          <div className="row">
            <TextAreaInput name="werknotiz" label="Notiz" />
            {touched.werknotiz && errors.werknotiz && (
              <div
                className="error text-danger fw-semibold"
                style={{ fontSize: "0.8rem" }}
              >
                {errors.werknotiz}
              </div>
            )}
          </div>

          <div className="row">
            <div className="col">
              <label className="fw-semibold">Service</label>
              <div className="row">
                {serviceOptions.map((option, i) => (
                  <div key={i} className="col-6 col-sm-3 d-flex gap-1">
                    <Field
                      type="checkbox"
                      name="service"
                      value={option}
                      id={option}
                    />
                    <label htmlFor={option}>{option}</label>
                  </div>
                ))}
              </div>
            </div>

            {touched.service && errors.service && (
              <div
                className="error text-danger fw-semibold"
                style={{ fontSize: "0.8rem" }}
              >
                {errors.service}
              </div>
            )}
          </div>

          <div className={`${editData ? "d-flex gap-3" : ""}`}>
            <button
              className={`btn ${editData ? "btn-success" : "btn-primary"}`}
              type="submit"
            >
              {editData ? "Bearbeiten" : "Eingeben"}
            </button>
            {editData && (
              <button
                className={`btn ${editData ? "btn-secondary" : "btn-primary"}`}
                type="button"
                onClick={() => {
                  setEditData(null);
                  resetForm();
                }}
              >
                Abbrechen
              </button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default WerkstattForm;
