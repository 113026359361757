import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { IoExitOutline } from "react-icons/io5";

import { Link, useNavigate } from "react-router-dom";
import useAuthCall from "../../hooks/useAuthCall";
import axios from "axios";
import {
  setKundeCount,
  setMenuCount,
  setSelectedTagesTour,
} from "../../app/services/tagesTour/tagesTourSlice";

const TagesTourModal = () => {
  const { token, user } = useSelector((state) => state.auth);

  const url = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValue = {
    tour: "",
    fahrzeug: "",
  };

  const [tagesTourData, setTagesTourData] = useState([]);
  const [fahrzeugeData, setFahrzeugeData] = useState([]);
  const [formData, setFormData] = useState(initialValue);
  const [matchedUser, setMatchedUser] = useState([]);
  const [todaySelectedTour, setTodaySelectedTour] = useState([]);
  const [todaySelectedFahrzeug, setTodaySelectedFahrzeug] = useState([]);

  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];

  useEffect(() => {
    const checkPreviousSelection = async () => {
      const { data } = await axios.get(`${url}logs?access_token=${token}`);

      const matchingUser = data.find(
        (d) =>
          d.userId === user.id &&
          new Date(d.timestamp).toISOString().split("T")[0] === formattedDate
      );

      const todaySelectedTour = data.filter(
        (d) =>
          new Date(d.timestamp).toISOString().split("T")[0] === formattedDate
      );

      const todaySelectedFahrzeugID = data
        .filter(
          (d) =>
            new Date(d.timestamp).toISOString().split("T")[0] === formattedDate
        )
        .map((d) => d.fahrzeugId)[0];

      setTodaySelectedTour(todaySelectedTour);
      setTodaySelectedFahrzeug(todaySelectedFahrzeugID);
      setMatchedUser(matchingUser);
      if (matchingUser) {
        navigate(`/`);
      } else {
        fetchTagesTour();
        fetchFahrzeuge();
      }
    };

    checkPreviousSelection();
  }, []);

  const fetchTagesTour = async () => {
    const { data } = await axios.get(`${url}tagestours?access_token=${token}`);
    setTagesTourData(data);
  };

  const fetchFahrzeuge = async () => {
    const { data } = await axios.get(`${url}fahrzeuges?access_token=${token}`);
    setFahrzeugeData(data);
  };

  const selectTourValue = tagesTourData.map((t) => t.t_tour);
  const uniqueTourValue = [...new Set(selectTourValue)];
  const sortingTourValue = uniqueTourValue.sort();

  const todaySelectedTourID = todaySelectedTour.map((t) => t.tourId)[0];
  const todaySelectedTourName = tagesTourData
    .filter((t) => t.id === todaySelectedTourID)
    .map((t) => t.t_tour)[0];

  const todaySelectedFahrzeugName = fahrzeugeData
    .filter((f) => f.id === todaySelectedFahrzeug)
    .map((f) => f.kfzkennzeichen)[0];
  console.log(todaySelectedFahrzeug, "todaySelectedFahrzeug");
  console.log("todaySelectedFahrzeugName", todaySelectedFahrzeugName);

  const selectFahrzeugValue = fahrzeugeData.map((f) => f.kfzkennzeichen);
  const sortingFahrzeugValue = selectFahrzeugValue.sort();

  const renderingTourValue = sortingTourValue.filter(
    (t) => t !== todaySelectedTourName
  );

  const renderingFahrzeugValue = sortingFahrzeugValue.filter(
    (f) => f !== todaySelectedFahrzeugName
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [tourBorder, settourBorder] = useState(false);
  const [fahrzeugBorder, setFahrzeugBorder] = useState(false);

  const handleSave = async (e) => {
    e.preventDefault();

    if (!formData.tour) {
      settourBorder(true);
      return;
    }

    if (!formData.fahrzeug) {
      setFahrzeugBorder(true);
      return;
    }

    const submittedTour = tagesTourData
      .filter((t) => t.t_tour === formData.tour)
      .map((t) => t.id);
    const submittedFahrzeug = fahrzeugeData
      .filter((f) => f.kfzkennzeichen === formData.fahrzeug)
      .map((f) => f.id);

    const apiValues = { ...formData };

    apiValues.tour = submittedTour[0];
    apiValues.fahrzeug = submittedFahrzeug[0];

    try {
      const newTagesTourValue = {
        userId: user.id,
        fahrzeugId: apiValues.fahrzeug,
        tourId: apiValues.tour,
        category: "string",
        key: "string",
        value: "string",
      };

      await axios.post(`${url}logs?access_token=${token}`, newTagesTourValue);
      console.log("newTagesTourValue", newTagesTourValue);
      dispatch(setSelectedTagesTour(newTagesTourValue));
      setFormData(initialValue);
    } catch (error) {
      console.error("error", error);
    }

    navigate(`/tagestouransicht/${user.id}`);
  };

  const { logout } = useAuthCall();
  const handleLogout = () => {
    dispatch(setKundeCount(0));
    dispatch(setMenuCount(0));
    dispatch(setSelectedTagesTour(null));
    logout();
  };
  if (matchedUser) {
    return (
      <div
        className="
      position-absolute top-50 start-50 translate-middle  text-center 
      "
      >
        <div class="spinner-grow" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <Modal show={true} size="sm" centered>
      <Modal.Body>
        <form
          onSubmit={handleSave}
          className="d-flex flex-column text-center gap-4 position-relative py-5  "
          style={{ height: "500px" }}
        >
          <h3 className="text-uppercase"> {user.username}</h3>

          <div className="mt-5 d-flex flex-column gap-4">
            <select
              className={`form-select  ${
                tourBorder ? "border border-danger" : ""
              }`}
              name="tour"
              id="tour"
              onChange={handleChange}
            >
              <option value="">Tour auswählen</option>
              {renderingTourValue &&
                renderingTourValue.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </select>
            <select
              className={`form-select  ${
                fahrzeugBorder ? "border border-danger" : ""
              }`}
              name="fahrzeug"
              id="fahrzeug"
              onChange={handleChange}
            >
              <option value="">Fahrzeug auswählen</option>
              {renderingFahrzeugValue &&
                renderingFahrzeugValue.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
            </select>
          </div>

          <button
            type="submit"
            className="btn btn-primary position-absolute fixed-bottom"
          >
            Speichern
          </button>

          <Link
            onClick={handleLogout}
            className="position-absolute top-0 end-0 cursor-pointer fs-5"
          >
            <IoExitOutline />
          </Link>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default TagesTourModal;
