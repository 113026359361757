import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { useSelector } from "react-redux";
import * as Yup from "yup";

const getInputDateValue=(isoDate)=>{
  const date = new Date(isoDate);
 let year = date.getFullYear();
 let month = date.getMonth()+1;
 let dt = date.getDate();
 
 if (dt < 10) {
   dt = '0' + dt;
 }
 if (month < 10) {
   month = '0' + month;
 }
 return `${year}-${month}-${dt}`
 }


const Formmformik = ({ handleTour,formatDate }) => {
  const [users, setUsers] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const apiUrl = "https://dev.zone-edv.de/api/";
  const { tour } = useSelector((state) => state.tour);
  
 

 

  const defaultFormData = {
    id: tour.id || null,
    region: tour.region || "",
    dbdatum: new Date().toISOString().slice(0, 10),
    tourname: tour.tourname || "",
    fahrtbeginndatum:getInputDateValue(tour.fahrtbeginndatum) || new Date().toISOString().slice(0, 10),
    fahrtbeginnort: tour.fahrtbeginnort || "",
    fahrtendeort: tour.fahrtendeort || "",
    fahrtbeginnuhr: tour.fahrtbeginnuhr || "",
    fahrtendeuhr: tour.fahrtendeuhr || "",
    fahrtdauer: tour.fahrtdauer || "",
    fahrtkm: tour.fahrtkm || "",
    fahrzeugtype: tour.fahrzeugtype || "",
    vkpreis: tour.vkpreis || "",
    tourfixpreis: tour.tourfixpreis || "",
    preistype: tour.preistype || 0,
    telematiklink: tour.telematiklink || "",
    telematikmandant: tour.telematikmandant || "",
    telematikfirma: tour.telematikfirma || "",
    telematikkurzel: tour.telematikkurzel || "",
    telematikfahrzeug: tour.telematikfahrzeug || "",
    tournotiz: tour.tournotiz || "",
    tankkarte: tour.tankkarte || "",
  };

  useEffect(() => {
    fetchRegion();
    fetchTour();
  }, []);

  const fetchRegion = async () => {
    try {
      const { data } = await axios.get(
        `${apiUrl}regions?access_token=${token}`
      );
      // const regionn=data.map(x=>x.regionname)
      console.log(data);
      setUsers(data);
    } catch (error) {
      console.error("Data getting error", error);
    }
  };

  const fetchTour = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}tours?access_token=${token}`);
      // const regionn=data.map(x=>x.regionname)
      console.log(data);
    } catch (error) {
      console.error("Data getting error", error);
    }
  };
 

  const rows = [
    [
      { label: "Region", name: "region", type: "select" },
      { label: "Tourname", name: "tourname", type: "text"},
      { label: "Fahrtbeginn(Datum)", name: "fahrtbeginndatum", type: "date" },
    ],
    [
      {
        label: "Fahrtbeginn (Str. Nr, Ort)",
        name: "fahrtbeginnort",
        type: "text",
      },
      { label: "Fahrtende (Str. Nr, Ort)", name: "fahrtendeort", type: "text" },
      { label: "Fahrtbeginn (Uhrzeit)", name: "fahrtbeginnuhr", type: "time" },
    ],
    [
      { label: "Fahrtende (Uhrzeit)", name: "fahrtendeuhr", type: "time" },
      { label: "Fahrtdauer (in Min.)", name: "fahrtdauer", type: "text" },
      { label: "Fahrtleistung (in km)", name: "fahrtkm", type: "text" },
    ],
    [
      { label: "Fahrzeugtype", name: "fahrzeugtype", type: "text" },
      {
        label: "Tourfixpreis",
        name: "tourfixpreis",
        type: "number",
        placeholder: "0",
        order: 29,
      },
    ],
  ];
  const rows2 = [
    [
      {
        label: "Preistype",
        name: "preistype",
        type: "select",
        options: [
          "Fix",
          "Variable",
          "Fahrer Variable(genaue stunden Abrechnung)",
        ],
        order: 36,
      },
      { label: "Preis/Std", name: "vkpreis", type: "number", placeholder: "0" },
      { label: "Telematiklink", name: "telematiklink", type: "text" },
    ],
    [
      { label: "Mandantenkennung", name: "telematikmandant", type: "text" },
      { label: "Tankarte", name: "tankkarte", type: "text" },
      { label: "Unternehmer", name: "telematikfirma", type: "text" },
    ],
    [
      { label: "Namenskürzel", name: "telematikkurzel", type: "text" },
      { label: "Fahrzeugnummer", name: "telematikfahrzeug", type: "text" },
    ],
  ];

   const validationSchema = Yup.object().shape({
       region:Yup.string().required("Pflichtfeld"),
       tourname: Yup.string().required("Tourname is required"),
     tourfixpreis: Yup.number("This must be a number").required(),
  //   preistype: Yup.number("This must be a number").required("required"),
 
  //   serviceart: Yup.string(),
    vkpreis: Yup.number().required("vkpreis is required"),


     fahrtdauer: Yup.string().required("fahrtdauer is required"),
     fahrtbeginndatum: Yup.date().required("Fahrtbeginndatum is required"),
      fahrtbeginnort: Yup.string().required("Fahrtbeginnort is required"),
      fahrtendeort: Yup.string().required("Fahrtendeort is required"),
      fahrtbeginnuhr: Yup.string().required("fahrtbeginnuhr is required"),
      fahrtendeuhr: Yup.string().required("fahrtendeuhr is required"),
     fahrtkm: Yup.string().required("fahrtkm is required"),
     fahrzeugtype: Yup.string().required("fahrzeugtype is required"),
  });

  return (
    <Formik
      initialValues={defaultFormData}
       validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        console.log("Form submitted:", values);
        // Submit logic goes here
        handleTour(values, actions);
       
        actions.setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          {rows.map((row, index) => (
            <div key={index} className="d-flex flex-wrap gap-3">
              {row.map((item, itemIndex) => (
                <div key={itemIndex} className="col-sm-12 col-md-6 col-lg-3 form-group">
                  <label htmlFor={item.name} className="fw-semibold">{item.label}</label>
                  {item.type === "select" ? (
                    <Field
                      as="select"
                      name={item.name}
                      className="form-select form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[item.name]}
                      
                    >
                      <option value="">Select Region</option>
                      {users.map((user, index) => (
                        <option key={index} value={user.regionname}>
                          {user.regionname}
                        </option>
                      ))}
                    </Field>
                  ) : (
                    <Field
                      type={item.type}
                      name={item.name}
                      className="form-control"
                      placeholder={item.placeholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[item.name]} // Değer için doğru alan adını kullanın
                    />
                  )}
                
               { touched[item.name] && errors[item.name] &&(
   <div style={{color: 'red', fontSize: '13px'}}>{errors[item.name]}</div> 
)}
                </div>
              ))}
            </div>
          ))}

          {/* //rows2 */}
          {rows2.map((row, index) => (
            <div key={index} className="d-flex flex-wrap gap-3">
              {row.map((field) => (
                <div
                  key={field.name}
                  style={{ marginBottom: "10px" }}
                  className="col-sm-12  col-md-6 col-lg-3 form-group"
                >
                  <label htmlFor={field.name} className="fw-semibold">
                    {field.label}
                  </label>
                  {field.type === "select" ? (
                    <Field
                      as="select"
                      name={field.name}
                      className="form-select form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    >
                      <option value="">Select</option>
                      {field.options.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Field>
                  ) : (
                    <Field
                      type={field.type}
                    
                      name={field.name}
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values[field.name]} // Değer için doğru alan adını kullanın
                    />
                  )}
             { touched[field.name] && errors[field.name] &&(
   <div style={{color: 'red', fontSize: '13px'}}>{errors[field.name]}</div> 
)}
                </div>
              ))}
            </div>
          ))}
          {/* Notiz input:textarea */}
          <div className="form-group flex-fill col-6">
            <label htmlFor="tournotiz">Notiz </label>
            <textarea
              name="tournotiz"
              className="form-control"
              id="tournotiz"
              rows="3"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.tournotiz}
            ></textarea>
          </div>
          <div className="row px-md-5 py-3 d-flex justify-content-between align-items-center">
            <div className="form-group col">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ width: "120px" }}
              >
                Speichern
              </button>
            </div>
           
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Formmformik;
