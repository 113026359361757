import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TourPlannenTable from './tourPlannenTable/TourPlannenTable';
import TourPlannenForm from './tourPlannenForm/TourPlannenForm';

export const TourPlanen = () => {
  const [fahrzeugOptions, setFahrzeugOptions] = useState([]);
  const [fahrzeugData, setFahrzeugData] = useState([]);
  const [fahrerOptions, setFahrerOptions] = useState([]);
  const [fahrerData, setFahrerData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tourId, setTourId] = useState([]);
  const [lastTourData, setLastTourData] = useState({});
  const url = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.auth);

  const getTourplanData = async () => {
    const { data } = await axios.get(`${url}/tourenplans?access_token=${token}`);
    setTableData(data);

    const lastData = {};
    data.forEach((item) => {
      if (!lastData[item.tourId] || new Date(item.tourendatum) > new Date(lastData[item.tourId].tourendatum)) {
        lastData[item.tourId] = {
          tourendatum: item.tourendatum,
          fahrer: item.fahrer,
          touruhrzeit: item.touruhrzeit,
          fahrzeugId: item.fahrzeugId,
        };
      }
    });
    setLastTourData(lastData);
  };

  const getTourID = async () => {
    const { data } = await axios.get(`${url}tours?access_token=${token}`);
    const tourIdData = data.map((tour) => ({
      label: tour.tourname,
      value: tour.id,
    }));
    setTourId(tourIdData);
  };

  const getFahrzeuges = async () => {
    const { data } = await axios.get(`${url}fahrzeuges?access_token=${token}`);
    const fahrzeuges = data.map((fahrzeug) => ({
      label: fahrzeug.kfzkennzeichen,
      value: fahrzeug.id,
    }));
    setFahrzeugOptions(fahrzeuges);
    setFahrzeugData(data);
  };

  const getFahrers = async () => {
    const { data } = await axios.get(`${url}users?access_token=${token}`);
    const fahrerUsers = data
      .filter((user) => user.role === 'FAHRER')
      .map((fahrer) => ({
        label: fahrer.username,
        value: fahrer.id,
      }));
    setFahrerOptions(fahrerUsers);
    setFahrerData(data);
  };

  useEffect(() => {
    getTourID();
    getFahrzeuges();
    getFahrers();
    getTourplanData();
  }, [token, url]);

  const onSubmit = async (values, resetForm) => {
    if (editData) {
      values.id = editData.id;
      await axios.put(`${url}tourenplans/${editData.id}?access_token=${token}`, values);
      setEditData(null);
    } else {
      await axios.post(`${url}tourenplans?access_token=${token}`, values);
    }
    resetForm();
    await getTourplanData();
  };

  const onEdit = useCallback(
    (row) => {
      const fahrer = fahrerData.find((fahrer) => fahrer.username === row.fahrer);
      const updatedRow = {
        ...row,
        fahrer: fahrer?.id,
      };
      setEditData(updatedRow);
    },
    [fahrerData]
  );

  const onDelete = async (id) => {
    await axios.delete(`${url}tourenplans/${id}?access_token=${token}`);
    await getTourplanData();
  };

  const modifiedData = tableData.map((data) => ({
    ...data,
    tourendatum: new Date(data.tourendatum).toISOString().split('T')[0],
    fahrer: fahrerData.find((fahrer) => fahrer.id === data.fahrer)?.username,
  }));


  return (
    <div className="d-flex align-content-center justify-content-center">
      <div className="container">
        <TourPlannenTable
          onEdit={onEdit}
          onDelete={onDelete}
          modifiedData={modifiedData}
          fahrerOptions={fahrerOptions}
          tourId={tourId}
          fahrzeugOptions={fahrzeugOptions}
          getTourplanData={getTourplanData}
        />
        <TourPlannenForm
          onSubmit={onSubmit}
          editData={editData}
          setEditData={setEditData}
          fahrerOptions={fahrerOptions}
          fahrzeugOptions={fahrzeugOptions}
          tourId={tourId}
          lastTourData={lastTourData}
        />
      </div>
    </div>
  );
};

export default TourPlanen;
