import Form from 'react-bootstrap/Form';

function StatusColumnCell({ row, onUpdate }) {
  return (
    <Form.Check
      type="switch"
      id="custom-switch"
      checked={row.status}
      onChange={(e) => onUpdate(row.id, e.target.checked, row.regionname)}
    />
  );
}

export default StatusColumnCell;
