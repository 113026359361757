import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";

const BewerberForm = ({
  editData,
  setEditData,
  initialValues,
  onSubmit,
  validate,
  arbeitstageError,
}) => {
  return (
    <div className="card">
      <div className="card-header bg-light-blue text-white">Bewerbungsform</div>
      <div className="card-body">
        <Formik
          enableReinitialize={true}
          initialValues={editData || initialValues}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values, resetForm);
          }}
          validationSchema={validate}
        >
          {({ resetForm, values }) => (
            <Form className="d-flex flex-column gap-2">
              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="bewerbervorname" className="fw-semibold">
                    Vorname
                  </label>
                  <Field
                    type="text"
                    name="bewerbervorname"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bewerbervorname"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="bewerbernachname" className="fw-semibold">
                    Nachname
                  </label>
                  <Field
                    type="text"
                    name="bewerbernachname"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bewerbernachname"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="bewerberemail" className="fw-semibold">
                    Email
                  </label>
                  <Field
                    type="email"
                    name="bewerberemail"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bewerberemail"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <label htmlFor="bewerbertelefon" className="fw-semibold">
                    Telefon
                  </label>
                  <Field
                    type="text"
                    name="bewerbertelefon"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bewerbertelefon"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="bewerberstr" className="fw-semibold">
                    Straße-HausNr
                  </label>
                  <Field
                    type="text"
                    name="bewerberstr"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bewerberstr"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>

                <div className="col-12 col-md-6">
                  <label htmlFor="bewerberplz " className="fw-semibold">
                    PLZ
                  </label>
                  <Field
                    type="text"
                    name="bewerberplz"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bewerberplz"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="bewerberort" className="fw-semibold">
                    Wohnort
                  </label>
                  <Field
                    type="text"
                    name="bewerberort"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bewerberort"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="bewerbergebdatum" className="fw-semibold">
                    Geburtsdatum
                  </label>
                  <Field
                    type="date"
                    name="bewerbergebdatum"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bewerbergebdatum"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 ">
                  <div className="d-flex gap-3">
                    <label htmlFor="bewerbergeschlecht" className="fw-semibold">
                      Geschlecht
                    </label>
                    <div className="d-flex gap-3">
                      <div className="d-flex gap-1">
                        <Field
                          type="radio"
                          name="bewerbergeschlecht"
                          value="männlich"
                          className="form-check-input"
                          id="männlich"
                        />
                        <label htmlFor="männlich">männlich</label>
                      </div>
                      <div className="d-flex gap-1">
                        <Field
                          type="radio"
                          name="bewerbergeschlecht"
                          value="weiblich"
                          className="form-check-input"
                          id="weiblich"
                        />
                        <label htmlFor="weiblich">weiblich</label>
                      </div>
                      <div className="d-flex gap-1">
                        <Field
                          type="radio"
                          name="bewerbergeschlecht"
                          value="diverse"
                          className="form-check-input"
                          id="diverse"
                        />
                        <label htmlFor="diverse">diverse</label>
                      </div>
                    </div>
                  </div>

                  <ErrorMessage
                    name="bewerbergeschlecht"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <label htmlFor="fuhrerscheinklasse" className="fw-semibold">
                    Führerscheinklasse
                  </label>

                  <Field
                    as="select"
                    name="fuhrerscheinklasse"
                    className="form-control"
                  >
                    <option value="">Wählen Sie eine Option aus.</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </Field>

                  <ErrorMessage
                    name="fuhrerscheinklasse"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="eintrittsdatum" className="fw-semibold">
                    Startwunsch
                  </label>
                  <Field
                    type="date"
                    name="eintrittsdatum"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="eintrittsdatum"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div id="checkbox-group" className="fw-semibold">
                    Wochenarbeitstage
                  </div>
                  <div
                    role="group"
                    aria-labelledby="checkbox-group"
                    className="d-flex gap-2"
                  >
                    <label className="d-flex gap-1">
                      <Field
                        type="checkbox"
                        name="bewerberarbeitstage"
                        value="Mo"
                        className="form-check-input"
                      />
                      Mo
                    </label>
                    <label className="d-flex gap-1">
                      <Field
                        type="checkbox"
                        name="bewerberarbeitstage"
                        value="Di"
                        className="form-check-input"
                      />
                      Di
                    </label>
                    <label className="d-flex gap-1">
                      <Field
                        type="checkbox"
                        name="bewerberarbeitstage"
                        value="Mi"
                        className="form-check-input"
                      />
                      Mi
                    </label>
                    <label className="d-flex gap-1">
                      <Field
                        type="checkbox"
                        name="bewerberarbeitstage"
                        value="Do"
                        className="form-check-input"
                      />
                      Do
                    </label>
                    <label className="d-flex gap-1">
                      <Field
                        type="checkbox"
                        name="bewerberarbeitstage"
                        value="Fr"
                        className="form-check-input"
                      />
                      Fr
                    </label>
                    <label className="d-flex gap-1">
                      <Field
                        type="checkbox"
                        name="bewerberarbeitstage"
                        value="Sa"
                        className="form-check-input"
                      />
                      Sa
                    </label>
                    <label className="d-flex gap-1">
                      <Field
                        type="checkbox"
                        name="bewerberarbeitstage"
                        value="So"
                        className="form-check-input"
                      />
                      So
                    </label>
                  </div>
                  {arbeitstageError && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      Pflichtfeld
                    </div>
                  )}

                  <ErrorMessage
                    name="bewerberarbeitstage"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="bewerberarbeitsart" className="fw-semibold">
                    Beschäftigungsart
                  </label>
                  <div className="d-flex gap-3">
                    <div className="d-flex gap-1">
                      <Field
                        type="radio"
                        name="bewerberarbeitsart"
                        value="Aushilfe"
                        className="form-check-input"
                        id="Aushilfe"
                      />
                      <label htmlFor="Aushilfe" className="form-check-label">
                        Aushilfe
                      </label>
                    </div>
                    <div className="d-flex gap-1">
                      <Field
                        type="radio"
                        name="bewerberarbeitsart"
                        value="Teilzeit"
                        className="form-check-input"
                        id="Teilzeit"
                      />
                      <label htmlFor="Teilzeit" className="form-check-label">
                        Teilzeit
                      </label>
                    </div>
                    <div className="d-flex gap-1">
                      <Field
                        type="radio"
                        name="bewerberarbeitsart"
                        value="Vollzeit"
                        className="form-check-input"
                        id="Vollzeit"
                      />
                      <label htmlFor="Vollzeit" className="form-check-label">
                        Vollzeit
                      </label>
                    </div>
                  </div>

                  <ErrorMessage
                    name="bewerberarbeitsart"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 ">
                  <div className="d-flex gap-3">
                    <label className="fw-semibold">Behindert</label>
                    <div className="d-flex gap-1">
                      <Field
                        type="radio"
                        name="isbehindert"
                        value="ja"
                        className="form-check-input"
                        id="ja"
                      />
                      <label htmlFor="ja">Ja</label>
                    </div>
                    <div className="d-flex gap-1">
                      <Field
                        type="radio"
                        name="isbehindert"
                        value="nein"
                        className="form-check-input"
                        id="nein"
                      />
                      <label htmlFor="nein">Nein</label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="isbehindert"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "0.8rem" }}
                  />

                  <Field
                    as="textarea"
                    name="behinderungart"
                    className="form-control"
                    disabled={values.isbehindert !== "ja"}
                    value={
                      values.isbehindert !== "ja" ? "" : values.behinderungart
                    }
                    placeholder="Bitte beschreiben Sie kurz."
                  />
                </div>

                <div className="col-12 col-md-6">
                  <label htmlFor="bewerbernotiz" className="fw-semibold">
                    Notiz
                  </label>
                  <Field
                    as="textarea"
                    name="bewerbernotiz"
                    className="form-control"
                    placeholder="Hier können Sie Ihre Notizen eingeben"
                  />
                  <ErrorMessage name="bewerbernotiz" component="div" />
                </div>
              </div>

              <div className="d-flex gap-3">
                <button
                  type="submit"
                  className="btn btn-sm btn-primary"
                  style={{ width: "100px" }}
                >
                  {editData !== null ? "Aktualisieren" : "Senden"}
                </button>
                {editData ? (
                  <button
                    type="button"
                    onClick={() => {
                      setEditData(null);
                      resetForm();
                    }}
                    className="btn btn-sm btn-secondary"
                    style={{ width: "100px" }}
                  >
                    Abbrechen
                  </button>
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BewerberForm;
