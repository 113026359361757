import React from "react";
import { Modal } from "react-bootstrap";
import { GrNext, GrPrevious } from "react-icons/gr";
import { IoExitOutline, IoNavigateCircle } from "react-icons/io5";
import { Link } from "react-router-dom";

const TerminPlanerIsDataModal = ({
  sortingData,
  currentIndex,
  handlePrevious,
  handleNext,
  handleZurück,
  handleWeiter,
  handleClickFahren,
  handleLogout,
  fahrerName,
  selectedFahrzeug,
  matchingPartnerNames,
  matchingPartnerStr,
  matchingPartnerPLZ,
  matchingPartnerOrt,
  isOnTheWay,
}) => {
  return (
    <div>
      <Modal show={true} size="md" centered>
        <Modal.Body>
          <div
            className="d-flex flex-column text-center gap-4 position-relative "
            style={{ height: "530px" }}
          >
            <div className="mx-5">
              {sortingData?.map(
                (item, index) =>
                  index === currentIndex && (
                    <div
                      key={index}
                      className="container text-start d-flex flex-column gap-2"
                    >
                      <div>
                        <h5 className="text-center">Terminplaner</h5>
                      </div>
                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Fahrer
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          {" "}
                          {fahrerName}
                        </span>
                      </div>
                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Termin
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          {" "}
                          {item.werkdatum.split("T")[0]}
                        </span>
                      </div>
                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Uhrzeit
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          {" "}
                          {item.werkuhrzeit}
                        </span>
                      </div>
                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Kennzeichen
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          {" "}
                          {selectedFahrzeug}
                        </span>
                      </div>
                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Partner
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          {" "}
                          {matchingPartnerNames}
                        </span>
                      </div>

                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Straße - Nr
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          {matchingPartnerStr}
                        </span>
                      </div>
                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          PLZ - Ort
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          {matchingPartnerPLZ} - {matchingPartnerOrt}
                        </span>
                      </div>

                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Service
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          {" "}
                          {item.service}
                        </span>
                      </div>
                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Serviceart
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          {" "}
                          {item.serviceart}
                        </span>
                      </div>

                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Kilometer
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          {" "}
                          {item.werkkilometer} km
                        </span>
                      </div>
                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Notiz
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          {" "}
                          {item.werknotiz}
                        </span>
                      </div>
                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Status
                        </span>
                        <span className="col-1">:</span>{" "}
                        <span className="col-7 text-primary">
                          {" "}
                          {isOnTheWay ? "Unterwegs" : "---"}
                        </span>
                      </div>
                      <div className="row">
                        <span className="col-4 fw-semibold badge bg-dark">
                          Navigiere
                        </span>
                        <span className="col-1">:</span>
                        <span className="col-7 text-primary">
                          <IoNavigateCircle
                            className="fs-3"
                            onClick={() => handleClickFahren()}
                          />
                        </span>
                      </div>
                    </div>
                  )
              )}
              {sortingData.length > 1 && (
                <GrPrevious
                  onClick={handlePrevious}
                  className="  cursor-pointer  text-secondary rounded-circle fs-3 position-absolute top-50 start-0 translate-middle-y "
                />
              )}
              {sortingData.length > 1 && (
                <GrNext
                  onClick={handleNext}
                  className=" cursor-pointer text-secondary rounded-circle fs-3 position-absolute top-50 end-0 translate-middle-y "
                />
              )}
            </div>

            <div className="d-flex justify-content-between align-items-center position-absolute fixed-bottom">
              <button onClick={handleZurück} className="btn btn-secondary">
                Zurück
              </button>

              <button
                type="submit"
                className="btn btn-primary "
                onClick={handleWeiter}
              >
                Weiter
              </button>
            </div>
            <Link
              onClick={handleLogout}
              className="position-absolute top-0 end-0 cursor-pointer fs-5"
            >
              <IoExitOutline />
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TerminPlanerIsDataModal;
