import { useSelector } from 'react-redux';
import { useGetOptionsQuery } from './option/optionApi';
import FormOption from './FormOption';
import TableOption from './TableOption';


const OptionModels = () => {
  const { token } = useSelector((state) => state.auth);
  const { refetch } = useGetOptionsQuery(token);
 
  

  return (
    <div className="container">
      <TableOption refetch={refetch} />
      <FormOption refetch={refetch}/>
    </div>
  );
};
export default OptionModels