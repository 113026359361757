import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinFill } from "react-icons/ri";
import { useSelector } from "react-redux";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Ausgaben = () => {
  const { token } = useSelector((state) => state.auth);
  const [ausgaben, setAusgaben] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [leistung, setLeistung] = useState([]);
  const [fahrzeugIds, setFahrzeugIds] = useState([]);
  const [belegtext, setBelegtext] = useState([]);

  const url = `${BASE_URL}ausgabens?access_token=${token}`;

  const [formData, setFormData] = useState({
    dbdatum: new Date().toISOString().slice(0, 10),
    belegdatum: new Date().toISOString().slice(0, 10),
    belegtext: "",
    verwendungszweck: "",
    belegart: "",
    belegzahlungsart: "",
    ausgabenzeit: "",
    ausgabenkilometer: "",
    ausgabenvolumen: 0,
    belegbetrag: 0,
    ausgabenbrutto: 0,
    mwSt: 0,
    // id: 0,
    fahrzeugId: 0,
    telefoneId: 0,
  });

  const getLeistung = async () => {
    try {
      const res = await axios.get(`${BASE_URL}leistungs?access_token=${token}`);
      console.log(res.data);
      setLeistung(res.data);

      // Extract unique fahrzeugIds from leistung data
      const uniqueFahrzeugIds = Array.from(
        new Set(res.data.map((item) => item.fahrzeugId))
      );
      setFahrzeugIds(uniqueFahrzeugIds);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getBelegtext = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}belegtexts?access_token=${token}`
      );
      console.log("Belegtext: ", res.data);
      setBelegtext(res.data);
    } catch (error) {
      console.error("Error fetching Belegdata:", error);
    }
  };

  const getAusgabe = async (id) => {
    const urll = `${BASE_URL}ausgabens/${id}?access_token=${token}`;

    try {
      const response = await axios.get(urll);
      const ausgabenData = response.data;
      console.log("ausgabenData: ", ausgabenData);
      setFormData({
        dbdatum: ausgabenData.dbdatum,
        belegdatum: ausgabenData.belegdatum,
        belegtext: ausgabenData.belegtext,
        verwendungszweck: ausgabenData.verwendungszweck,
        belegart: ausgabenData.belegart,
        belegzahlungsart: ausgabenData.belegzahlungsart,
        ausgabenzeit: ausgabenData.ausgabenzeit,
        ausgabenkilometer: ausgabenData.ausgabenkilometer,
        ausgabenvolumen: ausgabenData.ausgabenvolumen,
        belegbetrag: ausgabenData.belegbetrag,
        ausgabenbrutto: ausgabenData.ausgabenbrutto,
        mwSt: ausgabenData.mwSt,
        id: ausgabenData.id,
        fahrzeugId: ausgabenData.fahrzeugId,
        telefoneId: ausgabenData.telefoneId,
      });

      console.log("Selected ausgaben data:", ausgabenData);
    } catch (error) {
      console.error("GET request failed:", error.response.data);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setAusgaben(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getLeistung();
    getBelegtext();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Perform calculation when 'belegbetrag' or 'ausgabenbrutto' is changed
    if (name === "belegbetrag" || name === "ausgabenbrutto") {
      const enteredValue = parseFloat(value);

      if (!isNaN(enteredValue) && value !== "") {
        // If the entered value is a valid number
        const mwSt =
          name === "belegbetrag"
            ? enteredValue * 0.19
            : (enteredValue * 0.19) / 1.19;
        const calculatedAusgabenbrutto =
          name === "belegbetrag" ? enteredValue + mwSt : enteredValue;
        const calculatedBelegbetrag =
          name === "ausgabenbrutto" ? enteredValue - mwSt : enteredValue;

        // Update the form data with calculated values
        setFormData((prevFormData) => ({
          ...prevFormData,
          mwSt: isNaN(mwSt) ? 0 : mwSt.toFixed(2),
          belegbetrag: isNaN(calculatedBelegbetrag)
            ? 0
            : calculatedBelegbetrag.toFixed(0),
          ausgabenbrutto: isNaN(calculatedAusgabenbrutto)
            ? 0
            : calculatedAusgabenbrutto.toFixed(0),
        }));
      } else {
        // If the entered value is not a valid number, update only the form data
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
          mwSt: 0,
          belegbetrag: 0,
          ausgabenbrutto: 0,
        }));
      }
    } else {
      // Update the form data for other fields
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    // Additional validation for "Ausgabenart" and "Volumen" fields
    if (name === "belegart") {
      // If the changed field is "belegart" (Ausgabenart)
      if (value === "KFZ-LAufendekosten") {
        // If "KFZ laufende Kosten" is selected, mark the "Volumen" field as required
        setFormData((prevFormData) => ({
          ...prevFormData,
          ausgabenvolumen: "", // Clear the previous value to trigger the required validation
          
        }));
      } else {
        // If another option is selected, reset the required validation for "Volumen" field
        setFormData((prevFormData) => ({
          ...prevFormData,
          ausgabenvolumen: prevFormData.ausgabenvolumen,
          
        }));
      }
    }

    if (name === "ausgabenzeit" || name === "fahrzeugId") {
      // If the changed field is "ausgabenzeit" or "fahrzeugId"
      let selectedFahrzeugId = formData.fahrzeugId;
      let enteredTime = formData.ausgabenzeit;

      if (name === "fahrzeugId") {
        // If the changed field is "fahrzeugId", update the selectedFahrzeugId
        selectedFahrzeugId = parseInt(value, 10);
      } else {
        // If the changed field is "ausgabenzeit", update the enteredTime
        enteredTime = value;
      }

      // Find the corresponding leistungData based on the selectedFahrzeugId
      const leistungData = leistung.find(
        (item) => item.fahrzeugId === selectedFahrzeugId
      );

      if (leistungData) {
        const isBefore12 =
          new Date(`2000-01-01T${enteredTime}`).getHours() < 12;
        const tankKm = isBefore12 ? leistungData.startkm : leistungData.endkm;

        // Update the form data with ausgabenkilometer and fahrzeugId
        setFormData((prevFormData) => ({
          ...prevFormData,
          ausgabenkilometer: tankKm || 0,
          fahrzeugId: selectedFahrzeugId,
        }));
      } else {
        // Handle the case where leistungData is undefined
        console.log(
          "No matching leistungData found for fahrzeugId:",
          selectedFahrzeugId
        );
      }
    } else {
      // Update the form data for other fields
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Updated Data:", formData);
    console.log(formData.fahrzeugId);

    // Remove 'id' from the formData
    const { id, ...formDataWithoutId } = formData;

    // Check if ausgabenvolumen is required and empty
    if (
      formDataWithoutId.belegart === "KFZ-LAufendekosten" &&
      formDataWithoutId.ausgabenvolumen === ""
    ) {
      // You can display an alert, console.log, or handle it in your preferred way
      alert("Volumen is required when Ausgabenart is 'KFZ laufende Kosten'.");
      return;
    }

    try {
      let response;

      if (isEditMode) {
        // Edit modunda isek, güncelleme işlemini yapiyoruz
        response = await axios.put(
          `https://dev.zone-edv.de/api/ausgabens/${formData.id}?access_token=${token}`,
          formData
        );
      } else {
        // Yeni veri ekleniyorsa, ekleme işlemini yapiyoruz
        response = await axios.post(
          `https://dev.zone-edv.de/api/ausgabens?access_token=${token}`,
          formData
        );
        console.log("Server Response:", response);
      }

      if (response.status === 200) {
        console.log("Form submitted successfully!");

        if (isEditMode) {
          // Edit modunda isek, ausgabens state'ini güncelliyoruz
          setAusgaben((prevAusgaben) =>
            prevAusgaben.map((ausgabe) =>
              ausgabe.id === formData.id ? response.data : ausgabe
            )
          );
        } else {
          // Yeni veri ekleniyorsa, ausgabens state'ini güncelliyoruz
          setAusgaben([...ausgaben, response.data]);
          console.log("Updated Ausgaben:", ausgaben);
        }

        setFormData({
          dbdatum: new Date().toISOString().slice(0, 10),
          belegdatum: new Date().toISOString().slice(0, 10),
          belegtext: "",
          verwendungszweck: "",
          belegart: "",
          belegzahlungsart: "",
          ausgabenzeit: "",
          ausgabenkilometer: "",
          ausgabenvolumen: 0,
          belegbetrag: 0,
          ausgabenbrutto: 0,
          mwSt: 0,
          id: 0,
          fahrzeugId: 0,
          telefoneId: 0,
        });

        setIsEditMode(false); // Edit modunu kapat
      } else {
        console.error(
          "Das Absenden des Formulars ist fehlgeschlagen:",
          response.statusText
        );
      }
    } catch (error) {
      console.error(
        "Beim Absenden des Formulars ist ein Fehler aufgetreten:",
        error
      );
      // Additional debugging:
      if (error.response) {
        console.error("Server Response Data:", error.response.data);
      }
    }
  };

  const handleEditClick = (id) => {
    // Edit butonuna tıklandığında, seçili ausgaben in verilerini getir
    getAusgabe(id);
    setIsEditMode(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      // Send a request to delete the item with the specified id
      const response = await axios.delete(
        `${BASE_URL}ausgabens/${id}?access_token=${token}`
      );

      if (response.status === 200) {
        // Remove the deleted item from the 'ausgaben' state
        setAusgaben((prevAusgaben) =>
          prevAusgaben.filter((ausgabe) => ausgabe.id !== id)
        );
        console.log(`Ausgabe with id ${id} deleted successfully`);
      } else {
        console.error(
          `Deleting Ausgabe with id ${id} failed: ${response.statusText}`
        );
      }
    } catch (error) {
      console.error(`Error deleting Ausgabe with id ${id}: ${error.message}`);
    }
  };

  return (
    <div className="card p-1 border-0">
      <div className="card mb-2">
        <h1 className="card-header ml-2 fs-5 text-white bg-primary p-3">
          Ausgabenliste
        </h1>
        <div className="card-body">
          <div className="d-flex flex-wrap gap-2 mb-2">
            <button type="button" className="btn btn-info">
              Copy
            </button>
            <button type="button" className="btn btn-success">
              Excel
            </button>
            <button type="button" className="btn btn-danger">
              PDF
            </button>
            <button type="button" className="btn btn-outline-secondary">
              YAZICI
            </button>
            <button type="button" className="btn btn-outline-secondary">
              Titel
            </button>
            <button type="button" className="btn btn-outline-secondary">
              SearchPanes
            </button>
          </div>
          <div
            className="table-responsive overflow-scroll max-height-400"
            style={{ maxHeight: "300px" }}
          >
            <table className="table table-striped table-hover overflow-scroll mb-4 border-2 rounded-2">
              <thead className="table-dark sticky-top">
                <tr>
                  <th scope="col">Datum</th>
                  <th scope="col">Belegtext</th>
                  <th scope="col">Zweck</th>
                  <th scope="col">Zahlungsart</th>
                  <th scope="col">Fahrzeug</th>
                  <th scope="col">Liter</th>
                  <th scope="col">Brutto</th>
                  <th scope="col">Letz. Tank-km</th>
                  <th scope="col">Akt. km</th>
                  <th scope="col">Diff.</th>
                  <th scope="col">Letzte EK/L</th>
                  <th scope="col">€/100km</th>
                  <th scope="col">EDIT</th>
                  <th scope="col">DEL</th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {ausgaben &&
                  ausgaben.map((data) => (
                    <tr key={data.id}>
                      <th scope="row">
                        {new Date(data.dbdatum).toLocaleDateString()}
                      </th>
                      <th>{data.belegtext}</th>
                      <th>{data.verwendungszweck}</th>
                      <th>{data.belegzahlungsart}</th>
                      <th>{data.fahrzeugId}</th>
                      <th>{data.ausgabenvolumen}</th>
                      <th>{data.ausgabenbrutto}</th>
                      <th>{data.ausgabenkilometer}</th>
                      <th>xxxxx</th>
                      <th>xxxxx</th>
                      <th>xxxxx</th>
                      <th>xxxxx</th>
                      <th>
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          onClick={() => handleEditClick(data.id)}
                        >
                          <FiEdit />
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDeleteClick(data.id)}
                        >
                          <RiDeleteBinFill />
                        </button>
                      </th>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="rounded-2 overflow-hidden">
        <form
          className="border rounded-2 overflow-hidden"
          onSubmit={handleSubmit}
        >
          <h1 className="ml-2 fs-5 text-white bg-primary p-3">
            Ausgaben Erfassen
          </h1>
          <div className="row px-2 py-2">
            <div className="col-sm-12">
              <div className="col p-2">
                <div className="row">
                  <div className="col-12 col-md-4 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="dbdatum"
                    >
                      Datum <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      id="dbdatum"
                      name="dbdatum"
                      className="form-control"
                      value={formData.dbdatum}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="belegdatum"
                    >
                      Datum2 <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      id="belegdatum"
                      name="belegdatum"
                      className="form-control"
                      value={formData.belegdatum}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <select
                      type="text"
                      className="form-select fw-semibold mt-4"
                      aria-label="belegzahlungsart"
                      id="belegzahlungsart"
                      name="belegzahlungsart"
                      value={formData.belegzahlungsart}
                      onChange={handleChange}
                      required
                    >
                      <option value="" selected>
                        Zahlungsart auswählen
                      </option>
                      <option value="Barzahlung">Barzahlung</option>
                      <option value="Kartenzahlung">Kartenzahlung</option>
                      <option value="Überweisung">Überweisung</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-6 mb-3">
                    <div>
                      <label
                        className="form-label mb-0 fw-semibold"
                        htmlFor="belegtext"
                      >
                        Benennung
                      </label>
                      <select
                        type="text"
                        id="belegtext"
                        name="belegtext"
                        className="form-select"
                        value={formData.belegtext}
                        onChange={handleChange}
                        required
                      >
                        <option value="" selected>
                          Benennung auswählen
                        </option>
                        {belegtext.map((item) => (
                          <option key={item.id} value={item.belegart}>
                            {item.belegart}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 mb-3">
                    <div>
                      <label
                        className="form-label mb-0 fw-semibold"
                        htmlFor="belegart"
                      >
                        Ausgabenart
                      </label>
                      <select
                        type="text"
                        id="belegart"
                        name="belegart"
                        className="form-select"
                        value={formData.belegart}
                        onChange={handleChange}
                        required
                      >
                        <option value="" selected>
                          Ausgabenart auswählen
                        </option>
                        {belegtext.map((item) => (
                          <option key={item.id} value={item.belegname}>
                            {item.belegname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="verwendungszweck"
                    >
                      Verwendungszweck (Notiz)
                    </label>
                    <input
                      type="text"
                      id="verwendungszweck"
                      name="verwendungszweck"
                      placeholder="Verwendungszweck eingeben"
                      className="form-control"
                      value={formData.verwendungszweck}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="fahrzeugId"
                    >
                      Fahrzeug
                    </label>
                    <select
                      type="number"
                      id="fahrzeugId"
                      name="fahrzeugId"
                      className="form-select fw-semibold"
                      aria-label="fahrzeugId"
                      value={formData.fahrzeugId}
                      onChange={handleChange}
                      required
                    >
                      <option value="" selected>
                        Fahrzeug auswählen
                      </option>
                      {fahrzeugIds.map((id) => (
                        <option key={id} value={id}>
                          {id}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="telefoneId"
                    >
                      Telefone
                    </label>
                    <input
                      type="tel"
                      id="telefoneId"
                      name="telefoneId"
                      placeholder="Telefon eingeben"
                      className="form-control"
                      value={formData.telefoneId}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="ausgabenvolumen"
                    >
                      Volumen (Liter)
                    </label>
                    <input
                      type="number"
                      min={0}
                      id="ausgabenvolumen"
                      name="ausgabenvolumen"
                      placeholder="Volumen eingeben"
                      className="form-control"
                      value={formData.ausgabenvolumen}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="ausgabenzeit"
                    >
                      Uhrzeit
                    </label>
                    <input
                      type="time"
                      id="ausgabenzeit"
                      name="ausgabenzeit"
                      placeholder="Zeit eingeben"
                      className="form-control"
                      value={formData.ausgabenzeit}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="ausgabenkilometer"
                    >
                      Tankkilometer
                    </label>
                    <input
                      type="number"
                      min={0}
                      id="ausgabenkilometer"
                      name="ausgabenkilometer"
                      placeholder="Km eingeben"
                      className="form-control"
                      value={formData.ausgabenkilometer}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="belegbetrag"
                    >
                      Belegbetrag (Netto)
                    </label>
                    <input
                      type="number"
                      min={0}
                      id="belegbetrag"
                      name="belegbetrag"
                      placeholder="Betrag eingeben"
                      className="form-control"
                      value={formData.belegbetrag}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="mwSt"
                    >
                      Mehrwertsteuer
                    </label>
                    <input
                      type="number"
                      min={0}
                      id="mwSt"
                      name="mwSt"
                      placeholder="Wert eingeben"
                      className="form-control bg-secondary-subtle"
                      value={formData.mwSt}
                      onChange={handleChange}
                      readOnly
                      required
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label
                      className="form-label mb-0 fw-semibold"
                      htmlFor="ausgabenbrutto"
                    >
                      Belegbetrag (Brutto)
                    </label>
                    <input
                      type="number"
                      min={0}
                      id="ausgabenbrutto"
                      name="ausgabenbrutto"
                      placeholder="Betrag eingeben"
                      className="form-control"
                      value={formData.ausgabenbrutto}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="btn bg-primary fw-semibold text-white"
              >
                Eingeben
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Ausgaben;
