import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { optionForEdit } from "./option/optionSlice";
import { useDeleteOptionsMutation, useUpdateOptionStatusMutation, useUpdateOptionsMutation } from "./option/optionApi";
import DataTableExtensions from 'react-data-table-component-extensions';
import ActionColumnCell from "./option/ActionColumnCell";
import { toast } from "react-toastify";
import { BootyCheckbox } from "../UIElements/BootyCheckbox";


const TableOption = ({ refetch }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.option.optionData);
  const { token } = useSelector((state) => state.auth);
  const [deleteOptions] = useDeleteOptionsMutation();
  const [updateOptionStatuss] = useUpdateOptionStatusMutation();

  const editOption = (id) => {
    const optionToEdit = data.find((option) => option.id === id);
    dispatch(optionForEdit(optionToEdit));
  };

  const deleteOption = async (id) => {
    try {
      await deleteOptions({ id, token });
      refetch();
    } catch (error) {
      console.error("Error Delete", error);
    }
  };

  // const updateOptionStatus = async (id,status,key, kategorie,value) => {
  //   await updateOptionStatuss({ id, token, status,key, kategorie,value });
  //   refetch();
  //   console.log('Option status')
  // };
  const handleStatus = async (id) => {
    try {
      const updatedOptionData = data.map((opt) => {
        if (opt.id === id) {
          return {
            ...opt,
            status: opt.status === 1 ? 0 : 1,
            token
          };
        }
        return opt
      }).find((opt) => opt?.id === id)
      await updateOptionStatuss({ ...updatedOptionData });

      refetch();
    } catch (error) {
      console.error("Data update error (toggle):", error);
    }
  };

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log("Selected Rows: ", selectedRows);
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "KATEGORİE",
      selector: (row) => row.kategorie,
      sortable: true,
    },
    {
      name: "KEY",
      selector: (row) => row.key,
      sortable: true,
    },
    {
      name: "VALUE",
      selector: (row) => row.value,
      sortable: true,
    },
    // {
    //   name: 'STATUS',
    //   selector: (row) => row.status,
    //   sortable: true,
    //   cell: (row) => <StatusColumnCell row={row} onUpdate={updateOptionStatus} />,
    // },
    {
      name: "STATUS",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={row.status === 1}
            onChange={() => handleStatus(row.id)}
          />
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => "action",
      sortable: false,
      right: "true",
      cell: (row) => (
        <ActionColumnCell
          row={row}
          onEdit={editOption}
          onDelete={deleteOption}
        />
      ),
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="card my-4">
      <div className="card-header bg-primary text-white">Option Übersicht</div>
      <div className="card-body">
      <DataTableExtensions {...tableData}>
        <DataTable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          // {...tableData}
          pagination
          paginationPerPage={5} // En fazla 5 satır göster
          columns={tableData.columns}
          data={tableData.data}
          responsive={true}
          // paginationComponent={BootyPagination}
          highlightOnHover={false}
          dense={false}
          selectableRows
          selectableRowsHighlight={true}
          onSelectedRowsChange={handleChange}
          selectableRowsComponent={BootyCheckbox}
          fixedHeader={true}

        />
        </DataTableExtensions>
      </div>
    </div>
  );
};

export default TableOption;
