import React from "react";
import {
  toastSuccessNotify,
  toastWarnNotify,
} from "../../../helper/ToastNotify";

const ReklamationForm = ({
  formik,
  handleChange,
  fahrerData = [],
  tourData = [],
  kundenData = [],
  rmaData = [],
  userRole
}) => {
  const handleKundenChange = (event) => {

    formik.handleChange(event);
    const { name, value } = event.target;

    // Seçilen değere göre kundenData içinden ilgili kunde objesini bul
    const selectedKunde = kundenData.find((kunde) => {
      switch (name) {
        case "rmakundennummer":
          return kunde.kundennummer === value;
        case "rmakundenname":
          return kunde.kundenname === value;
        case "rmakundenstr":
          return kunde.kundenstr === value;
        case "tourId":
          return kunde.kundentournr === value;
        default:
          return false;
      }
    });

    // Eğer bir Kunde seçildiyse, ilgili değerleri güncelle
    if (selectedKunde) {
      formik.setFieldValue("rmakundennummer", selectedKunde.kundennummer || "");
      formik.setFieldValue("rmakundenname", selectedKunde.kundenname || "");
      formik.setFieldValue("rmakundenstr", selectedKunde.kundenstr || "");
      formik.setFieldValue("tourId", selectedKunde.kundentournr || "");
    }
  };

  const saveLocalData = () => {
    // Check if the form has been submitted before saving
    if (formik.values && !formik.values.submitted) {
      localStorage.setItem("savedValues", JSON.stringify(formik.values));
      toastSuccessNotify("Formulardaten lokal gespeichert.");
      formik.resetForm();
    } else {
      // Clear local storage if the form has been submitted
      localStorage.removeItem("savedValues");
      console.log("Local storage data cleared.");
    }
  };

  const loadLocalData = () => {
    const savedData = localStorage.getItem("savedValues");
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      formik.setValues(parsedData);
      toastSuccessNotify("Aus dem lokalen Speicher geladene Formulardaten.");
    } else {
      toastWarnNotify("Keine gespeicherten Formulardaten gefunden.");
    }
  };

  return (
    <div className="rounded-2 overflow-hidden">
      <div></div>
      <form
        className="border rounded-2 overflow-hidden"
        onSubmit={formik.handleSubmit}
      >
        <div className="ml-2 fs-5 text-white bg-primary p-3">Reklamation</div>
        <div className="row px-2 py-2">
          <div className="col-sm-12">
            <div className="col p-2">
              <div className="row">
                {/* Fahrtype: radiobutton */}
                <div className="col-12 col-md-2 mb-3 d-flex flex-md-column flex-grow gap-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="neue"
                      value="neue"
                      checked={formik.values.status === "neue"}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      defaultChecked
                    />
                    <label
                      className="form-check-label fw-semibold"
                      htmlFor="neue"
                    >
                      Neu
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="ignorieren"
                      value="ignorieren"
                      checked={formik.values.status === "ignorieren"}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className="form-check-label fw-semibold"
                      htmlFor="ignorieren"
                    >
                      Intern
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="status"
                      id="gesendet"
                      value="gesendet"
                      checked={formik.values.status === "gesendet"}
                      onChange={handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                    <label
                      className="form-check-label fw-semibold"
                      htmlFor="gesendet"
                    >
                      Gesendet
                    </label>
                  </div>
                </div>
                {/* Datum */}
                <div className="col-12 col-md-4 mb-3">
                  <label className="fw-semibold" htmlFor="dbdatum">
                    Datum{" "}
                  </label>
                  <input
                    name="dbdatum"
                    type="date"
                    className={`form-control ${
                      formik.touched.dbdatum && formik.errors.dbdatum
                        ? "is-invalid"
                        : ""
                    }`}
                    id="dbdatum"
                    value={formik.values.dbdatum}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.dbdatum && formik.errors.dbdatum ? (
                    <div className="error text-danger">
                      {formik.errors.dbdatum}
                    </div>
                  ) : null}
                </div>
                {/* Datum-2 */}
                <div className="col-12 col-md-4 mb-3">
                  <label className="fw-semibold" htmlFor="rmadatum">
                    rmaDatum
                  </label>
                  <input
                    name="rmadatum"
                    type="date"
                    className={`form-control ${
                      formik.touched.rmadatum && formik.errors.rmadatum
                        ? "is-invalid"
                        : ""
                    }`}
                    id="rmadatum"
                    value={formik.values.rmadatum}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.rmadatum && formik.errors.rmadatum ? (
                    <div className="error text-danger">
                      {formik.errors.rmadatum}
                    </div>
                  ) : null}
                </div>
                {/* Fahrzeug */}
                <div className="col-12 col-md-2 mb-3">
                  <label className="fw-semibold" htmlFor="rmauhrzeit">
                    Uhrzeit{" "}
                  </label>
                  <input
                    name="rmauhrzeit"
                    type="time"
                    className={`form-control ${
                      formik.touched.rmauhrzeit && formik.errors.rmauhrzeit
                        ? "is-invalid"
                        : ""
                    }`}
                    id="rmauhrzeit"
                    value={formik.values.rmauhrzeit}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.rmauhrzeit && formik.errors.rmauhrzeit ? (
                    <div className="error text-danger">
                      {formik.errors.rmauhrzeit}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="row mt-2">
                {/* Fahrer */}
                <div className="col-12 col-md-4 mb-3">
                  <label className="fw-semibold" htmlFor="fahrer">
                    Fahrer
                  </label>
                  <select
                    name="fahrer"
                    id="fahrer"
                    className="form-select form-control"
                    value={formik.values.fahrer}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" defaultValue>
                      Fahrer auswählen
                    </option>
                    {Array.isArray(fahrerData) && fahrerData.length > 0 ? (
                      fahrerData.map((fahrer) => (
                        <option key={fahrer.id} value={fahrer.id}>
                          {fahrer.realm}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                </div>
                {/* Tour */}
                <div className="col-12 col-md-4 mb-3">
                  <label className="fw-semibold" htmlFor="tourId">
                    Tour
                  </label>
                  <select
                    name="tourId"
                    type="number"
                    id="tourId"
                    className="form-control"
                    value={formik.values.tourId}
                    onChange={handleKundenChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" defaultValue>
                      Tour auswählen
                    </option>
                    {kundenData ? (
                      kundenData.map((kunden) => (
                        <option key={kunden.id} value={kunden.kundentournr}>
                          {kunden.kundentournr}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                </div>
                {/* Kundennummer */}
                <div className="col-12 col-md-4 mb-3">
                  <label className="fw-semibold" htmlFor="rmakundennummer">
                    Kundennummer
                  </label>
                  <select
                    name="rmakundennummer"
                    type="text"
                    className="form-control"
                    id="rmakundennummer"
                    value={formik.values.rmakundennummer}
                    onChange={handleKundenChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" defaultValue>
                      Nummer auswählen
                    </option>
                    {kundenData ? (
                      kundenData.map((kunden) => (
                        <option key={kunden.id} value={kunden.kundennummer}>
                          {kunden.kundennummer}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="row mt-2">
                {/* Anzahl Tischgäste : Number */}
                <div className="col-12 col-md-6 mb-3">
                  <label className="fw-semibold" htmlFor="rmakundenname">
                    Kundenname
                  </label>
                  <select
                    name="rmakundenname"
                    type="text"
                    className="form-control"
                    id="rmakundenname"
                    value={formik.values.rmakundenname}
                    onChange={handleKundenChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" defaultValue>
                      Name auswählen
                    </option>
                    {kundenData ? (
                      kundenData.map((kunden) => (
                        <option key={kunden.id} value={kunden.kundenname}>
                          {kunden.kundenname}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                </div>
                {/* Kindergarten :Number */}
                <div className="col-12 col-md-6 mb-3">
                  <label className="fw-semibold" htmlFor="rmakundenstr">
                    Straße
                  </label>
                  <select
                    name="rmakundenstr"
                    type="text"
                    className="form-control"
                    id="rmakundenstr"
                    placeholder="Straße eingeben..."
                    value={formik.values.rmakundenstr}
                    onChange={handleKundenChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" defaultValue>
                      Str. auswählen
                    </option>
                    {kundenData ? (
                      kundenData.map((kunden) => (
                        <option key={kunden.id} value={kunden.kundenstr}>
                          {kunden.kundenstr}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="row mt-2">
                {/* Menüfix : Number*/}
                <div className="col-12 col-md-6 mb-3">
                  <label className="fw-semibold" htmlFor="rmaauswahl">
                    RMA Auswahl
                  </label>
                  <select
                    name="rmaauswahl"
                    type="text"
                    id="rmaauswahl"
                    className={`form-select form-control ${
                      formik.touched.rmaauswahl && formik.errors.rmaauswahl
                        ? "is-invalid"
                        : ""
                    }`}
                    value={formik.values.rmaauswahl}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">RMA auswählen</option>
                    {rmaData ? (
                      rmaData.map((rma) => (
                        <option key={rma.id} value={rma.rmaart}>
                          {rma.rmaart}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                  {formik.touched.rmaauswahl && formik.errors.rmaauswahl ? (
                    <div className="error text-danger">
                      {formik.errors.rmaauswahl}
                    </div>
                  ) : null}
                </div>
                {/* Mikrowelle : Number*/}
                <div className="col-12 col-md-6 mb-3">
                  <label className="fw-semibold" htmlFor="rmanotiz">
                    RMA Notiz
                  </label>
                  <select
                    name="rmanotiz"
                    id="rmanotiz"
                    className={`form-select form-control ${
                      formik.touched.rmanotiz && formik.errors.rmanotiz
                        ? "is-invalid"
                        : ""
                    }`}
                    value={formik.values.rmanotiz}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">RMA-Notiz auswählen</option>
                    {rmaData ? (
                      rmaData.map((rma) => (
                        <option key={rma.rmatext} value={rma.rmatext}>
                          {rma.rmatext}
                        </option>
                      ))
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                  {formik.touched.rmanotiz && formik.errors.rmanotiz ? (
                    <div className="error text-danger">
                      {formik.errors.rmanotiz}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Notiz input:textarea */}
              <div className="form-group mt-2">
                <label className="fw-semibold" htmlFor="rmanotiz2">
                  RMA Notiz-2{" "}
                </label>
                <textarea
                  name="rmanotiz2"
                  className="form-control"
                  id="rmanotiz2"
                  placeholder="Notiz eingeben..."
                  value={formik.values.rmanotiz2}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 ms-2 mt-2">
            <button type="submit" className="btn btn-primary">
              Abschließen
            </button>

            <button
              type="button"
              onClick={saveLocalData}
              className="btn btn-info"
            >
              ZwischernSpeichern
            </button>
            <button
              type="button"
              onClick={loadLocalData}
              className="btn btn-warning"
            >
              Laden
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReklamationForm;
