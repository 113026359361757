const genderOptions = [
  { label: "", value: "" },
  { label: "männlich", value: "männlich" },
  { label: "weiblich", value: "weiblich" },
  { label: "diverse", value: "diverse" },
];

const familienstandOptions = [
  { label: "", value: "" },
  { label: "Verheiratet", value: "verheiratet" },
  { label: "Ledig", value: "ledig" },
  { label: "Verwitwet", value: "verwitwet" },
  { label: "Geschieden", value: "geschieden" },
];

const behindertOptions = [
  { label: "Ja", value: "ja" },
  { label: "Nein", value: "nein" },
];

const beschaftigungsartOptions = [
  { label: "", value: "" },
  { label: "Nebenbeschäftigung", value: "nebenbeschaftigung" },
  { label: "Hauptbeschäftigung", value: "hauptbeschaftigung" },
];

const beschaeftigungOptions = [
  { label: "", value: "" },
  { label: "Vollzeit", value: "vollzeit" },
  { label: "Teilzeit", value: "teilzeit" },
  { label: "Aushilfe", value: "aushilfe" },
];

const weiterebeschaftigungOptions = [
  { label: "", value: "" },
  { label: "Ja", value: "Ja" },
  { label: "Nein", value: "Nein" },
];

const befristungsartOptions = [
  { label: "", value: "" },
  { label: "Befristet", value: "befristet" },
  { label: "Zweckbefristet", value: "zweckbefristet" },
];

const personalgruppeOptions = [
  { label: "", value: "" },
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
];

const schulabschlussOptions = [
  { label: "", value: "" },
  { label: "Ohne Schulabschluss", value: "ohneSchulabschluss" },
  { label: "Haupt-/Volksschulabschluss", value: "hauptvolksschulabschluss" },
  { label: "Mittlere Reife", value: "mittlerereife" },
  { label: "Abitur", value: "abitur" },
];

const berufsausbildungOptions = [
  { label: "", value: "" },
  { label: "Ohne Ausbildung", value: "ohneausbildung" },
  {
    label: "Anerkannte Berufsausbildung",
    value: "anerkannteberufsausbildung",
  },
  { label: "Meister/Techniker", value: "meistertechniker" },
  { label: "Bachelor", value: "bachelor" },
  { label: "Diplom/Magister", value: "diplommagister" },
  { label: "Promotion", value: "promotion" },
];

const steuerklasseOptions = [
  { label: "", value: "" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
];

const wochenarbeitstageOptions = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];

const formField = (usersOptions) => {
  const formRows = [
    [
      { label: "User", name: "userId", type: "select", options: usersOptions },
      { label: "Vorname", name: "personvorname", type: "text" },
      { label: "Nachname", name: "personnachname", type: "text" },
    ],
    [
      { label: "Strasse", name: "personstr", type: "text" },
      { label: "PLZ", name: "personplz", type: "text" },
      { label: "Wohnort", name: "personort", type: "text" },
    ],
    [
      { label: "Mobiltelefon", name: "mobiltelefon", type: "text" },
      { label: "Festtelefon", name: "festtelefon", type: "text" },
      {
        label: "Sozialversicherungsnummer",
        name: "personversnummer",
        placeholder: "12345678D234",
        type: "text",
      },
    ],
    [
      {
        label: "Geburtsdatum",
        name: "persongebdatum",
        type: "date",
      },
      { label: "Geburtsort", name: "gebort", type: "text" },
      { label: "Geburtsland", name: "gebland", type: "text" },
    ],
    [
      {
        label: "Geschlecht",
        name: "persongeschlecht",
        type: "select",
        options: genderOptions,
      },
      {
        label: "Familienstand",
        name: "familienstand",
        type: "select",
        options: familienstandOptions,
      },

      {
        label: "Staatsangehörigkeit",
        name: "staatsangehorigkeit",
        type: "text",
      },
    ],
    [
      { label: "Bankname", name: "personbankname", type: "text" },
      { label: "IBAN", name: "personiban", type: "text" },
      { label: "BIC", name: "personbic", type: "text" },
    ],
    [
      {
        label: "Erstellt Datum ",
        name: "dbdatum",
        type: "date",
      },
      {
        label: "Eintrittsdatum",
        name: "eintrittsdatum",
        type: "date",
      },
      {
        label: "Austrittsdatum",
        name: "austrittsdatum",
        type: "date",
      },
    ],
    [
      {
        label: "Berufsbezeichnung",
        name: "berufsbezeichnung",
        type: "text",
      },
      {
        label: "Ausgeubteberuf",
        name: "ausgeubteberuf",
        type: "text",
      },
      {
        label: "Beschaftigungsart",
        name: "beschaftigungsart",
        type: "select",
        options: beschaftigungsartOptions,
      },
    ],
    [
      {
        label: "Beschaeftigung",
        name: "beschaeftigung",
        type: "select",
        options: beschaeftigungOptions,
      },
      {
        label: "Weiterebeschaftigung",
        name: "weiterebeschaftigung",
        type: "select",
        options: weiterebeschaftigungOptions,
      },
      {
        label: "Befristungsart",
        name: "befristungsart",
        type: "select",
        options: befristungsartOptions,
      },
    ],
    [
      {
        label: "Wochenarbeitstage",
        name: "wochenarbeitstage",
        type: "checkbox",
        options: wochenarbeitstageOptions,
      },
      {
        label: "Arbeitsbeginn",
        name: "arbeitsstartuhr",
        type: "time",
      },
      {
        label: "Behindert",
        name: "behindert",
        type: "radio",
        options: behindertOptions,
      },
    ],
    [
      {
        label: "Befristungzum",
        name: "befristungzum",
        type: "date",
      },
      {
        label: "Urlaubsanschpruch",
        name: "urlaubsanschpruch",
        type: "number",
      },
      {
        label: "Steueridentifikationsnummer",
        name: "steueridentnummer",
        type: "text",
        placeholder: "12345678912",
      },
    ],
    [
      {
        label: "Personalgruppe",
        name: "personalgruppe",
        type: "select",
        options: personalgruppeOptions,
      },
      {
        label: "Schulabschluss",
        name: "schulabschluss",
        type: "select",
        options: schulabschlussOptions,
      },
      {
        label: "Berufsausbildung",
        name: "berufsausbildung",
        type: "select",
        options: berufsausbildungOptions,
      },
    ],
    [
      { label: "Personalnummer", name: "personalnr", type: "number" },
      { label: "Stundenlohn", name: "stundenlohn", type: "number" },
      {
        label: "Monatsarbeitszeit",
        name: "monatsarbeitszeit",
        type: "number",
      },
    ],
    [
      { label: "Finanzamt", name: "finanzamt", type: "text" },
      {
        label: "Steuerklasse",
        name: "steuerklasse",
        type: "select",
        options: steuerklasseOptions,
      },
      {
        label: "Kinderfreibetrage",
        name: "kinderfreibetrage",
        type: "number",
      },
    ],
    [
      { label: "Konfession", name: "konfession", type: "text" },
      { label: "Krankenkasse", name: "krankenkasse", type: "text" },
      {
        label: "Krankenkassevnr",
        name: "krankenkassevnr",
        type: "text",
      },
    ],
    [{ label: "Notiz", name: "personalnotiz", type: "textarea" }],
  ];

  return formRows;
};

const initialValue = {
  personvorname: "",
  dbdatum: new Date().toISOString().split("T")[0],
  personnachname: "",
  personstr: "",
  personplz: "",
  festtelefon: "",
  mobiltelefon: "",
  personort: "",
  persongebdatum: "",
  persongeschlecht: "",
  personversnummer: "",
  familienstand: "",
  gebort: "",
  gebland: "",
  staatsangehorigkeit: "",
  behindert: "",
  behinderungsart: "",
  personbankname: "",
  personiban: "",
  personbic: "",
  eintrittsdatum: new Date().toISOString().split("T")[0],
  austrittsdatum: "",
  berufsbezeichnung: "",
  ausgeubteberuf: "",
  beschaftigungsart: "",
  personalnr: null,
  stundenlohn: null,
  personalgruppe: "",
  weiterebeschaftigung: "",
  schulabschluss: "",
  berufsausbildung: "",
  monatsarbeitszeit: null,
  arbeitsstartuhr: "",
  wochenarbeitstage: [],
  beschaeftigung: "",
  urlaubsanschpruch: null,
  befristungsart: "",
  befristungzum: "",
  steueridentnummer: "",
  finanzamt: "",
  steuerklasse: "",
  kinderfreibetrage: null,
  konfession: "",
  krankenkasse: "",
  krankenkassevnr: "",
  personalnotiz: "",
  userId: 0,
};

const formikValidation = (Yup) => {
  const validate = Yup.object({
    personvorname: Yup.string().required("Pflichtfeld"),
    dbdatum: Yup.date().required("Pflichtfeld"),
    personnachname: Yup.string().required("Pflichtfeld"),
    personstr: Yup.string().required("Pflichtfeld"),
    personplz: Yup.string().required("Pflichtfeld"),
    // festtelefon: Yup.string().required("Required"),
    mobiltelefon: Yup.string().required("Pflichtfeld"),
    personort: Yup.string().required("Pflichtfeld"),
    persongebdatum: Yup.date().required("Pflichtfeld"),
    persongeschlecht: Yup.string().required("Pflichtfeld"),
    personversnummer: Yup.string().required("Pflichtfeld"),
    familienstand: Yup.string().required("Pflichtfeld"),
    gebort: Yup.string().required("Pflichtfeld"),
    gebland: Yup.string().required("Pflichtfeld"),
    staatsangehorigkeit: Yup.string().required("Pflichtfeld"),
    behindert: Yup.string().required("Pflichtfeld"),
    personbankname: Yup.string().required("Pflichtfeld"),
    // personiban ilk 2 karakter büyük harf olmali ve string olmali ve bosluklar haric toplamda 22 hane olmali
    personiban: Yup.string()
      .required("Pflichtfeld")
      .matches(/^[A-Z]{2}\d{2}([ ]?\d{4}){4}\d{2}$/, "Invalid IBAN format"),
    personbic: Yup.string().required("Pflichtfeld"),
    eintrittsdatum: Yup.date().required("Pflichtfeld"),
    berufsbezeichnung: Yup.string().required("Pflichtfeld"),
    ausgeubteberuf: Yup.string().required("Pflichtfeld"),
    beschaftigungsart: Yup.string().required("Pflichtfeld"),
    personalnr: Yup.number().required("Pflichtfeld"),
    stundenlohn: Yup.number().required("Pflichtfeld"),
    personalgruppe: Yup.string().required("Pflichtfeld"),
    weiterebeschaftigung: Yup.string().required("Pflichtfeld"),
    schulabschluss: Yup.string().required("Pflichtfeld"),
    berufsausbildung: Yup.string().required("Pflichtfeld"),
    monatsarbeitszeit: Yup.number().required("Pflichtfeld"),
    arbeitsstartuhr: Yup.string().required("Pflichtfeld"),
    wochenarbeitstage: Yup.array().min(1, "Pflichtfeld"),
    beschaeftigung: Yup.string().required("Pflichtfeld"),
    urlaubsanschpruch: Yup.number().required("Pflichtfeld"),
    befristungsart: Yup.string().required("Pflichtfeld"),
    befristungzum: Yup.date().required("Pflichtfeld"),
    steueridentnummer: Yup.string().required("Pflichtfeld"),
    kinderfreibetrage: Yup.number().required("Pflichtfeld"),
    finanzamt: Yup.string().required("Pflichtfeld"),
    steuerklasse: Yup.string().required("Pflichtfeld"),
    krankenkassevnr: Yup.string().required("Pflichtfeld"),
    krankenkasse: Yup.string().required("Pflichtfeld"),
    konfession: Yup.string().required("Pflichtfeld"),
  });

  return validate;
};

export { formField, initialValue, formikValidation };
