import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { CiEdit } from 'react-icons/ci';
import { FaSave } from 'react-icons/fa';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import axios from 'axios';
import { useSelector } from 'react-redux';

const TourPlannenTable = ({
  modifiedData,
  onEdit,
  onDelete,
  fahrerOptions,
  fahrzeugOptions,
  getTourplanData,
  tourId,
}) => {
  const url = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.auth);
  const [updatingRowId, setUpdatingRowId] = useState(false);
  const fahrerOptLabel = fahrerOptions.map((f) => f.label);
console.log("yeni",fahrerOptLabel)
  function getSaatOptions() {
    // Saat seçenekleri oluşturuluyor (örneğin her yarım saat için)
    const options = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        options.push(`${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`);
      }
    }
    return options;
  }

  // Zamanı formatlayan fonksiyon
  function formatTime(value) {
    if (!value) return '';
    const [hour, minute] = value.split(':');
    const date = new Date();
    date.setHours(hour, minute);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  const column = React.useMemo(
    () => [
      {
        field: 'tourendatum',
        headerName: 'Datum',
        width: 150,
        editable: true,
        type: 'date',
        valueGetter: (params) => {
          const tourendatum = params.value;
          if (!tourendatum) {
            return '';
          }
          return new Date(tourendatum);
        },
      },
      {
        field: 'touruhrzeit',
        headerName: 'Start',
        width: 150,
        editable: true,
        type: 'singleSelect',
        valueOptions: getSaatOptions(), // Zaman seçeneklerini döndüren bir fonksiyon
        valueFormatter: ({ value }) => formatTime(value), // Zamanı formatlayan bir fonksiyon
      },
      {
        field: 'tourId',
        headerName: 'Tour',
        width: 150,
        editable: true,
        type: 'singleSelect',
        valueOptions: tourId,
      },
      {
        field: 'fahrer',
        headerName: 'Fahrer',
        width: 150,
        editable: true,
        type: 'singleSelect',
        valueOptions: fahrerOptLabel,
      },
      {
        field: 'fahrzeugId',
        valueGetter: (params) => {
          const fahrzeugId = params.value;
          if (!fahrzeugId) {
            return '';
          }
          return fahrzeugOptions.find((f) => f.value === fahrzeugId)?.label;
        },
        headerName: 'Fahrzeug',
        width: 150,
        editable: true,
        type: 'singleSelect',
        valueOptions: fahrzeugOptions.map((f) => f.value),
      },
      {
        field: 'tourennotiz',
        headerName: 'Notiz',
        width: 150,
        editable: true,
        type: 'text',
      },
      {
        field: 'actions',
        headerName: 'Aktionen',
        width: 150,
        renderCell: (params) => {
          return (
            <div className="d-flex gap-3">
              <FaSave onClick={() => handleTableSubmit(params.row)} className="text-success" />
              <CiEdit
                className="text-primary"
                onClick={() => {
                  onEdit(params.row);
                }}
              />
              <RiDeleteBin6Fill className="text-danger" onClick={() => onDelete(params.row.id)} />
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fahrerOptLabel, fahrzeugOptions, onDelete, onEdit, tourId]
  );

  const handleTableSubmit = async (row) => {
    setUpdatingRowId(row.id); // Güncelleme başladığında güncellenen satırı belirt

    if (row.tourendatum instanceof Date) {
      const utcDate = new Date(
        Date.UTC(row.tourendatum.getFullYear(), row.tourendatum.getMonth(), row.tourendatum.getDate())
      );
      row.tourendatum = utcDate.toISOString().split('T')[0];
    }

    const updatedRow = {
      ...row,
      fahrer: fahrerOptions.find((fahrer) => fahrer.label === row.fahrer)?.value,
      fahrzeugId: fahrzeugOptions.find((fahrzeug) => fahrzeug.label === row.fahrzeugId)?.value,
      tourendatum: new Date(row.tourendatum).toISOString(),
    };

    await axios.put(`${url}tourenplans/${row.id}?access_token=${token}`, updatedRow);

    getTourplanData();
    setUpdatingRowId(null); // Güncelleme tamamlandığında null yaparak tabloyu aktif hale getir
  };

  const [selectedRowData, setSelectedRowData] = useState([]);

  const handleRowSelection = (selectionModel) => {
    const selectedRowsData = modifiedData.filter((row) => selectionModel.includes(row.id));

    setSelectedRowData(selectedRowsData);
  };

  React.useEffect(() => {
    if (selectedRowData.length > 0) {
    }
  }, [selectedRowData]);

  return (
    <div className="card my-3">
      <div className="card-header bg-primary text-white">Tour Planen Übersicht</div>
      <div className="card-body">
        <DataGrid
          rows={modifiedData}
          columns={column}
          style={{ maxHeight: 400, width: '100%', overflowX: 'auto' }}
          hideFooterPagination
          density="compact"
          components={{
            Toolbar: GridToolbar,
          }}
          isRowSelectable={(params) => !updatingRowId || params.id !== updatingRowId} // Güncelleme sırasında sadece güncellenen satır seçilebilir
          disableSelectionOnClick={!!updatingRowId} // Güncelleme sırasında satır seçimi devre dışı
          disableColumnMenu={true} // Kolon menüsünü devre dışı bırak
          onSelectionModelChange={handleRowSelection}
        />
      </div>
    </div>
  );
};

export default TourPlannenTable;
