import React from "react";
import TelefonForm from "./TelefonForm";
import TelefonTable from "./TelefonTable";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { number, object, string } from "yup";
import {
  useGetTelefonQuery,
  //useAddTelefonMutation,
  //useUpdatedTelefonMutation,
  //useDeleteTelefonMutation,
} from "../telefon/telefonApi";
import { useGetToursQuery} from "../../leistung/core/leistungApi";
import axios from "axios";
import { toastSuccessNotify, toastErrorNotify } from "../../../helper/ToastNotify";

const Telefon = () => {
  const { token } = useSelector((state) => state.auth);
  const BASE_URL = "https://dev.zone-edv.de/api/";
  const url = `${BASE_URL}telefones?access_token=${token}`;

  const {
    data: telefonData,
    isLoading,
    error,
    refetch,
  } = useGetTelefonQuery(token);
  const { data: toursData } = useGetToursQuery(token);
 // const [createTelefon] = useAddTelefonMutation(token);
  //const [updateTelefon] = useUpdatedTelefonMutation();
  //const [deleteTelefon] = useDeleteTelefonMutation();
  // console.log("telefonData: ", telefonData);
  //console.log("toursData: ", toursData);

  const validatePhoneNumber = (value) => {
    const regex = /^\+\d{11,18}$/; 
    return regex.test(value);
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  };
  
  const validateIMEI = (value) => {
    // Regex to validate a 15 or 17-digit IMEI number
    const imeiRegex = /^\d{15}(\d{2})?$/;
    return imeiRegex.test(value) ;
  };
    const initialValues = {
        telefonnummer: "",
        telefonanbieter:"",
        tourId: 0,
        telefonpin1: "",
        telefonpin2: "",
        telefonpuk1: "",
        telefonpuk2: "",
        telefonemail: "",
        telemailpasswort: "",
        telefonimei1: "",
        telefonimei2: "",
        telefonsimnr: "",
        vertragsbeginn: new Date().toISOString().slice(0, 10),
        status: "",
  };

  const telefonSchema = object({
    telefonnummer: string().required(),
    telefonanbieter: string(),
    tourId: number(),
    telefonpin1: string(),
    telefonpin2: string(),
    telefonpuk1: string(),
    telefonpuk2: string(),
    telefonemail: string(),
    telemailpasswort: string(),
    telefonimei1: string(),
    telefonimei2: string(),
    telefonsimnr: string(),
    vertragsbeginn: string(),
    status: string("AKTIV"),
  });

  const formik = useFormik({
    initialValues,
    telefonSchema,
    onSubmit: async (values) => {
     // console.log(`${token}`);
      try {
        if (!validatePhoneNumber(values.telefonnummer)) {
          formik.setFieldError('telefonnummer', 'Geçersiz telefon numarası formatı!');
          toastErrorNotify("Ungültige telefonnummer");
          return;
        }

        if (!isValidEmail(values.telefonemail)) {
          formik.setFieldError('telefonemail', 'Geçersiz Email formatı!');
          toastErrorNotify("Ungültige Email");
          return;
        }
        if (!validateIMEI(values.telefonimei1)) {
          formik.setFieldError('telefonimei1', 'Geçersiz IMEI formatı!');
          toastErrorNotify("Ungültige IMEI1");
          return;
        }
        if (!validateIMEI(values.telefonimei2)) {
          formik.setFieldError('telefonimei2', 'Geçersiz IMEI formatı!');
          toastErrorNotify("Ungültige IMEI2");
          return;
        }
        let response;

        if (values.id) {
          response = await axios.put(
            `https://dev.zone-edv.de/api/telefones/${values.id}?access_token=${token}`,
            values
          );
          toastSuccessNotify("Erfolgreich aktualisiert");
        } else {
          const isUnique = !telefonData.some(
            (tel) => tel.id === values.id
          );

          if (isUnique) {
            response = await axios.post(url, values);
            toastSuccessNotify("Erfolgreich gesendet");
          } else {
            console.error("Duplicate Data");
            return;
          }
          
        } 
        await refetch();
        console.log("Telefon eklendi:", values);
        formik.resetForm();
        //console.log(telefonData);
      } catch (error) {
        console.error("Error in addTelefon:", error);
      }
    }
  });
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.handleChange(e);
  
   // if (name === "tourId") { 
     // const selectedTour = toursData.find((tour) => tour.tourId === parseInt(value, 10) , );
   //  formik.setFieldValue("tour", selectedTour?.tourId || {});
    //} 
  }
  const onDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}telefones/${id}?access_token=${token}`);
      toastSuccessNotify("Erfolgreich gelöscht!");
      await refetch();
    } catch (error) {
      console.error("Error Delete", error);
    }
  };

  const onEdit = (telefon) => {
    formik.setValues({ ...telefon });
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>ERROR{error.message}</p>;
  }
 
  return (
    <div className="container-fluid ">
      <TelefonTable
        telefonData={telefonData}
        onDelete={onDelete}
        onEdit={onEdit}
        toursData={toursData}
      />
      <TelefonForm
        formik={formik}
        handleChange={handleChange}
        toursData={toursData}
      />
    </div>
         );
  };

 export default Telefon;