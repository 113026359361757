import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Form = () => {
  const { token, user } = useSelector((state) => state.auth);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        setData(data); // Speichert die ausgelesenen Daten im State
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const url = `${BASE_URL}users/${user.id}/tagestour?access_token=${token}`;
  // Funktion zum Hochladen der Daten
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (data.length > 0) {
      setUploading(true);

      try {
        // Delete existing data for the user
        await axios.delete(url, {
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers needed
          },
        });

        // Transformation der Daten für das Backend
        const dbEntries = data.map((row) => ({
          t_tour: row.Tour,
          t_name: row.Name,
          t_strasse: row.Strasse,
          t_plz: row.PLZ,
          t_ort: row.Ort,
          t_alp: row.Reihenfolge,
          t_tel: row.ExtField04,
          t_kundennr: row.Kundennummer,
          t_menueart: row.ExtField09,
          t_menues: row.Fahrzeuggruppe,
          t_info: row.Info,
        }));

        // Send transformed data to the backend
        const response = await axios.post(url, dbEntries, {
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers needed
          },
        });

        setAlert({
          show: true,
          message: "Daten erfolgreich hochgeladen!",
          type: "success",
        });
        console.log("Daten erfolgreich hochgeladen!", response);
      } catch (error) {
        setAlert({
          show: true,
          message: "Fehler beim Hochladen der Daten.",
          type: "danger",
        });
        console.error("Fehler beim Hochladen der Daten:", error);
      } finally {
        setUploading(false);
      }
    } else {
      setAlert({
        show: true,
        message: "Bitte wählen Sie zuerst eine Datei aus.",
        type: "warning",
      });
    }
  };

  const DataTable = ({ data }) => {
    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Tour</th>
              <th>Name</th>
              <th>Straße</th>
              <th>Plz</th>
              <th>Ort</th>
              <th>Alp</th>
              <th>Tel</th>
              <th>Kundennummer</th>
              <th>Menueart</th>
              <th>Menues</th>
              <th>Info</th>

              {/* Fügen Sie hier weitere Themen hinzu, je nachdem, was in Ihrer Excel-Datei steht */}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>{row.Tour}</td>
                <td>{row.Name}</td>
                <td>{row.Strasse}</td>
                <td>{row.PLZ}</td>
                <td>{row.Ort}</td>
                <td>{row.Reihenfolge}</td>
                <td>{row.ExtField04}</td>
                <td>{row.Kundennummer}</td>
                <td>{row.ExtField09}</td>
                <td>{row.Fahrzeuggruppe}</td>
                <td>{row.Info}</td>

                {/* Fügen Sie hier weitere Zellen hinzu, entsprechend Ihrer Datenstruktur */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="container mt-3">
      <div className="card mt-3">
        {alert.show && (
          <div
            className={`alert alert-${alert.type} alert-dismissible fade show`}
            role="alert"
          >
            {alert.message}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}
        <div className="card-header bg-primary text-white">Excel Upload</div>
        <div className="card-body">
          <div className="d-flex flex-column flex-md-row gap-2">
            <div>
              <input
                className="form-control "
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange}
              />
            </div>

            <div className="d-flex gap-2">
              <button
                className="btn btn-primary"
                onClick={handleFileUpload}
                disabled={!file}
              >
                Datei einlesen
              </button>
              <button
                className="btn btn-success"
                onClick={handleSubmit}
                disabled={data.length === 0 || uploading}
              >
                Hochladen
              </button>
            </div>
          </div>
        </div>
      </div>
      {data && data.length > 0 && <DataTable data={data} />}
    </div>
  );
};

export default Form;
