import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showInfo: null,
  partnerInfo: [],
  lastTour: [],
};

const werkstattSlice = createSlice({
  name: "werkstattSlice",
  initialState,
  reducers: {
    setShowInfo: (state, action) => {
      state.showInfo = action.payload;
    },
    setPartnerInfo: (state, action) => {
      state.partnerInfo = action.payload;
    },
    setLastTour: (state, action) => {
      state.lastTour = action.payload;
    },
  },
});

export const { setShowInfo, setPartnerInfo, setLastTour } =
  werkstattSlice.actions;
export default werkstattSlice.reducer;
