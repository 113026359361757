import { Link } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import foto from "../assets/th.jpg";
import { useSelector } from "react-redux";

const MenuListItemForm = ({
  open,
  toggleMenu,
  ListItems,
  AdminPanelItems,
  handleLogoutClick,
  isMenuOpen,
}) => {
  // A flag to determine if the user is an admin
  const { user, userRole } = useSelector((state) => state.auth);

  return (
    <>
      <div className={`mx-auto p-2 ${!open ? "border-bottom" : ""}`}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <img src={foto} alt="" width={35} className="rounded-circle" />
        </Link>
      </div>
      <div
        className="text-white text-center bg-info p-2"
        style={{
          cursor: "pointer",
          display: open ? "block" : "none",
          marginBottom: "-2rem",
          marginTop: "-2.8rem",
        }}
      >
        {user?.name || "-"}
      </div>
        {/* Scrollbar */}
      <nav
        className="flex-column"
        style={{
          maxHeight: "calc(100vh - 200px)",
          overflowY: "auto",
          scrollBehavior: "smooth",
          scrollbarWidth: "none",
        }}
      >
        {ListItems.map((item, index) => (
          <Link
            to={item.href}
            key={index}
            className="nav-link"
            style={{ textDecoration: "none" }}
          >
            <li
              id={
                item.name === "Admin Panel" || item.name === "Name"
                  ? "IdAdminMenuTogglling"
                  : ""
              }
              onClick={
                item.name === "Admin Panel" || item.name === "Name"
                  ? toggleMenu
                  : null
              }
              className="list-group-item rounded-2 bg-dark border-0"
              style={{
                height: "48px",
                padding: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: open ? "initial" : "left",
                cursor: "pointer",
                marginTop: "-.5rem",
              }}
            >
              <span
                className="me-3"
                style={{
                  minWidth: 30,
                  fontSize: "1.2rem",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                {item.icon}
              </span>
              <span
                className="fs-6 text-white"
                style={{
                  opacity: open ? 1 : 0,
                  display: open ? "flex" : "none",
                }}
              >
                {item.name}
              </span>
            </li>
          </Link>
        ))}

        {/* Rendering Admin Panel submenu if the user is an admin */}
        {(userRole === "ADMIN" || userRole === "FAHRER") && isMenuOpen && (
          <div
            onClick={toggleMenu}
            style={{
              color: "white",
            }}
          >
            {isMenuOpen && (
              <ul className="list-group">
                {AdminPanelItems.map((item) => (
                  <Link
                    to={item.href}
                    key={item.name}
                    className="nav-link"
                    style={{ textDecoration: "none" }}
                  >
                    <li
                      className="list-group-item rounded-2 bg-dark border-0"
                      style={{
                        height: "48px",
                        padding: "0.5rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: open ? "initial" : "left",
                        cursor: "pointer",
                        marginTop: "-.9rem",
                      }}
                    >
                      <span
                        className="me-3 text-primary"
                        style={{
                          minWidth: 30,
                          fontSize: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          color: item.name === "Abmelden" ? "red" : "white",
                        }}
                      >
                        {item.icon}
                      </span>
                      <span
                        className="text-white"
                        style={{
                          opacity: open ? 1 : 0,
                          display: open ? "flex" : "none",
                          fontSize: ".9rem",
                        }}
                      >
                        {item.name}
                      </span>
                    </li>
                  </Link>
                ))}
              </ul>
            )}
          </div>
        )}
        <Link
          onClick={handleLogoutClick}
          to="/"
          className="nav-link"
          style={{ textDecoration: "none" }}
        >
          <li
            className="list-group-item rounded-2 bg-dark border-0"
            style={{
              height: "48px",
              padding: "0.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: open ? "initial" : "left",
              cursor: "pointer",
              marginTop: "-.7rem",
            }}
          >
            <span
              className="fs-5 me-3"
              style={{
                minWidth: 30,
                fontSize: "1rem",
                display: "flex",
                justifyContent: "center",
                color: "red",
              }}
            >
              <FaSignOutAlt className="fs-5" style={{ color: "red" }} />
            </span>
            <span
              className="fs-6 text-white"
              style={{
                opacity: open ? 1 : 0,
                display: open ? "flex" : "none",
              }}
            >
              Abmelden
            </span>
          </li>
        </Link>
      </nav>
    </>
  );
};

export default MenuListItemForm;
