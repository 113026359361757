import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const werkstattApi = createApi({
  reducerPath: "werkstattApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),

  endpoints: (builder) => ({
    getWerkstatt: builder.query({
      query: (token) => `werkstatts?access_token=${token}`,
    }),
    addWerkstatt: builder.mutation({
      query: (newWerkstatt) => ({
        url: `werkstatts`,
        method: "POST",
        body: newWerkstatt,
      }),
    }),
    updateWerkstatt: builder.mutation({
      query: ({ id, updatedWerkstatt }) => ({
        url: `werkstatts/${id}`,
        method: "PUT",
        body: updatedWerkstatt,
      }),
    }),
    deleteWerkstatt: builder.mutation({
      query: (id) => ({
        url: `werkstatts/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetWerkstattQuery,
  useAddWerkstattMutation,
  useUpdateWerkstattMutation,
  useDeleteWerkstattMutation,
} = werkstattApi;
