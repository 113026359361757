import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const kundenApi = createApi({
  reducerPath: "kundenApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),

  endpoints: (builder) => ({
    getKunden: builder.query({
      query: (token) => `kundens?access_token=${token}`,
    }),

    addKunde: builder.mutation({
      query: (newKunde) => ({
        url: "kundens",
        method: "POST",
        body: newKunde,
      }),
    }),

    updatedKunde: builder.mutation({
      query: ({ id, updateKunde }) => ({
        url: `kundens/${id}`,
        method: "PUT",
        body: updateKunde,
      }),
    }),

    deleteKunde: builder.mutation({
      query: (id) => ({
        url: `kundens/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetKundenQuery,
  useAddKundeMutation,
  useUpdatedKundeMutation,
  useDeleteKundeMutation,
} = kundenApi;
