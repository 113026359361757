import { useId } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCreateRegionMutation,
  useUpdateRegionMutation,
} from '../../../app/services/region/regionApi';
import { CustomAlertDismissible } from '../../../components';
import { toast } from 'react-toastify';
import { regionForEdit } from '../../../app/services/region/regionSlice';
import RegionFormikHandler from './RegionFormikHandler';
import RegionFormHeader from './RegionFormHeader';

const RegionForm = ({ refetchRegions }) => {
  const generatedId = useId();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { region } = useSelector((state) => state.region);

  // Mutation Hook für das Erstellen einer Region
  const [createRegion, createRegionResult] = useCreateRegionMutation();
  const [updateRegion, updateRegionResult] = useUpdateRegionMutation();

  const actionLoading = createRegionResult.isLoading || updateRegionResult.isLoading;
  const isError = createRegionResult.isError || updateRegionResult.isError;
  const errorMessage =
    createRegionResult.error?.data?.error?.message ||
    updateRegionResult.error?.data?.error?.message;

  const createRegionHandler = async (values, { setFieldValue }) => {
    if (region?.id) {
      const body = {
        regionname: values.regionName,
      };
      await updateRegion({ id: region.id, token, ...body })
        .unwrap()
        .then((res) => {
          refetchRegions();
          toast.success('Region erfolgreich aktualisiert');
          setFieldValue('regionName', '', false);
          dispatch(regionForEdit({}));
        })
        .catch((err) => {
          toast.error('Region konnte nicht aktualisiert werden');
        });
    } else {
      const body = {
        token: token,
        formData: { regionname: values.regionName },
      };
      await createRegion(body)
        .unwrap()
        .then((res) => {
          refetchRegions();
          toast.success('Region erfolgreich hinzugefügt');
          setFieldValue('regionName', '', false);
        })
        .catch((err) => {
          toast.error('Region konnte nicht hinzugefügt werden');
        });
    }
  };

  return (
    <div className="card">
      <div className="card-header bg-light">
        <RegionFormHeader region={region} />
      </div>

      <div className="card-body">
        {/* Formik Handler */}
        <RegionFormikHandler
          key={region?.id || `cretaeRegionForm_${generatedId}`}
          createRegionHandler={createRegionHandler}
          actionLoading={actionLoading}
          region={region}
        />

        {/* Fetch Error Check */}
        {isError && (
          <CustomAlertDismissible
            type={'error'}
            message={errorMessage}
            isShow={isError}
          />
        )}
      </div>
    </div>
  );
};
export default RegionForm;
