import { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { BsFillPersonFill, BsGrid3X3GapFill } from "react-icons/bs";
import { PiBellRingingFill } from "react-icons/pi";
import { GiPositionMarker } from "react-icons/gi";
import { IoSettings } from "react-icons/io5";
import { LuMenuSquare } from "react-icons/lu";

import MenuListItem from "../components/MenuListItem";
import { useDispatch, useSelector } from "react-redux";
import { toggleSettingsModal } from "../features/SettingsSlice";
import SettingsModal from "../components/modals/settings/SettingsModal";
import { MdOutlineRoomService } from "react-icons/md";

import ResetBtn from "../components/RESET/ResetBtn";
import axios from "axios";

export const MainLayout = ({ handleShow }) => {
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { userRole, token, user } = useSelector((state) => state.auth);
  const {
    selectedTagesTour,
    kundeCount: kundeAmount,
    menuCount: menuAmount,
  } = useSelector((state) => state.tagesTourSlice);
  const url = process.env.REACT_APP_BASE_URL;
  const [logData, setLogData] = useState([]);
  const [fahrzeugesData, setFahrzeugesData] = useState([]);
  const [tagesTourData, setTagesTourData] = useState([]);

  const fetchLog = async () => {
    const { data } = await axios.get(`${url}logs?access_token=${token}`);
    setLogData(data);
  };

  const fetchFahrzeuges = async () => {
    const { data } = await axios.get(`${url}fahrzeuges?access_token=${token}`);
    setFahrzeugesData(data);
  };

  const fetchTagesTour = async () => {
    const { data } = await axios.get(`${url}tagestours?access_token=${token}`);
    setTagesTourData(data);
  };

  useEffect(() => {
    fetchLog();
    fetchFahrzeuges();
    fetchTagesTour();

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuToggle = () => {
    if (windowWidth <= 768 && open) {
      setOpen(false);
    } else {
      setOpen(!open);
    }
  };

  const dispatch = useDispatch();

  const isOpenSettingsModal = useSelector(
    (state) => state.settings.isOpenSettingsModal
  );

  const matchingTourID = logData
    .filter((l) => l.userId === user.id)
    .map((l) => l.tourId)[0];

  const matchingTourName = tagesTourData
    .filter((t) => t.id === matchingTourID)
    .map((t) => t.t_tour)[0];

  const matchingFahrzeugID = logData
    .filter((l) => l.userId === user.id)
    .map((l) => l.fahrzeugId)[0];
  const matchingFahrzeug = fahrzeugesData
    .filter((f) => f.id === matchingFahrzeugID)
    .map((f) => f.kfzkennzeichen)[0];

  // Data From Redux Toolkit
  const tourName = tagesTourData
    .filter((t) => selectedTagesTour?.tourId === t.id)
    .map((t) => t.t_tour)[0];

  const fahrzeugKennzeichen = fahrzeugesData
    .filter((f) => selectedTagesTour?.fahrzeugId === f.id)
    .map((f) => f.kfzkennzeichen)[0];

  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];

  const matchedUserData = logData.filter(
    (item) =>
      item.userId === user.id &&
      new Date(item.timestamp).toISOString().split("T")[0] === formattedDate
  );
  const selectedTagesTourId = matchedUserData.map((i) => i.tourId)[0];
  const matchedTourName = tagesTourData
    .filter((t) => t.id === selectedTagesTourId)
    .map((t) => t.t_tour)[0];

  const tourDatas = tagesTourData.filter((t) => t.t_tour === matchedTourName);
  const completedTours = tourDatas.filter((t) => t.t_status !== null);
  const uncompletedTours = tourDatas.filter((t) => t.t_status === null);
  const kundeCount = tourDatas.length - completedTours.length;
  const menues = uncompletedTours.map((t) => t.t_menues);
  const countMenus = () => {
    let normalMenuCount = 0;
    let tkMenuCount = 0;

    menues.forEach((menu) => {
      menu.split("\n").forEach((item) => {
        const parts = item.split(" ");
        const menuCode = parts[1];
        if (menuCode.startsWith("4") && menuCode.length === 4) {
          normalMenuCount += parseInt(parts[0], 10);
        } else {
          tkMenuCount += parseInt(parts[0], 10);
        }
      });
    });

    return { normalMenuCount, tkMenuCount };
  };

  const { normalMenuCount, tkMenuCount } = countMenus();

  return (
    <>
      <div className="d-flex">
        <div className="position-fixed" style={{ zIndex: 999 }}>
          <div
            style={{
              display: windowWidth <= 768 ? (open ? "flex" : "none") : "",
              zIndex: "999",
              height: "100vh",
            }}
          >
            <MenuListItem open={open} setOpen={setOpen} />
          </div>
        </div>
        <nav
          className="navbar position-fixed bg-body-tertiary bg-dark"
          style={{
            marginLeft:
              windowWidth <= 768
                ? open
                  ? "180px"
                  : "0"
                : open
                ? "180px"
                : "60px",
            transition: "all 0.3s ease",
            backgroundColor: "black",
            marginTop: "-.6rem",
            width:
              windowWidth <= 768
                ? open
                  ? `calc(100% - 180px)`
                  : "100%"
                : open
                ? `calc(100% - 180px)`
                : `calc(100% - 60px)`,
            zIndex: 2,
          }}
        >
          <div className="d-flex align-content-center justify-content-between gap-5 bg-dark w-100 py-3 px-3">
            <div className="d-flex gap-3">
              <Link className="" onClick={handleMenuToggle}>
                <LuMenuSquare className="fs-5 fw-bolder text-white" />
              </Link>
              <Link to="location" className="">
                <GiPositionMarker className="fs-5 fw-bolder text-white" />
              </Link>

              {userRole === "ADMIN" ? (
                <Link onClick={() => dispatch(toggleSettingsModal())}>
                  <IoSettings className="fs-5 fw-bolder text-white" />
                </Link>
              ) : null}

              {userRole === "FAHRER" ? (
                <>
                  <Link className="text-decoration-none text-white">
                    <BsFillPersonFill className="fs-5 fw-bolder" />
                    <span
                      className="position-relative text-warning "
                      style={{ top: "-10px", fontSize: "10px" }}
                    >
                      {kundeAmount || kundeCount}
                    </span>
                  </Link>
                  <Link className="text-decoration-none text-white">
                    <MdOutlineRoomService className="fs-5 fw-bolder" />
                    <span
                      className="position-relative text-warning "
                      style={{ top: "-10px", fontSize: "10px" }}
                    >
                      {menuAmount || normalMenuCount + tkMenuCount}
                    </span>
                  </Link>
                </>
              ) : null}
            </div>

            {userRole === "FAHRER" &&
            (selectedTagesTour || (matchingTourName && matchingFahrzeug)) ? (
              <marquee width="30%" direction="left" className="text-white">
                {selectedTagesTour ? (
                  <div className="text-warning p-1 text-center">
                    <span className="border border-warning px-2 rounded">
                      {fahrzeugKennzeichen}
                    </span>
                    <span className="mx-2">-</span>
                    <span className="border border-warning px-2 rounded">
                      {tourName}
                    </span>
                  </div>
                ) : matchingTourName && matchingFahrzeug ? (
                  <div className="text-warning p-1 text-center">
                    <span className="border border-warning px-2 rounded">
                      {matchingFahrzeug}
                    </span>
                    <span className="mx-2">-</span>
                    <span className="border border-warning px-2 rounded">
                      {matchingTourName}
                    </span>
                  </div>
                ) : null}
              </marquee>
            ) : null}
            <div className="d-flex gap-3">
              <ResetBtn />
              <Link className="position-relative">
                <PiBellRingingFill className="fs-5 fw-bolder text-white" />
                <span
                  className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-warning text-danger"
                  style={{ fontSize: ".5rem" }}
                >
                  3
                </span>
              </Link>
              <Link className="">
                <BsGrid3X3GapFill className="fs-5 fw-bolder text-white" />
              </Link>
            </div>
          </div>
        </nav>

        <div
          className="p-1"
          style={{
            marginTop: "3.6rem",
            marginLeft: windowWidth <= 768 ? "0.3rem" : "4rem",
            transition: "all .3s ease",
            width: "98%",
            zIndex: 0,
          }}
        >
          <Outlet />
        </div>
      </div>
      <div style={{ height: "75px" }}></div>
      {isOpenSettingsModal && <SettingsModal />}
    </>
  );
};
