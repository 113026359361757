
import TableTur from "./core/TableTur";
import { useSelector } from "react-redux";

import { useGetTourQuery } from "./core/tourApi";
import FormTour from "./FormTour";

const Touren = () => {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const isAdmin = user?.role === "ADMIN";
  const { refetch } = useGetTourQuery(token);

  return (
    <div className="container">
      <div>
        <TableTur refetch={refetch}/>
      </div>

      {isAdmin && (
        <div>
          <FormTour refetch={refetch}/>
        </div>
      )}
    </div>
  );
};
export default Touren;