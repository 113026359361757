import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutSuccess } from "../../../features/authSlice";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: fetchBaseQuery({
     baseUrl: baseUrl,
     //!cors hatası veriyordu onu atlatmak için ekledim silinebilir
     credentials: 'include', // if you need to send cookies
  mode: 'cors' // ensures CORS headers are included
   }),
  endpoints: (builder) => ({
    getMe: builder.query({
      query: ({ userId, token }) => ({
        method: "GET",
        url: `users/${userId}?access_token=${token}`, // `users/pingMe?access_token=${token}`
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (e) {
          console.log("me-error", e);
          if (e?.meta?.response?.status === 401) {
            // dispatch(logoutSuccess());
            // redirect unauthorized page
          }

          dispatch(logoutSuccess());
        }
      },
    }),
  }),
});

export const { useGetMeQuery } = usersApi;
