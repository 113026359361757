import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const rmaAuswahlApi = createApi({
  reducerPath: "rmaAuswahlApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  // prepareHeaders: (headers, { getState }) => {
  //   const token = getState().auth.token;
  //   if (token) {
  //     headers.set("Authorization", `Bearer ${token}`);
  //   }
  //   return headers;
  // },

  endpoints: (builder) => ({
    getRma: builder.query({
      query: () => "rmaauswahls",
    }),
    getReklamation: builder.query({
      query: (token) => `reklamations?access_token=${token}`,
    }),
    addRma: builder.mutation({
      query: (newTodo) => ({
        url: "rmaauswahls",
        method: "POST",
        body: newTodo,
      }),
    }),

    updateRma: builder.mutation({
      query: ({ id, updatedRma }) => ({
        url: `rmaauswahls/${id}`,
        method: "PUT",
        body: updatedRma,
      }),
    }),
    updateRmaStatus: builder.mutation({
      query: (updatedStatus) => ({
        url: `rmaauswahls/${updatedStatus.id}`,
        method: "PUT",
        body: updatedStatus,
      }),
    }),
    deleteRma: builder.mutation({
      query: (id) => ({
        url: `rmaauswahls/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetRmaQuery,
  useGetReklamationQuery,
  useAddRmaMutation,
  useUpdateRmaMutation,
  useDeleteRmaMutation,
  useUpdateRmaStatusMutation,
} = rmaAuswahlApi;
