import React, { useEffect } from "react";
import BewerberForm from "./BewerberForm";
import BewerberTable from "./BewerberTable";
import axios from "axios";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  useAddPersonalMutation,
  useAddUserMutation,
} from "../../../app/services/register/registerApi";
// import { deletingData, fetchingData, postingData, updatingData } from "./crud";

const Bewerber = () => {
  const url = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.auth);
  const [tableData, setTableData] = React.useState([]);
  const [editData, setEditData] = React.useState(null);
  const [addUser] = useAddUserMutation();
  const [addPersonal] = useAddPersonalMutation();
  const [arbeitstageError, setArbeitstageError] = React.useState(false);

  const fetchBewerber = async () => {
    const { data } = await axios.get(`${url}bewerbers?access_token=${token}`);
    setTableData(data);
  };
  const modifiedData = tableData.map((data) => {
    return {
      ...data,
      eintrittsdatum: new Date(data.eintrittsdatum).toISOString().split("T")[0],
    };
  });

  useEffect(() => {
    fetchBewerber();
  }, [token, url]);

  const handleEdit = (rowData) => {
    const updatedRow = {
      ...rowData,
      bewerberarbeitstage: rowData.bewerberarbeitstage.split(","),
    };

    setEditData(updatedRow);
  };
  const handleDelete = async (id) => {
    await axios.delete(`${url}bewerbers/${id}?access_token=${token}`);
    await fetchBewerber();
  };

  const currentDate = new Date();
  const formattedDate =
    currentDate.getFullYear() +
    "-" +
    (currentDate.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    currentDate.getDate().toString().padStart(2, "0");
  const initialValues = {
    bewerbervorname: "",
    bewerbernachname: "",
    bewerberstr: "",
    bewerberplz: "",
    bewerberort: "",
    bewerbertelefon: "",
    bewerberemail: "",
    bewerbergebdatum: "",
    bewerbergeschlecht: "",
    isbehindert: "",
    behinderungart: "",
    bewerberarbeitstage: [],
    fuhrerscheinklasse: "",
    bewerberarbeitsart: "",
    eintrittsdatum: formattedDate,
    bewerbernotiz: "",
  };

  const validate = Yup.object({
    bewerbervorname: Yup.string().required("Pflichtfeld"),
    bewerbernachname: Yup.string().required("Pflichtfeld"),
    bewerberstr: Yup.string().required("Pflichtfeld"),
    bewerberplz: Yup.string()
      .required("Pflichtfeld")
      .matches(/^[0-9]{4,5}$/, "PLZ muss 4 oder 5 Zahlen haben"),
    bewerberort: Yup.string().required("Pflichtfeld"),
    bewerbertelefon: Yup.string().required("Pflichtfeld"),
    bewerberemail: Yup.string()
      .required("Pflichtfeld")
      .email("Email ist ungültig"),

    bewerbergebdatum: Yup.string()
      .required("Pflichtfeld")
      .test("age", "Bewerber muss mindestens 18 Jahre alt sein", (value) => {
        const today = new Date();
        const birthDate = new Date(value);
        const age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (
          month < 0 ||
          (month === 0 && today.getDate() < birthDate.getDate())
        ) {
          return age - 1 >= 18;
        }
        return age >= 18;
      }),
    bewerbergeschlecht: Yup.string().required("Pflichtfeld"),
    isbehindert: Yup.string().required("Pflichtfeld"),
    bewerberarbeitstage: Yup.array().min(1, "Pflichtfeld"),
    fuhrerscheinklasse: Yup.string().required("Pflichtfeld"),
    bewerberarbeitsart: Yup.string().required("Pflichtfeld"),
    eintrittsdatum: Yup.string()
      .required("Pflichtfeld")
      .test(
        "date",
        "Eintrittsdatum kann nicht in der Vergangenheit liegen",
        (value) => {
          const today = new Date();
          const selectedDate = new Date(value);
          return selectedDate >= today;
        }
      ),
  });

  const onSubmit = async (values, resetForm) => {
    values.behinderungart =
      values.isbehindert === "ja" ? values.behinderungart : "";

    values.bewerberarbeitstage = values.bewerberarbeitstage
      .sort((a, b) => {
        const weekdays = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
        return weekdays.indexOf(a) - weekdays.indexOf(b);
      })
      .join(",");

    if (editData !== null) {
      await axios.put(
        `${url}bewerbers/${values.id}?access_token=${token}`,
        values
      );

      setEditData(null);
      await fetchBewerber();
    } else {
      await axios.post(`${url}bewerbers?access_token=${token}`, values);

      await fetchBewerber();
    }
    resetForm();
  };

  const handleTransfer = async (rowData) => {
    const capitalizeFirstLetter = (str) =>
      str.charAt(0).toUpperCase() + str.slice(1);
    const userFields = {
      name: rowData.bewerbervorname,
      nachname: rowData.bewerbernachname,
      email: rowData.bewerberemail,
      password: "1",
      role: "GAST",
      username: `${capitalizeFirstLetter(
        rowData.bewerbervorname.toLowerCase()
      )}${capitalizeFirstLetter(
        rowData.bewerbernachname.slice(0, 2).toLowerCase()
      )}`,
    };
    const personalFields = {
      personvorname: rowData.bewerbervorname,
      personnachname: rowData.bewerbernachname,
      personstr: rowData.bewerberstr,
      personplz: rowData.bewerberplz,
      mobiltelefon: rowData.bewerbertelefon,
      personort: rowData.bewerberort,
      persongebdatum: rowData.bewerbergebdatum,
      persongeschlecht: rowData.bewerbergeschlecht,
      behindert: rowData.isbehindert,
      eintrittsdatum: rowData.eintrittsdatum,
      beschaftigungsart: rowData.beschaftigungsart,
      wochenarbeitstage: rowData.bewerberarbeitstage,
      personalnotiz: rowData.bewerbernotiz,
      familienstand: "verheiratet",
    };
    try {
      const newUser = await addUser(userFields);
      const newUserId = newUser.data.id;
      await addPersonal({ ...personalFields, userId: newUserId });
      await handleDelete(rowData.id);
      await fetchBewerber();
    } catch (error) {
      console.error("Error during user transfer:", error);
    }
  };

  return (
    <div className="container">
      <BewerberTable
        modifiedData={modifiedData}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleTransfer={handleTransfer}
      />
      <BewerberForm
        editData={editData}
        setEditData={setEditData}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        arbeitstageError={arbeitstageError}
      />
    </div>
  );
};

export default Bewerber;
