import React from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinFill } from "react-icons/ri";
import useAxios from "../../../hooks/useAxios";

const Table = () => {
  // get users from api use hooks use base_url
  const { axiosWithToken } = useAxios();
  const [data, setData] = React.useState([]);
  const [, setLoading] = React.useState(false);
  const [, setError] = React.useState(null);

  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await axiosWithToken.get("/users");
      // format date
      res.data.map((item) => {
        item.dbdatum = new Date(item.dbdatum).toLocaleDateString("tr-TR");
        return item;
      });
      // fahrer name
      setData(res.data);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  }

  // delete user
  const deleteUser = async (id) => {
    try {
      setLoading(true);
      const res = await axiosWithToken.delete(`/users/${id}`);
      // find id  in data then delete then setdata
      const newData = data.filter((item) => item.id !== id);
      setData(newData);
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  }

  React.useEffect(() => {
    getUsers();
  }, []);


  return (
    <div className="card mb-4">
      <div className="card-header text-white bg-primary">Benutzer Übersicht</div>
      <div className="card-body">
        <div className="d-flex flex-wrap gap-2 mb-2">
          <button type="button" className="btn btn-info">
            Copy
          </button>
          <button type="button" className="btn btn-success">
            Excel
          </button>
          <button type="button" className="btn btn-danger">
            PDF
          </button>
          <button type="button" className="btn btn-outline-secondary">
            YAZICI
          </button>
          <button type="button" className="btn btn-outline-secondary">
            Titel
          </button>
          <button type="button" className="btn btn-outline-secondary">
            SearchPanes
          </button>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered table-striped table-hover table-sm">
            <thead className="table-dark">
              <tr>
                <th>Anrede</th>
                <th>Name</th>
                <th>Nachname</th>
                <th>Email</th>
                <th>Loginname</th>
                <th>Passwort</th>
                <th>Kürzel</th>
                <th>Rolle</th>
                <th>Region</th>
                <th><FiEdit /><div className="d-none d-xl-inline"> Edit</div></th>
                <th><RiDeleteBinFill /><div className="d-none d-xl-inline"> Del</div></th>
              </tr>
            </thead>
            <tbody>
            {data.map((record, index) => (
                <tr key={index}>
                  <td>{record.anrede}</td>
                  <td>{record.name}</td>
                  <td>{record.nachname}</td>
                  <td>{record.email}</td>
                  <td>{record.username}</td>
                  <td>{record.password}</td>
                  <td>{record.kurzel}</td>
                  <td>{record.role}</td>
                  <td>{record.region}</td>
                  <td>
                    <button type="button" className="btn btn-sm btn-info">
                      <FiEdit />
                    </button>
                  </td>
                  <td>
                    <button type="button" className="btn btn-sm btn-danger" onClick={() => deleteUser(record.id)}>
                      <RiDeleteBinFill />
                    </button>
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default Table;


/*
sample users json

 {
        "datum": "2023-09-29T15:29:23.000Z",
        "name": "admin",
        "nachname": "nachname",
        "anrede": "herr",
        "image": "image",
        "role": "ADMIN",
        "realm": "realm",
        "username": "username",
        "email": "admin@zone-edv.de",
        "emailVerified": false,
        "id": 1,
        "regionId": 17,
        "region": null
    },
*/