import React from 'react'

const Footer = () => {
  return (
    <footer className="container-fluid bg-dark text-light fixed-bottom">
      <div className="row p-3">
        <div className="col text-center d-flex align-item-center justify-content-center">
          &copy; <span className='me-2'>{new Date().getFullYear()} </span> Logistik. All Rights
          Reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer
