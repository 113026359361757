import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
  

  const logoutUser = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userId');
    window.location.href = '/logout';
  }


  
const baseUrl = process.env.REACT_APP_BASE_URL;
export const regionApi = createApi({
  reducerPath: 'regionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,

  }),
  

  endpoints: (builder) => ({
    getRegions: builder.query({
      query: (token) => `regions?access_token=${token}`,
    }),

    updateRegion: builder.mutation({
      query: ({ id, token, ...patch }) => ({
        url: `regions/${id}?access_token=${token}`,
        method: 'PATCH',
        body: patch,
      }),
      transformErrorResponse: ({error}) => {
        if (error.response.status === 401) {
      // // Örneğin, formdaki verilerin kaydedilmesi
      // saveFormData();
      logoutUser();
    }
      }
     }),

    deleteRegion: builder.mutation({
      query: (body) => ({
        url: `regions/${body.id}?access_token=${body.token}`,
        method: 'DELETE',
      }),
    }),

    createRegion: builder.mutation({
      query: (body) => ({
        url: `regions?access_token=${body.token}`,
        method: 'POST',
        body: body.formData,
      }),
    }),

    getUsersByRegion: builder.query({
      query: (regionId) => `regions/${regionId}/users`,
    }),

    fetchUsers: builder.query({
      query: (token) => `users?filter[fields][regions]=true&access_token=${token}`,
      transformResponse: (response) => {
        const regionsList = [];
        // Durchlaufen Sie die User-Daten und erstellen Sie eine Liste der Regionen
        for (const user of response) {
          if (user.regions) {
            for (const region of user.regions) {
              regionsList.push(region);
            }
          }
        }
        return regionsList;
      },
    }),
  }),
});

export const {
  useGetRegionsQuery,
  useUpdateRegionMutation,
  useDeleteRegionMutation,
  useCreateRegionMutation,
  useFetchUsersQuery,
} = regionApi;
