import React, { useEffect, useState } from "react";
import CarwashTable from "./CarwashTable";
import CarwashForm from "./CarwashForm";
import axios from "axios";
import { useSelector } from "react-redux";

const CarwashMainComponent = () => {
  const [fahrzeugOptions, setFahrzeugOptions] = useState([]);
  const [fahrzeugData, setFahrzeugData] = useState([]);
  const [fahrerOptions, setFahrerOptions] = useState([]);
  const [fahrerData, setFahrerData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const url = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.auth);

  const getCarwashData = async () => {
    const { data } = await axios.get(`${url}autopfleges?access_token=${token}`);

    setTableData(data);
  };

  const modifiedData = tableData.map((data) => {
    return {
      ...data,
      pflegedatum: new Date(data.pflegedatum).toISOString().split("T")[0],
      pflegeart: data.pflegeart ? data.pflegeart.split(",") : [],
      fahrer: fahrerData.find((fahrer) => fahrer.id === data.fahrer)?.username,
      fahrzeugId: fahrzeugData.find(
        (fahrzeug) => fahrzeug.id === data.fahrzeugId
      )?.kfzkennzeichen,
    };
  });

  console.log(modifiedData, "modifiedData");

  const getFahrzeuges = async () => {
    const { data } = await axios.get(`${url}fahrzeuges?access_token=${token}`);
    setFahrzeugData(data);
    const fahrzeuges = data.map((fahrzeug) => ({
      label: fahrzeug.kfzkennzeichen,
      value: fahrzeug.id,
    }));
    setFahrzeugOptions(fahrzeuges);
  };

  const getFahrers = async () => {
    const { data } = await axios.get(`${url}users?access_token=${token}`);
    setFahrerData(data);

    const fahrerUsers = data
      .filter((user) => user.role === "FAHRER")
      .map((fahrer) => ({
        label: fahrer.username,
        value: fahrer.id,
      }));
    setFahrerOptions(fahrerUsers);
  };

  useEffect(() => {
    getFahrzeuges();
    getFahrers();
    getCarwashData();
  }, [token, url]);

  const onSubmit = async (values, resetForm) => {
    values.fahrer = parseInt(values.fahrer);
    values.fahrzeugId = parseInt(values.fahrzeugId);
    values.pflegebetrag = parseInt(values.pflegebetrag);
    values.pflegedatum = new Date(values.pflegedatum).toISOString();

    if (editData) {
      await axios.put(
        `${url}autopfleges/${editData.id}?access_token=${token}`,
        values
      );
      setEditData(null);
    } else {
      const { data } = await axios.post(
        `${url}autopfleges?access_token=${token}`,
        values
      );

      console.log(data, "postedData");
    }
    resetForm();
    await getCarwashData();
  };

  console.log(tableData, "tableData");

  const onEdit = (row) => {
    console.log(row, "row");
    row.fahrer = fahrerData.find(
      (fahrer) => fahrer.username === row.fahrer
    )?.id;
    row.fahrzeugId = fahrzeugData.find(
      (fahrzeug) => fahrzeug.kfzkennzeichen === row.fahrzeugId
    )?.id;
    setEditData(row);
  };

  const onDelete = async (id) => {
    await axios.delete(`${url}autopfleges/${id}?access_token=${token}`);
    await getCarwashData();
  };

  return (
    <div className="container">
      <CarwashTable
        onEdit={onEdit}
        onDelete={onDelete}
        modifiedData={modifiedData}
        fahrerOptions={fahrerOptions}
        fahrzeugOptions={fahrzeugOptions}
        getCarwashData={getCarwashData}
      />
      <CarwashForm
        onSubmit={onSubmit}
        editData={editData}
        setEditData={setEditData}
        fahrerOptions={fahrerOptions}
        fahrzeugOptions={fahrzeugOptions}
      />
    </div>
  );
};

export default CarwashMainComponent;
