import { useSelector } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";
import PersonalForm from "./PersonalForm";
import PersonalTable from "./PersonalTable";
import * as Yup from "yup";
import { formField, formikValidation, initialValue } from "./formElements";
import { set } from "date-fns";

const Personal = () => {
  const url = process.env.REACT_APP_BASE_URL;
  const { token } = useSelector((state) => state.auth);
  const [users, setUsers] = useState([]);
  const [personalData, setPersonalData] = useState([]);
  const [initialValues, setInitialValues] = useState(initialValue);
  const [editData, setEditData] = useState(null);
  const [submittedMsg, setSubmittedMsg] = useState(null);

  const usersOptions = users.map((user) => ({
    label: user.name,
    value: user.id,
  }));

  const formRows = formField(usersOptions);
  const validate = formikValidation(Yup);

  useEffect(() => {
    getUserData();
    getPersonalData();
  }, [token, url]);

  const getUserData = async () => {
    const { data } = await axios.get(`${url}users?access_token=${token}`);
    setUsers(data);
  };

  const getPersonalData = async () => {
    const { data } = await axios.get(
      `${url}personalbogens?access_token=${token}`
    );
    setPersonalData(data);
  };

  const modifiedData = personalData.map((data) => {
    return {
      ...data,
      eintrittsdatum: new Date(data.eintrittsdatum).toISOString().split("T")[0],
      austrittsdatum: data.austrittsdatum
        ? new Date(data.austrittsdatum).toISOString().split("T")[0]
        : null,
      persongebdatum: new Date(data.persongebdatum).toISOString().split("T")[0],
      dbdatum: new Date(data.dbdatum).toISOString().split("T")[0],
      befristungzum: new Date(data.befristungzum).toISOString().split("T")[0],

      wochenarbeitstage: data.wochenarbeitstage.split(","),
    };
  });

  const onDelete = async (id) => {
    try {
      await axios.delete(`${url}personalbogens/${id}?access_token=${token}`);
      await getPersonalData();
    } catch (error) {
      console.error("Error Delete", error);
    }
  };

  const onEdit = (row) => {
    console.log(row.austrittsdatum);
    setEditData(row);
  };

  const onSubmit = async (values, resetForm) => {
    const apiValues = { ...values };
    apiValues.austrittsdatum = apiValues.austrittsdatum
      ? new Date(values.austrittsdatum).toISOString().split("T")[0]
      : null;
    try {
      if (editData && editData.id) {
        const { id, ...rest } = values;
        await axios.put(
          `${url}personalbogens/${editData.id}?access_token=${token}`,
          rest
        );
        setSubmittedMsg("Data Updated Successfully");
        setTimeout(() => {
          setSubmittedMsg(null);
        }, 3000);
      } else {
        await axios.post(
          `${url}personalbogens?access_token=${token}`,
          apiValues
        );
        setSubmittedMsg("Data Submitted Successfully");
        setTimeout(() => {
          setSubmittedMsg(null);
        }, 3000);
      }
      setEditData(null);
      await getPersonalData();
      resetForm();
      fillFormData(null);
    } catch (error) {
      console.error("Error Submit", error);
    }
  };

  const fillFormData = (userId) => {
    const userData = personalData.find(
      (data) => data.userId === parseInt(userId)
    );

    if (userData) {
      const changedValues = {
        ...userData,
        eintrittsdatum: new Date(userData.eintrittsdatum)
          .toISOString()
          .split("T")[0],
        austrittsdatum: new Date(userData.austrittsdatum)
          .toISOString()
          .split("T")[0],
        persongebdatum: new Date(userData.persongebdatum)
          .toISOString()
          .split("T")[0],
        dbdatum: new Date(userData.dbdatum).toISOString().split("T")[0],
        befristungzum: new Date(userData.befristungzum)
          .toISOString()
          .split("T")[0],
        wochenarbeitstage: userData.wochenarbeitstage.split(","),
      };

      setInitialValues(changedValues);
      setEditData(changedValues);
    } else {
      setInitialValues(initialValue);
    }
  };

  return (
    <div className="container">
      <PersonalTable
        onDelete={onDelete}
        onEdit={onEdit}
        modifiedData={modifiedData}
      />
      <PersonalForm
        editData={editData}
        setEditData={setEditData}
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        formRows={formRows}
        fillFormData={fillFormData}
        submittedMsg={submittedMsg}
      />
    </div>
  );
};

export default Personal;
