import React from "react";
import ReklamationForm from "./reklamationForm/ReklamationForm";
import ReklamationTable from "./reklamationTable/ReklamationTable";
import { useSelector } from "react-redux";
import {
  useGetKundenQuery,
  useGetReklamationsQuery,
} from "./services/reklamationApi";
import { number, object, string } from "yup";
import { useFormik } from "formik";
import {
  useGetFahrerQuery,
  useGetToursQuery,
} from "../leistung/core/leistungApi";
import { useGetRmaQuery } from "../../app/services/rmaAuswahl/rmaAuswahlApi";
import axios from "axios";
import { toastSuccessNotify } from "../../helper/ToastNotify";
import moment from "moment-timezone";

const Reklamation = () => {
  const { token, userRole, currentUser } = useSelector((state) => state.auth);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const url = `${BASE_URL}reklamations?access_token=${token}`;

  console.log("token: " + token);
  console.log("userRole: " + userRole);
  console.log("currentUser: " + currentUser);

  const {
    data: reklamationData,
    isLoading,
    error,
    refetch,
  } = useGetReklamationsQuery({ token, userRole, userId: currentUser });
  const { data: fahrerData } = useGetFahrerQuery({
    token,
    userRole,
    userId: currentUser,
  });
  const { data: tourData } = useGetToursQuery(token);
  const { data: kundenData } = useGetKundenQuery(token);
  const { data: rmaData } = useGetRmaQuery(token);
  // const [createReklamation] = useCreateReklamationMutation();
  // const [updateReklamation] = useUpdateReklamationMutation();
  // const [deleteReklamation] = useDeleteReklamationMutation();

  console.log("reklamationData", reklamationData);
  console.log("FahrerData", fahrerData);
  // console.log("rmaData", rmaData);
  // console.log("tourData", tourData);
  console.log("kundenData", kundenData);

  const initialValues = {
    dbdatum: new Date().toISOString().slice(0, 10),
    rmadatum: new Date().toISOString().slice(0, 10),
    rmauhrzeit: moment().tz("Europe/Berlin").format("HH:mm"),
    rmakundennummer: "",
    rmakundenname: "",
    rmakundenstr: "",
    rmaauswahl: "",
    rmanotiz: "",
    rmanotiz2: "",
    tourId: 0,
    status: "neue",
    fahrer: 0,
  };

  const reklamationSchema = object({
    dbdatum: string().required("Datum ist erforderlich"),
    rmadatum: string().required("RMADatum ist erforderlich"),
    rmauhrzeit: string().required("Uhrzeit ist erforderlich"),
    rmakundennummer: string(),
    rmakundenname: string(),
    rmakundenstr: string(),
    rmaauswahl: string().required("RMAAuswahl ist erforderlich"),
    rmanotiz: string().required("RMANotiz ist erforderlich"),
    rmanotiz2: string(),
    tourId: number(),
    status: string(),
    fahrer: number(),
  });

  const formik = useFormik({
    initialValues,
    reklamationSchema,
    onSubmit: async (values) => {
      console.log(`${token}`);
      try {
        let response;

        let apiUrl = BASE_URL;
        if (userRole === "FAHRER") {
          apiUrl += `users/${currentUser}/reklamations`;
        } else {
          apiUrl += `reklamations`;
        }
        apiUrl += `?access_token=${token}`;

        if (values.id) {
          let fullUrl =
            userRole === "FAHRER"
              ? `${apiUrl}/${values.id}`
              : `${apiUrl}/${values.id}`; // Adjust if needed for FAHRER
          response = await axios.put(fullUrl, values);
          toastSuccessNotify("Erfolgreich aktualisiert");
        } else {
          // If 'id' doesn't exist, it means it's a create operation
          const isUnique = !reklamationData.some(
            (reklamation) => reklamation.id === values.id
          );

          if (isUnique) {
            response = await axios.post(apiUrl, values);
            toastSuccessNotify("Erfolgreich gesendet");
          } else {
            console.error("Duplicate Data");
            return;
          }
        }

        // await createLeistung(values);
        // console.log(res.data);
        await refetch();
        console.log("Reklamation eklendi:", values);
        formik.resetForm();
        console.log(formik.leistungData);
      } catch (error) {
        console.error("Error in addReklamation:", error);
      }
    },
    validate: (values) => {
      const errors = {};

      // "Start km" ve "End km" değerlerini kontrol et
      if (!values.dbdatum) {
        errors.dbdatum = "Datum ist erforderlich";
      } else if (!values.rmadatum) {
        errors.rmadatum = "RMADatum ist erforderlich";
      } else if (!values.rmauhrzeit) {
        errors.rmauhrzeit = "RMAUhrzeit ist erforderlich";
      } else if (!values.rmaauswahl) {
        errors.rmaauswahl = "RMAAuswahl ist erforderlich";
      } else if (!values.rmanotiz) {
        errors.rmanotiz = "RMANotiz ist erforderlich";
      }

      return errors;
    },
  });

  const handleChange = (e) => {
    formik.handleChange(e);
  };

  const onDelete = async (id) => {
    try {
      // await deleteReklamation(id);
      await axios.delete(`${BASE_URL}reklamations/${id}?access_token=${token}`);
      toastSuccessNotify("Erfolgreich gelöscht!");
      await refetch();
    } catch (error) {
      console.error("Error Delete", error);
    }
  };

  const onEdit = (leistung) => {
    // Düzenlenecek öğenin tüm verilerini ve önemlisi ID'yi formik değerlerine ata
    formik.setValues({ ...leistung });
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>ERROR{error.message}</p>;
  }

  return (
    <div className="container-fluid">
      <ReklamationTable
        reklamationData={reklamationData}
        fahrerData={fahrerData}
        tourData={tourData}
        kundenData={kundenData}
        rmaData={rmaData}
        onDelete={onDelete}
        onEdit={onEdit}
      />
      <ReklamationForm
        formik={formik}
        handleChange={handleChange}
        fahrerData={fahrerData}
        tourData={tourData}
        kundenData={kundenData}
        rmaData={rmaData}
        userRole={userRole}
      />
    </div>
  );
};

export default Reklamation;
