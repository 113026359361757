import { Route, Routes } from "react-router-dom";
import { AuthRouter } from "./AuthRouter";
import { PrivateRouter } from "./PrivateRouter";
import { GastRouter } from "./GastRouter";
import { AdminRouter } from "./AdminRouter";

import Abrechnung from "../pages/adminPanel/Abrechnung";
import AdminDashboard from "../pages/adminPanel/AdminDashboard";
import Ausgaben from "../pages/adminPanel/Ausgaben";
import Benutzer from "../pages/adminPanel/Benutzer";
import Dokumantation from "../pages/Dokumantation";
import FirmenInformation from "../pages/FirmenInformation";
import Home from "../pages/Home";
import Kunden from "../pages/adminPanel/kunden/Kunden";
import Leistung from "../pages/leistung/Leistung";
import Upload from "../pages/upload/Upload";
import Location from "../pages/Location";
import Partner from "../pages/adminPanel/Partner";
import Personal from "../pages/adminPanel/personal/Personal";
import Fahrzeug from "../pages/adminPanel/fahrzeug/Fahrzeug";
import FahrzeugsInspektion from "../pages/adminPanel/fahrzeugInspektion/FahrzeugsInspektion";
import Reklamation from "../pages/reklamation/Reklamation";
import Region from "../pages/region/Region";
import Telefon from "../pages/adminPanel/telefon/Telefon";
import TourPlan from "../pages/TourPlan";
import TourPlanen from "../pages/adminPanel/tourPlannen/TourPlanen";
import Werkstatt from "../pages/adminPanel/werkstatt/Werkstatt";
import Footer from "../components/Footer";
import Login from "../pages/Login";
import FirmenInfoConfirmation from "../pages/FirmenInfoConfirmation";
import TagesTourModal from "../pages/tages-tour/TagesTourModal";
import TagesTourAnsicht from "../pages/tages-tour/TagesTourAnsicht";
import TagesTourDetails from "../pages/tages-tour/TagesTourDetails";
import Bewerbungsbogen from "../pages/bewerbungsbogen/Bewerbungsbogen";
import Tuv from "../components/TUV-UVV/Tuv";
import Bewerber from "../pages/adminPanel/bewerber/Bewerber";
import PersonalBogen from "../pages/adminPanel/bewerber/personalbogenone/PersonalBogen";
import ZertifikatHome from "../pages/adminPanel/zertifikat/ZertifikatHome";
import TerminPlaner from "../pages/terminplaner/TerminPlaner";

import ReifenWechselnKm from "../pages/adminPanel/fahrzeugReifenKontrolle/ReifenWechselnKm";

import Touren from "../pages/Touren/Touren";
import NotFound from "../pages/notfound/NotFound";
import UnAuthorized from "../pages/notfound/UnAuthorized";
import Erledigt from "../pages/erledigte/Erledigt";
import Logs from "../pages/adminPanel/logs/Logs";
import CarwashMainComponent from "../pages/carwash/CarwashMainComponent";

const AppRouter = () => {

  return (
    <>
      <Routes>
        <Route element={<AuthRouter />}>
          <Route path="/login" element={<Login />} />
          <Route path="bewerbungsbogen" element={<Bewerbungsbogen />} />
        </Route>

        <Route element={<PrivateRouter />}>
          <Route index element={<Home />} />
          

          {/* ADMIN  START*/}
          <Route element={<AdminRouter />}>
            <Route path="adminDashboard" element={<AdminDashboard />} />
            {/* <Route path="tourplanen" element={<TourPlanen/>} /> */}
            <Route path="tourplanen" element={<TourPlanen />} />
            <Route path="bewerber" element={<Bewerber />} />
            <Route path="benutzer" element={<Benutzer />} />
            <Route path="fahrzeug" element={<Fahrzeug />} />
            <Route path="upload" element={<Upload />} />
            <Route path="abrechnung" element={<Abrechnung />} />
            <Route path="ausgaben" element={<Ausgaben />} />
            <Route path="kunden" element={<Kunden />} />
            <Route path="partner" element={<Partner />} />
            <Route path="personal" element={<Personal />} />
            <Route path="telefon" element={<Telefon />} />
            <Route path="touren" element={<Touren />} />
            <Route path="werkstatt" element={<Werkstatt />} />
            <Route path="zertifikat" element={<ZertifikatHome />} />
            <Route path="tuv" element={<Tuv />} />
            <Route path="region" element={<Region />} />
            <Route path="firmenInformation" element={<FirmenInformation />} />
            <Route path="tourplan" element={<TourPlan />} />
            <Route path="dokumantation" element={<Dokumantation />} />
            <Route
              path="firmenInfoConfirmation"
              element={<FirmenInfoConfirmation />}
            />
          </Route>
          {/* ADMIN  END */}

          <Route path="tuv" element={<Tuv />} />
          <Route path="terminplaner/:userId" element={<TerminPlaner />} />
          <Route path="tagestourmodal/:userId" element={<TagesTourModal />} />
          <Route
            path="tagestouransicht/:userId"
            element={<TagesTourAnsicht />}
          />
          <Route path="tagestourdetails" element={<TagesTourDetails />} />
          <Route path="bewerber" element={<Bewerber />} />

          <Route path="region" element={<Region />} />

          <Route path="location" element={<Location />} />
          <Route path="leistung" element={<Leistung />} />
          <Route path="erledigte" element={<Erledigt />} />
          <Route path="reklamation" element={<Reklamation />} />
          <Route path="carwash" element={<CarwashMainComponent />} />

          <Route path="firmenInformation" element={<FirmenInformation />} />
          <Route
            path="firmenInfoConfirmation"
            element={<FirmenInfoConfirmation />}
          />
          <Route path="upload" element={<Upload />} />
          <Route path="adminDashboard" element={<AdminDashboard />}>
            <Route path="" element={<FahrzeugsInspektion />} />
            <Route path="" element={<ReifenWechselnKm />} />
          </Route>
          <Route path="abrechnung" element={<Abrechnung />} />
          <Route path="ausgaben" element={<Ausgaben />} />
          <Route path="benutzer" element={<Benutzer />} />
          <Route path="fahrzeug" element={<Fahrzeug />} />
          {/* <Route path="fahrzeugInspektion" element={<FahrzeugsInspektion />} /> */}
          <Route path="kunden" element={<Kunden />} />
          <Route path="partner" element={<Partner />} />
          <Route path="personal" element={<Personal />} />
          <Route path="telefon" element={<Telefon />} />
          <Route path="touren" element={<Touren />} />
          <Route path="werkstatt" element={<Werkstatt />} />
          <Route path="terminplaner/:id" element={<TerminPlaner />} />
          <Route path="logs" element={<Logs />} />
        </Route>

        <Route element={<GastRouter />}>
          <Route path="personalbogen" element={<PersonalBogen />} />
        </Route>

        <Route path="*" element={<NotFound />} />
        <Route path="unauthorized" element={<UnAuthorized />} />
      </Routes>
      <Footer />
    </>
  );
};

export default AppRouter;
