import React, { useEffect, useState } from "react";
import folderLogo from "../assets/folderLogo.png";
import { IoSettingsOutline } from "react-icons/io5";
import { BsInfoCircle } from "react-icons/bs";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { toastErrorNotify, toastSuccessNotify } from "../helper/ToastNotify";

const FirmenInformation = () => {
  const [logo, setLogo] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();
  // Retrieve state from location
  const location = useLocation();
  const { state } = location;

  const [formData, setFormData] = useState({
    firmenname: "",
    firmeninhaber: "",
    firmenemail: "",
    firmentelefon: 0,
    firmenhandy: 0,
    firmenweb: "",
    firmenfax: 0,
    firmenstr: "",
    firmenplz: 0,
    firmenort: "",
    firmenbankname: "",
    firmeniban: "",
    firmenbic: "",
    firmenust: "",
    firmenustid: "",
    firmenfinanzamt: "",
    firmeneori: "",
    firmenlogo: "",
  });

  // Use state to pre-fill form data
  useEffect(() => {
    const fetchData = async () => {
      const url = "https://dev.zone-edv.de/api/firmendatens";

      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          const data = response.data[response.data.length - 1]; // Assuming your API response is an object containing form data
          setFormData(data);
          setLogo(data.firmenlogo || null); // Set logo from the API response
        } else {
          console.error("Failed to fetch data:", response.statusText);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };

    if (state && state.formData) {
      setFormData(state.formData);
      setLogo(state.logo);
    } else {
      // Fetch data only if state.formData is not available
      fetchData();
    }
  }, [state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const file = e.target.files ? e.target.files[0] : null;

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Check file size (max 300KB)
        if (file.size <= 300 * 1024) {
          setLogo(reader.result);
        } else {
          alert("Logo size exceeds the limit of 300KB.");
        }
      };
      reader.readAsDataURL(file);
    }

    // Handle firmenname length
    if (name === "firmenname" && value.length > 100) {
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the form has already been submitted
    if (formSubmitted) {
      console.log("Form has already been submitted.");
      return;
    }

    console.log("Updated data:", formData);

    try {
      // Check if formData is not empty (PUT request)
      if (Object.keys(formData).length > 0) {
        const response = await axios.put(
          "https://dev.zone-edv.de/api/firmendatens", // Replace with your update endpoint
          formData
        );

        // Check if the request was successful (status code 2xx)
        if (response.status === 200) {
          console.log("Form updated successfully!");
          setFormSubmitted(true);
          toastSuccessNotify("Formular erfolgreich aktualisiert!");
          navigate("/firmenInfoConfirmation");
        } else {
          console.error("Form update failed:", response.statusText);
        }
      } else {
        // formData is empty, so it's a POST request
        const response = await axios.post(
          "https://dev.zone-edv.de/api/firmendatens",
          formData
        );

        // Check if the request was successful (status code 2xx)
        if (response.status === 200) {
          console.log("Form submitted successfully!");
          setFormSubmitted(true);
          toastSuccessNotify("Formular erfolgreich eingereicht!");
          navigate("/mockeingelogt/firmenInfoConfirmation");
        } else {
          console.error("Form submission failed:", response.statusText);
        }
      }
    } catch (error) {
      toastErrorNotify("Entgültige Daten");
      console.error("An error occurred during form submission:", error);
    }
  };

  const handleClearFields = () => {
    setFormData({
      firmenname: "",
      firmeninhaber: "",
      firmenemail: "",
      firmentelefon: 0,
      firmenhandy: 0,
      firmenweb: "",
      firmenfax: 0,
      firmenstr: "",
      firmenplz: 0,
      firmenort: "",
      firmenbankname: "",
      firmeniban: "",
      firmenbic: "",
      firmenust: "",
      firmenustid: "",
      firmenfinanzamt: "",
      firmeneori: "",
      firmenlogo: "",
    });
    setLogo(null); // Clear the logo as well
  };

  return (
    <div className="row mx-2 rounded-2 overflow-hidden ">
      <div className="bg-dark d-flex justify-content-between align-items-center px-3 py-2 rounded-2 shadow-lg mb-2">
        <div className="d-flex align-items-center gap-2">
          <IoSettingsOutline size={24} className="text-white cursor-pointer" />
          <span className="fs-4 text-info fw-semibold">Einstellungen</span>
          <BsInfoCircle className="text-white cursor-pointer" />
        </div>
        <div className="d-flex flex-column flex-md-row align-items-center gap-0 gap-md-3">
          <Link
            onClick={handleClearFields}
            className="font-semibold d-flex align-items-center gap-1 text-white"
          >
            <MdKeyboardDoubleArrowLeft />
            <span>Zurück</span>
          </Link>
          <Link to="firmenInfoConfirmation">
            <button
              type="submit"
              onClick={handleSubmit}
              className="btn btn-warning my-2 font-semibold ml-2 text-danger fw-semibold"
            >
              Speichern
            </button>
          </Link>
        </div>
      </div>
      <form className="border rounded-2" onSubmit={handleSubmit}>
        <div className="row px-4 py-4">
          <div className="col-sm-12 col-md-6">
            <h1 className="bg-primary ml-2 font-semibold fs-5 p-2 rounded-2 text-white">
              FirmenInformationen
            </h1>
            <div className="">
              <div className="col mb-4 p-2">
                <div className="col mb-3">
                  <div className="form-outline">
                    <label className="form-label mb-0" htmlFor="firmenname">
                      Firmenname <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="firmenname"
                      name="firmenname"
                      placeholder="Firmenname eingeben (max. 100 Zeichen)"
                      className="form-control"
                      value={formData.firmenname}
                      onChange={handleChange}
                      maxLength={100} // Add maxLength attribute
                      required
                    />
                  </div>
                </div>
                <div className="col mb-3">
                  <div className="form-outline">
                    <label className="form-label mb-0" htmlFor="firmeninhaber">
                      Inhaber <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="firmeninhaber"
                      name="firmeninhaber"
                      placeholder="Firmeninhaber eingeben"
                      className="form-control"
                      value={formData.firmeninhaber}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col mb-3">
                  <div className="form-outline">
                    <label className="form-label mb-0" htmlFor="firmenemail">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      id="firmenemail"
                      name="firmenemail"
                      placeholder="beispiel@gmail.com"
                      className="form-control"
                      value={formData.firmenemail}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-outline col-md-4 mb-3">
                    <label className="form-label mb-0" htmlFor="firmentelefon">
                      Phone <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="firmentelefon"
                      name="firmentelefon"
                      placeholder=""
                      className="form-control"
                      value={formData.firmentelefon}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-outline col-md-4 mb-3">
                    <label className="form-label mb-0" htmlFor="firmenfax">
                      Fax
                    </label>
                    <input
                      type="number"
                      id="firmenfax"
                      name="firmenfax"
                      className="form-control"
                      value={formData.firmenfax}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-outline col-md-4 mb-3">
                    <label className="form-label mb-0" htmlFor="firmenhandy">
                      Handy
                    </label>
                    <input
                      type="number"
                      id="firmenhandy"
                      name="firmenhandy"
                      className="form-control"
                      value={formData.firmenhandy}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col mb-3">
                  <div className="form-outline">
                    <label className="form-label mb-0" htmlFor="firmenweb">
                      Web
                    </label>
                    <input
                      type="url"
                      id="firmenweb"
                      name="firmenweb"
                      placeholder="www.firmenweb.com"
                      className="form-control"
                      value={formData.firmenweb}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col mb-3">
                  <div className="form-outline">
                    <label className="form-label mb-0" htmlFor="firmenstr">
                      Straße und Hausnummer{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="firmenstr"
                      name="firmenstr"
                      placeholder="Straße und Hausnummer eingeben"
                      className="form-control"
                      value={formData.firmenstr}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-outline col-md-4 mb-3">
                    <label className="form-label mb-0" htmlFor="firmenplz">
                      PLZ <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      id="firmenplz"
                      name="firmenplz"
                      className="form-control"
                      value={formData.firmenplz}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-outline col-md-4 mb-3">
                    <label className="form-label mb-0" htmlFor="firmenort">
                      Ort <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="firmenort"
                      name="firmenort"
                      placeholder="Firmenort eingeben"
                      className="form-control"
                      value={formData.firmenort}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6">
            <h1 className="bg-primary ml-2 font-semibold fs-5 p-2 rounded-2 text-white">
              Bankverbindung & SteuerInformationen
            </h1>
            <div className="p-2">
              <div className="form-outline mb-3">
                <label className="form-label mb-0" htmlFor="firmenbankname">
                  Bank <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="firmenbankname"
                  name="firmenbankname"
                  placeholder="Bankname eingeben"
                  className="form-control"
                  value={formData.firmenbankname}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-outline mb-1">
                <label className="form-label mb-0" htmlFor="firmeniban">
                  IBAN <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="firmeniban"
                  name="firmeniban"
                  placeholder="IBAN eingeben"
                  className="form-control"
                  value={formData.firmeniban}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-outline mb-3">
                <label className="form-label mb-0" htmlFor="firmenbic">
                  BIC <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="firmenbic"
                  name="firmenbic"
                  placeholder="BIC eingeben"
                  className="form-control"
                  value={formData.firmenbic}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="p-2">
              <div className="col mb-3">
                <div className="form-outline">
                  <label className="form-label mb-0" htmlFor="firmenust">
                    USt.-Nummer <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="firmenust"
                    name="firmenust"
                    placeholder="USt.-Nummer eingeben"
                    className="form-control"
                    value={formData.firmenust}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col mb-3">
                <div className="form-outline">
                  <label className="form-label mb-0" htmlFor="firmenustid">
                    USt-IdentNummer <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="firmenustid"
                    name="firmenustid"
                    placeholder="USt.-IdNummer eingeben"
                    className="form-control"
                    value={formData.firmenustid}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col mb-3">
                <div className="form-outline">
                  <label className="form-label mb-0" htmlFor="firmenfinanzamt">
                    Finanzamt <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="firmenfinanzamt"
                    name="firmenfinanzamt"
                    placeholder="Finanzamt eingeben"
                    className="form-control"
                    value={formData.firmenfinanzamt}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="col mb-3">
                <div className="form-outline">
                  <label className="form-label mb-0" htmlFor="firmeneori">
                    Steuernummer <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="firmeneori"
                    name="firmeneori"
                    placeholder="Steuernummer eingeben"
                    className="form-control"
                    value={formData.firmeneori}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="p-2">
              <label className="block text-sm fw-semibold" htmlFor="firmenlogo">
                LOGO
              </label>
              <div
                className="mt-1 d-flex flex-column align-content-center justify-content-center px-6 py-3 rounded-md"
                style={{ border: "2px dashed #000" }}
              >
                <div className="text-center">
                  <img
                    className="mx-auto"
                    width={50}
                    src={logo || folderLogo}
                    alt="Logo Preview"
                  />
                  <div className="d-flex flex-column justify-content-center">
                    <div>
                      <span className="text-warning me-1">
                        Eine Datei hochladen
                      </span>
                      <input
                        type="file"
                        id="firmenlogo"
                        name="firmenlogo"
                        className="sr-only"
                        // value={formData.firmenlogo}
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <span className="">oder per Drag & Drop </span>
                      <span className="text-xs">
                        ({" "}
                        <span className="text-danger">
                          PNG, JPG, GIF bis zu 300 KB
                        </span>{" "}
                        )
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FirmenInformation;
